import React, {useEffect, useState} from 'react'
import AgenciesCompanyForm, {AgencyFormDataProps} from "../../forms/agencies-company-form/AgenciesCompanyForm";
import {CreateAgencyRequest} from "../../../api/model/agency";
import {FormPropsAgency} from "../../settings/agencies-company/agencies-company-data-grid/AgenciesCompanyDataGrid";
import {CheckboxInput, IconAndText, Text, TextButton, TextInput} from 'atmosfair-ui'
import {validateEmail, validateFirstAndLastName} from "../../forms/utils";
import {UserProps, UserToInviteProps} from "../../../api/model/user";
import './styles.css'
import cloneDeep from "lodash/cloneDeep";
import LogoutButton from "../../../molecules/logout-button/LogoutButton";
import {theme} from "../../../styles/theme";
import StringPair from "../../../molecules/string-pair/StringPair";
import {useTranslation} from "react-i18next";
import RegistrationWrapper from "../registration-wrapper/RegistrationWrapper";
import clsx from "clsx";
import {filterUndefinedOrNull, isNotUndefinedOrNull, someStringsNonEmpty} from "../../../utils/misc";
import {VALIDATE_FORM_STARTING_NUMBER} from "../../../constants";

export interface CreateInitialAgencyAndInviteUsersProps {
    agencyAdmin: UserProps,
    onSkip: () => void,
    onBack: () => void,
    onCreateAgencyInviteUsers: (users: UserToInviteProps[], agency: CreateAgencyRequest) => void,
}

interface NewUserProps {
    firstName: string | null | undefined,
    lastName: string | null | undefined,
    email: string | null | undefined,
    isAdmin: boolean
}


interface NewUserError {
    firstName: boolean,
    lastName: boolean,
    email: boolean,
}

const NEW_USER: NewUserProps = {
    firstName: "",
    lastName: "",
    email: "",
    isAdmin: false
}

const NEW_USER_ERROR: NewUserError = {
    firstName: false,
    lastName: false,
    email: false,
}

export interface CreateInitialAgencyAndInviteUsersFormProps {
    inviteFirstContact: boolean,
    inviteSecondContact: boolean
}

const FIRST_CONTACT_PERSON_INDEX = 0
const SECOND_CONTACT_PERSON_INDEX = 1
const MARGIN_TOP = 11

const CreateInitialAgencyAndInviteUsers = ({
                                               agencyAdmin,
                                               onSkip,
                                               onBack,
                                               onCreateAgencyInviteUsers
                                           }: CreateInitialAgencyAndInviteUsersProps) => {
    const [users, setUsers] = useState([NEW_USER])
    const [usersValid, setUsersValid] = useState(false)
    const [userErrors, setUserErrors] = useState([NEW_USER_ERROR])
    const [formData, setFormData] = useState<CreateInitialAgencyAndInviteUsersFormProps>({
        inviteFirstContact: false,
        inviteSecondContact: false
    })
    const [agencyFormData, setAgencyFormData] = useState<AgencyFormDataProps | null>(null)
    const [validateForm, setValidateForm] = useState(VALIDATE_FORM_STARTING_NUMBER)
    const {t} = useTranslation()
    const {inviteFirstContact, inviteSecondContact} = formData

    const _updateFormData = <K extends keyof FormPropsAgency>(key: K, payload: FormPropsAgency[K]) => setFormData({
        ...formData,
        [key]: payload
    })

    useEffect(() => {
        if (agencyFormData && usersValid) {
            const {language} = agencyAdmin
            const usersWithLanguage: UserToInviteProps[] = users
                .filter(user => {
                    const {firstName, lastName, email} = user;
                    return firstName && lastName && email;
                })
                .map(user => ({
                    ...user,
                    language,
                }) as UserToInviteProps);
            if (agencyFormData) {
                const {
                    agencyCompanyData,
                    phoneData,
                    addressData,
                    firstContactPersonData,
                    secondContactPersonData
                } = agencyFormData
                const {
                    agencyCompanyName,
                    agencyCompanyEmail,
                } = agencyCompanyData
                const {
                    street,
                    houseNumber,
                    zipCode,
                    city,
                    country,
                    additionalAddressInformation,
                } = addressData
                const {
                    phoneNumber,
                    internationalPhonePrefix
                } = phoneData
                onCreateAgencyInviteUsers(usersWithLanguage, {
                    name: agencyCompanyName,
                    address: {
                        street,
                        houseNumber,
                        zipCode,
                        city,
                        country: country!!,
                        additionalInformation: additionalAddressInformation,
                        phoneNumber,
                        internationalPhonePrefix: internationalPhonePrefix!!,
                    },
                    generalEmail: agencyCompanyEmail,
                    firstContactFirstName: firstContactPersonData.firstName,
                    firstContactLastName: firstContactPersonData.lastName,
                    firstContactEmail: firstContactPersonData.email,
                    secondContactFirstName: secondContactPersonData.firstName,
                    secondContactLastName: secondContactPersonData.lastName,
                    secondContactEmail: secondContactPersonData.email
                })
            }
        }
    }, [usersValid, agencyFormData]);

    const _validateUsers = () => {
        const updatedUserError: NewUserError[] = []
        users.map(user => {
            const {firstName, lastName, email} = user
            const userInformation = [firstName, lastName, email]
            if (isNotUndefinedOrNull(userInformation)) {
                if (someStringsNonEmpty(filterUndefinedOrNull(userInformation))) {
                    updatedUserError.push({
                        firstName: !validateFirstAndLastName(firstName),
                        lastName: !validateFirstAndLastName(lastName),
                        email: !validateEmail(email)
                    })
                } else {
                    updatedUserError.push(NEW_USER_ERROR)
                }
            } else {
                updatedUserError.push(NEW_USER_ERROR)
            }
        })
        setUserErrors(updatedUserError)
        if (!updatedUserError.some(updatedUserError => {
            const {firstName, lastName, email} = updatedUserError
            return firstName || lastName || email
        })) setUsersValid(true)
    }

    const _addElementToErrorState = () => {
        const updatedUserErrors = cloneDeep(userErrors)
        updatedUserErrors.push(NEW_USER_ERROR)
        setUserErrors(updatedUserErrors)
    }

    const _removeElementFromErrorState = (index: number) => {
        const updatedUserErrors = cloneDeep(userErrors)
        updatedUserErrors.splice(index, 1)
        setUserErrors(updatedUserErrors)
    }

    const _addUser = () => {
        const updatedUsers = cloneDeep(users)
        updatedUsers.push(NEW_USER)
        setUsers(updatedUsers)
        _addElementToErrorState()
    }

    const _removeUser = (index: number, whichContact?: "first" | "second") => {
        const updatedUsers = cloneDeep(users)
        updatedUsers.splice(index, 1)
        _removeElementFromErrorState(index)
        setUsers(updatedUsers)
    }

    const _updateUser = (index: number, value: string, key: keyof NewUserProps) => {
        const updatedUsers = cloneDeep(users) as NewUserProps[]
        if (updatedUsers[index]) {
            //@ts-ignore
            updatedUsers[index][key] = value
            setUsers(updatedUsers)
        }
    }

    const _onChangeAdmin = (index: number) => {
        const updatedUsers = cloneDeep(users)
        if (updatedUsers[index]) {
            updatedUsers[index].isAdmin = !updatedUsers[index].isAdmin
            setUsers(updatedUsers)
        }
    }

    const _inviteContactPerson = <K extends keyof FormPropsAgency>(
        key: K,
        payload: FormPropsAgency[K],
        firstName: string,
        lastName: string,
        email: string,
    ) => {
        _updateFormData(key, payload)
        if (payload) {
            const updatedUsers = cloneDeep(users)
            const newUser = {
                firstName,
                lastName,
                email,
                isAdmin: false,
            }
            if (key === "inviteFirstContact") updatedUsers.splice(FIRST_CONTACT_PERSON_INDEX, 0, newUser)
            if (key === "inviteSecondContact") {
                if (users.length === 1) {
                    updatedUsers.unshift(newUser)
                } else {
                    updatedUsers.splice(SECOND_CONTACT_PERSON_INDEX, 0, newUser)
                }
            }
            setUsers(updatedUsers)
            _addElementToErrorState()
        } else {
            if (key === "inviteFirstContact") _removeUser(FIRST_CONTACT_PERSON_INDEX, "first")
            if (key === "inviteSecondContact") _removeUser(SECOND_CONTACT_PERSON_INDEX, "second")
            _updateFormData(key, payload)
        }
    }

    const _onChangeContactPerson = <K extends keyof FormPropsAgency>(key: K, payload: FormPropsAgency[K]) => {
        if (typeof payload === "string") {
            const updatedUsers = cloneDeep(users)
            let localUserKey: keyof NewUserProps | null = null
            if (key === "firstContactFirstName" || key === "secondContactFirstName") localUserKey = "firstName"
            if (key === "firstContactLastName" || key === "secondContactLastName") localUserKey = "lastName"
            if (key === "firstContactEmail" || key === "secondContactEmail") localUserKey = "email"
            if (localUserKey) {
                if (_firstOrSecondContactPerson(key) === "first" && inviteFirstContact) updatedUsers[FIRST_CONTACT_PERSON_INDEX][localUserKey] = payload
                if (_firstOrSecondContactPerson(key) === "second" && inviteSecondContact) updatedUsers[SECOND_CONTACT_PERSON_INDEX][localUserKey] = payload
                setUsers(updatedUsers)
            }
        }
    }

    const _isContactPersonUser = (index: number) => {
        if (users.length === 2) return (index === FIRST_CONTACT_PERSON_INDEX && inviteFirstContact) || index === 0 && inviteSecondContact
        return (index === FIRST_CONTACT_PERSON_INDEX && inviteFirstContact) || (index === SECOND_CONTACT_PERSON_INDEX && inviteSecondContact)
    }

    const _firstOrSecondContactPerson = (input: string): "first" | "second" | null => {
        const firstWord = input.match(/^[a-z]+/);
        if (firstWord) {
            if (firstWord[0] === "first") {
                return "first"
            } else if (firstWord[0] === "second") {
                return "second"
            }
        }
        return null
    }

    const _proceed = () => {
        _validateUsers()
        setValidateForm(validateForm + 1)
    }

    return <RegistrationWrapper title={"SCREENS.REGISTRATION.AGENCY_USER_TITLE"}>
        <div className={'create-initial-agency-and-invite-users-container'}>
            <LogoutButton/>
            <AgenciesCompanyForm
                showMakeAdminCheckboxes={false}
                allUsers={[agencyAdmin]}
                isEdit={false}
                whichType={'agencies'}
                validateForm={validateForm}
                onShowSecondContact={value => {
                    if (inviteSecondContact && !value) _removeUser(SECOND_CONTACT_PERSON_INDEX, "second")
                    _updateFormData("inviteSecondContact", false)
                }}
                onChangeContact={_onChangeContactPerson}
                setInviteContact={_inviteContactPerson}
                onFormIsValid={(firstContactPersonData, secondContactPersonData, addressData, phoneData, agencyCompanyData) => {

                    setAgencyFormData({
                        firstContactPersonData,
                        secondContactPersonData,
                        addressData,
                        phoneData,
                        agencyCompanyData
                    })
                }}/>
            <div className={'create-initial-agency-and-invite-users-agency-members-wrapper'}>
                <Text
                    style={{width: '100%', marginTop: theme.spacer.large}}
                    variant={'headlineMedium'}>SCREENS.CREATE_AGENCY_INVITE_USERS.INVITE_USERS.HEADLINE</Text>
                {users.map((user, index) => {
                    const numbering = index + 1
                    const {firstName, lastName, email} = user
                    return _isContactPersonUser(index) ?
                        <div
                            className={clsx(
                                'create-initial-agency-and-invite-users-agency-member-wrapper',
                                'create-initial-agency-and-invite-users-contact-persons-wrapper',
                            )}
                            key={index}>
                            <Text
                                variant={"headlineSmall"}
                                style={{marginTop: MARGIN_TOP + 5}}>{`${numbering}. `}</Text>
                            <StringPair
                                containerStyle={{flex: 1}}
                                label={"GENERAL.FIRST_NAME"}
                                value={firstName?.length === 0 ? "SCREENS.CREATE_AGENCY_INVITE_USERS.INVITE_USERS.ADD_FIRST_NAME_HINT" : user.firstName}/>
                            <StringPair
                                containerStyle={{flex: 1}}
                                label={"GENERAL.LAST_NAME"}
                                value={lastName?.length === 0 ? "SCREENS.CREATE_AGENCY_INVITE_USERS.INVITE_USERS.ADD_LAST_NAME_HINT" : user.lastName}/>
                            <StringPair
                                containerStyle={{flex: 1}}
                                label={"GENERAL.EMAIL"}
                                value={email?.length === 0 ? "SCREENS.CREATE_AGENCY_INVITE_USERS.INVITE_USERS.ADD_EMAIL_HINT" : user.email}/>
                            <CheckboxInput
                                labelColor={"primary"}
                                checked={user.isAdmin}
                                onChange={() => _onChangeAdmin(index)}
                                label={"SCREENS.SETTINGS.IS_ADMIN_LABEL"}/>
                            <Text
                                style={{flex: 1, marginTop: MARGIN_TOP}}>
                                {`${numbering}. ${t("GENERAL.CONTACT_PERSON")}`}
                            </Text>
                        </div> :
                        <div
                            className={'create-initial-agency-and-invite-users-agency-member-wrapper'}
                            key={index}>
                            <Text
                                variant={"headlineSmall"}
                                style={{marginTop: MARGIN_TOP + 5}}>{`${numbering}. `}</Text>
                            <TextInput
                                value={user.firstName}
                                ariaErrorMessage={"SCREENS.COMMON.ARIA_LABELS.FIRST_NAME_ERROR"}
                                labelColor={"primary"}
                                error={userErrors[index].firstName}
                                containerStyle={{flex: 1}}
                                onBlur={value => _updateUser(index, value, "firstName")}
                                onChange={value => _updateUser(index, value, "firstName")}
                                placeholder={"GENERAL.FIRST_NAME"}
                                label={"GENERAL.FIRST_NAME"}/>
                            <TextInput
                                value={user.lastName}
                                ariaErrorMessage={"SCREENS.COMMON.ARIA_LABELS.LAST_NAME_ERROR"}
                                labelColor={"primary"}
                                error={userErrors[index].lastName}
                                containerStyle={{flex: 1}}
                                onBlur={value => _updateUser(index, value, "lastName")}
                                onChange={value => _updateUser(index, value, "lastName")}
                                placeholder={"GENERAL.LAST_NAME"}
                                label={"GENERAL.LAST_NAME"}/>
                            <TextInput
                                value={user.email}
                                ariaErrorMessage={"SCREENS.COMMON.ARIA_LABELS.EMAIL_ERROR"}
                                labelColor={"primary"}
                                error={userErrors[index].email}
                                containerStyle={{flex: 1}}
                                onBlur={value => _updateUser(index, value, "email")}
                                onChange={value => _updateUser(index, value, "email")}
                                placeholder={"GENERAL.EMAIL"}
                                label={"GENERAL.EMAIL"}/>
                            <CheckboxInput
                                labelColor={"primary"}
                                checked={user.isAdmin}
                                onChange={() => _onChangeAdmin(index)}
                                label={"SCREENS.SETTINGS.IS_ADMIN_LABEL"}/>
                            <IconAndText
                                containerStyle={{marginTop: MARGIN_TOP}}
                                textStyle={{marginRight: "auto"}}
                                classes={'create-initial-agency-and-invite-users-remove-user-button'}
                                size={'mediumSmall'}
                                text={"SCREENS.CREATE_AGENCY_INVITE_USERS.INVITE_USERS.REMOVE_EXPEDIENT_BUTTON"}
                                onClick={() => _removeUser(index)}
                                icon={"userMinus"}/>
                        </div>
                })}
                {users.length > 0 &&
                    <Text style={{width: '100%'}} variant={"subtitleLarge"}>SCREENS.SETTINGS.IS_ADMIN_TOOLTIP</Text>
                }
                {users.length === 0 &&
                    <Text
                        classes={'create-initial-agency-and-invite-users-no-users-hint'}>SCREENS.CREATE_AGENCY_INVITE_USERS.INVITE_USERS.NO_EXPEDIENT_HINT</Text>}
                <IconAndText
                    containerStyle={{marginTop: theme.spacer.small}}
                    text={"SCREENS.CREATE_AGENCY_INVITE_USERS.INVITE_USERS.ADD_EXPEDIENT_BUTTON"}
                    onClick={_addUser}
                    icon={'userPlus'}/>
            </div>
            <div className={'create-initial-agency-and-invite-users-button-wrapper'}>
                <TextButton
                    onClick={onBack}
                    variant={"abort"}>GENERAL.ABORT</TextButton>
                <div className={'create-initial-agency-and-invite-users-right-buttons-wrapper'}>
                    <TextButton
                        onClick={onSkip}
                        variant={"abort"}>GENERAL.SKIP</TextButton>
                    <TextButton
                        onClick={_proceed}>GENERAL.PROCEED</TextButton>
                </div>
            </div>
        </div>
    </RegistrationWrapper>
}

export default CreateInitialAgencyAndInviteUsers