import {AircraftTypeResponse} from "../../../../api/model/activity-flight";
import {DropdownOption} from "atmosfair-ui"


import {TravelClass} from "../../../../screens/activity/activity-form/flight";

export const convertAircraftTypeToDropdownFormat = (aircrafts: AircraftTypeResponse[]): DropdownOption[] => {
    return aircrafts.map(aircraft => {
        const {id, name, iata} = aircraft
        return {
            label: name,
            value: id,
            raw: {name, iata, id}
        }
    });
}

export const convertTravelClassesToDropdownFormat = (travelClasses: TravelClass[]): DropdownOption[] => {
    return travelClasses.map(travelClass => {
        const {name, id} = travelClass
        return {
            label: name,
            value: id,
            raw: {name, id}
        }
    });
}

export const convertTravelClassesFromDropdownFormat = (travelClasses: DropdownOption[] | null): TravelClass | null => {
    if (travelClasses === null) return null
    return travelClasses.map(travelClass => travelClass.raw)[0]
}

export const convertAircraftTypeFromDropdownFormat = (aircraftTypes: DropdownOption[] | null): AircraftTypeResponse | null => {
    if (aircraftTypes === null) return null
    return aircraftTypes.map(aircraftType => aircraftType.raw)[0]
}
