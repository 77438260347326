import React from "react"
import {useParams} from "react-router-dom";
import {ACTIVITY_TYPE} from "../../../types-enums/activity";
import ActivityForm from "../../../screens/activity/activity-form/ActivityForm";

const SelectActivityForm = () => {
    const {activity, index} = useParams();
    switch (activity) {
        case ACTIVITY_TYPE.flight:
            return <ActivityForm
                activityType={ACTIVITY_TYPE.flight}
                editIndex={index ? Number(index) : null}/>
        case ACTIVITY_TYPE.hotel:
            return <ActivityForm
                activityType={ACTIVITY_TYPE.hotel}
                editIndex={index ? Number(index) : null}/>
        default:
            return <p>Something went wrong selecting activity</p>
    }
}

export default SelectActivityForm