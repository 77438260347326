import React from "react"
import {useNavigate} from "react-router-dom";
import "./styles.css"
import {PATH_STRINGS} from "../../routes/route-props";
import BottomButtons from "../../components/bottom-buttons/BottomButtons";
import TravelComponentsTable from "../../container/travel-components-table/TravelComponentsTable";
import {useAppSelector} from "../../redux/reducer/store";

const TravelComponentsOverview = () => {
    const {activities} = useAppSelector(state => state.travelComponents)
    const navigate = useNavigate()

    return <div className={"travel-components-overview-container"}>
        <TravelComponentsTable/>
        {activities.length > 0 && <BottomButtons
            buttons={[
                {
                    text: "GENERAL.ABORT",
                    variant: "abort",
                    onClick: () => navigate(PATH_STRINGS.travelDatabase)
                },
                // {
                //     text: "GENERAL.SAVE",
                //     onClick: () => console.log("Send to BE")
                // }
            ]}
        />}
    </div>
}

export default TravelComponentsOverview