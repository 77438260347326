import React from 'react'
import {useTranslation} from "react-i18next";
import './styles.css'
import {AVAILABLE_LANGUAGES, LANGUAGE_ABBREVATION} from "../../locales/locales";
import {Text} from "atmosfair-ui"
import {useUpdateUserInfoSelfMutation} from "../../redux/reducer/api";
import clsx from "clsx";

interface LanguageSelectorProps {
    classes?: string | string[]
}

const LanguageSelector = ({
                              classes
                          }: LanguageSelectorProps) => {
    const {i18n} = useTranslation()
    const [setUserInfo, {
        data: acceptTermsAndConditionsResponse,
        isLoading: isLoadindAcceptTermsAndConditions,
        error: acceptTermsAndConditionsError
    }] = useUpdateUserInfoSelfMutation()

    const _renderFlags = () => {
        return AVAILABLE_LANGUAGES.map((language, index) => (
            <Text
                onClick={() => {
                    i18n.changeLanguage(language)
                    setUserInfo({language: LANGUAGE_ABBREVATION[language]})
                }}
                color={"white"}
                classes={'language-selector-flag-emoji'}
                key={index}>
                {_getFlagEmoji(language)}
            </Text>
        ))
    }

    const _getFlagEmoji = (countryCode: string) => {
        // https://dev.to/jorik/country-code-to-flag-emoji-a21
        if (countryCode === LANGUAGE_ABBREVATION.en) countryCode = 'GB'
        const codePoints = countryCode
            .toUpperCase()
            .split('')
            // @ts-ignore
            .map(char => 127397 + char.charCodeAt());
        return String.fromCodePoint(...codePoints);
    }

    return <div className={clsx('language-selector-flags-container', classes && classes)}>
        {_renderFlags()}
    </div>
}

export default LanguageSelector