import {UserState} from "../redux/reducer/user";
import {CompanyProps} from "../api/model/company";
import {UserProps} from "../api/model/user";

export const isSomeAdmin = (
    userData: UserState["userData"],
    agenciesData: UserState["agencies"],
    companiesData: UserState["companies"]
) => {
    let isSomeAgencyAdmin = false
    let isCompanyAdmin = false
    let isKlimaLinkAdmin = false
    if (agenciesData) isSomeAgencyAdmin = agenciesData.some(agency => agency.isAdmin)
    if (companiesData) isCompanyAdmin = companiesData[0].isAdmin
    if (userData) isKlimaLinkAdmin = userData.isKlimaLink
    return {isSomeAgencyAdmin, isCompanyAdmin, isKlimaLinkAdmin}
}

export const companyAdminHasAcceptedTermsAndConditions = (companies?: CompanyProps[], user?: UserProps) => {
    if (companies && user) {
        const {isAdmin} = companies[0] // currently a user (unless its KlimaLink Admin) can only be in one compant at a time, that is why it is save to simply pick the first company in the array
        if (isAdmin) return user.termsAndConditionsAccepted
        return true
    }
    return false
}

export const normalUserHasNotAcceptedTermsAndConditions = (user?: UserProps) => {
    if (user) return user.termsAndConditionsAccepted
    return false
}