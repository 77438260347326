import React, {ReactNode, useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
    CheckboxInput,
    DelayedSpinner,
    Fieldset,
    IconAndText,
    IconButton,
    Modal,
    NumberInput,
    Text,
    TextButton
} from "atmosfair-ui"
import './styles.css'
import '../../styles.css'
import cloneDeep from 'lodash/cloneDeep';
import {
    AircraftTypeResponse,
    AirlineResponse,
    AirportResponse,
    FlightRequestLeg,
    FlightsResponse
} from "../../../../api/model/activity-flight";
import {ActivityFlight, ActivityFlightState, Leg} from "../../../../types-enums/activity-flight";
import FlightSelector from "../flight-selector/FlightSelector";
import {useCalculateFlightEmissionsMutation} from "../../../../redux/reducer/api";
import {
    CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL,
    CALCULATION_TYPE,
    relevantErrorCodes
} from "../../../../api/model/common";
import {ActivityCommonProperties} from "../../../../types-enums/activity-common-properties";
import {GET_AIRCRAFTS, GET_FLIGHTS_BY_DATE_AND_AIRLINE} from "../../../../api/endpoints";
import {toast, TypeOptions} from "react-toastify";
import ToastWithTextOnly from "../../../../molecules/toasts/toast-with-text-only/ToastWithTextOnly";
import {ACTIVITY_TYPE} from "../../../../types-enums/activity";
import {TRAVEL_OPTION_TYPE} from "../../../../types-enums/travel-option";
import {formatCo2ETotal, NO_DATA_STRING, ROUTE_SEPARATOR} from "../../../../utils/string-mutation";
import {findIndex, isEqual} from "lodash"
import {COOKIE_NAME, FLIGHT_PROPERTIES} from "../../../../constants";
import {theme} from '../../../../styles/theme';
import Cookies from "js-cookie";
import {isCalculatedFlightEmissionsErrorResponse} from "../../../../utils/type-guards";
import {v4 as uuidv4} from "uuid";
import {getFirstUiLeg, getUiStopover} from "../../../../utils/flight-getter";
import {DateTime} from "luxon";
import {
    convertUiAircraftTypeToApiAircraftType,
    convertUiTravelClassToApiTravelClass,
    createRoundtripPassengersCountString,
    generateFlightTypeString,
    generatePassengerString
} from "./utils";
import {CalculatedEmissionsResponseTotal} from "../../../../api/model/emissions";
import {E_CO2} from "../../../../utils/unit-strings";
import usePrevious from "../../../../hooks/usePrevious";
import AmbiguousFlightList from "../ambiguous-flight-list/AmbiguousFlightList";
import {PATH_STRINGS} from "../../../../routes/route-props";
import {TRAVEL_CLASSES, TravelClass} from "../../../../screens/activity/activity-form/flight";
import {_getDomain} from "../../utils";
import AutocompleteQuery, {
    AUTOCOMPLETE_ACTIVITY_TYPE,
    AUTOCOMPLETE_QUERY_TYPE
} from '../../../../molecules/autocomplete-query/AutocompleteQuery';

export interface ChooseFlightConnectionProps {
    editIndex: ActivityCommonProperties["common"]["editIndex"],
    editFlight: ActivityFlightState | null
    getCalculatedActivity: (activity: ActivityFlightState) => void
    resetFlightForm: () => void
}

export interface LegForUi extends Leg {
    errors: {
        airport: boolean
        flightNumber: boolean,
    },
}

const emptyLeg: LegForUi = {
    airport: null,
    travelClass: null,
    aircraftType: null,
    airline: null,
    flightNumber: null,
    date: null,
    ownAirportTransfer: false,
    errors: {
        airport: false,
        flightNumber: false,
    }
}

const ChooseFlight = ({
                          editIndex,
                          editFlight,
                          getCalculatedActivity,
                          resetFlightForm
                      }: ChooseFlightConnectionProps) => {
    const [legs, setLegs] = useState<LegForUi[]>([emptyLeg, emptyLeg])
    const previousLegs = usePrevious(legs)
    const [returnFlight, setReturnFlight] = useState<ActivityFlight["returnFlight"]>(false)
    const [onlyUsedAirportsForEmissionCalculation, setOnlyUsedAirportsForEmissionCalculation] = useState(false)
    const [aircrafts, setAircrafts] = useState<AircraftTypeResponse[]>([]);
    const [travelClasses, setTravelClasses] = useState<TravelClass[]>([]);
    const [aircraftsNetworkError, setAircraftsNetworkError] = useState(false);
    const [passengerCount, setPassengerCount] = useState<ActivityFlight["passengerCount"]>(1)
    const [numberPassengersError, setNumberPassengersError] = useState(false)
    const [disableCalculateButton, setDisableCalculateButton] = useState(false)
    const [modalVisible, setModalVisible] = useState(false)
    const [modalContent, setModalContent] = useState<FlightsResponse[]>([])
    const gettingFlightsByFlightNumberAndDate = useRef(false)
    const selectedFlightsViaFlightNumberAndDateFromModal = useRef<FlightsResponse[]>([])
    const allFlightsFoundViaFlightNumberAndDate = useRef<FlightsResponse[][]>([])
    const legIndexFromWhichModalWasOpened = useRef(-1)
    const flightsForRequestFormat = useRef<FlightRequestLeg[]>([])
    const {t, i18n} = useTranslation();
    const currentLanguage = i18n.resolvedLanguage

    const [calculateEmissions, {
        data: resultCalculatingEmissions,
        error: errorCalculatingEmissions,
        isLoading: isCalculatingEmissions,
    }] = useCalculateFlightEmissionsMutation()

    useEffect(() => {
        if (disableCalculateButton) setDisableCalculateButton(false)
    }, [legs, returnFlight, aircrafts, travelClasses, passengerCount]);

    const _getAircraftTypes = useCallback(async () => {
        try {
            let headers = new Headers();
            const token = Cookies.get(COOKIE_NAME)
            headers.set('Authorization', `Bearer ${token}`);
            const response = await fetch(GET_AIRCRAFTS, {
                method: "GET",
                headers
            });
            if (response.ok) {
                const aircrafts: AircraftTypeResponse[] = await response.json();
                setAircrafts(aircrafts);
                setAircraftsNetworkError(false);
            } else {
                setAircraftsNetworkError(true)
                console.error(`Response not in range of 200 - 299. Response is ${response.status}`);
            }
        } catch (error) {
            setAircraftsNetworkError(true)
            console.error(`Something went wrong with getting airports: ${error}`);
        }
        return Promise.resolve()
    }, [])

    useEffect(() => {
        // This is a workaround
        // Material UI select component compares strict https://stackoverflow.com/questions/71981327/mui-object-in-select-default-value-not-showing-but-shows-when-object-selected
        // For a reason which I do not fully understand yet: When this screens appears because someone wants to edit a flight,
        // and _getAircraftTypes() and setTravelClasses(TRAVEL_CLASSES) is called before the useEffect() depending on [editFlight, editIndex]
        // the <DropdownInput /> executes onChange() which then returns an undefined, resulting in no selection (even though in firstLeg a travelClass or aircraftType is set)
        // I think this could be avoided using areOptionsEqual() https://mui.com/base-ui/react-select/hooks-api/, but
        // in the Material ui version used for the <DropdownInput /> in atmosfair-ui this is no implemented
        // thus material-ui in atmosfair ui must be updated and then it can be tried, if this helps
        // the workaround is to set aircraftTypes and travelClasses AFTER the defaultValues have been passed to the <DropdownInput />
        if (editIndex === null) {
            (async () => {
                await _getAircraftTypes()
                setTravelClasses(TRAVEL_CLASSES)
            })();
        }
    }, [_getAircraftTypes])

    useEffect(() => {
        if (editIndex !== null && editFlight) {
            const {legs} = editFlight
            const {passengerCount, returnFlight} = editFlight
            setLegs(cloneDeep(legs).map(leg => _convertLegToLegForUi(leg)))
            setPassengerCount(passengerCount)
            setReturnFlight(returnFlight)
            _getAircraftTypes()
            setTravelClasses(TRAVEL_CLASSES)
        }
    }, [editFlight, editIndex])

    useEffect(() => {
        if (isCalculatedFlightEmissionsErrorResponse(errorCalculatingEmissions)) {
            const {data} = errorCalculatingEmissions
            const {status, flightActivities, emissionKgCO2eTotal, errorCodes} = data
            let informativeToastShowed = false;

            const errorStrings: string[] = []
            flightActivities.forEach(flight => {
                if (flight.errorCodes) flight.errorCodes.forEach(error => {
                    const {code} = error
                    const failedLeg = flightsForRequestFormat.current[flightsForRequestFormat.current.findIndex(leg => leg.index === flight.index)]
                    const {arrival, departure, flightDate, airline, aircraftType, flightNumber} = failedLeg
                    const airports = [departure, arrival].join(` ${ROUTE_SEPARATOR} `)

                    switch (code) {
                        case 601:
                            errorStrings.push(t(`${_createErrorCodeString(code)}`, {airport: departure}));
                            break;
                        case 602:
                            errorStrings.push(t(`${_createErrorCodeString(code)}`, {airline}));
                            break;
                        case 603:
                            errorStrings.push(t(`${_createErrorCodeString(code)}`, {aircraftType}));
                            break;
                        case 604:
                            errorStrings.push(t(`${_createErrorCodeString(code)}`, {airports}));
                            break;
                        case 605:
                            errorStrings.push(t(`${_createErrorCodeString(code)}`))
                            break;
                        case 606:
                            errorStrings.push(t(`${_createErrorCodeString(code)}`))
                            break;
                        case 607:
                            errorStrings.push(t(`${_createErrorCodeString(code)}`))
                            break;
                        case 608:
                            errorStrings.push(t(`${_createErrorCodeString(code)}`, {flightDate}));
                            break;
                        case 609:
                            errorStrings.push(t(`${_createErrorCodeString(code)}`,));
                            break
                        case 610:
                            errorStrings.push(t(`${_createErrorCodeString(code)}`,));
                            break
                        case 611:
                            errorStrings.push(t(`${_createErrorCodeString(code)}`));
                            break;
                        case 612:
                            errorStrings.push(t(`${_createErrorCodeString(code)}`, {flightNumber}));
                            break;
                        case 613:
                            errorStrings.push(t(`${_createErrorCodeString(code)}`, {
                                flightDate,
                                flightNumber
                            }));
                            break;
                        case 614:
                            errorStrings.push(t(`${_createErrorCodeString(code)}`, {
                                airline,
                                aircraftType,
                                airports
                            }));
                            break;
                        case 615:
                            errorStrings.push(t(`${_createErrorCodeString(code)}`, {
                                airline,
                                airports
                            }));
                            break;
                        case 616:
                            errorStrings.push(t(`${_createErrorCodeString(code)}`, {
                                aircraftType,
                                airports
                            }));
                            break;
                        case 617:
                            errorStrings.push(t(`${_createErrorCodeString(code)}`));
                            break;
                        case 618:
                            errorStrings.push(t(`${_createErrorCodeString(code)}`))
                            break;
                        case 619:
                            errorStrings.push(t(`${_createErrorCodeString(code)}`))
                            break;
                        case 620:
                            errorStrings.push(t(`${_createErrorCodeString(code)}`))
                            break;
                        case 621:
                            errorStrings.push(t(`${_createErrorCodeString(code)}`))
                            break;
                        case 622:
                            errorStrings.push(t(`${_createErrorCodeString(code)}`))
                            break;
                        case 623:
                            errorStrings.push(t(`${_createErrorCodeString(code)}`))
                            break;
                        case 624:
                            errorStrings.push(t(`${_createErrorCodeString(code)}`,));
                            break
                        case 625:
                            errorStrings.push(t(`${_createErrorCodeString(code)}`,));
                            break
                        case 817:
                            errorStrings.push(t(`${_createErrorCodeString(code)}`, {
                                aircraftType,
                                airports
                            }));
                            break;
                        case 830:
                            errorStrings.push(t(`${_createErrorCodeString(code)}`,));
                            break
                        default:
                            errorStrings.push(t(`ERRORS.BACKEND_ERRORS.${relevantErrorCodes[code as keyof typeof relevantErrorCodes]}`, {
                                aircraftType,
                                airports
                            }))
                    }
                })
            })
            if (status === CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.failed) {
                errorStrings.unshift([
                    t("ERRORS.CALCULATION_COMPLETE_FAILURE"),
                    t("ACTIVITIES.FLIGHT.ERRORS.GENERAL.PARTIAL_OR_COMPLETE_CALCULATION_ERROR")
                ].join("\n"))
            }
            if (status === CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success_with_errors) {
                errorStrings.push([
                    t("ERRORS.CALCULATION_PARTIAL_FAILURE"),
                    t("ACTIVITIES.FLIGHT.ERRORS.GENERAL.PARTIAL_OR_COMPLETE_CALCULATION_ERROR")
                ].join("\n"))
            }
            if (errorStrings.length > 0) {
                _renderToast(errorStrings.join("\n \n"), "warning")
                informativeToastShowed = true
            }
            if (!informativeToastShowed) toast(<ToastWithTextOnly
                    message={t("ERRORS.GENERAL_NETWORK_ERROR_BODY")}/>,
                {progress: undefined, type: "error"}
            );

            getCalculatedActivity({
                common: {
                    activityType: ACTIVITY_TYPE.flight,
                    travelOptionType: TRAVEL_OPTION_TYPE.transport,
                    editIndex: null,
                    totalEmissions: {
                        data: flightActivities,
                        status,
                        emissionKgCO2eTotal,
                        errorCodes,
                    },
                    id: uuidv4(),
                    request: flightsForRequestFormat.current
                },
                legs,
                passengerCount,
                returnFlight,
            })
        }
    }, [errorCalculatingEmissions]);

    useEffect(() => {
        if (resultCalculatingEmissions) {
            setDisableCalculateButton(true)
            const {emissionKgCO2eTotal} = resultCalculatingEmissions
            _getOnlyUsedAirportsForEmissionCalculation()
            if (emissionKgCO2eTotal) _renderToast("GENERAL.EMISSIONS_CALCULATED", "success")
            getCalculatedActivity({
                common: {
                    activityType: ACTIVITY_TYPE.flight,
                    travelOptionType: TRAVEL_OPTION_TYPE.transport,
                    editIndex: null,
                    totalEmissions: resultCalculatingEmissions,
                    id: uuidv4(),
                    request: flightsForRequestFormat.current,
                },
                legs,
                passengerCount,
                returnFlight,
            })
        }

    }, [resultCalculatingEmissions]);

    const _getOnlyUsedAirportsForEmissionCalculation = () => {
        let onlyUsedAirports = true
        const {airline, aircraftType, travelClass} = getFirstUiLeg(legs)
        if (airline || travelClass || aircraftType) onlyUsedAirports = false
        getUiStopover(legs).forEach(stop => {
            const {airline, aircraftType, travelClass} = stop
            if (airline || travelClass || aircraftType) onlyUsedAirports = false
        })
        setOnlyUsedAirportsForEmissionCalculation(onlyUsedAirports)
    }

    const _createErrorCodeString = (code: keyof typeof relevantErrorCodes) => `ERRORS.BACKEND_ERRORS.${relevantErrorCodes[code]}`

    const _convertLegToLegForUi = (leg: Leg): LegForUi => {
        return {
            ...leg,
            errors: {airport: false, flightNumber: false},
        }
    }

    const _showToastOnFlightViaFlightNumberAndDate = (countAirportsReplaced: number) => {
        if (countAirportsReplaced > 0) {
            _renderToast(
                [
                    t("ACTIVITIES.FLIGHT.GETTING_FLIGHTS_VIA_NUMBER_AND_DATE_SUCCESS"),
                    countAirportsReplaced === 1 ? t("TOASTS.SUCCESS.REPLACED_AIRPORT_SINGULAR") : t("TOASTS.SUCCESS.REPLACED_AIRPORT_PLURAL")
                ].join("\n \n"),
                "success")
        } else {
            _renderToast("ACTIVITIES.FLIGHT.GETTING_FLIGHTS_VIA_NUMBER_AND_DATE_SUCCESS", "success")
        }
    }

    const _renderToast = (message: string, type: TypeOptions) => toast(<ToastWithTextOnly
            message={t(message)}/>,
        {progress: undefined, type}
    )

    const _isValidFlightNumber = (flightNumber: string | null | undefined) => {
        if (flightNumber) {
            const pattern = /^([A-Z]{2,3}|[A-Z]\d|\d[A-Z])(\d{1,4})$/;
            return flightNumber.length === 0 ? false : pattern.test(flightNumber)
        }
        return false
    }

    const _hasMissingAirport = (leg: Leg | null) => {
        if (leg) return !Boolean(leg.airport)
        return true
    }

    const _formatDateToRequestFormat = (date: DateTime) => {
        const {year, day, month} = date
        const leadingZeroDay = day <= 9 ? `0${day}` : day
        const leadingZeroMonth = month <= 9 ? `0${month}` : month
        return `${year}-${leadingZeroMonth}-${leadingZeroDay}`
    }

    const _fillFormBasedOnFlightNumberAndDate = (index: number, flights: FlightsResponse[]) => {
        allFlightsFoundViaFlightNumberAndDate.current.push(flights)
        let updatedLegs = cloneDeep(legs)
        let countAirportsReplaced = 0
        flights.forEach((flight, flightNumberIndex) => {
            const lookupIndexDeparture = index + flightNumberIndex
            const lookupIndexArrival = index + flightNumberIndex + 1
            const {
                departureAirport,
                arrivalAirport,
                airline,
                aircraftType,
                flightNumber,
                flightDate,
            } = flight
            const date = DateTime.fromISO(flightDate)
            const arrivalLegViaFlightNumber = _convertLegToLegForUi({
                airport: arrivalAirport,
                travelClass: null,
                aircraftType,
                airline,
                date,
                flightNumber,
                ownAirportTransfer: false,
            })

            // The format of the returned flights is different than the one used in the UI
            // [{departure: MUC, arrival: IST}, {departure: IST, arrival: JFK}, {departure: JFK, arrival: BER}, {departure: BER, arrival: DOH}]
            // because of that, only on the very first leg (index === 0) the departure is used
            // on all the other legs (index > 0), arrival is used
            if (flightNumberIndex === 0) {
                const departureLegViaFlightNumber = _convertLegToLegForUi({
                    airport: departureAirport,
                    travelClass: null,
                    aircraftType,
                    airline,
                    date,
                    flightNumber,
                    ownAirportTransfer: false,
                })

                departureLegViaFlightNumber.travelClass = updatedLegs[lookupIndexDeparture].travelClass
                updatedLegs[lookupIndexDeparture] = departureLegViaFlightNumber
            }

            if (typeof updatedLegs[lookupIndexArrival] === 'undefined') {
                updatedLegs.splice(lookupIndexArrival, 0, arrivalLegViaFlightNumber)
            } else {
                arrivalLegViaFlightNumber.travelClass = updatedLegs[lookupIndexArrival].travelClass
                updatedLegs[lookupIndexArrival] = arrivalLegViaFlightNumber
                if (!isEqual(legs[lookupIndexArrival].airport, arrivalLegViaFlightNumber.airport)) countAirportsReplaced += 1
            }

            // If there is a leg after (lookupIndexArrival + 1) the last leg (lookupIndexArrival) that has been found via flight number and flight date
            // we need to show the user that the leg that is already in the UI is not part of the flights
            // that have been found with the flight number and flight date
            if (flightNumberIndex === flights.length - 1) {
                const afterLegIndex = lookupIndexArrival + 1;
                if (
                    updatedLegs[afterLegIndex] !== undefined &&
                    (updatedLegs[afterLegIndex].airport || updatedLegs[afterLegIndex].flightNumber)
                ) updatedLegs = _updateFlightDataOnCurrentLeg(lookupIndexArrival, updatedLegs, "flightNumber", null)
            }

        })
        setLegs(updatedLegs)
        _showToastOnFlightViaFlightNumberAndDate(countAirportsReplaced)
        _resetModalRefs()
    }

    const _requestFlightsByFlightNumberAndDate = async (index: number, leg: Leg) => {
        const {date} = leg
        if (date && _isValidFlightNumber(leg.flightNumber)) {
            const query = `flightNumber=${leg.flightNumber}&flightDate=${_formatDateToRequestFormat(date)}`
            let headers = new Headers();
            const token = Cookies.get(COOKIE_NAME)
            headers.set('Authorization', `Bearer ${token}`);
            try {
                const response = await fetch(`${GET_FLIGHTS_BY_DATE_AND_AIRLINE}?${query}`,
                    {
                        method: "GET",
                        headers
                    });
                if (response.ok) {
                    const flights: FlightsResponse[] = await response.json();
                    const isCodeSharingFlight = flights.some(flight => flight.isNonOperationalCodeSharingFlight)
                    if (isCodeSharingFlight) {
                        _renderToast("ACTIVITIES.FLIGHT.ERRORS.GENERAL.GETTING_FLIGHTS_CODE_SHARING_FLIGHT_ERROR", "error")
                    } else {
                        if (flights.length > 1) {
                            setModalVisible(true)
                            legIndexFromWhichModalWasOpened.current = index
                            setModalContent(flights)
                        } else if (flights.length === 1) {
                            _fillFormBasedOnFlightNumberAndDate(index, flights)
                        } else if (flights.length === 0) {
                            if (leg.airport) {
                                _renderToast("TOASTS.WARNING.GETTING_FLIGHTS_VIA_NUMBER_AND_DATE_NO_FLIGHTS_FOUND_AIRPORTS_IN_UI", "warning")
                            } else {
                                _renderToast("TOASTS.WARNING.GETTING_FLIGHTS_VIA_NUMBER_AND_DATE_NO_FLIGHTS_FOUND_NO_AIRPORTS_IN_UI", "warning")
                            }
                        } else {
                        }
                    }
                } else {
                    _renderToast("ACTIVITIES.FLIGHT.ERRORS.NETWORK_ERROR.GETTING_FLIGHTS_VIA_NUMBER_AND_DATE", "error")
                    console.error(`Response not in range of 200 - 299. Response is ${response.status}`);
                }
            } catch (error) {
                _renderToast("ACTIVITIES.FLIGHT.ERRORS.NETWORK_ERROR.GETTING_FLIGHTS_VIA_NUMBER_AND_DATE", "error")
                console.error(`Something went wrong with getting airports: ${error}`);
            }
        }
    }

    const _setFlightsWithDateAndFlightNumber = async (index: number) => {
        if (previousLegs) {
            if (legs[index].flightNumber === null) {
                let updatedLegs = cloneDeep(legs)
                updatedLegs = _updateFlightDataOnCurrentLeg(index, updatedLegs, 'flightNumber', null)
                setLegs(updatedLegs)
            } else if (previousLegs[index].flightNumber !== legs[index].flightNumber || !isEqual(previousLegs[index].date, legs[index].date)) {
                gettingFlightsByFlightNumberAndDate.current = true
                const currentLeg = legs[index]
                await _requestFlightsByFlightNumberAndDate(index, currentLeg)
                gettingFlightsByFlightNumberAndDate.current = false
            }
        }
    }

    const _legsMatchFlightFoundViaFlightNumberAndDate = (leg: LegForUi, isLastLeg: boolean) => {
        for (let i = 0; i < allFlightsFoundViaFlightNumberAndDate.current.length; i++) {
            const flightsViaFlightNumberAndDate = allFlightsFoundViaFlightNumberAndDate.current[i]
            for (let j = 0; j < flightsViaFlightNumberAndDate.length; j++) {
                const flight = flightsViaFlightNumberAndDate[j]
                if (isLastLeg) {
                    if (isEqual(leg.airport, flight.arrivalAirport)) return true
                } else {
                    if (
                        // The format of the returned flights is different from the one used in the UI
                        // [{departure: MUC, arrival: IST}, {departure: IST, arrival: JFK}, {departure: JFK, arrival: BER}, {departure: BER, arrival: DOH}]
                        // because of that, only on the very first leg (j === 0) the departure is used
                        // on all the other legs (j > 0), arrival is used
                        isEqual(leg.airport, j === 0 ? flight.departureAirport : flightsViaFlightNumberAndDate[j - 1].arrivalAirport) &&
                        isEqual(leg.aircraftType, flight.aircraftType) &&
                        isEqual(leg.flightNumber, flight.flightNumber)
                    ) return true
                }
            }
        }
        return false
    }

    const _matchPreviouslyFlightsViaFlightNumberAndFlightDate = (updatedLegs: LegForUi[]) => {
        const matchingLegsIndices: number[] = [];
        updatedLegs.forEach((leg, index) => {
            if (index === updatedLegs.length - 1) {
                _legsMatchFlightFoundViaFlightNumberAndDate(leg, true) && matchingLegsIndices.push(index)
            } else {
                _legsMatchFlightFoundViaFlightNumberAndDate(leg, false) && matchingLegsIndices.push(index)
            }
        })

        if (matchingLegsIndices.length > 0) {
            updatedLegs.forEach((_, currentLegIndex) => {
                const nextLegIndex = currentLegIndex + 1;
                if (
                    !matchingLegsIndices.includes(currentLegIndex) ||
                    !matchingLegsIndices.includes(nextLegIndex)
                ) updatedLegs = _updateFlightDataOnCurrentLeg(currentLegIndex, updatedLegs, 'flightNumber', null)
            });
        }
        return updatedLegs
    }

    const _updateFlightDataOnCurrentLeg = <K extends keyof LegForUi>(
        index: number,
        legs: Array<LegForUi>,
        key: K,
        payload: LegForUi[K],
    ): Array<LegForUi> => legs.map((leg, idx) => {
        if (idx === index) return {
            ...leg,
            [key]: payload,
        };
        return leg;
    });

    const _setAirline = (index: number, airline: AirlineResponse | null, leg: LegForUi) => {
        const {flightNumber} = leg
        let updatedLegs = _updateFlightDataOnCurrentLeg(index, legs, "airline", airline)
        if (flightNumber && !isEqual(leg.airline, airline)) updatedLegs = _updateFlightDataOnCurrentLeg(index, legs, "flightNumber", null)
        updatedLegs = _matchPreviouslyFlightsViaFlightNumberAndFlightDate(updatedLegs)
        setLegs(updatedLegs)
    }

    const _setAirport = (index: number, airport: AirportResponse | null, leg: LegForUi) => {
        const {flightNumber} = leg
        let updatedLegs = _updateFlightDataOnCurrentLeg(index, legs, "airport", airport)
        // updatedLegs = _conditionallySetFlightDataPropsToNull(index, updatedLegs, 'flightNumber')
        if (flightNumber && !isEqual(leg.airport, airport)) updatedLegs = _updateFlightDataOnCurrentLeg(index, updatedLegs, "flightNumber", null)
        updatedLegs = _matchPreviouslyFlightsViaFlightNumberAndFlightDate(updatedLegs)
        setLegs(updatedLegs)
    }

    const _setAircraftType = (index: number, aircraftType: AircraftTypeResponse | null, leg: LegForUi) => {
        const {flightNumber} = leg
        let updatedLegs = _updateFlightDataOnCurrentLeg(index, legs, "aircraftType", aircraftType)
        if (flightNumber && !isEqual(leg.aircraftType, aircraftType)) updatedLegs = _updateFlightDataOnCurrentLeg(index, updatedLegs, "flightNumber", null)
        updatedLegs = _matchPreviouslyFlightsViaFlightNumberAndFlightDate(updatedLegs)
        setLegs(updatedLegs)
    }

    const _removeStopover = (index: number) => {
        let updatedLegs = legs.filter((_, idx) => idx !== index)
        updatedLegs = _matchPreviouslyFlightsViaFlightNumberAndFlightDate(updatedLegs)
        setLegs(updatedLegs)
        const indexToUpdateOwnAirportTransfer = index - 1
        if (updatedLegs[indexToUpdateOwnAirportTransfer]) setLegs(
            _updateFlightDataOnCurrentLeg(
                indexToUpdateOwnAirportTransfer,
                updatedLegs, "ownAirportTransfer",
                _showOwnAirportTransfer(
                    indexToUpdateOwnAirportTransfer,
                    updatedLegs
                )
            )
        )
    }

    const _renderArrivalAirport = (index: number, leg: LegForUi) => {
        const {airport, errors} = leg
        return <AutocompleteQuery
            placeholder={"ACTIVITIES.FLIGHT.PLACEHOLDER_AIRPORT"}
            ariaErrorMessage={"ACTIVITIES.FLIGHT.ARIA_LABELS.ERROR_AIRPORT_SELECT_FROM_LIST"}
            labelColor={"primary"}
            label={"GENERAL.AIRPORT"}
            required={true}
            containerStyle={{width: "100%"}}
            containerClasses={'choose-compensateFlight-connection-last-leg-width'}
            defaultValue={airport}
            error={errors.airport}
            queryType={AUTOCOMPLETE_QUERY_TYPE.airport}
            type={AUTOCOMPLETE_ACTIVITY_TYPE.flight}
            listBoxClasses={'choose-compensateFlight-connection-last-leg-listbox-width'}
            setSelection={airport => _setAirport(index, airport as AirportResponse, leg)}/>
    }

    const _renderFlightConnection = (index: number, leg: LegForUi) => <FlightSelector
        aircrafts={aircrafts}
        aircraftsNetworkError={aircraftsNetworkError}
        travelClasses={travelClasses}
        airportError={leg.errors.airport}
        flightNumberError={leg.errors.flightNumber}
        airportLabel={"GENERAL.AIRPORT"}
        required={index === 0 || index === legs.length - 1 ? true : false}
        leg={leg}
        onBlurFlightNumber={async () => !gettingFlightsByFlightNumberAndDate.current && _setFlightsWithDateAndFlightNumber(index)}
        setFlightNumber={flightNumber => setLegs(_updateFlightDataOnCurrentLeg(index, legs, "flightNumber", flightNumber))}
        onBlurFlightDate={async () => !gettingFlightsByFlightNumberAndDate.current && _setFlightsWithDateAndFlightNumber(index)}
        setDate={date => setLegs(_updateFlightDataOnCurrentLeg(index, legs, "date", date))}
        setAirline={airline => _setAirline(index, airline, leg)}
        setAirport={airport => _setAirport(index, airport, leg)}
        setAircraftType={aircraftType => _setAircraftType(index, aircraftType, leg)}
        setTravelClass={travelClass => setLegs(_updateFlightDataOnCurrentLeg(index, legs, "travelClass", travelClass))}/>

    const _showOwnAirportTransfer = (index: number, allLegs: Leg[]) => {
        let showOwnAirportTransfer = true
        if (
            (allLegs[index - 1] && allLegs[index - 1].ownAirportTransfer) ||
            index === allLegs.length - 2
        ) showOwnAirportTransfer = false
        return showOwnAirportTransfer
    }

    const _renderStopover = (index: number, leg: LegForUi) => {
        const showOwnAirportTransfer = _showOwnAirportTransfer(index, legs)
        return <div
            style={{width: "100%"}} key={index}>
            <Text
                weight={'bold'}
                color={"primary"}
                variant={'subtitleLarge'}>
                {`${index}. ${t("ACTIVITIES.FLIGHT.STOPOVER")}`}
            </Text>
            <div
                className={'choose-flight-stopover-wrapper'}>
                {_renderFlightConnection(index, leg)}
                <div
                    className={'choose-flight-airport-transfer-checkbox'}>
                    <CheckboxInput
                        containerStyle={{visibility: showOwnAirportTransfer ? "visible" : "hidden"}}
                        checked={Boolean(legs[index].ownAirportTransfer)}
                        onChange={() => setLegs(_updateFlightDataOnCurrentLeg(index, legs, "ownAirportTransfer", !leg.ownAirportTransfer))}
                        label={"ACTIVITIES.FLIGHT.AIRPORT_TRANSFER"}
                        hideLabel={true}/>
                    <Text
                        style={{visibility: showOwnAirportTransfer ? "visible" : "hidden"}}>ACTIVITIES.FLIGHT.AIRPORT_TRANSFER</Text>
                    <IconButton
                        tooltip={"ACTIVITIES.FLIGHT.REMOVE_STOPOVER_TOOLTIP"}
                        icon={'minus'}
                        onClick={() => _removeStopover(index)}
                        variant={'warning'}
                        size={'medium'}
                    />
                </div>
            </div>
        </div>
    }

    const _renderNumberOfPassengerFlightsReturnFlight = () => {
        const {minNumberFlights, maxNumberPassenger, minNumberPassenger} = FLIGHT_PROPERTIES
        const WIDTH_INPUT = 30;
        return <Fieldset
            legend={"ACTIVITIES.FLIGHT.ARIA_LABELS.NUMBER_FLIGHT_PERSONS_RETURN"}
            showLegend={false}
            fieldsetClasses={'choose-flight-additional-options-wrapper'}>
            <NumberInput
                labelColor={"primary"}
                error={numberPassengersError}
                ariaErrorMessage={t("ACTIVITIES.FLIGHT.ARIA_LABELS.MIN_MAX_FLIGHTS_EXCEEDED_ERROR", {
                    min: minNumberFlights,
                    max: maxNumberPassenger
                })}
                min={minNumberPassenger}
                max={maxNumberPassenger}
                injectMinimum={true}
                required={true}
                label={"GENERAL.PASSENGER"}
                onBlur={setPassengerCount}
                onChange={setPassengerCount}
                containerStyle={{width: `${WIDTH_INPUT}px`}}
                value={passengerCount}/>
            <CheckboxInput
                labelColor={"primary"}
                checked={returnFlight}
                inputClasses={'choose-compensateFlight-connection-checkbox-input'}
                label={"ACTIVITIES.FLIGHT.RETURN_FLIGHT_SINGULAR"}
                onChange={() => setReturnFlight(!returnFlight)}/>
            <Text
                style={{color: theme.colors.primary}}
                variant={'subtitleLarge'}
                classes={'choose-flight-additional-options-information-text'}>
                {createRoundtripPassengersCountString({
                    flightCount: 0,
                    passengerCount,
                    forString: t("GENERAL.FOR"),
                    passengerCountString: t(generatePassengerString(passengerCount)),
                    returnOrOnewayString: t(generateFlightTypeString(1, returnFlight))
                })}
            </Text>
            {returnFlight && legs.some(leg => leg.flightNumber) && <Text
                color={"warningLight"}
                variant={"subtitleLarge"}>SCREENS.FLIGHT.RETURN_FLIGHT_HINT</Text>}
        </Fieldset>
    }

    const _renderResultString = (
        status: CalculatedEmissionsResponseTotal["status"],
        emissionKgCO2eTotal: CalculatedEmissionsResponseTotal["emissionKgCO2eTotal"]
    ) => <>
        <Text variant={"headlineMedium"}>
            {
                status !== CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.failed ?
                    formatCo2ETotal(emissionKgCO2eTotal!, currentLanguage) :
                    `${NO_DATA_STRING} ${E_CO2}`
            }
        </Text>
        {onlyUsedAirportsForEmissionCalculation &&
            <Text color={'warningLight'}>SCREENS.COMMON.HINT_BETTER_FLIGHT_EMISSION_CALCULATION_GENERAL</Text>}
    </>

    const _renderResult = () => {
        if (resultCalculatingEmissions) {
            const {status, emissionKgCO2eTotal} = resultCalculatingEmissions
            return _renderResultString(status, emissionKgCO2eTotal)
        }

        if (isCalculatedFlightEmissionsErrorResponse(errorCalculatingEmissions)) {
            const {data} = errorCalculatingEmissions
            const {status, emissionKgCO2eTotal} = data
            return _renderResultString(status, emissionKgCO2eTotal)
        }

        return null
    }

    const _setCommonFlightRequestProps = (leg: Leg, index: number, departureAirportIata: string, arrivalAirportIata: string): FlightRequestLeg => {
        const {flightNumber, date} = leg
        let flightDate: string | undefined = ''
        if (flightNumber && date) {
            flightDate = _formatDateToRequestFormat(date)
        } else {
            flightDate = undefined
        }

        return {
            index,
            type: CALCULATION_TYPE.flight,
            passengerCount,
            airline: leg.airline ? leg.airline?.iata : null,
            travelClass: convertUiTravelClassToApiTravelClass(leg.travelClass),
            aircraftType: convertUiAircraftTypeToApiAircraftType(leg.aircraftType),
            flightCount: returnFlight ? 2 : 1,
            departure: departureAirportIata,
            arrival: arrivalAirportIata,
            flightDate,
            flightNumber: flightNumber ?? undefined,
        }
    };

    const _getIndicesWithOwnAirportTransfer = (legs: LegForUi[]) => legs
        .map((leg, index) => leg.ownAirportTransfer ? index : -1)
        .filter(index => index !== -1);

    const _convertLegsIntoRequestFormat = () => {
        const allFlights: FlightRequestLeg[] = []
        let flightIndex = 0
        const legIndexWithOwnAirportTransfer = _getIndicesWithOwnAirportTransfer(legs)

        for (let i = 0; i < legs.length; i++) {
            if (i === legs.length - 1) break
            if (legIndexWithOwnAirportTransfer.includes(i)) i += 1
            let departureLeg = {} as LegForUi
            let arrivalLeg = {} as LegForUi
            departureLeg = legs[i];
            arrivalLeg = legs[i + 1];

            allFlights.push(
                _setCommonFlightRequestProps(
                    departureLeg,
                    flightIndex,
                    departureLeg.airport?.iata!!,
                    arrivalLeg.airport?.iata!!
                )
            )
            flightIndex += 1
        }
        return allFlights
    }

    const _calculateEmissions = async () => {
        flightsForRequestFormat.current = []
        flightsForRequestFormat.current = _convertLegsIntoRequestFormat()
        // flightsForRequestFormat.current = _triggerErrors(11)
        try {
            calculateEmissions({flightActivities: flightsForRequestFormat.current})
        } catch (error) {
            console.error(`Something went wrong when transforming flights into request format: ${error}`);
        }
    }

    const _evaluateForm = () => {
        // general form errors
        let duplicatedAirportErrorIndex: number | null = null
        const {minNumberPassenger, maxNumberPassenger} = FLIGHT_PROPERTIES
        let updatedLegs = cloneDeep(legs)
        legs.forEach((leg, index) => {
            const nextLegIndex = index + 1
            const {ownAirportTransfer, flightNumber, airport} = leg
            if (flightNumber) {
                updatedLegs[index].errors.flightNumber = !_isValidFlightNumber(flightNumber)
            } else {
                updatedLegs[index].errors.flightNumber = false
            }
            // duplicated airports
            if (!ownAirportTransfer && legs[nextLegIndex]) {
                if (isEqual(airport, updatedLegs[nextLegIndex].airport)) {
                    updatedLegs[nextLegIndex].errors.airport = true
                    duplicatedAirportErrorIndex = nextLegIndex
                }
            }

            // avoid overwriting error in airport
            if (duplicatedAirportErrorIndex === null || (duplicatedAirportErrorIndex !== null && index !== duplicatedAirportErrorIndex)) updatedLegs[index].errors.airport = _hasMissingAirport(leg)
        })

        const numberOfPassengersError = !(passengerCount >= minNumberPassenger && passengerCount <= maxNumberPassenger)
        const anyErrors = updatedLegs.some(leg => leg.errors.airport || leg.errors.flightNumber)
        setLegs(updatedLegs)
        setNumberPassengersError(numberOfPassengersError)
        if (!(
            anyErrors ||
            numberOfPassengersError
        )) _calculateEmissions();
    }

    const _renderStopoverAddButton = (key: number) => <IconAndText
        key={key}
        text={"ACTIVITIES.FLIGHT.ADD_OR_REMOVE_VIA_AIRPORT"}
        onClick={() => setLegs([...legs.slice(0, -1), emptyLeg, ...legs.slice(-1)])}
        iconColor={theme.colors.primary}
        icon={'plus'}/>

    const _renderLegs = () => {
        const allLegs: ReactNode[] = legs.map((leg, index) => {
            if (index === 0) {
                return <Fieldset
                    key={index}
                    role={"group"}
                    fieldsetClasses={'choose-flight-stopover-container'}
                    showLegend={true}
                    required={true}
                    legend={"ACTIVITIES.FLIGHT.DEPARTURE_AIRPORT"}>
                    {_renderFlightConnection(index, leg)}
                </Fieldset>
            } else if (index === legs.length - 1) {
                return <Fieldset
                    key={index}
                    fieldsetClasses={'choose-flight-stopover-container'}
                    fieldsetStyles={{justifyContent: 'flex-start',}}
                    showLegend={true}
                    required={true}
                    legend={"ACTIVITIES.FLIGHT.ARRIVAL_AIRPORT"}>
                    {_renderArrivalAirport(index, leg)}
                </Fieldset>
            } else {
                return <Fieldset
                    key={index}
                    role={"group"}
                    fieldsetClasses={'choose-flight-stopover-container'}
                    showLegend={index === 1 ? true : false}
                    required={false}
                    legend={getUiStopover(legs).length > 0 ? "ACTIVITIES.FLIGHT.STOPOVERS" : "ACTIVITIES.FLIGHT.STOPOVER"}>
                    {_renderStopover(index, leg)}
                </Fieldset>
            }
        })

        allLegs.splice(allLegs.length - 1, 0, _renderStopoverAddButton(allLegs.length))
        return allLegs
    }

    const _resetModalRefs = () => {
        selectedFlightsViaFlightNumberAndDateFromModal.current = []
        legIndexFromWhichModalWasOpened.current = 1
    }

    return <>
        <Text style={{marginBottom: theme.spacer.large}}>
            ACTIVITIES.FLIGHT.DESCRIPTION
        </Text>
        <div
            role={"form"}
            className={'choose-flight-route-selection-container'}>
            {_renderLegs()}
            {_renderNumberOfPassengerFlightsReturnFlight()}
            <div className={'choose-flight-position-button'}>
                {_renderResult()}
                <div className={'choose-flight-buttons-wrapper'}>
                    <TextButton
                        onClick={() => {
                            if (editIndex !== null) window.history.replaceState({}, document.title, `${_getDomain()}${PATH_STRINGS.addActivity}/${ACTIVITY_TYPE.flight}`);
                            resetFlightForm()
                        }}>
                        GENERAL.RESET
                    </TextButton>
                    <TextButton
                        disabled={disableCalculateButton}
                        onClick={() => _evaluateForm()}>
                        {editIndex !== null ? "GENERAL.CALCULATE_AGAIN" : "GENERAL.CALCULATE"}
                    </TextButton>
                </div>
            </div>
            {isCalculatingEmissions && <DelayedSpinner fullscreen={true} delayMs={500}/>}
        </div>
        <Modal
            headline={"ACTIVITIES.FLIGHT.CHOOSE_FLIGHTS"}
            show={modalVisible}
            closeButton={"GENERAL.ABORT"}
            confirmButton={"GENERAL.OK"}
            onClickConfirm={() => {
                setModalVisible(false)
                if (selectedFlightsViaFlightNumberAndDateFromModal.current.length > 0) _fillFormBasedOnFlightNumberAndDate(legIndexFromWhichModalWasOpened.current, selectedFlightsViaFlightNumberAndDateFromModal.current)
            }}
            onClickClose={() => {
                _resetModalRefs()
                setModalVisible(false)
            }}
            showPaper={true}>
            <AmbiguousFlightList
                setFlight={selectedFlight => {
                    const selectedFlights = selectedFlightsViaFlightNumberAndDateFromModal.current
                    const indexOfSelectedFlight = findIndex(selectedFlights, (flight: FlightsResponse) => isEqual(flight, selectedFlight))
                    if (indexOfSelectedFlight !== -1) {
                        selectedFlightsViaFlightNumberAndDateFromModal.current = (selectedFlights.slice(0, indexOfSelectedFlight).concat(selectedFlights.slice(indexOfSelectedFlight + 1)))
                    } else {
                        selectedFlightsViaFlightNumberAndDateFromModal.current = ([...selectedFlights, selectedFlight])
                    }
                }}
                flights={modalContent}
            />
        </Modal>
    </>
}

export default ChooseFlight