import {UserProps} from "../api/model/user";
import {UserState} from "../redux/reducer/user";
import {CompanyProps} from "../api/model/company";

export const getCompany = (isKlimaLinkAdmin: UserProps["isKlimaLink"], usersCompanyId: UserProps["companyId"], companies: UserState['companies'],) => {
    if (companies) {
        if (isKlimaLinkAdmin) return companies.filter(company => company.id === usersCompanyId)[0]
        return companies[0]
    }
    return {} as CompanyProps
}

export const getAgencies = (agencies: UserState['agencies'], userData: UserState['userData']) => agencies?.filter(agency => userData?.agencyIds.includes(agency.id))