import {allInternationalPhonePrefixesFormattedForDropdownAlpha2CountryCode} from "../settings/utils";
import {AgencyProps} from "../../api/model/agency";

export const validateEmail = (email: string | null | undefined) => {
    if (email) return (/^\w+([\.\-\+]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(email.trim()))
    return false
}

export const validateCompanyName = (companyName: string | null) => {
    if (companyName) return companyName.length >= 3
    return false
}

export const validatePhoneNumber = (telephone: string) => telephone.trim().length > 6 && !isNaN(Number(telephone))

export const validateStreet = (address: string) => address.trim().length >= 3

export const validateZipCode = (zipCode: string) => zipCode.trim().length >= 3

export const validateCity = (city: string) => city.trim().length >= 3

export const validateName = (name: string) => name.trim().length >= 2

export const validateContactPerson = (name: string | null | undefined) => {
    if (name) return name.trim().length >= 3
    return false
}

export const validateHouseNumber = (houseNumber: string) => houseNumber.trim().length >= 1

export const validateCountry = (country: string | null | undefined) => country !== null || typeof country === 'undefined'

export const validateAdditionalAddressInformation = (additionalAddressInformation: string) => additionalAddressInformation.trim().length > 2

export const validateFirstAndLastName = (name: string | null | undefined) => {
    if (name) return name.trim().length >= 3
    return false
}

export const validTravelComponentsOrJounrneyName = (name: string | null | undefined) => {
    if (name) return name.trim().length >= 3
    return false
}

export const validateAgency = (agency: AgencyProps | null | undefined) => agency instanceof Object

export const validateInternationalPhonePrefix = (phonePrefix: keyof typeof allInternationalPhonePrefixesFormattedForDropdownAlpha2CountryCode | null | undefined) => phonePrefix !== null || typeof phonePrefix !== 'undefined'

