import {createSlice, current, PayloadAction} from '@reduxjs/toolkit';
import {ActivityCommonProperties} from "../../types-enums/activity-common-properties";
import {deleteItem} from "./journey-utils";
import {getActivities, getActivitiesByTravelOptionTypeAndActivityTypeAndDay} from "../../utils/activities-getter";
import {TRAVEL_OPTION_TYPE} from "../../types-enums/travel-option";
import {
    AllActivityTypes,
    AllActivityTypesArrayDiscriminating,
    TravelOptionToActivitiesMap
} from "../../types-enums/activity";
import {DateTime} from "luxon";

export interface JourneyState {
    title: string | number,
    customer?: string,
    startTravelDate: DateTime,
    daysCount: number,
    personsCount: number,
    lastEdited: DateTime,
    lastEditedFrom: string,
    place: string,
    travelOptions: TravelOptionToActivitiesMap,
    queueForDeleteActivity: { [key: string]: { index: number, activity: AllActivityTypesArrayDiscriminating } },
    queueForDeleteDay: {
        [key: string]: { day: number, activities: AllActivityTypesArrayDiscriminating }
    },
}

const initialState: JourneyState = {
    title: 9876543,
    startTravelDate: DateTime.now(),
    daysCount: 4,
    lastEdited: DateTime.now(),
    lastEditedFrom: "Maria Schneider",
    personsCount: 2,
    place: '',
    queueForDeleteActivity: {},
    queueForDeleteDay: {},
    travelOptions: {} as TravelOptionToActivitiesMap
};

export const journeySlice = createSlice({
    name: 'journey',
    initialState,
    reducers: {
        deleteDayFromJourney: (state, action: PayloadAction<{
            id: string,
            day: ActivityCommonProperties["common"]["day"]
        }>) => {
            const {payload} = action
            const payloadDay = payload.day
            const id = payload.id
            const {travelOptions} = state;
            let activitiesForQueue = [] as AllActivityTypes[]
            getActivities(travelOptions, (activities, travelOptionType) => {
                let index = activities.length
                while (index >= 0) {
                    const activity = activities[index]
                    if (activity) {
                        const {common} = activity
                        const activityDay = common.day
                        if (activityDay) {
                            if (activityDay === payloadDay) {
                                activitiesForQueue.push(activity)
                                travelOptions[travelOptionType].splice(index, 1)
                            }
                            if (activity.common.day && payloadDay && activityDay > payloadDay) activity.common.day -= 1
                        }
                    }
                    index -= 1
                }
            });
            state.daysCount -= 1
            if (payloadDay) state.queueForDeleteDay[id] = {day: payloadDay, activities: activitiesForQueue}
        },
        undoDeleteDayFromJourney: (state, action: PayloadAction<string>) => {
            const activitiesFromQueue = state.queueForDeleteDay[action.payload].activities
            const payloadDay = state.queueForDeleteDay[action.payload].day
            const {travelOptions, daysCount} = state;
            getActivities(travelOptions, activities => {
                activities.forEach(activity => {
                    const {common} = activity
                    const activityDay = common.day
                    if (payloadDay === 1) {
                        if (activity.common.day) activity.common.day += 1
                    } else if (payloadDay > daysCount) {
                        return
                    } else {
                        if (activityDay && activity.common.day) {
                            if (activityDay >= payloadDay) activity.common.day += 1
                            if (activityDay < payloadDay) activity.common.day -= 1
                        }
                    }
                });
            });
            activitiesFromQueue.forEach(activityFromQueue => {
                const {travelOptionType} = activityFromQueue.common
                //@ts-ignore
                state.travelOptions[travelOptionType as keyof typeof TRAVEL_OPTION_TYPE].push(activityFromQueue)
            })
            state.daysCount += 1
        },
        deleteDayFromJourneyDayQueue: (state, action: PayloadAction<string>) => {
            delete state.queueForDeleteDay[action.payload]
        },
        deleteJourney: () => {
            return initialState
        },
        dragAndDropDay: (state, action: PayloadAction<{
            originalDay: ActivityCommonProperties["common"]["day"],
            newDay: ActivityCommonProperties["common"]["day"]
        }>) => {
            const {travelOptions} = state;
            const {originalDay, newDay} = action.payload
            let dragAndDropDirection: "topToBottom" | "bottomToTop" = "bottomToTop"
            if (typeof newDay === "number" && typeof originalDay === "number") {
                if (newDay > originalDay) dragAndDropDirection = "topToBottom"
                getActivities(travelOptions, activities => {
                    activities.forEach(activity => {
                        const {common} = activity
                        const activityDay = common.day
                        if (typeof activityDay === "number" && typeof activity.common.day !== "undefined") {
                            if (dragAndDropDirection === "topToBottom") {
                                if (activityDay < originalDay) {
                                    // do nothing
                                } else if (activityDay === originalDay) {
                                    activity.common.day = newDay
                                } else if (activityDay <= newDay) {
                                    activity.common.day -= 1
                                }
                            }

                            if (dragAndDropDirection === "bottomToTop") {
                                if (activityDay < newDay) {
                                    // do nothing
                                } else if (activityDay < originalDay) {
                                    activity.common.day += 1
                                } else if (activityDay === originalDay) {
                                    activity.common.day = newDay
                                }
                            }
                        }
                    });
                });
            }
        },
        deleteActivityFromJourney: (state, action: PayloadAction<{
            activityType: ActivityCommonProperties["common"]["activityType"],
            travelOptionType: TRAVEL_OPTION_TYPE,
            day: ActivityCommonProperties["common"]["day"],
            index: number,
            id: string,
        }>) => {
            const {activityType, travelOptionType, day, index, id} = action.payload
            const activity = [getActivitiesByTravelOptionTypeAndActivityTypeAndDay(current(state.travelOptions), travelOptionType, activityType, day)[index]]
            state.queueForDeleteActivity[id] = {index, activity}
            state.travelOptions = deleteItem(state.travelOptions, {activityType, day, index})
        },
        undoDeleteActivityFromJourney: (state, action: PayloadAction<{
            activityType: ActivityCommonProperties["common"]["activityType"],
            travelOptionType: TRAVEL_OPTION_TYPE,
            id: string,
            index: number,
            day: ActivityCommonProperties["common"]["day"]
        }>) => {
            const {travelOptionType, activityType, id, index, day} = action.payload
            const activities = getActivitiesByTravelOptionTypeAndActivityTypeAndDay(current(state.travelOptions), travelOptionType, activityType, day)
            const item = state.queueForDeleteActivity[id]
            activities.splice(index, 0, item.activity[0])
            //@ts-ignore
            state.travelOptions[travelOptionType] = activities
            delete state.queueForDeleteActivity[id]
        },
        deleteActivityFromJourneyActivityQueue: (state, action: PayloadAction<string>) => {
            delete state.queueForDeleteActivity[action.payload]
        }
    }
});

export const {
    deleteDayFromJourney,
    undoDeleteDayFromJourney,
    deleteJourney,
    deleteActivityFromJourney,
    undoDeleteActivityFromJourney,
    deleteActivityFromJourneyActivityQueue,
    deleteDayFromJourneyDayQueue,
    dragAndDropDay
} = journeySlice.actions;
export default journeySlice.reducer;