import {ActivityFlightState, Leg} from "../types-enums/activity-flight";
import {
    ADDITIONAL_FLIGHT_INFORMATION_SEPARATOR,
    formatCo2EPerPerson,
    formatCo2ETotal,
    formatDate,
    ROUTE_SEPARATOR
} from "./string-mutation";
import {getFlightFailedOrSuccessfulLegs} from "./flight-getter";
import {i18n} from "i18next";
import {
    CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL,
    CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL
} from "../api/model/common";
import {DateTime} from "luxon";

export const generateProfessionalFlightRouteStringWithoutCo2 = (legs: ActivityFlightState['legs'], i18n: i18n) => {
    const {t} = i18n
    let onlyUsedAirportsForEmissionCalculation = true
    const legsString = legs.map((leg, index) => {
        if (index < legs.length - 1) {
            const {airline, aircraftType, travelClass} = leg
            if (airline || travelClass || aircraftType) onlyUsedAirportsForEmissionCalculation = false
            const stopoverAdditionalInformation = [
                leg?.airline?.iata,
                leg?.aircraftType?.iata,
                leg?.travelClass?.name ? t(leg?.travelClass?.name) : false
            ].filter(Boolean).join(ADDITIONAL_FLIGHT_INFORMATION_SEPARATOR)
            return `${leg?.airport?.iata} ${stopoverAdditionalInformation.length === 0 ? "" : `(${stopoverAdditionalInformation})`}`
        } else {
            return leg?.airport?.iata
        }
    }).filter(Boolean).join(`${ROUTE_SEPARATOR}`)

    return {flightRouteString: legsString, showFootnote: onlyUsedAirportsForEmissionCalculation}
}

export const generateCustomerFlightRouteStringForClipboard = (
    flight: ActivityFlightState,
    i18n: i18n,
    withCo2E: boolean,
) => {
    const {legs, common} = flight
    let flightRouteString
    let onlyUsedAirportsForEmissionCalculation = true
    if (common) {
        const {t, resolvedLanguage} = i18n
        const {totalEmissions} = common
        if (totalEmissions) {
            const {data} = totalEmissions
            let previousDate: DateTime | null = null
            flightRouteString = data.map(stop => {
                let arrivalAirport
                let dateString = ""
                let airlineString = ""
                let travelClassString = ""
                let metaDataString = ""
                let departure: Leg
                let arrival: Leg
                const {index, emissionKgCO2e} = stop
                departure = legs[index]
                arrival = legs[index + 1]
                const departureAirport = departure.airport
                const {
                    date: departureDate,
                    airline: deparureAirline,
                    travelClass: departureTravelClass,
                    aircraftType: departureAircraftType
                } = departure
                if (departureDate) {
                    if (departureDate !== previousDate) {
                        dateString = `${formatDate(departureDate, resolvedLanguage)}: `
                    } else {
                        dateString = ""
                    }
                }
                if (deparureAirline || departureTravelClass || departureAircraftType) onlyUsedAirportsForEmissionCalculation = false
                if (departureTravelClass) travelClassString = t(departureTravelClass.name)
                if (deparureAirline) airlineString = deparureAirline.name
                if (arrival) arrivalAirport = arrival.airport
                if (airlineString.length > 0 && travelClassString.length > 0) {
                    metaDataString = `, ${airlineString}, ${travelClassString}`
                } else if (airlineString.length > 0) {
                    metaDataString = `, ${airlineString}`
                } else if (travelClassString.length > 0) {
                    metaDataString = `, ${travelClassString}`
                } else {
                    metaDataString = ""
                }
                let routeString = `${departureAirport?.name} (${departureAirport?.iata})${ROUTE_SEPARATOR}${arrivalAirport?.name} (${arrivalAirport?.iata})`
                if (onlyUsedAirportsForEmissionCalculation) routeString = `${routeString}*`
                let flightString = `${dateString}${routeString}${metaDataString}`
                if (withCo2E) flightString = `${flightString} ${formatCo2ETotal(emissionKgCO2e!!, resolvedLanguage)}`
                previousDate = departureDate
                return flightString
            }).filter(Boolean).join("; ")
        }
    }
    return {flightRouteString, showFootnote: onlyUsedAirportsForEmissionCalculation}

}

export const generateCo2E = (flight: ActivityFlightState, i18n: i18n) => {
    const {resolvedLanguage} = i18n
    let co2E: string[] = []
    const {common} = flight
    if (common) {
        const {totalEmissions} = common
        if (totalEmissions) {
            const {data} = totalEmissions
            const {passengerCount} = flight
            co2E = data.map(stop => {
                const {emissionKgCO2e, status} = stop
                if (status === CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success) return `${formatCo2EPerPerson(emissionKgCO2e!!, passengerCount, resolvedLanguage)} p. P.`
                return ROUTE_SEPARATOR
            })
        }
    }
    return co2E

}

export const generateCustomerFlightRouteStringWithMetaDataAndCo2ForPdf = (flight: ActivityFlightState, i18n: i18n) => {
    const {legs} = flight
    let showFootNote = false
    const {resolvedLanguage, t} = i18n
    let flightRoute: string[] = []
    const {common} = flight
    if (common) {
        const {totalEmissions} = common
        if (totalEmissions) {
            const {data} = totalEmissions
            flightRoute = data.map((_, index) => {
                const departure = legs[index]
                const arrival = legs[index + 1]
                const {airport: departureAirport} = departure
                const {airport: arrivalAirport} = arrival
                const {
                    airline: departureAirline,
                    travelClass: departureTravelClass,
                    aircraftType: depatureAircraftType,
                    date: departureFlightDate
                } = departure
                const departureTravelClassName = departureTravelClass?.name
                let departureMetaDataString = ""
                if (
                    (!departureAirline || !departureTravelClass || !depatureAircraftType) &&
                    !showFootNote
                ) showFootNote = true
                let routeString = `${departureAirport?.name} (${departureAirport?.iata})${ROUTE_SEPARATOR}${arrivalAirport?.name} (${arrivalAirport?.iata})`
                if (!departureAirline || !departureTravelClass || !depatureAircraftType) routeString = `${routeString}*`
                const departureAirlineString = `${departureAirline?.name ?? ""}`
                const departureTravelClassString = `${departureTravelClassName ? i18n.t(departureTravelClassName) : ""}`
                const departureFlightDateString = `${departureFlightDate ? formatDate(departureFlightDate, resolvedLanguage) + ": " : ""}`
                if (departureAirlineString.length > 0 && departureTravelClassString.length > 0) {
                    departureMetaDataString = `, ${departureAirlineString}, ${departureTravelClassString}`
                } else if (departureAirlineString.length > 0) {
                    departureMetaDataString = `, ${departureAirlineString}`
                } else if (departureTravelClassString.length > 0) {
                    departureMetaDataString = `, ${departureTravelClassString}`
                } else {
                    departureMetaDataString = ""
                }
                let flightString = `${departureFlightDateString}${routeString}${departureMetaDataString}`
                return flightString
            })
        }
    }
    if (flight.common.totalEmissions?.status === CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success_with_errors) flightRoute.unshift(t("ERRORS.CALCULATION_PARTIAL_FAILURE"))
    return {flightRoute, showFootNote}
}

export const generateFailedOrSuccessfulFlightLegsString = (
    flight: ActivityFlightState,
    responseStatis: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL,
    i18n: i18n,
) => {
    const {resolvedLanguage, t} = i18n
    let emissionsCo2EString = ""
    const failedLegs = getFlightFailedOrSuccessfulLegs(flight, responseStatis)
    const {passengerCount, returnFlight} = flight
    let co2ETotal = 0
    if (flight.common.totalEmissions) {
        co2ETotal = flight.common.totalEmissions.emissionKgCO2eTotal!
        emissionsCo2EString = `${formatCo2EPerPerson(co2ETotal, passengerCount, resolvedLanguage)} ${t("GENERAL.PER_PERSON")} (${formatCo2ETotal(co2ETotal!, resolvedLanguage)} ${i18n.t("GENERAL.TOTAL")})`
    }
    const failedLegsString = failedLegs?.map(leg => {
        const departureLeg = leg[0]
        const arrivalLeg = leg[1]
        const departureAirport = departureLeg.airport
        const arrivalAirport = arrivalLeg.airport
        return `${departureAirport?.name} (${departureAirport?.iata}) - ${arrivalAirport?.name} (${arrivalAirport?.iata})`
    }).filter(Boolean).join(", ")

    let summaryString = ""
    if (responseStatis === CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success) {
        if (returnFlight) {
            summaryString = t("ACTIVITIES.FLIGHT.CALCULATION_SUCCESSFUL_FOR_LEGS_WITH_RETURN_FLIGHT")
        } else {
            summaryString = t("ACTIVITIES.FLIGHT.CALCULATION_SUCCESSFUL_FOR_LEGS_WITHOUT_RETURN_FLIGHT")
        }
    } else {
        summaryString = t("ACTIVITIES.FLIGHT.CALCULATION_FAILED_FOR_LEGS")
    }

    return `${summaryString} ${failedLegsString}. ${emissionsCo2EString.length > 0 && t("ACTIVITIES.FLIGHT.CALCULATION_DONE_FOR_LEGS") + " " + emissionsCo2EString}`
}
