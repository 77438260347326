import React, {useEffect} from 'react';
import {Navigate, useLocation} from "react-router-dom";
import {useFusionAuth} from "@fusionauth/react-sdk";
import {PATH_STRINGS} from "./routes/route-props";
import AuthenticatingUser from "./screens/authenticating-user/AuthenticatingUser";
import FullscreenSpinner from "./molecules/fullscreen-spinner/FullscreenSpinner";

const App = () => {
    const {isAuthenticated, isLoading, login} = useFusionAuth()
    const location = useLocation();

    useEffect(() => {
        if (!isLoading && !isAuthenticated) login()
    }, [isAuthenticated, isLoading, location.pathname]);

    if (isLoading) return <AuthenticatingUser/>
    if (isAuthenticated) return <Navigate to={PATH_STRINGS.travelDatabase}/>
    if (!isAuthenticated) login()
    return <FullscreenSpinner showLogo={true}/>
}
export default App;
