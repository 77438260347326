import {PATH_STRINGS} from "../routes/route-props";
import {FusionAuthConfig} from '@fusionauth/react-sdk';

// export const config: FusionAuthConfig = {
//     clientID: "c29aacd3-a8b2-4f0b-b624-b5bb8acf0ab0",
//     redirectUri: `https://b2b.dev.klimalink.org${PATH_STRINGS.redirectOnSuccessfulLogin}`,
//     serverUrl: "https://session-sso.dev.klimalink.org"
// }

export const config: FusionAuthConfig = {
    clientID: "c29aacd3-a8b2-4f0b-b624-b5bb8acf0ab0",
    redirectUri: `${process.env.REACT_APP_BASE_ENDPOINT_FUSION_AUTH_REDIRECT_URI}${PATH_STRINGS.redirectOnSuccessfulLogin}`,
    serverUrl: `${process.env.REACT_APP_BASE_ENDPOINT_FUSION_AUTH_SERVER_URL}`
}