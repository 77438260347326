const german = {
    GENERAL: {
        POWERED_BY: "Unterstützt von",
        DESCRIPTION: "Beschreibung",
        START_DATE: "Startdatum",
        END_DATE: "Enddatum",
        TRAVEL_COMPONENT_NAME: "Reisebaustein-Name",
        CONTACT_PERSON: "Kontaktperson",
        CUSTOMER_FIRST_NAME: "Kunde_in Vorname",
        CUSTOMER_LAST_NAME: "Kunde_in Nachname",
        CUSTOMER_EMAIL: "Kunde_in E-Mail",
        CUSTOMER_PHONE: "Kunde_in Telefonnummer",
        CUSTOMER_STREET: "Kunde_in Straße",
        CUSTOMER_CITY: "Kunde_in Stadt",
        CUSTOMER_HOUSE_NUMBER: "Kunde_in Hausnummer",
        CUSTOMER_ZIP_CODE: "Kunde_in Postleitzahl",
        TOTAL_EMISSIONS: "Summe Emissionen",
        BACK: "Zurück",
        YES: "Ja",
        NO: "Nein",
        ADMIN: "Admin",
        EMAIL: "E-Mail",
        PHONE: 'Telefonnummer',
        DAY: "Tag",
        ABORT: "Abbrechen",
        SAVE: "Speichern",
        TRANSPORT: "Leistungen",
        ACCOMMODATION: "Unterkunft",
        UNDERTAKING: "Aktivität",
        ADD: "Hinzufügen",
        PROCEED: "Weiter",
        TOTAL: "gesamt",
        JOURNEY: "Reise",
        PERSONS: "Personen",
        DAYS: "Tage",
        FROM: "von",
        LAST_EDITED: "zuletzt bearbeitet",
        DELETE: "Löschen",
        SEARCH: "Suche",
        SHARE: "Teilen",
        NAME: "Name",
        DATE_OF_ACTIVITY: "Datum der Leistung",
        TRANSFERS: "Umstiege",
        EMISSIONS: "Emissionen",
        SPINNER: "Spinner, welcher solange angezeigt wird, bis Dateien geladen sind.",
        COPIED: "Kopiert",
        PASSENGER: 'Person',
        PASSENGERS: 'Personen',
        OPTION_OPTIONAL: 'Optional',
        FOR: "für",
        CALCULATE: "Berechnen",
        CALCULATE_AGAIN: "Erneut berechnen",
        UNDO: "Rückgängig machen",
        DATE: "Datum",
        FAILED: "Fehlgeschlagen",
        TOTAL_CO2: "Gesamt CO₂",
        RESET: "Zurücksetzen",
        SELECT_ALL: "Alles auswählen",
        OK: "OK",
        EMISSIONS_CALCULATED: "Emissionen erfolgreich berechnet!",
        EMISSIONS_CALCULATED_WITH_SCOPE: "Emissionen wurden erfolgreich auf der Ebene von {{scope}} berechnet!",
        GROUP: "Gruppieren",
        TRAVELERS_COUNT: "Anzahl Reisende",
        EMISSIONS_PER_PERSON: "Emissionen pro Person",
        PER_PERSON: "pro Person",
        INVITE: "Einladen",
        TRAVEL_COMPONENTS: "Reisebausteine",
        BETA_TOOLTIP: "Dies ist eine Betaversion. Manche Funktionalitäten können fehlerhaft sein. Falls Sie Feedback und Anregung haben, schicken Sie dieses bitte an info@klimalink.org.",
        CO2_E_EXPLANATION: 'Alle Emissionen sind in "kg CO₂-Äquivalent" (kg CO₂e) angegeben; dies entspricht der Menge CO₂, die denselben klimaerwärmenden Effekt hat wie die Summe aller produzierten Emissionen, einschließlich anderer Treibhausgase (z.B. Methan) und Effekte (Kondensstreifen, Ozonbildung).',
        AGENCY: "Agentur",
        AGENCY_NAME: "Agenturname",
        COMPANY_NAME: "Firmenname",
        FIRST_CONTACT_PERSON_FIRST_NAME: "1. Ansprechpartner_in Vorname",
        FIRST_CONTACT_PERSON_LAST_NAME: "1. Ansprechpartner_in Nachname",
        SECOND_CONTACT_PERSON_FIRST_NAME: "2. Ansprechpartner_in Vorname",
        SECOND_CONTACT_PERSON_LAST_NAME: "2. Ansprechpartner_in Nachname",
        FIRST_CONTACT_PERSON: "1. Ansprechpartner_in",
        SECOND_CONTACT_PERSON: "2. Ansprechpartner_in",
        STREET: "Straße",
        FIRST_NAME: "Vorname",
        LAST_NAME: "Nachname",
        HOUSE_NUMBER: "Hausnummer",
        ADDITIONAL_ADDRESS_INFORMATION: "Zusätzliche Inforamtionen",
        ZIP_CODE: "Postleitzahl",
        CITY: "Ort",
        TELEPHONE_NUMBER_WITH_AREA_CODE_LABEL: "Telefonnummer mit Vorwahl",
        COUNTRY_LABEL: "Land",
        COUNTRY_CODE_LABEL: "Ländervorwahl",
        HELP: "Hilfe",
        TERMS: "AGB",
        AIRPORT: 'Flughafen',
        HOTEL: 'Hotel',
        EINEN: 'einen',
        EIN: 'ein',
        EINE: 'eine',
        AIRLINE: "Airline",
        COUNTRY: 'Land',
        COMPANY_EMAIL: 'Firmen E-Mail',
        AGENCY_EMAIL: 'Agentur E-Mail',
        EMAIL_CONTACT_PERSON: '{{contactPerson}} E-Mail',
        SKIP: "Überspringen",
        LOADING: "Laden..."
    },
    TOASTS: {
        SUCCESS: {
            CREATE_BUNDLE: "Reisebausteine gespeichert.",
            UPDATE_USER_SUCCESS_ADMIN: "Expedient_in erfolgreich bearbeitet.",
            MAKE_USER_AGENCY_ADMIN: "Expedient_in ist nun Agenturadmin.",
            UNMAKE_USER_AGENCY_ADMIN: "Expedient_in ist nicht mehr Agenturadmin.",
            ADD_COMPANY: "Firma erfolgreich hinzugefügt.",
            ADD_AGENCY: "Agentur erfolgreich hinzugefügt.",
            EDIT_AGENCY: "Agentur erfolgreich bearbeitet.",
            EDIT_COMPANY: "Firma erfolgreich bearbeitet.",
            INVITE_COMPANY_ADMIN: "Die E-Mail an den Firmenadmin wurde versandt.",
            INVITE_USER: "Expedient_in erfolgreich eingeladen. Eine E-Mail mit weiteren Informationen wurde versandt.",
            DELETE_USER: "Expedient_in erfolgreich gelöscht.",
            DELETE_AGENCY: "Agentur erfolgreich gelöscht.",
            UPLOAD_AGENCY_LOGO: "Agenturlogo erfolgreich hochgeladen.",
            DELETE_AGENCY_LOGO: "Agenturlogo erfolgreich gelöscht.",
            CHANGE_USERS_AGENCY: "Expedient_in erfolgreich einer anderen Agentur zugeordnet.",
            SEND_PASSWORD_RESET_EMAIL: "Wir haben Ihnen eine E-Mail gesendet. Folgen Sie den Anweisungen in der E-Mail, um ein neues Passwort zu setzen.",
            UPDATE_USER_SELF: "Sie haben Ihre Daten erfolgreich bearbeitet.",
            HOTEL_FOUND_VIA_GIATA_ID: "Hotel mit GIATA ID gefunden.",
            HOTEL_FOUND_VIA_GIATA_ID_REPLACED_PREVIOUS: "Wir haben Ihre bisherigen Eingaben in Hotel, Land, Stadt und Sterne falls nötig, ersetzt.",

            REPLACED_AIRPORT_SINGULAR: "Wir haben den zuvor eingebenen Flughafen passend zur Flugnummer und Flugdatum automatisch korrigiert.",
            REPLACED_AIRPORT_PLURAL: "Wir haben einige der zuvor eingebenen Flughäfen passend zur Flugnummer und Flugdatum automatisch korrigiert.",
            GETTING_FLIGHTS_VIA_NUMBER_AND_DATE_SUCCESS: "Der Flug wurde gefunden und automatisch ergänzt.",

        },

        WARNING: {
            GETTING_FLIGHTS_VIA_NUMBER_AND_DATE_NO_FLIGHTS_FOUND_NO_AIRPORTS_IN_UI: "Mit dem eingebenen Datum und Flugnummer konnte kein Flug gefunden werden. Bitte geben Abflugs- und Ankunftsflughafen stattdessen ein.",
            GETTING_FLIGHTS_VIA_NUMBER_AND_DATE_NO_FLIGHTS_FOUND_AIRPORTS_IN_UI: "Mit dem eingebenen Datum und Flugnummer konnte kein Flug gefunden werden. Sie können stattdessn mit Start- und Ankunftsflughafen suchen.",
        },
        ERROR: {
            CREATE_BUNDLE: "Reisebausteine konnten nicht gespeichert.",
            UPDATE_USER_SELF: "Ihre Daten konnten nicht bearbeitet werden.",
            UPDATE_USER_ADMIN: "Expedient_in konnte nicht bearbeitet werden.",
            MAKE_USER_ADMIN: "Expedient_in konnte nicht zum Agenturadmin gemacht werden.",
            UNSET_USER_ADMIN: "Dem_der Expedient_in konnten nicht die Adminrecht entzogen werden.",
            ADD_COMPANY: "Firma konnte nicht hinzugefügt werden, bitte versuchen Sie es erneut.",
            EDIT_COMPANY: "Firma konnte nicht bearbeitet werden.",
            ADD_AGENCY: "Agentur konnte nicht hinzugefügt werden, bitte versuchen Sie es erneut.",
            EDIT_AGENCY: "Agentur konnte nicht bearbeitet werden.",
            INVITE_COMPANY_ADMIN: "Die E-Mail konnte nicht an den Firmenadmin versendet werden.",
            INVITE_USER: "Einladung konnte nicht gesendet werden.",
            DELETE_USER: "Expedient_in konnte nicht gelöscht werden",
            DELETE_AGENCY: "Agentur konnte nicht gelöscht werden.",
            UPLOAD_AGENCY_LOGO: "Agenturlogo konnte nicht hochgeladen werden.",
            DELETE_AGENCY_LOGO: "Agenturlogo konnte nicht gelöscht werden.",
            CHANGE_USERS_AGENCY: "Expedient_in konnte nicht der neuen Agentur zugeordnet werden.",
            SEND_PASSWORD_RESET_EMAIL: "E-Mail konnte nicht versendet werden.",
        }
    },
    NAVIGATION: {
        STATISTIC: "Statistik",
        JOURNEYS: "Reisen",
        HOME: "CO₂e berechnen",
        ADMINISTRATION: "Administration",
        SETTINGS: "Einstellungen",
        LOGO_ALT: "KlimaLinkLogo der Firma"
    },
    SCREENS: {
        HELP: {
            TITLE: "Hier wird Ihnen geholfen!",
        },
        CREATE_AGENCY_INVITE_USERS: {
            GENERAL_FAILED: "Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal. Sie können dennoch die Travel Footprint Database nutzen.",
            FAILED_CREATE_AGENCY: 'Agentur konnte nicht erstellt werden. Keine Sorge, Sie können eine Agentur unter dem Menüpunkt "Einstellungen" anlegen.',
            COMPLETE_FAIL_INVITE_USER: 'Expedient_innen konnten nicht eingeladen werden. Keine Sorge, Sie können Expedient_innen unter dem Menüpunkt "Einstellungen" einladen.',
            INVITE_USERS: {
                ADD_EMAIL_HINT: "Bitte E-Mail angeben",
                ADD_FIRST_NAME_HINT: "Bitte Vornamen angeben",
                ADD_LAST_NAME_HINT: "Bitte Nachnamen angeben",
                HEADLINE: "Expedient_innen einladen",
                ADD_EXPEDIENT_BUTTON: 'Weitere_n Expedient_in hinzufügen',
                REMOVE_EXPEDIENT_BUTTON: 'Expedient_in entfernen',
                NO_EXPEDIENT_HINT: "Sie können eine Agentur erstellen, ohne dass Sie dieser Expedient_innen hinzufügen. Sie können Expedient_innen im Nachhinein über die Einstellungen der Agentur hinzufügen."
            }
        },
        TERMS_AND_CONDITIONS: {
            COMPANY_ADMIN: {
                TITLE_WITH_ACCEPT: "Bitte akzeptieren Sie unsere allgemeinen Geschäftsbedingungen",
                TITLE_WITHOUT_ACCEPT: "Unsere allgemeinen Geschäftsbedingungen",
                CHECKBOX_LABEL: "Ich akzeptiere die allgemeinen Geschäftsbedingungen",
                UPDATE_USER_INFO_ERROR: "Die allgemeinen Geschäftsbedingungen konnten nicht akzeptiert werden. Bitte versuchen Sie es erneut."
            },
            NORMAL_USER: {
                TITLE: 'Allgemeine Geschäftsbedingungen',
                HINT: 'Sie können die Travel Database von KlimaLink derzeit nicht nutzen, da ihr Firmen-Admin die Allgemeinen Geschäftsbedingungen noch nicht akzeptiert hat.'
            },
        },
        NO_ROUTE: {
            TITLE: 'Huch, hier gibt es nix zu sehen...'
        },
        REGISTRATION: {
            REGISTRATION_COMPLETE_MESSAGE: "Registrierung erfolgreich",
            CREATING_AGENCY_MESSAGE: "Agentur wird erstellt...",
            INVITING_USERS_MESSAGE: "Einladungsemail wird an Expedient_innen versandt...",
            COMPANY_USER_TITLE: 'Bitte vervollständigen Sie Ihre Daten, um die Registrierung bei KlimaLink, der Travel Footprint Database, ab zu schließen',
            AGENCY_USER_TITLE: "Bitte legen Sie eine Agentur an und laden Sie Expedient_innen ein",
            GIVEN_INFORMATION_TITLE: "Bereits angelegte Informationen",
            MISSING_USER_INFORMATION_TITLE: "Bitte ergänzen Sie die fehlende Nutzer_innen Informationen für {{userName}} ({{email}})",
            COMPANY_ADMIN: {
                MISSING_COMPANY_INFORMATION_TITLE: "Bitte ergänzen Sie die Fehlende Firmeninformationen für {{company}} ergänzen",
            },
            UPDATE_USER_INFO_ERROR: "Registrierung konnte nicht abgeschlossen werden. Bitte versuchen Sie es erneut.",
            ARIA_LABELS: {
                TERMS_AND_CONDITIONS_ERROR: "Bitte akzeptieren Sie die allgemeinen Geschäftsbedingen",
            }
        },
        SETTINGS: {
            MENU: {
                ADMIN_SETTINGS: "Admin Einstellungen",
                USER_SETTINGS: "Persönliche Einstellungen",
                KLIMALINK_SETTINGS: "KlimaLink Einstellungen",
            },
            ASPECT_RATIO_LOGO_HINT: "Das von Ihnen ausgewählte KlimaLinkLogo hat keine optimalen Dimensionen. Idealerweise ist ihr Agenturlogo quadratisch.",
            NO_AGENCY_HINT_ADD_USERS: "Bitte legen Sie eine Agentur an. Erst dann können Sie Expedient_innen hinzufügen.",
            NO_AGENCY_HINT_ADD_LOGO: "Bitte legen Sie eine Agentur an. Erst dann können Sie ein Agenturlogo hochladen.",
            NO_AGENCY_HINT_USER_SETTINGS: "Bisher sind sie noch keiner Agentur zugehörig.",
            INVITE_COMPANY_ADMIN: "Firmenadmin zu KlimaLink einladen",
            AMI_I_AGENCY_ADMIN: "Bin ich Agenturadmin?",
            TITLE: "Einstellungen",
            PERSONAL_DATA: "Persönliche Daten",
            USER_SETTINGS_AGENCY_TITLE: "Agentur, der ich zugeordnet bin",
            USER_SETTINGS_AGENCIES_TITLE: "Meine Agenturen",
            ADD_USERS_NO_AGENCY_HINT: "Bitte fügen Sie eine Agentur hinzu. Erst wenn Sie eine Agentur hinzugefügt haben, können Sie Expedient_innen hinzufügen.",
            MAKE_ADMIN_MODAL_TITLE: "{{user}} zum Agenturadmin machen",
            UNMAKE_ADMIN_MODAL_TITLE: "{{user}} Agentur-Adminrechte entziehen",
            UNMAKE_USER_ADMIN_MODAL_TEXT: "Möchten Sie {{user}} die Agentur-Adminrechte entziehen? {{user}} kann dann keine Expedient_innen mehr zur Agentur hinzufügen oder entfernen, die Agentur nicht mehr löschen und das Agenturlogo nicht mehr bearbeiten.",
            MAKE_USER_ADMIN_MODAL_TEXT: "Möchten Sie {{user}} zum Agenturadmin machen? {{user}} kann dann Expedient_innen zur Agentur hinzufügen, die Agentur löschen und das Agenturlogo bearbeiten.",
            DELETE_USER: "Expedient_in {{user}} löschen?",
            DELETE_USER_TEXT: "Möchten Sie {{user}} wirklich löschen? {{user}} kann dann nicht mehr die Travel Footprint Database von KlimaLink verwenden. Die von {{user}} gespeicherten Daten bleiben jedoch erhalten.",
            DELETE_AGENCY_TEXT: "Möchten Sie {{agency}} wirklich löschen? Alle zu {{agency}} zugehörigen Expedient_innen werden ebenfalls gelöscht. Alle Daten, welche die Expedient_innen von {{agency}} gespeichert haben werden ebenfalls gelöscht.",
            DELETE_OWN_AGENCY_TEXT: "Da Sie die Agentur löschen der Sie ebenfalls angehören, wird Ihr Account ebenfalls gelöscht.",
            DELETE_COMPANY_TEXT: "Möchten Sie {{company}} wirklich löschen? Alle zu {{company}} zugehörigen Agenturen und Expedient_innen werden ebenfalls gelöscht. Alle Daten, welche die Expedient_innen von {{company}} gespeichert haben werden ebenfalls gelöscht. Ihr eigener Account wird ebenfalls gelöscht.",
            DELETE_AGENCY: "Agentur {{name}} löschen",
            DELETE_COMPANY: "Firma {{name}} löschen",
            LOADING_AGENCY_LOGO_ERROR: "Agenturlogo konnte nicht geladen werden.",
            CHANGE_PASSWORD_LABEL: "Passwort ändern",
            CHANGE_PASSWORD_REQUEST_EMAIL: "E-Mail erhalten",
            CHANGE_PASSWORD_EMAIL_SEND: "E-Mail gesendet",
            CHANGE_PASSWORD_TOOLTIP: "Wir haben eine E-Mail an Ihre hinterlegte Emailadresse mit Anweisung zum Ändern Ihres Passwortes geschickt.",
            EDIT_USER: "Nutzer_in bearbeiten",
            EDIT_USER_PERSONAL: "Persönliche Daten bearbeiten",
            ADD_NEW_USER: "Nutzer_in anlegen",
            ADD_NEW_AGENCY: "Agentur anlegen",
            ADD_NEW_COMPANY: "Firma anlegen",
            ADD_NEW_USER_FAIL: "Expedient_in konnte nicht hinzugefügt werden.",
            COMPANY_TITLE: "Firma",
            AGENCIES_TITLE: "Agenturen",
            USERS_TITLE: "Expedient_innen",
            EDIT_AGENCY: "Agentur bearbeiten",
            EDIT_COMPANY: "Firma bearbeiten",
            AGENCY_NAME_ALREADY_GIVEN: "Der Agenturname {{agencyName}} wird bereits verwendet. Bitte verwenden sie einen anderen.",
            INVITE_CONTACT_PERSON_FAIL: 'Eine Ihrer Kontaktpersonen konnte nicht zur Travel Footprint Database hinzugefügt werden',
            ADD_LOGO: "Agenturlogo",
            ADD_LOGOS: "Agenturlogos",
            NO_LOGO_UPLOADED_YET: "Kein KlimaLinkLogo bisher hochgeladen.",
            LOGO_UPLOADED_SUCCESS: "Folgende Datei hochgeladen",
            FILE_NAME: "Dateiname",
            DROP_LOGO_HOVER: "KlimaLinkLogo hier ablegen",
            DROP_LOGO_TEXT: "Datei hochladen oder per Drag & Drop reinziehen. Idealerweise 200x200px.",
            USERS_COUNT: "Anzahl Expedient_innen",
            IS_ADMIN_LABEL: "Agenturadmin",
            MAKE_ADMIN_TOOLTIP: "Zum Agenturadmin machen.",
            REMOVE_ADMIN_TOOLTIP: "Agentur-Adminrecht entziehen",
            EDIT_USER_TOOLTIP: "Expedient_in bearbeiten",
            EDIT_AGENCY_TOOLTIP: "Agentur bearbeiten",
            DELETE_AGENCY_TOOLTIP: "Agentur löschen",
            DELETE_USER_TOOLTIP: "Expedient_in löschen",
            DELETE_COMPANY_TOOLTIP: "Firma löschen",
            EDIT_COMPANY_TOOLTIP: "Firma bearbeiten",
            INVITE_CONTACT_PERSON: 'Möchten Sie den_die {{contactPerson}} zur Travel Footprint Database einladen?',
            MAKE_CONTACT_PERSON_AGENCY_ADMIN: 'Möchten Sie den_die {{contactPerson}} zum Agenturadmin machen?',
            ADD_USER_EMAIL_ALREADY_IN_USE: "Expedient_in kann nicht hinzugefügt werden. Die Emailadresse ({{email}}) wird bereits verwendet.",
            USER_REGISTRATION_NOT_COMPLETED: "Registrierung noch nicht abgeschlossen.",
            CHANGE_USERS_AGENCY_TOOLTIP: "Agenturzugehörigkeit ändern",
            CHANGE_USERS_AGENCY_MODAL_TITLE: "Ordnen Sie {{name}} einer anderen Agentur zu",
            ADD_SECOND_CONTACT: 'Weitere_n Ansprechpartner_in hinzufügen',
            CHANGE_USERS_AGENCY_MODAL_TEXT: "Aktuell ist {{name}} in {{oldAgency}}. Möchten Sie die Agenturzugehörigkeit von {{name}} ändern?",
            CHANGE_USERS_AGENCY_MODAL_TEXT_ADMIN: "{{name}} wird seine Adminrechte in der neuen Agentur verlieren. Sie können diese aber einfach erneut zuweisen.",
            IS_ADMIN_TOOLTIP: "Wenn Sie eine_n Nutzer_in zum Admin machen, kann diese_r Nutzer_innen anlegen und löschen, Informationen der Agentur bearbeiten sowie das Logo der Agentur bearbeiten.",
            USER_PREFERENCES: {
                TITLE: "Personalisierung",
                SHOW_GIATA_ID_FIELD_TEXT: "Möchten Sie bei der Hotelsuche die Möglichkeit haben, mit einer GIATA ID zu suchen?",
                SHOW_GIATA_ID_FIELD_LABEL: "Giata ID Feld anzeigen",
            },
            ERRORS: {
                WRONG_FILE_TYPE: "Das KlimaLinkLogo muss vom Type {{fileType}} sein."
            },
        },
        COMMON: {
            AUTOMATED_REFETCHING_ERROR: "Da ist etwas schief gegangen. Bitte laden Sie die Seite erneut.",
            HINT_BETTER_FLIGHT_EMISSION_CALCULATION_SPECIFIC: "Die Emissionen von diesem Flugabschnitte können Sie genauer berechnen, indem Sie weitere Informationen wie Airline, Flugzeugtyp oder Serviceklasse hinzufügen.",
            HINT_BETTER_FLIGHT_EMISSION_CALCULATION_GENERAL: "Die Emissionen von mindestens einem der Flugabschnitte können Sie genauer berechnen, indem Sie weitere Informationen wie Airline, Flugzeugtyp oder Serviceklasse hinzufügen.",
            GO_TO_TRAVEL_COMPONENTS: "Zu Reisebausteinen",
            NO_TRAVEL_COMPONENTS_TOOLTIP: "Bisher keine Reisebausteine vorhanden",
            ARIA_LABELS: {
                TRAVEL_COMPONENT_NAME_ERROR: "Bitte geben Sie einen Namen ein,",
                CUSTOMER_NAME_ERROR: "Bitte geben Sie einen Kunde_in Namen ein.",
                AGENCY_USER_ERROR: "Bitte wählen Sie eine Agentur für den_die Expedient_in aus.",
                AGENCY_ERROR: "Bitte geben Sie einen Agenturnamen an.",
                COMPANY_ERROR: "Bitte geben Sie einen Firmenname an.",
                FIRST_CONTACT_PERSON_FIRST_NAME_ERROR: "Bitte geben Sie den Vornamen der ersten Kontaktperson an.",
                FIRST_CONTACT_PERSON_LAST_NAME_ERROR: "Bitte geben Sie den Nachnamen der ersten Kontaktperson an.",
                STREET_ERROR: "Bitte geben Sie ein Straße an.",
                HOUSE_NUMBER_ERROR: "Bitte geben Sie eine Hausnummer an.",
                ZIP_CODE_ERROR: "Bitte geben Sie eine Postleitzahl an.",
                CITY_ERROR: "Bitte geben Sie eine Stadt an.",
                COUNTRY_CODE_ERROR: "Bitte geben Sie einen Ländercode an.",
                COUNTRY_ERROR: "Bitte geben Sie ein Land an.",
                FIRST_NAME_ERROR: "Bitte geben Sie einen gültigen Vornamen an.",
                LAST_NAME_ERROR: "Bitte geben Sie einen gültigen Nachnamen an.",
                TELEPHONE_NUMBER_WITH_AREA_CODE_ERROR: "Bitte geben Sie eine Telefonnummer an.",
                EMAIL_ERROR: "Bitte geben Sie eine gültige E-Mailadresse an.",
                ADDITIONAL_ADDRESS_INFORMATION_ERROR: "Wenn Sie zusätzliche Adressinformationen angeben, müssen diese mindestens zwei Zeichen lang sein.",
                TRAVEL_COMPONENT_DESCRIPTION_ERROR: "Bitte geben Sie eine gültige Beschreibung ein."
            }
        },
        AUTHENTICATING_USER: {
            TEXT: "KLimaLink authentifiziert Sie..."
        },
        SELECT_ACTIVITY_BY_TRAVEL_OPTION: {
            SITE_TITLE: "Reisebausteine abfragen",
            SEARCH_BAR_LABEL: "Reisebausteine suchen"
        },
        CREATE_JOURNEY: {
            SITE_TITLE: "Reise anlegen",
            JOURNEY_NUMBER: "Reisenummer",
        },
        FLIGHT: {
            SECOND_BUTTON: "Flug hinzufügen, weitere Reisebausteine abfragen",
            THIRD_BUTTON: "Flug zu Reisebausteinen hinzufügen",
            THIRD_BUTTON_UPDATE: "Flug in Reisebausteinen aktualisieren",
            FOURTH_BUTTON: "Flug zu Reisebausteinen hinzufügen, weiteren Flug abfragen",
            FIFTH_BUTTON: "Reise mit Flug erstellen",
            ADD_FLIGHT_TO_TRAVEL_COMPONENTS: "Flug zu Reisebausteinen hinzugefügt.",
            RETURN_FLIGHT_HINT: "Der Rückflug hat i.d.R. eine andere Flugnummer. Wenn Sie den Rückflug nicht gesondert angeben, kann dies zu einer ungenaueren Berechnung der Emissionen führen."

        },
        HOTEL: {
            HOTEL_INFO: "Hotelinformationen",
            STAY_INFO: "Aufenthaltsinformationen",
            GIATA_ID: "Giata-ID",
            SEARCH: "Hotel suchen",
            HOTEL_NAME: "Hotelname",
            SEARCH_HOTEL_NAME: "Opseational",
            PROPERTY: "Eigentum",
            CITY: "Stadt",
            SEARCH_CITY: "Stadt",
            REGION: "Region",
            COUNTRY: "Land",
            SEARCH_COUNTRY: "Land",
            STARS: "Sterne",
            ROOM_CATEGORY: "Zimmerkategorie",
            ROOM_CATEGORY_SS: "EZ",
            ROOM_CATEGORY_DS: "DZ",
            ROOM_CATEGORY_SP: "EZKomf",
            ROOM_CATEGORY_DP: "DZKomf",
            ROOM_CATEGORY_S: "Suite",
            ROOMS: "Zimmer",
            MEAL_PLAN: "Verpflegungsplan",
            MEAL_CLASS_OV: "OV",
            MEAL_CLASS_ÜF: "ÜF",
            MEAL_CLASS_HP: "HP",
            MEAL_CLASS_VP: "VP",
            MEAL_CLASS_AI: "AI",
            CHOOSE_DATES: "Daten auswählen",
            PEOPLE: "Personen",
            NIGHTS: "Nächte",
            SECOND_BUTTON: "Hotel hinzufügen, weitere Reisebausteine abfragen",
            THIRD_BUTTON: "Hotel zu Reisebausteinen hinzufügen",
            THIRD_BUTTON_UPDATE: "Hotel in Reisebausteinen aktualisieren",
            FOURTH_BUTTON: "Hotel zu Reisebausteinen hinzufügen, weiteres Hotel abfragen",
            FIFTH_BUTTON: "Reise mit Hotel erstellen",
            ADD_HOTEL_TO_TRAVEL_COMPONENTS: "Hotel zu Reisebausteinen hinzugefügt.",
            ARIA_LABELS: {
                GIATA_ID_ERROR: "Bitte geben Sie eine GIATA ID ein.",
                COUNTRY_ERROR: "Bitte wählen Sie ein Land aus.",
                CITY_ERROR: "Bitte wählen Sie eine Stadt aus.",
            },
        },
        TRAVEL_OPTIONS_OVERVIEW: {
            SITE_TITLE: "Reisebausteine",
            ADD: "Weitere Reisebausteine hinzufügen"
        },
        TRAVEL_COMPONENTS_OVERVIEW: {
            SAVE_TRAVEL_COMPONENTS_MODAL: {
                ADDITIONAL_CUSOMTER_INFORMATION: "Weitere Kund_innen Informationen",
                TITLE: "Reisebausteine speichern"
            },
            CHECKBOX_LABEL: "Ausgewählter Reisebaustein",
            CREATE_JOURNEY: "Reise erstellen",
            SAVE_TRAVEL_COMPONENTS: "Reisebausteine abspeichern",
            CREATE_PDF_TOOLTIP: "PDF erstellen",
            COPY_CLIPBOARD_TOOLTIP: "In die Zwischenablage kopieren",
            EDIT_TRAVEL_COMPONENT_TOOLTIP: "Reisebaustein bearbeiten",
            DELETE_TRAVEL_COMPONENT_TOOLTIP: "Reisebaustein löschen",
            EDIT_CUSTOMER_INFORMATION_TOOLTIP: "Kund_innen Informationen bearbeiten",
            SELECT_AGENCY_MODAL_TITLE: "Bitte Agentur auswählen",
            SELECT_AGENCY_MODAL_EXPLANATION: "Sie sind mehreren Agenturen zugeordnet. Bitte wählen Sie die Agentur aus, mit welcher Sie das PDF signieren möchten.",
            RADIO_BUTTON_GROUP: "Agentur auswählen",
            ADD_TRAVEL_COMPONENTS: "Reisebausteine hinzufügen",
            NO_TRAVEL_COMPONENTS: "Sie haben bisher noch keine Reisebausteine hinzugefügt.",
            TRAVEL_COMPONENTS_COPIED: "Reisebausteine in die Zwischenablage kopiert.",
            NO_AGENCY_PDF: "Sie sind keiner Agentur zugeordnet. Bitte erstellen Sie eine Agentur um ein PDF erstellen zu können.",
            STAR: "Stern",
            STARS: "Sterne",
            ROOM: "Zimmer",
            ROOMS: "Zimmer",
            NIGHT: "Nacht",
            NIGHTS: "Nächte",
        }
    },
    ALT: {
        KLIMALINK_LOGO: "Das KlimaLink Logo. Ein Bild mit den Wörtern: KlimaLink - Travel Footprint Database",
        ATMOSFAIR_LOGO: "Das atmosfair Logo. Ein Bild mit den Wörtern: atmofair - nachdenken, klimabewusst reisen."
    },
    ACTIVITIES: {
        CAR: {
            TITLE: "Auto/Mietwagen"
        },
        FLIGHT: {
            REMOVE_STOPOVER_TOOLTIP: "Stopover entfernen",
            TITLE: "Flug",
            FLIGHT_SINGULAR: "Flug",
            FLIGHT_PLURAL: "Flüge",
            NAME: 'Flug',
            STOPOVER: 'Zwischenstopp',
            STOPOVERS: 'Zwischenstopps',
            ONEWAY_SINGULAR: 'Hinflug',
            RETURN_FLIGHT_SINGULAR: 'Rückflug',
            ONEWAY_ONLY_SINGULAR: 'Nur Hinflug',
            ONEWAY_PLURAL: 'Hinflüge',
            ONEWAY_ONLY_PLURAL: 'Hinflüge',
            ROUNDTRIP_SINGULAR: 'Hin- und Rückflug',
            ROUNDTRIP_ONLY_SINGULAR: 'Hin- und Rückflug',
            ROUNDTRIP_PLURAL: 'Hin- und Rückflüge',
            ROUNDTRIP_ONLY_PLURAL: 'Hin- und Rückflüge',
            OFFSET_ENTIRE_AIRCRAFT: 'Gesamtes Flugzeug kompensieren',
            FLIGHT_TYPE: 'Flugart',
            FLIGHT_TYPE_CHARTER: 'Charter',
            FLIGHT_TYPE_LINE: 'Linie',
            FLIGHT_CLASS_FIRST: 'First Class',
            FLIGHT_CLASS_BUSINESS: 'Business Class',
            FLIGHT_CLASS_ECONOMY_PREMIUM: 'Premium Eco',
            FLIGHT_CLASS_ECONOMY: 'Eco',
            FLIGHT_NUMBER: "Flugnummer",
            DEPARTURE_AIRPORT: 'Startflughafen',
            NO_CITY_PAIR_FOUND_TITLE: 'Keine Flugverbindung',
            NO_CITY_PAIR_FOUND: 'Diese Flugverbindung können wir nicht finden. Bitte Versuchen Sie eine andere.',
            VIA_AIRPORT: 'Umsteigeflughafen',
            ARRIVAL_AIRPORT: 'Zielflughafen',
            TRAVEL_CLASS: 'Serviceklasse',
            AIRCRAFT_TYPE: 'Flugzeugtyp',
            ADD_VIA_AIRPORT: 'Umsteigeflughafen hinzufügen',
            ADD_OR_REMOVE_VIA_AIRPORT: 'Zwischenstopp hinzufügen',
            FLIGHT_COUNT: 'Flüge',
            PLACEHOLDER_AIRPORT: 'Stadt oder Flughafen',
            FLIGHT_DISTANCE: 'Flugdistanz',
            DEPARTURE_DATE: "Abflugdatum",
            CALCULATION_FAILED_FOR_LEGS: "Für folgende Abschnitte konnte keine Emissionsberechnung durchgeführt werden:",
            CALCULATION_SUCCESSFUL_FOR_LEGS_WITH_RETURN_FLIGHT: "Für folgende Abschnitte (Hin- und Rückflug) konnte eine Emissionsberechnung durchgeführt werden:",
            CALCULATION_SUCCESSFUL_FOR_LEGS_WITHOUT_RETURN_FLIGHT: "Für folgende Abschnitte konnte eine Emissionsberechnung durchgeführt werden:",
            CALCULATION_DONE_FOR_LEGS: "Die Emissionen betragen:",
            CHOOSE_FLIGHTS: "Bitte wählen Sie die passenden Flüge aus",
            DESCRIPTION: "Sie können eine Verbindung entweder über Abflug- und Ankunftsflughafen suchen oder aber über eine Flugnummer und Flugdatum. Nach Eingabe der Flugnummer und Flugdatum wird die Verbindung automatisch gesucht und Abflug- und Ankunftsflughafen werden ausgefüllt. Wenn keine Angaben zu Datum, Flugnummer, oder Serviceklasse vorliegen, wird der Emissionswert gemittelt aus allen Flügen auf diesem City Pair ausgewiesen.",
            AIRPORT_TRANSFER: "Flughafentransfer",
            ERRORS: {
                NETWORK_ERROR: {
                    GETTING_FLIGHTS_VIA_NUMBER_AND_DATE: "Da ist etwas schief gegangen. Bitte versuchen es es erneut oder geben Sie statt Flugnummer und Flugdatum den Ablufgs- und Ankunftsflughafen ein."
                },
                GENERAL: {
                    GETTING_FLIGHTS_CODE_SHARING_FLIGHT_ERROR: "Leider ist dieser Flug ein Codesharing-Flug, der von einer anderen Airline durchgeführt wird. Bitte suchen Sie mit Abflugs- und Ankunftsflughafen oder mit der Flugnummer der durchführenden Airline stattdessen.",
                    PARTIAL_OR_COMPLETE_CALCULATION_ERROR: "Sie können die Serviceleistung dennoch weiterhin verwenden, es wird jedoch kein CO₂ e für diesen Service angezeigt.",
                },
            },
            ARIA_LABELS: {
                ERROR_FLIGHT_NUMBER_INVALID: "Die Flugnummer die Sie eingegeben haben ist ungültig. Flugnummern bestehen aus 1 Buchstaben und 1-4 Zahlen.",
                ERROR_AIRPORT_SELECT_FROM_LIST: 'Bitte Flughafen angeben. Hierzu müssen Sie einen Flughafen aus der sich öffnenden Liste auswählen. Sie können nicht den Flughafen komplett selber auschreiben.',
                ERROR_AIRLINE_SELECT_FROM_LIST: 'Bitte Airline angeben. Hierzu müssen Sie eine Airline aus der sich öffnenden Liste auswählen. Sie können nicht die Airline komplett selber auschreiben.',
                MIN_MAX_PASSENGERS_EXCEEDED_ERROR: 'Bitte geben Sie eine Anzahl an Passagieren ein die größer oder gleich {{min}} und kleiner gleich {{max}} ist.',
                MIN_MAX_FLIGHTS_EXCEEDED_ERROR: 'Bitte geben Sie eine Anzahl an Flügen ein die größer oder gleich {{min}} und kleiner gleich {{max}} ist.',
                NUMBER_FLIGHT_PERSONS_RETURN: 'Anzahl der Flüge, Anzahl der Personen, Rückflug Ja/Nein',
            },
        },
        TRAIN: {
            TITLE: "Bahn"
        },
        HOTEL: {
            TITLE: "Hotel",
            DESCRIPTION: "Sollte ein Hotel nicht aufgelistet sein, reicht es Stadt, Land und Sternekategorie anzugeben, um den durchschnittlichen Klimafußabruck zu berechnen.",
            ARIA_LABELS: {
                MIN_MAX_PEOPLE_EXCEEDED_ERROR: 'Bitte geben Sie eine Anzahl von Personen ein, die größer oder gleich {{min}} und kleiner oder gleich {{max}} ist.',
                MIN_MAX_ROOMS_EXCEEDED_ERROR: 'Bitte geben Sie eine Anzahl von Zimmern ein, die größer oder gleich {{min}} und kleiner oder gleich {{max}} ist.',
                MIN_MAX_NIGHTS_EXCEEDED_ERROR: 'Bitte geben Sie eine Anzahl von Nächten ein, die größer oder gleich {{min}} und kleiner oder gleich {{max}} ist.',
                MEAL_PLAN: 'Bitte wählen Sie die Art und Weise der Verpflegung aus.',
                NUMBER_OF_NIGHTS: 'Bitte wählen Sie die Anzahl der Nächte aus.',
            },
            HOTEL_CLASS_UNKNOWN: "Unbekannt"

        },
        HOSTEL: {
            TITLE: "Hostel"
        }
    },
    TRAVEL_OPTIONS: {
        TRANSPORT: {
            TITLE: "Reiseleistung",
        },
        ACCOMMODATION: {
            TITLE: "Unterkunft",
        }
    },
    ERRORS: {
        CLICK_AWAY_AUTOCOMPLETE_QUERY: "Bitte wählen Sie {{value}} aus der Liste aus.",
        HOTEL_NOT_FOUND: "Hotel nicht gefunden",
        GENERAL_NETWORK_ERROR_TITLE: "Allgemeiner Fehler",
        GENERAL_NETWORK_ERROR_BODY: 'Huch... leider ist ein technischer Fehler aufgetreten.',
        CALCULATION_PARTIAL_FAILURE: "Berechnung teilweise fehlgeschlagen",
        CALCULATION_COMPLETE_FAILURE: "Berechnung komplett fehlgeschlagen",
        ERROR_HINT: "Falls der Fehler bestehen bleibt, wenden Sie sich bitte an KlimaLink.",
        BACKEND_ERRORS: {
            CODE_501: "Benutzer_in ist kein Administrator",
            CODE_504: "Maximale Anzahl von Unternehmen überschritten",
            CODE_508: "Agentur mit demselben Namen existiert bereits",
            CODE_514: 'Expedient_in wurde deaktiviert. Bitte wenden Sie sich an den KlimaLink Support.',
            CODE_518: 'Expedient_in wurde deaktiviert. Bitte wenden Sie sich an den KlimaLink Support.',

            // Flight
            CODE_601: "Flughafen \"{{airport}}\" nicht gefunden.",
            CODE_602: "Airline \"{{airline}}\" nicht gefunden.",
            CODE_603: "Flugzeug \"{{aircraftType}}\" nicht gefunden.",
            CODE_604: "Keine Flüge für City-Pair {{airports}} gefunden.",
            CODE_605: "Flugnummer und Flugdatum nicht angegeben.",
            CODE_606: "Flugnummer nicht angegeben.",
            CODE_607: "Flugdatum nicht angegeben.",
            CODE_608: "Flugdatum \"{{flightDate}}\" konnte nicht geparst werden.",
            CODE_609: "Flugnummer \"{{flightNumber}}\" konnte nicht geparst werden.",
            CODE_610: "Der Flug ist ein Codesharing-Flug, der von einer anderen Airline durchgeführt wird. Bitte mit der Flugnummer der durchführenden Airline suchen.",
            CODE_611: "Keine Flüge für Flugnummer \"{{flightNumber}}\" und Flugdatum \"{{flightDate}}\" gefunden.",
            CODE_612: "Für den Flug \"{{flightNumber}}\" wurden mehrere City-Pairs gefunden. Bitte das gewünschte CityPair zusätzlich spezifizieren.",
            CODE_613: "Für den Flug \"{{flightNumber}}\" am \"{{flightDate}}\" wurden mehrere City-Pairs gefunden. Bitte das gewünsche CityPair zusätzlich spezifizieren.",
            CODE_614: "Für den Flug \"{{airports}},\" Airline \"{{airline}}\" und Flugzeugtyp {{aircraftType}} konten keine Flüge gefunden werden.",
            CODE_615: "Für den Flug \"{{airports}}\" und Airline \"{{airline}}\" konten keine Flüge gefunden werden.",
            CODE_616: "Für den Flug \"{{airports}}\" und Flugzeugtyp \"{{aircraftType}}\" konnten keine Flüge gefunden werden.",
            CODE_617: "Während der Emissionsberechnung ist ein Fehler aufgetreten.",
            CODE_618: "Für die Anfrage konnten keine Flüge gefunden werden.",
            CODE_619: "Der Index {{index}} kommt bei mehr als einer Activity vor.",
            CODE_620: "Fluganzahl ist 0 oder negativ.",
            CODE_621: "Passagierzahl ist 0 oder negativ.",
            CODE_622: "Die Flugnummer und das Flugdatum müssen zusammen (oder gar nicht) angegeben werden.",
            CODE_623: "Abflug und Ankunft müssen zusammen (oder gar nicht) angegeben werden.",
            CODE_624: "Es müssen entweder Flugnummer und -datum oder Abflug- und Ankunftsort angegeben werden.",
            CODE_625: "Der Abflugs- und Ankunftsflughafen sind identisch: {{departure}}, {{arrival}}.",

            // Hotel
            CODE_701: "Die Anzahl der Zimmer muss eine gültige, positive Ganzzahl sein.",
            CODE_702: "Die Anzahl der Übernachtungen muss eine gültige, positive Ganzzahl sein.",
            CODE_703: "Das Land muss ein gültiger ISO3166 Alpha3-Ländercode sein.",
            CODE_704: "Die Anzahl der Personen muss eine gültige, positive Ganzzahl sein.",

            CODE_802: "Raten Limitierung überschritten",
            CODE_817: "Während der Emissionsberechnung ist ein Fehler aufgetreten.",
            CODE_830: "Index muss eine gültige, positive ganze Zahl sein. (angegben: {{index}}.",

            CODE_906: "Ihr Konto wurde deaktiviert. Bitte wenden Sie sich an Ihren Administrator, wenn dies nicht beabsichtigt war.",
            CODE_907: 'Firma nicht gefunden',
            CODE_910: 'Konnte keine E-Mail senden',
            CODE_912: 'Firmenadministrator hat Geschäftsbedingungen nicht akzeptiert',
            CODE_917: 'Expedient_in wurde deaktiviert. Bitte wenden Sie sich an den KlimaLink Support.',
        },
    },
    PDF: {
        TRAVEL_COMPONENTS: {
            PDF_TITLE: "Emissionen der Reisebausteine",
            PDF_SUBJECT: "Emissionen der Reisebausteine",
            CO2_EMISSIONS_TOTAL_FAILURE: "Keine CO2 Berechnung möglich",
            CO2_EMISSIONS_PARTIAL_FAILURE: "Für nicht alle Abschnitte konnte CO2 berechnet werden",
            FOOTER: {
                REDUCE_EMISSIONS: "Möchten Sie Ihren Klimafußabdruck verringern? Ihr Reisebüro gibt Ihnen gerne Tipps, wie Sie klimabewusster Reisen können.",
                DISCLAIMER: "Diese Daten sind ohne Gewähr und wurden ermittelt von: KlimaLink e.V.",
            }
        }
    }
}

export default german