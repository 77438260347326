import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';
import userReducer from './user';
import journeyReducer from './journey';
import travelComponentsReducer from "./travel-components"
import settingsReducer from "./settings"
import storage from 'redux-persist/lib/storage'
import {persistReducer, persistStore,} from 'redux-persist'
import {getPersistConfig} from 'redux-deep-persist';
import {apiSlice} from "./api";

export const rootReducer = combineReducers({
    user: userReducer,
    journey: journeyReducer,
    travelComponents: travelComponentsReducer,
    settings: settingsReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
})

const config = getPersistConfig({
    key: 'root',
    storage,
    blacklist: ['journey', 'user', 'travelComponents', 'api', 'settings'],
    rootReducer
})

const persistedReducer = persistReducer(config, rootReducer)

export const setupStore = (preloadedState?: Partial<RootState>) => {
    return configureStore({
        reducer: persistedReducer,
        devTools: process.env.NODE_ENV !== 'production',
        middleware: getDefaultMiddleware =>
            getDefaultMiddleware({
                // serializableCheck: {
                //     ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
                // },
                serializableCheck: false
            })
                .concat(apiSlice.middleware),
        preloadedState
    })
}


export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            // serializableCheck: {
            //     ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            // },
            serializableCheck: false
        })
            .concat(apiSlice.middleware),
});

export const persistor = persistStore(store)
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector