/**
 * These are all the available activities. If you want to add a new activity, make sure to add it also to the enum ACTIVITY_TYPE
 * **/
import {ActivityCommonProperties} from "./activity-common-properties";
import {TRAVEL_OPTION_TYPE} from "./travel-option";
import {ActivityFlightState} from "./activity-flight";
import {ActivityHotelState} from "./activity-hotel";
import {
    CalculatedEmissionsResponseTotal,
    FlightsCalculatedEmissionsResponsePartial,
    HotelCalculatedEmissionsResponsePartial
} from "../api/model/emissions";
import {FlightRequestLeg} from "../api/model/activity-flight";
import {HotelCalculationRequest} from "../api/model/activity-hotel";

export enum ACTIVITY_TYPE {
    flight = "flight",
    hotel = "hotel",
}

export interface AnotherStateToBeFilled extends ActivityCommonProperties {

}

/**
 * These are all the available travel options and there corresponding activity interfaces.
 * The interfaces are the interfaces used to create the reducer.
 * If you want to add a new travel option as well as a new activity, make sure to add it also to the interface TravelOptionsToActivitiesMap
 * **/
export interface TravelOptionToActivitiesMap {
    [TRAVEL_OPTION_TYPE.transport]: (ActivityFlightState)[],
    [TRAVEL_OPTION_TYPE.accommodation]: (ActivityHotelState)[],
    [TRAVEL_OPTION_TYPE.undertaking]: (AnotherStateToBeFilled)[]
}

export type AllActivitiesValues<T> = T[keyof T]
export type AllActivityTypesArray = AllActivitiesValues<TravelOptionToActivitiesMap>
export type AllActivityTypes = AllActivitiesValues<TravelOptionToActivitiesMap>[0]
export type AllActivityTypesArrayDiscriminating = AllActivityTypes[]
export type ActivityRequestTypes = (HotelCalculationRequest | FlightRequestLeg)
export type CalculatedActivityResponsesPartial =
    FlightsCalculatedEmissionsResponsePartial[]
    | HotelCalculatedEmissionsResponsePartial[]

export interface TransformedEmissionsResponseTotal extends CalculatedEmissionsResponseTotal {
    data: CalculatedActivityResponsesPartial
}