import {useEffect, useRef} from "react";
import usePrevious from "../hooks/usePrevious";

export const useEffectAllDepsChange = (callBack: () => void, deps: any[]) => {
    const prevDeps = usePrevious(deps);
    const changeTarget = useRef<any>();

    useEffect(() => {
        if (changeTarget.current === undefined) changeTarget.current = prevDeps;
        if (changeTarget.current && changeTarget.current.every((dep: any, i: number) => dep !== deps[i])) {
            changeTarget.current = deps;
            return callBack();
        }
    }, [callBack, prevDeps, deps]);
}