import React, {useEffect, useRef, useState} from 'react'
import "./styles.css"
import {CheckboxInput, IconButton, Modal, Text, TextButton} from "atmosfair-ui"
import {UserProps} from "../../../api/model/user";
import {AgencyProps} from "../../../api/model/agency";
import {CompanyProps} from "../../../api/model/company";
import UsersForm, {UserFormProps} from "../../forms/users-form/UsersForm";
import {useSendChangePasswordEmailMutation, useUpdateUserInfoSelfMutation} from "../../../redux/reducer/api";
import {toast} from "react-toastify";
import ToastWithTextOnly from "../../../molecules/toasts/toast-with-text-only/ToastWithTextOnly";
import {useTranslation} from "react-i18next";
import StringPair from "../../../molecules/string-pair/StringPair";
import {ContactPersonsProps, GENERAL_BACKEND_RESPONSE} from "../../../api/model/common";
import {allInternationalPhonePrefixesFormattedForDropdownAlpha2CountryCode} from "../utils";
import {Tooltip} from "@mui/material";
import clsx from "clsx";
import SectionTitle from "../section-title/SectionTitle";
import {useAppSelector} from "../../../redux/reducer/store";
import {QueryStatus} from "@reduxjs/toolkit/query";
import {UserPreferencesProps} from '../../../api/model/userPreferences';
import {isNotUndefinedOrNull} from "../../../utils/misc";
import {NO_DATA_STRING} from "../../../utils/string-mutation";
import {VALIDATE_FORM_STARTING_NUMBER} from "../../../constants";

interface UserSettingsProps {
    userData: UserProps,
    agencies: AgencyProps[],
    company: CompanyProps,
}

const UserSettings = ({
                          userData,
                          agencies,
                          company,
                      }: UserSettingsProps) => {
    const {firstName, lastName, phoneNumber, internationalPhonePrefix, email, userPreferences} = userData
    const {showGiataIdField} = userPreferences
    const [modalVisible, setModalVisible] = useState(false)
    const [localShowGiataIdField, setLocalShowGiataIdField] = useState<UserPreferencesProps["showGiataIdField"]>(showGiataIdField)
    const [validateForm, setValidateForm] = useState(VALIDATE_FORM_STARTING_NUMBER)
    const [changePasswordButtonClicked, setChangePasswordButtonClicked] = useState(false)
    const showSpinnerPersonalData = useRef(false)
    const showToastPersonalDataEdited = useRef(false)
    const {t} = useTranslation()

    const isLoadingGetUserInfo = useAppSelector(state => {
        return Object.values(state.api.queries).some(query => {
            if (query && query.endpointName === "getUserInfo") return query.status === QueryStatus.pending;
        });
    });

    const isErrorGetUserInfo = useAppSelector(state => {
        return Object.values(state.api.queries).some(query => {
            if (query && query.endpointName === "getUserInfo") return query.status === QueryStatus.rejected;
        });
    });

    const [updateUserInfo, {
        data: updateUserInfoResponse,
        isLoading: isLoadingUpdateUserInfo,
        isError: isErrorUpdateUserInfo
    }] = useUpdateUserInfoSelfMutation()

    const [sendResetPasswordEmail, {
        data: sendResetPasswordEmailResponse,
        isLoading: isLoadingSendResetPasswordEmail,
        isError: isErrorSendResetPasswordEmail,
    }] = useSendChangePasswordEmailMutation()

    useEffect(() => {
        if (isErrorGetUserInfo) toast(<ToastWithTextOnly
                message={t("SCREENS.COMMON.AUTOMATED_REFETCHING_ERROR",)}/>,
            {progress: undefined, type: "error"}
        );
    }, [isErrorGetUserInfo]);

    useEffect(() => {
        if (updateUserInfoResponse?.status === GENERAL_BACKEND_RESPONSE.success && showToastPersonalDataEdited.current) toast(
            <ToastWithTextOnly
                message={t("TOASTS.ERROR.UPDATE_USER_SELF")}/>,
            {progress: undefined, type: "success"}
        );
    }, [updateUserInfoResponse]);

    const _updateUserInfo = (data: UserFormProps) => {
        const {
            firstName,
            lastName,
            email,
            phoneNumber,
            internationalPhonePrefix
        } = data
        setModalVisible(false)
        setValidateForm(VALIDATE_FORM_STARTING_NUMBER)
        showSpinnerPersonalData.current = true
        showToastPersonalDataEdited.current = true
        if (email) updateUserInfo({
            firstName,
            lastName,
            email,
            phoneNumber,
            internationalPhonePrefix: internationalPhonePrefix ?? undefined,
        })
    }

    const _updateShowGiataIdSetting = (setting: boolean) => {
        showSpinnerPersonalData.current = false
        showToastPersonalDataEdited.current = false
        setLocalShowGiataIdField(setting)
        updateUserInfo({
            userPreferences: {
                showGiataIdField: setting
            }
        });
    };

    const _constructEmailString = (
        firstName: ContactPersonsProps["secondContactFirstName"],
        lastName: ContactPersonsProps["secondContactLastName"],
        email: ContactPersonsProps["secondContactEmail"]
    ) => {
        if (!isNotUndefinedOrNull([firstName, lastName])) return NO_DATA_STRING
        let contactString = `${firstName} ${lastName}`
        if (email) contactString = `${contactString} (${email})`
        return contactString

    }

    return <div className={"user-settings-container"}>
        <section
            className={clsx("user-settings-user-information", agencies.length === 0 && "user-settings-agency-list-no-row-gap")}>
            <SectionTitle
                title={`SCREENS.SETTINGS.USER_SETTINGS_${agencies.length > 1 ? "AGENCIES" : "AGENCY"}_TITLE`}/>
            {agencies?.map((agency, index) => {
                const {
                    name,
                    generalEmail,
                    firstContactFirstName,
                    firstContactLastName,
                    firstContactEmail,
                    secondContactFirstName,
                    secondContactLastName,
                    secondContactEmail,
                    isAdmin,
                    address,
                } = agency
                return <div key={index} className={"user-settings-agency-container"}>
                    <Text variant={"headlineSmall"}>{name}</Text>
                    <div className={"user-settings-user-information"}>
                        <StringPair containerClasses={"user-settings-string-pair"} label={"GENERAL.EMAIL"}
                                    value={generalEmail}/>
                        <StringPair containerClasses={"user-settings-string-pair"}
                                    label={"GENERAL.FIRST_CONTACT_PERSON"}
                                    value={_constructEmailString(firstContactFirstName, firstContactLastName, firstContactEmail)}/>
                        <StringPair containerClasses={"user-settings-string-pair"}
                                    label={"GENERAL.SECOND_CONTACT_PERSON"}
                                    lengthForNoDataString={2}
                                    value={_constructEmailString(secondContactFirstName, secondContactLastName, secondContactEmail)}/>
                        <StringPair containerClasses={"user-settings-string-pair"}
                                    label={"SCREENS.SETTINGS.AMI_I_AGENCY_ADMIN"}
                                    value={isAdmin ? "GENERAL.YES" : "GENERAL.NO"}/>
                    </div>
                </div>
            })}
            {agencies.length === 0 && <Text>SCREENS.SETTINGS.NO_AGENCY_HINT_USER_SETTINGS</Text>}
        </section>
        <section className={"user-settings-user-information"}>
            <div className={"user-settings-personal-data-title-wrapper"}>
                <SectionTitle
                    isLoading={(isLoadingUpdateUserInfo || isLoadingGetUserInfo || isLoadingSendResetPasswordEmail) && showSpinnerPersonalData.current}
                    title={"SCREENS.SETTINGS.PERSONAL_DATA"}/>
                <IconButton
                    onClick={() => setModalVisible(!modalVisible)}
                    size={"medium"}
                    icon={'penToSquare'}/>
            </div>
            <>
                <StringPair label={"GENERAL.FIRST_NAME"} value={firstName}/>
                <StringPair label={"GENERAL.LAST_NAME"} value={lastName}/>
                <StringPair label={"GENERAL.EMAIL"} value={email}/>
                <StringPair label={"GENERAL.COUNTRY_CODE_LABEL"}
                            value={allInternationalPhonePrefixesFormattedForDropdownAlpha2CountryCode[internationalPhonePrefix]}/>
                <StringPair label={"GENERAL.TELEPHONE_NUMBER_WITH_AREA_CODE_LABEL"} value={phoneNumber}/>
                <div>
                    <Text
                        color={"primary"}
                        weight={"bold"}
                        variant={"subtitleLarge"}>SCREENS.SETTINGS.CHANGE_PASSWORD_LABEL</Text>
                    {changePasswordButtonClicked ?
                        <Tooltip title={t("SCREENS.SETTINGS.CHANGE_PASSWORD_TOOLTIP")}>
                            <div>
                                <TextButton
                                    size={"small"}
                                    disabled={true}>
                                    SCREENS.SETTINGS.CHANGE_PASSWORD_EMAIL_SEND
                                </TextButton>
                            </div>
                        </Tooltip> :
                        <TextButton
                            size={"small"}
                            onClick={() => {
                                setChangePasswordButtonClicked(true)
                                sendResetPasswordEmail()
                            }}>
                            SCREENS.SETTINGS.CHANGE_PASSWORD_REQUEST_EMAIL
                        </TextButton>
                    }
                </div>
            </>
        </section>
        <section>
            <SectionTitle isLoading={isLoadingUpdateUserInfo}
                          title={"SCREENS.SETTINGS.USER_PREFERENCES.TITLE"}/>
            <CheckboxInput
                text={"SCREENS.SETTINGS.USER_PREFERENCES.SHOW_GIATA_ID_FIELD_TEXT"}
                hideLabel={true}
                textPosition={"right"}
                label={"SCREENS.SETTINGS.USER_PREFERENCES.SHOW_GIATA_ID_FIELD_LABEL"}
                checked={localShowGiataIdField}
                onChange={() => _updateShowGiataIdSetting(!localShowGiataIdField)}
            />
        </section>
        <Modal
            showSpinner={isLoadingUpdateUserInfo}
            headline={"SCREENS.SETTINGS.EDIT_USER_PERSONAL"}
            closeButton={"GENERAL.ABORT"}
            confirmButton={"GENERAL.OK"}
            showPaper={true}
            onClickConfirm={() => setValidateForm(validateForm + 1)}
            onClickClose={() => {
                setValidateForm(VALIDATE_FORM_STARTING_NUMBER)
                setModalVisible(false)
            }}
            show={modalVisible}>
            <UsersForm
                defaultValues={userData}
                showAgencySelection={false}
                showPhoneNumberFields={true}
                showEmailField={false}
                showIsAdminCheckbox={false}
                validateForm={validateForm}
                onFormIsValid={_updateUserInfo}
                agencies={agencies}/>
        </Modal>
    </div>
}

export default UserSettings