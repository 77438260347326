import React, {useEffect, useState} from "react";
import "./styles.css"
import {ACTIVITY_OPTIONS, ActivityOptionsProps} from "../../constants";
import SelectActivity from "../select-activity/SelectActivity";
import {ACTIVITY_TYPE} from "../../types-enums/activity";
import {TRAVEL_OPTION_TYPE} from "../../types-enums/travel-option";
import Fuse from "fuse.js";
import {Text} from "atmosfair-ui";
import groupBy from "lodash/groupBy";
import {useTranslation} from "react-i18next";

interface SelectActivityByTravelOptionProps {
    onSelect: (activityType: ACTIVITY_TYPE) => void
}

const SelectActivityByTravelOption = ({
                                          onSelect
                                      }: SelectActivityByTravelOptionProps) => {
    const [fuse, setFuse] = useState<Fuse<ActivityOptionsProps> | null>(null)
    const [activityOptions, setActivityOptions] = useState<ActivityOptionsProps[]>(ACTIVITY_OPTIONS)
    const groupedActivityOptions = groupBy(activityOptions, "travelOptionType")
    const {t, i18n} = useTranslation()
    const currentLanguage = i18n.resolvedLanguage

    useEffect(() => {
        const translatedActivityOptionsForFuse = ACTIVITY_OPTIONS.map(activityOption => {
            return {...activityOption, title: t(activityOption.title)}
        })
        setFuse(new Fuse<ActivityOptionsProps>(translatedActivityOptionsForFuse, {
            keys: ['title'],
            minMatchCharLength: 1,
            location: 0,
            threshold: 0.5,
            distance: 10,
        }))
    }, [currentLanguage])

    const _onChange = (searchString: string) => {
        if (searchString.length > 0 && fuse) {
            setActivityOptions(fuse
                .search(searchString)
                .map(({item}) => item)
            )
        } else {
            setActivityOptions(ACTIVITY_OPTIONS)
        }
    }

    const _getTravelOptionsTranslation = (travelOption: TRAVEL_OPTION_TYPE) => {
        if (travelOption === TRAVEL_OPTION_TYPE.accommodation) return "TRAVEL_OPTIONS.ACCOMMODATION.TITLE"
        if (travelOption === TRAVEL_OPTION_TYPE.transport) return "TRAVEL_OPTIONS.TRANSPORT.TITLE"
        if (travelOption === TRAVEL_OPTION_TYPE.undertaking) return "TRAVEL_OPTIONS.UNDERTAKING.TITLE"
    }

    return <div className={"select-activity-by-travel-option-container"}>
        {/*SEARCH OPTION CURRENTLY NO NEEDED. TURN ON ONCE NEEDED*/}
        {/*<section className={"select-activity-search-wrapper"}>*/}
        {/*    <Text*/}
        {/*        style={{width: "100%"}}*/}
        {/*        // ariaFor={searchLabelFor}*/}
        {/*        variant={"headlineSmall"}>*/}
        {/*        SCREENS.SELECT_ACTIVITY_BY_TRAVEL_OPTION.SEARCH_BAR_LABEL*/}
        {/*    </Text>*/}
        {/*    <input*/}
        {/*        style={{width: "30%"}}*/}
        {/*        // id={searchLabelFor}*/}
        {/*        autoFocus={true}*/}
        {/*        placeholder={`${t("GENERAL.SEARCH")}...`}*/}
        {/*        onChange={event => _onChange(event.target.value)}*/}
        {/*        type={"search"}/>*/}
        {/*</section>*/}
        {(Object.keys(groupedActivityOptions) as Array<TRAVEL_OPTION_TYPE>).map((travelOptionType, index) => {
            return <section
                key={index}
                className={"select-activity-by-travel-option-wrapper"}>
                <Text variant={"headlineMedium"}>{_getTravelOptionsTranslation(travelOptionType)}</Text>
                <SelectActivity activities={groupedActivityOptions[travelOptionType]}
                                onSelect={onSelect}/>
            </section>
        })
        }
    </div>
}

export default SelectActivityByTravelOption