import React from 'react'
import {ACTIVITY_TYPE, AllActivityTypes} from "../../types-enums/activity";
import {CheckboxInput, Text} from 'atmosfair-ui';
import {isActivityFlightState, isActivityHotelState} from "../../utils/type-guards";
import {allCountryNamesAlpha2CountryCode} from "../settings/utils";
import "./styles.css"
import {MORE_DATA_STRING, ROUTE_SEPARATOR} from "../../utils/string-mutation";

interface TravelComponentAbbreviationProps {
    travelComponent: AllActivityTypes,
    selectTravelComponent: () => void,
    selected: boolean,
}


const TravelComponentAbbreviation = ({
                                         travelComponent,
                                         selectTravelComponent,
                                         selected,
                                     }: TravelComponentAbbreviationProps) => {
    const {common} = travelComponent
    const {activityType} = common

    const _setActivityShortTitle = () => {
        let activityString = ''
        let activityTypeString = ''
        if (activityType === ACTIVITY_TYPE.flight && isActivityFlightState(travelComponent)) {
            const {legs} = travelComponent
            const firstLeg = legs[0]
            const lastLeg = legs[legs.length - 1]
            activityString = `${firstLeg?.airport?.iata} ${legs.length === 2 ? ROUTE_SEPARATOR : ` ${MORE_DATA_STRING} `} ${lastLeg?.airport?.iata}`
            activityTypeString = 'ACTIVITIES.FLIGHT.TITLE'
        }
        if (activityType === ACTIVITY_TYPE.hotel && isActivityHotelState(travelComponent)) {
            const {city, country, hotel} = travelComponent
            activityTypeString = 'ACTIVITIES.HOTEL.TITLE'
            if (country) {
                if (hotel && hotel.name.length > 0) {
                    activityString = hotel.name
                } else {
                    if (city) {
                        const {name} = city
                        const {alpha2} = country
                        activityString = `${name} (${allCountryNamesAlpha2CountryCode[alpha2]})`
                    }
                }
            }

        }
        return <div className={"travel-component-abbreviation-activity-wrapper"}>
            <div className={"travel-component-abbreviation-activity-icon-wrapper"}>
                <CheckboxInput
                    checked={selected}
                    onChange={selectTravelComponent}
                    label={"SCREENS.TRAVEL_COMPONENTS_OVERVIEW.SAVE_TRAVEL_COMPONENTS_MODAL.CHECKBOX_LABEL"}
                    hideLabel={true}/>
                <Text>{activityString}</Text>
            </div>
            <Text>{activityTypeString}</Text>
        </div>
    }
    return <div className={"travel-component-abbreviation-container"}>
        {_setActivityShortTitle()}
    </div>
}

export default TravelComponentAbbreviation