import {HOTEL_MEAL_PLAN, HOTEL_ROOM_CATEGORY, HOTEL_SCOPE} from "../../../api/model/activity-hotel";

export interface HotelCategory {
    name: string,
    id: number,
    value: number
}

export const zeroStar: HotelCategory = {id: 6, name: 'ACTIVITIES.HOTEL.HOTEL_CLASS_UNKNOWN', value: 0};
export const oneStar: HotelCategory = {id: 1, name: '1', value: 1};
export const twoStar: HotelCategory = {id: 2, name: '2', value: 2};
export const threeStar: HotelCategory = {id: 3, name: '3', value: 3};
export const fourStar: HotelCategory = {id: 4, name: '4', value: 4};
export const fiveStar: HotelCategory = {id: 5, name: '5', value: 5};
export const HOTEL_CLASSES = [
    zeroStar, oneStar, twoStar, threeStar, fourStar, fiveStar
];
export const getHotelCategory = (stars: string | null): HotelCategory => {
    switch (stars) {
        case "ONE":
            return oneStar;
        case "TWO":
            return twoStar;
        case "THREE":
            return threeStar;
        case "FOUR":
            return fourStar;
        case "FIVE":
            return fiveStar;
        default:
            return zeroStar; // Default to no star if stars value is not in the range of 1-5
    }
};
export const getHotelCategoryFromNumber = (stars: number | null): HotelCategory => {
    switch (stars) {
        case 1:
            return oneStar;
        case 2:
            return twoStar;
        case 3:
            return threeStar;
        case 4:
            return fourStar;
        case 5:
            return fiveStar;
        default:
            return zeroStar; // Default to no star if stars value is not in the range of 1-5
    }
};

export interface RoomCategory {
    name: string,
    id: number,
    value: HOTEL_ROOM_CATEGORY
}

export const singleStandard: RoomCategory = {id: 1, name: 'SCREENS.HOTEL.ROOM_CATEGORY_SS', value: HOTEL_ROOM_CATEGORY.SS};
export const doubleStandard: RoomCategory = {id: 2, name: 'SCREENS.HOTEL.ROOM_CATEGORY_DS', value: HOTEL_ROOM_CATEGORY.DS};
export const singlePremium: RoomCategory = {id: 3, name: 'SCREENS.HOTEL.ROOM_CATEGORY_SP', value: HOTEL_ROOM_CATEGORY.SP};
export const doublePremium: RoomCategory = {id: 4, name: 'SCREENS.HOTEL.ROOM_CATEGORY_DP', value: HOTEL_ROOM_CATEGORY.DP};
export const suite: RoomCategory = {id: 5, name: 'SCREENS.HOTEL.ROOM_CATEGORY_S', value: HOTEL_ROOM_CATEGORY.S};
export const ROOM_CLASSES = [
    singleStandard, doubleStandard, singlePremium, doublePremium, suite
];
export const getRoomCategory = (roomCategory: HOTEL_ROOM_CATEGORY | null): RoomCategory | null => {
    switch (roomCategory) {
        case HOTEL_ROOM_CATEGORY.SS:
            return singleStandard;
        case HOTEL_ROOM_CATEGORY.DS:
            return doubleStandard;
        case HOTEL_ROOM_CATEGORY.SP:
            return singlePremium;
        case HOTEL_ROOM_CATEGORY.DP:
            return doublePremium;
        case HOTEL_ROOM_CATEGORY.S:
            return suite;
        default:
            return null;
    }
};

export interface MealPlan {
    name: string,
    id: number,
    value: HOTEL_MEAL_PLAN
}

export const roomOnly: MealPlan = {id: 1, name: 'SCREENS.HOTEL.MEAL_CLASS_OV', value: HOTEL_MEAL_PLAN.OV};
export const breakfast: MealPlan = {id: 2, name: 'SCREENS.HOTEL.MEAL_CLASS_ÜF', value: HOTEL_MEAL_PLAN.ÜF};
export const halfBoard: MealPlan = {id: 3, name: 'SCREENS.HOTEL.MEAL_CLASS_HP', value: HOTEL_MEAL_PLAN.HP};
export const fullBoard: MealPlan = {id: 4, name: 'SCREENS.HOTEL.MEAL_CLASS_VP', value: HOTEL_MEAL_PLAN.VP};
export const allInclusive: MealPlan = {id: 5, name: 'SCREENS.HOTEL.MEAL_CLASS_AI', value: HOTEL_MEAL_PLAN.AI};
export const MEAL_CLASSES = [
    roomOnly, breakfast, halfBoard, fullBoard, allInclusive
];
export const getMealPlan = (mealPlan: HOTEL_MEAL_PLAN | null): MealPlan | null => {
    switch (mealPlan) {
        case HOTEL_MEAL_PLAN.OV:
            return roomOnly;
        case HOTEL_MEAL_PLAN.ÜF:
            return breakfast;
        case HOTEL_MEAL_PLAN.HP:
            return halfBoard;
        case HOTEL_MEAL_PLAN.VP:
            return fullBoard;
        case HOTEL_MEAL_PLAN.AI:
            return allInclusive;
        default:
            return null;
    }
};
export const getScopeTranslationKey = (scope: HOTEL_SCOPE): string => {
    switch (scope) {
        case HOTEL_SCOPE.property:
            return "SCREENS.HOTEL.PROPERTY";
        case HOTEL_SCOPE.city:
            return "SCREENS.HOTEL.CITY";
        case HOTEL_SCOPE.region:
            return "SCREENS.HOTEL.REGION";
        case HOTEL_SCOPE.country:
            return "SCREENS.HOTEL.COUNTRY";
    }
};