import {CALCULATION_TYPE} from "./common";
import {ACTIVITY_TYPE} from "../../types-enums/activity";

export enum HOTEL_SCOPE {
    property = 'PROPERTY',
    city = 'CITY',
    region = 'REGION',
    country = 'COUNTRY'
}

export enum HOTEL_CLASS {
    "one",
    "two",
    "three",
    "four",
    "five",
}

export enum HOTEL_MEAL_PLAN {
    OV = "OV",
    ÜF = "ÜF",
    HP = "HP",
    VP = "HP",
    AI = "AI"
}

export enum HOTEL_ROOM_CATEGORY {
    SS = "SS",
    DS = "DS",
    SP = "SP",
    DP = "DP",
    S = "S"
}

export interface HotelActivity {
    id?: number,
    co2KgE: number,
    activityType: ACTIVITY_TYPE,
    roomCategory: HOTEL_ROOM_CATEGORY
    numberNights: number,
    numberRooms: number,
    hotelClass: HOTEL_CLASS
}

export interface HotelResponse {
    id: number,
    hotelId: string | null,
    name: string,
    city: string,
    region: string | null,
    alpha2: string,
    alpha3: string,
    countryName: string
    stars: string
}

export interface CityResponse {
    id: number,
    name: string,
    region: string | null,
    alpha2: string,
    alpha3: string,
    countryName: string
}

export interface CountryResponse {
    id: number,
    name: string,
    alpha2: string,
    alpha3: string,
}

export interface HotelCalculationRequest {
    index: number,
    hotelId: string | null,
    hotelName: string | null,
    city: string,
    region: string | null,
    country: string,
    hotelClass: number | null,
    roomCategory: HOTEL_ROOM_CATEGORY | null,
    numberOfRooms: number,
    numberOfNights: number,
    numberOfPeople: number,
    mealPlan: HOTEL_MEAL_PLAN | null,
    type: CALCULATION_TYPE
}


