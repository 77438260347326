import {IconAndText} from "atmosfair-ui";
import React from "react";
import "./styles.css"
import {theme} from "../../styles/theme";

interface CommonTableButtonsProps {
    onClickSelectAll: () => void,
    onClickGroupRows: () => void,
}

const CommonTableButtons = ({
                                onClickSelectAll,
                                onClickGroupRows,
                            }: CommonTableButtonsProps) => {
    return <div className={"common-table-buttons-container"}>
        <IconAndText
            containerStyle={{marginTop: "auto"}}
            size={"medium"}
            iconColor={theme.colors.primary}
            text={"GENERAL.SELECT_ALL"}
            onClick={onClickSelectAll}
            icon={'squareCheck'}/>
        <IconAndText
            containerStyle={{marginTop: "auto"}}
            text={"GENERAL.GROUP"}
            iconColor={theme.colors.primary}
            size={"medium"}
            onClick={onClickGroupRows}
            icon={'tableList'}/>
    </div>
}

export default CommonTableButtons