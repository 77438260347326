import {COMMA_SEPARATOR, formatCo2EPerPersonForHotels, ROUTE_SEPARATOR,} from "./string-mutation";
import {i18n} from "i18next";
import {CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL} from "../api/model/common";
import {ActivityHotelState} from "../types-enums/activity-hotel";
import {isEmptyHotelResponse} from "./type-guards";
import {HotelResponse} from "../api/model/activity-hotel";


export const generateFailedOrSuccessfulHotelString = (
    hotel: ActivityHotelState,
    whichLeg: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL,
    i18n: i18n,
) => {
    return `something`
}

export const generateCo2EForHotel = (hotel: ActivityHotelState, i18n: i18n) => {
    const {resolvedLanguage} = i18n
    let co2E: string[] = []
    const {common} = hotel
    if (common) {
        const {totalEmissions} = common
        if (totalEmissions) {
            const {numberOfPeople} = hotel
            const {data} = totalEmissions
            co2E = data.map(stop => {
                const {emissionKgCO2e, status} = stop
                if (status === CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success) return `${formatCo2EPerPersonForHotels(emissionKgCO2e!!, numberOfPeople, resolvedLanguage)} p. P.`
                return ROUTE_SEPARATOR
            })
        }
    }
    return co2E
}

export const generateCustomerHotelLocationString = (
    activity: ActivityHotelState,
    i18n: i18n,
    onOneLine: boolean
) => {
    const {t} = i18n
    const {hotel, city, hotelClass, roomCategory, numberOfRooms, numberOfNights} = activity
    let locationString
    let stayString = `${hotelClass == null || hotelClass == 0 ? '' : hotelClass == 1 ? `${hotelClass} ${t("SCREENS.TRAVEL_COMPONENTS_OVERVIEW.STAR")}${COMMA_SEPARATOR}` : hotelClass > 1 ? `${hotelClass} ${t("SCREENS.TRAVEL_COMPONENTS_OVERVIEW.STARS")}${COMMA_SEPARATOR}` : ''}${roomCategory ? `${t(`SCREENS.HOTEL.ROOM_CATEGORY_${roomCategory}`)}${COMMA_SEPARATOR}` : ''}${numberOfRooms ? `${numberOfRooms} ${numberOfRooms === 1 ? `${t("SCREENS.TRAVEL_COMPONENTS_OVERVIEW.ROOM")}` : `${t("SCREENS.TRAVEL_COMPONENTS_OVERVIEW.ROOMS")}`}` : ''}${numberOfNights ? `${COMMA_SEPARATOR}${numberOfNights} ${numberOfNights === 1 ? `${t("SCREENS.TRAVEL_COMPONENTS_OVERVIEW.NIGHT")}` : `${t("SCREENS.TRAVEL_COMPONENTS_OVERVIEW.NIGHTS")}`}` : ''}`;
    let returnStayString
    if (hotel !== null && !isEmptyHotelResponse(hotel as HotelResponse)) {
        locationString = `${hotel.name}${COMMA_SEPARATOR}${hotel.city}${COMMA_SEPARATOR}${hotel.alpha3}`;
    } else {
        locationString = `${city?.name}${COMMA_SEPARATOR}${city?.alpha3}`;
    }

    if (onOneLine) {
        locationString = `${locationString}${COMMA_SEPARATOR}${stayString}`
    } else {
        returnStayString = `${stayString}`
    }


    return {locationString, returnStayString}

}

