import React, {CSSProperties} from 'react'
import {DropdownInput, DropdownOption} from "atmosfair-ui";
import {
    INITIAL_SELECTED_INTERNATIONAL_PHONE_PREFIX,
    INTERNATIONAL_PHONE_PREFIX_CODES_FOR_DROPDOWN,
    TOP_INTERNATIONAL_PHONE_PREFIX_CODES_ID
} from "../../utils/default-values";
import {allInternationalPhonePrefixesFormattedForDropdownAlpha2CountryCode} from "../../components/settings/utils";

export interface InternationalPhonePrefixDropdownProps {
    internationalPhonePrefix?: keyof typeof allInternationalPhonePrefixesFormattedForDropdownAlpha2CountryCode | null,
    style?: CSSProperties,
    required: boolean,
    error: boolean,
    setSelection: (selection: keyof typeof allInternationalPhonePrefixesFormattedForDropdownAlpha2CountryCode | null) => void,
    readOnly?: boolean,
}

const InternationalPhonePrefixDropdown = ({
                                              internationalPhonePrefix,
                                              style,
                                              required,
                                              error,
                                              setSelection,
                                              readOnly
                                          }: InternationalPhonePrefixDropdownProps) => {

    const _getValueForDropdown = (value: InternationalPhonePrefixDropdownProps["internationalPhonePrefix"]) => INTERNATIONAL_PHONE_PREFIX_CODES_FOR_DROPDOWN
        .filter(dropdownPhonePrefix =>
            dropdownPhonePrefix.raw.key.toUpperCase() === value?.toString().toUpperCase()
        )[0].value

    const _getDefaultValue = () => {
        if (internationalPhonePrefix !== null && typeof internationalPhonePrefix !== "undefined" && typeof internationalPhonePrefix === "string" && internationalPhonePrefix.length > 0) {
            return _getValueForDropdown(internationalPhonePrefix)
        } else {
            return _getValueForDropdown(INITIAL_SELECTED_INTERNATIONAL_PHONE_PREFIX)
        }
    }

    const _convertInternationalPhonePrefixFromDropdownFormat = (internationalPhonePrefixes: DropdownOption[] | null) => {
        if (internationalPhonePrefixes === null) return null
        return internationalPhonePrefixes.map(phonePrefix => phonePrefix.raw)[0].key
    }

    return <DropdownInput
        readOnly={readOnly}
        defaultValue={_getDefaultValue()}
        labelColor={"primary"}
        ariaErrorMessage={"SCREENS.COMMON.ARIA_LABELS.COUNTRY_CODE_ERROR"}
        formError={error}
        style={style}
        required={required}
        label={"GENERAL.COUNTRY_CODE_LABEL"}
        showOnTop={TOP_INTERNATIONAL_PHONE_PREFIX_CODES_ID}
        options={INTERNATIONAL_PHONE_PREFIX_CODES_FOR_DROPDOWN}
        setSelection={selection => setSelection(_convertInternationalPhonePrefixFromDropdownFormat(selection))}/>
}

export default InternationalPhonePrefixDropdown