import React, {useEffect, useState} from "react"
import BottomButtons from "../../../components/bottom-buttons/BottomButtons";
import {useNavigate} from "react-router-dom";
import {PATH_STRINGS} from "../../../routes/route-props";
import "./styles.css"
import ChooseFlight, {
    ChooseFlightConnectionProps
} from "../../../components/activity/flight/choose-flight-connection/ChooseFlight";
import {useAppDispatch, useAppSelector} from "../../../redux/reducer/store";
import {ActivityFlightState} from "../../../types-enums/activity-flight";
import {isActivityFlightState, isActivityHotelState} from "../../../utils/type-guards";
import {
    addActivityToTravelComponents,
    updateActivityInTravelComponents
} from "../../../redux/reducer/travel-components";
import {toast} from "react-toastify";
import ToastWithTextOnly from "../../../molecules/toasts/toast-with-text-only/ToastWithTextOnly";
import {useTranslation} from "react-i18next";
import {ActivityHotelState} from "../../../types-enums/activity-hotel";
import {ACTIVITY_TYPE} from "../../../types-enums/activity";
import ChooseHotel from "../../../components/activity/hotel/choose-hotel/ChooseHotel";

interface ActivityFlightFormProps {
    editIndex: ChooseFlightConnectionProps["editIndex"],
    activityType: ACTIVITY_TYPE
}

const ActivityForm = ({
                          activityType,
                          editIndex
                      }: ActivityFlightFormProps) => {
    const navigate = useNavigate()
    const [activity, setActivity] = useState<ActivityFlightState | ActivityHotelState | null>(null)
    const [activityTypeString, setActivityTypeString] = useState('')
    const [indexOfActivityToEdit, setIndexOfActivityToEdit] = useState<number | null>(editIndex)
    const [calculatedActivity, setCalculatedActivity] = useState<ActivityFlightState | ActivityHotelState | null>(null)
    const [resetActivityForm, setResetActivityForm] = useState(0)
    const {activities} = useAppSelector(state => state.travelComponents)
    const dispatch = useAppDispatch()
    const activityCalculated = !Boolean(calculatedActivity)
    const {t} = useTranslation()

    useEffect(() => {
        switch (activityType) {
            case ACTIVITY_TYPE.flight:
                setActivityTypeString('FLIGHT');
                break;
            case ACTIVITY_TYPE.hotel:
                setActivityTypeString('HOTEL');
                break;
        }
    }, []);

    useEffect(() => {
        if (indexOfActivityToEdit !== null) {
            const activity = activities[indexOfActivityToEdit]
            if (isActivityFlightState(activity) || isActivityHotelState(activity)) setActivity(activity)
        } else {
            setActivity(null)
        }
    }, [indexOfActivityToEdit]);

    const _writeActivityToTravelComponents = () => {
        if (calculatedActivity) {
            if (indexOfActivityToEdit === null) {
                dispatch(addActivityToTravelComponents(calculatedActivity))
            } else {
                dispatch(updateActivityInTravelComponents({
                    activity: calculatedActivity,
                    index: indexOfActivityToEdit!
                }))
            }
        }
    }

    const _showToastWhenActivityAddedToTravelComponents = () => toast(<ToastWithTextOnly
            message={t(`SCREENS.${activityTypeString}.ADD_${activityTypeString}_TO_TRAVEL_COMPONENTS`)}/>,
        {progress: undefined, type: "success"}
    );

    const _resetActivityForm = () => {
        setIndexOfActivityToEdit(null)
        setResetActivityForm(resetActivityForm + 1)
    }

    const _renderActivityForm = () => {
        if (activityType === ACTIVITY_TYPE.flight) return <ChooseFlight
            resetFlightForm={() => _resetActivityForm()}
            key={resetActivityForm}
            editIndex={indexOfActivityToEdit}
            editFlight={isActivityFlightState(activity) ? activity : null}
            getCalculatedActivity={setCalculatedActivity}/>


        if (activityType === ACTIVITY_TYPE.hotel) return <ChooseHotel
            resetHotelForm={() => _resetActivityForm()}
            key={resetActivityForm}
            editIndex={indexOfActivityToEdit}
            editHotel={isActivityHotelState(activity) ? activity : null}
            getCalculatedActivity={setCalculatedActivity}/>
    }

    return <>
        <div className={"activity-form-calculate-container"}>
            {_renderActivityForm()}
        </div>
        <BottomButtons
            buttons={[
                {
                    text: "GENERAL.ABORT",
                    variant: "abort",
                    onClick: () => navigate(PATH_STRINGS.travelDatabase)
                },
                {
                    text: `SCREENS.${activityTypeString}.SECOND_BUTTON`,
                    onClick: () => {
                        _writeActivityToTravelComponents()
                        _showToastWhenActivityAddedToTravelComponents()
                        navigate(PATH_STRINGS.travelDatabase)
                    },
                    disabled: activityCalculated
                },
                {
                    text: indexOfActivityToEdit !== null ? `SCREENS.${activityTypeString}.THIRD_BUTTON_UPDATE` : `SCREENS.${activityTypeString}.THIRD_BUTTON`,
                    onClick: () => {
                        _writeActivityToTravelComponents()
                        navigate(PATH_STRINGS.travelComponentsOverview)
                    },
                    disabled: activityCalculated
                },
                {
                    text: `SCREENS.${activityTypeString}.FOURTH_BUTTON`,
                    onClick: () => {
                        _writeActivityToTravelComponents()
                        _showToastWhenActivityAddedToTravelComponents()
                        setResetActivityForm(resetActivityForm + 1)
                        setCalculatedActivity(null)
                        setActivity(null)
                    },
                    disabled: activityCalculated
                },
                // {
                //     text: `SCREENS.${activityTypeString}.FIFTH_BUTTON`,
                //     onClick: () => navigate(PATH_STRINGS.newJourney),
                //     disabled: activityCalculated
                // },
                {
                    text: "SCREENS.COMMON.GO_TO_TRAVEL_COMPONENTS",
                    onClick: () => navigate(PATH_STRINGS.travelComponentsOverview),
                },
            ]}
        />
    </>
}

export default ActivityForm