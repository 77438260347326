export interface TravelClass {
    name: string,
    id: number,
}

export const firstClass: TravelClass = {id: 1, name: 'ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST'};
export const businessClass: TravelClass = {id: 2, name: 'ACTIVITIES.FLIGHT.FLIGHT_CLASS_BUSINESS'};
export const economyClass: TravelClass = {id: 3, name: 'ACTIVITIES.FLIGHT.FLIGHT_CLASS_ECONOMY'};
export const premiumEconomyClass: TravelClass = {id: 4, name: 'ACTIVITIES.FLIGHT.FLIGHT_CLASS_ECONOMY_PREMIUM'};
export const TRAVEL_CLASSES: TravelClass[] = [
    firstClass, businessClass, premiumEconomyClass, economyClass
];