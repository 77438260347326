import {ThemeProps} from "atmosfair-ui"

export const theme: ThemeProps = {
    colors: {
        primaryLighter: '#578CAC',
        primaryLight: '#3F7CA0',
        primary: '#0f5b88',
        primaryDark: '#0c496d',
        primaryDarker: '#093752',

        secondaryLighter: '#B9D477',
        secondaryLight: '#AFCE63',
        secondary: '#9bc23c',
        secondaryDark: '#7c9b30',
        secondaryDarker: '#5d7424',

        black: '#353535',
        white: '#FFFFFF',

        warningMain: '#832120',
        warningLight: '#FFA500',
        warningDark: '#C62828',

        infoLight: '#03A9F4',
        infoMain: '#0288D1',
        infoDark: '#01579B',

        successLight: '#4CAF50',
        successMain: '#2E7D32',
        successDark: '#1B5E20',

        textDisabled: 'rgba(0,0,0,0.12)',

        defaultIconColorInButton: '#FFFFFF',
        defaultIconColorOnlyIcon: '#0f5b88',
        othersDisabled: '#EBE8E8',
        disabledIcon: '#c2c0c0',

        lightGreyLighter: '#f5f5f5',
        lightGrey: '#E6E5E5',
        lightGreyDarker: '#C4C1BF',
        darkGrey: '#9D9D9E',
        darkGreyDarker: '#8A8A8A',
    },
    font: {
        fontFamily: '"Montsserat", "Helvetica", "Roboto", sans-serif',
        fontWeight: {
            lighter: 200,
            light: 300,
            regular: 400,
            bold: 700,
            bolder: 900,
        },
        fontStyle: {
            italic: "italic",
            normal: "normal"
        },
        fontSize: {
            fontSizeLarge: '36px',
            fontSizeMedium: '21px',
            fontSizeRegular: '16px',
            fontSizeSmall: '12px',
            fontSizeVerySmall: '8px',
        }
    },
    spacer: {
        verySmall: 2,
        small: 5,
        medium: 10,
        large: 15,
        veryLarge: 20
    },
}
