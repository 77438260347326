import React, {useEffect, useState} from 'react'
import {useAppDispatch, useAppSelector} from "../../../redux/reducer/store";
import DayCard from "../../../components/day-card/DayCard";
import './styles.css'
import {
    deleteActivityFromJourney,
    deleteActivityFromJourneyActivityQueue,
    deleteDayFromJourney,
    deleteDayFromJourneyDayQueue,
    dragAndDropDay,
    undoDeleteActivityFromJourney,
    undoDeleteDayFromJourney
} from "../../../redux/reducer/journey";
import {ActivityCommonProperties} from "../../../types-enums/activity-common-properties";
import {getActivitiesByTravelOptionTypeAndDay} from "../../../utils/activities-getter";
import {useNavigate} from "react-router-dom";
import {PATH_STRINGS} from "../../../routes/route-props";
import ToastWithButton from "../../../molecules/toasts/toast-with-button/ToastWithButton";
import {toast, ToastItem} from "react-toastify";
import {TRAVEL_OPTION_TYPE} from "../../../types-enums/travel-option";
import {ACTIVITY_TYPE} from "../../../types-enums/activity";
import {useTranslation} from "react-i18next";
import {v4 as uuidv4} from 'uuid';
import type {Active} from "@dnd-kit/core";
import {DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors} from "@dnd-kit/core";
import {SortableContext, sortableKeyboardCoordinates} from "@dnd-kit/sortable";

/**
 * This component displays a whole journey, grouped by days. It serves as a wrapper for the <DayCard /> component to
 * a) feed <DayCard /> the appropriate data and
 * b) to dispatch the actions. If you add a new activity to the App, you also must add a new else if branch to _editActivity
 * accounting for the newly added activity. Make sure you pass it the whole activity as well as the edit index. The action
 * editActivityFlight can be used as a template.
 * **/

const JourneyOverview = () => {
    const journey = useAppSelector(state => state.journey)
    const dispatch = useAppDispatch()
    const {travelOptions, daysCount} = journey
    const [items, setItems] = useState(Array.from({length: daysCount}, (_, index) => {
        return {
            day: index + 1,
            id: uuidv4()
        }
    }));
    const navigate = useNavigate()
    const [active, setActive] = useState<Active | null>(null);
    // const activeItem = useMemo(
    //     () => items.find(item => item.id === active?.id),
    //     [active, items]
    // );
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    );
    const {t} = useTranslation()

    useEffect(() => {
        setItems(Array.from({length: daysCount}, (_, index) => {
            return {
                day: index + 1,
                id: uuidv4()
            }
        }))
    }, [journey]);

    const _renderDeleteActivityToast = (
        activityType: ActivityCommonProperties["common"]["activityType"],
        travelOptionType: TRAVEL_OPTION_TYPE,
        queueId: string,
        day: ActivityCommonProperties["common"]["day"],
        index: number,
    ) => {
        let undoClicked = false;
        const toastId = toast(
            <ToastWithButton
                message={t(
                    "TOASTS.UNDO_DELETE_ACTIVITY_FROM_JOURNEY",
                    {activity: t(`TRAVEL_OPTIONS.TRANSPORT.ACTIVITIES.${activityType.toUpperCase()}.TITLE`)}
                )}
                onClick={() => {
                    undoClicked = true
                    dispatch(undoDeleteActivityFromJourney({
                        activityType,
                        travelOptionType,
                        id: queueId,
                        index,
                        day
                    }))
                    toast.dismiss(toastId)
                }}/>,
            {
                progress: undefined,
                type: 'success',
            }
        );
        const unsubscribe = toast.onChange((payload: ToastItem) => {
            switch (payload.status) {
                case "added":
                    break;
                case "updated":
                    break;
                case "removed":
                    !undoClicked && payload.id === toastId && dispatch(deleteActivityFromJourneyActivityQueue(queueId))
                    unsubscribe()
                    break;
            }
        })
    }

    const _renderDeleteDayToast = (queueId: string, day: ActivityCommonProperties["common"]["day"]) => {
        let undoClicked = false;
        const toastId = toast(
            <ToastWithButton
                message={`TOASTS.UNDO_DELETE_DAY_FROM_JOURNEY ${day}`}
                onClick={() => {
                    undoClicked = true
                    dispatch(undoDeleteDayFromJourney(queueId))
                    toast.dismiss(toastId)
                }}/>,
            {
                progress: undefined,
                type: 'success',
            }
        );

        const unsubscribe = toast.onChange((payload: ToastItem) => {
            switch (payload.status) {
                case "added":
                    break;
                case "updated":
                    break;
                case "removed":
                    !undoClicked && payload.id === toastId && dispatch(deleteDayFromJourneyDayQueue(queueId))
                    unsubscribe()
                    break;
            }
        })
    }

    const _editActivity = (
        activityType: ActivityCommonProperties["common"]["activityType"],
        travelOptionType: TRAVEL_OPTION_TYPE,
        index: number,
        day: ActivityCommonProperties["common"]["day"]
    ) => {
        const activityToEdit = getActivitiesByTravelOptionTypeAndDay(travelOptions, travelOptionType, day)[index]
        switch (activityType) {
            case ACTIVITY_TYPE.flight:
                console.log({...activityToEdit, common: {...activityToEdit.common, editIndex: index}})
                break
        }
    }

    const _deleteActivity = (activityType: ACTIVITY_TYPE, travelOptionType: TRAVEL_OPTION_TYPE, index: number, day: number,) => {
        const queueId = uuidv4()
        dispatch(deleteActivityFromJourney({
            activityType,
            travelOptionType,
            id: queueId,
            day,
            index
        }))
        _renderDeleteActivityToast(activityType, travelOptionType, queueId, day, index)
    }

    const _deleteDay = (day: number) => {
        const queueId = uuidv4()
        dispatch(deleteDayFromJourney({day, id: queueId}))
        _renderDeleteDayToast(queueId, day)
    }

    if (items.length === 0) navigate(PATH_STRINGS.travelDatabase)
    return <DndContext
        sensors={sensors}
        onDragStart={({active}) => setActive(active)}
        onDragEnd={({active, over}) => {
            if (over && active.id !== over?.id) {
                const activeIndex = items.findIndex(({id}) => id === active.id);
                const overIndex = items.findIndex(({id}) => id === over.id);
                const movedItems = items.at(activeIndex)
                if (movedItems) {
                    const originalDay = movedItems.day
                    const newDay = overIndex + 1
                    if (newDay !== originalDay) dispatch(dragAndDropDay({originalDay, newDay}))
                }
            }
            setActive(null);
        }}
        onDragCancel={() => setActive(null)}>
        <SortableContext items={items}>
            <ul className={"journey-overview-container"}>
                {items.map((item, index) => {
                    const {day, id} = item
                    return <DayCard
                        dndId={id}
                        deleteActivityFromJourney={(activityType, travelOptionType, index) => _deleteActivity(activityType, travelOptionType, index, day)}
                        editActivity={(activityType, travelOptionType, index) => _editActivity(activityType, travelOptionType, index, day)}
                        deleteDayFromJourney={() => _deleteDay(day)}
                        key={index}
                        travelOptions={travelOptions}
                        day={day}/>
                })
                }
            </ul>
        </SortableContext>
    </DndContext>

}

export default JourneyOverview