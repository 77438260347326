import {
    DelayedSpinner,
    DropdownInput,
    Fieldset,
    IconButton,
    NumberInput,
    Text,
    TextButton,
    TextInput
} from 'atmosfair-ui';
import React, {useEffect, useRef, useState} from 'react';
import {ActivityHotel, ActivityHotelState} from '../../../../types-enums/activity-hotel';
import {ActivityCommonProperties} from '../../../../types-enums/activity-common-properties';
import {theme} from '../../../../styles/theme';
import './styles.css'
import '../../styles.css'
import {
    CityResponse,
    CountryResponse,
    HotelCalculationRequest,
    HotelResponse
} from '../../../../api/model/activity-hotel';
import {
    areHotelsEqual,
    convertHotelClassesFromDropdownFormat,
    convertHotelClassesToDropdownFormat,
    convertMealClassesFromDropdownFormat,
    convertMealClassesToDropdownFormat,
    convertRoomClassesFromDropdownFormat,
    convertRoomClassesToDropdownFormat,
    DaysRange,
    emptyCityResponse,
    emptyHotelResponse,
    getDatePattern
} from './utils';
import {HOTEL_PROPERTIES} from '../../../../constants';
import {DateRange, RangeKeyDict} from 'react-date-range';
import {useTranslation} from 'react-i18next';
import {formatCo2ETotalForHotel, NO_DATA_STRING} from "../../../../utils/string-mutation";
import {E_CO2} from "../../../../utils/unit-strings";
import ToastWithTextOnly from "../../../../molecules/toasts/toast-with-text-only/ToastWithTextOnly";
// @ts-ignore
import {de, enGB} from 'react-date-range/dist/locale';
import {apiSlice, useCalculateHotelEmissionsMutation} from '../../../../redux/reducer/api';
import {
    isCalculatedHotelEmissionsErrorResponse,
    isEmptyCityResponse,
    isEmptyHotelResponse,
    isHotelCalculatedEmissionsResponsePartial
} from '../../../../utils/type-guards';
import {toast, TypeOptions} from 'react-toastify';
import {ACTIVITY_TYPE} from '../../../../types-enums/activity';
import {
    CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL,
    CALCULATION_TYPE,
    relevantErrorCodes
} from "../../../../api/model/common";
import {TRAVEL_OPTION_TYPE} from '../../../../types-enums/travel-option';
import {v4 as uuidv4} from "uuid";
import {CalculatedEmissionsResponseTotal} from '../../../../api/model/emissions';
import {useAppSelector} from '../../../../redux/reducer/store';
import format from 'date-fns/format';
import {
    getHotelCategory,
    getHotelCategoryFromNumber,
    getMealPlan,
    getRoomCategory,
    getScopeTranslationKey,
    HOTEL_CLASSES,
    HotelCategory,
    MEAL_CLASSES,
    MealPlan,
    ROOM_CLASSES,
    RoomCategory,
    zeroStar
} from "../../../../screens/activity/activity-form/hotel";
import {_getDomain} from "../../utils";
import {PATH_STRINGS} from "../../../../routes/route-props";
import AutocompleteQuery, {
    AUTOCOMPLETE_ACTIVITY_TYPE,
    AUTOCOMPLETE_QUERY_TYPE
} from '../../../../molecules/autocomplete-query/AutocompleteQuery';
import clsx from 'clsx';
import cloneDeep from "lodash/cloneDeep";

interface HotelStayProps {
    hotel: HotelResponse | null,
    city: CityResponse | null,
    country: CountryResponse | null,
    stars: HotelResponse['stars'] | null
}

export interface ChooseHotelProps {
    editIndex: ActivityCommonProperties["common"]["editIndex"],
    editHotel: ActivityHotelState | null
    getCalculatedActivity: (activity: ActivityHotelState) => void
    resetHotelForm: () => void;
}

// local state for range picker
const today = new Date();
const tomorrow = new Date(today);
tomorrow.setDate(today.getDate() + 1);

const ChooseHotel = ({
                         editIndex,
                         editHotel,
                         getCalculatedActivity,
                         resetHotelForm
                     }: ChooseHotelProps) => {
    const [hotelStay, setHotelStay] = useState<HotelStayProps | null>(null)
    const {userData} = useAppSelector(state => state.user)
    const [calculationRequest, setCalculationRequest] = useState<HotelCalculationRequest | null>(null)
    const [hotelClass, setHotelClass] = useState<HotelCategory | null>(null);
    const [roomClass, setRoomClass] = useState<RoomCategory | null>(null);
    const [mealClass, setMealClass] = useState<MealPlan | null>(null);
    const [roomCount, setRoomCount] = useState<ActivityHotel["numberOfRooms"]>(1)
    const [peopleCount, setPeopleCount] = useState<ActivityHotel["numberOfPeople"]>(1)
    const [nightCount, setNightCount] = useState<ActivityHotel["numberOfNights"]>(1)
    const [giataId, setGiataId] = useState<string | null>(null);
    const hotelForRequestFormat = useRef<HotelCalculationRequest[]>([]);
    const [disableCalculateButton, setDisableCalculateButton] = useState(false);
    const [numberPeopleError, setNumberPeopleError] = useState(false);
    const [numberOfNightsError, setNumberOfNightsError] = useState(false);
    const [numberOfRoomsError, setNumberOfRoomsError] = useState(false);
    const [networkError, setNetworkError] = useState(false);
    const [giataIdError, setGiataIdError] = useState(false)
    const [cityError, setCityError] = useState(false);
    const [countryError, setCountryError] = useState(false);
    const [open, setOpen] = useState(false)
    const {t, i18n} = useTranslation();
    const currentLanguage = i18n.resolvedLanguage
    const locale = currentLanguage === 'de' ? de : enGB;
    const [range, setRange] = useState<DaysRange>({
        startDate: today,
        endDate: tomorrow,
        key: 'selection',
    });
    const datePattern = getDatePattern(i18n.language === 'en' ? 'en-GB' : i18n.language);

    const [calculateHotelEmissions, {
        data: resultCalculatingEmissions,
        error: errorCalculatingEmissions,
        isLoading: isCalculatingEmissions,
    }] = useCalculateHotelEmissionsMutation()

    const [getHotelByGiataId, {
        data: resultHotelByGiataId,
        error: errorHotelByGiataId,
        isLoading: isGettingHotelByGiataId,
    }] = apiSlice.endpoints?.getHotelByGiataId.useLazyQuery()

    useEffect(() => {
        if (editIndex !== null && editHotel) {
            const {
                hotel,
                city,
                country,
                hotelClass,
                numberOfNights,
                numberOfPeople,
                numberOfRooms,
                roomCategory,
                mealPlan,
                startDate,
                endDate
            } = editHotel
            const stars = getHotelCategoryFromNumber(hotelClass)
            setHotelStay({
                hotel: hotel,
                city: city,
                country: country,
                stars: stars.name
            })
            setHotelClass(stars)
            setPeopleCount(numberOfPeople)
            setNightCount(numberOfNights)
            setRoomCount(numberOfRooms)
            if (startDate && endDate) setRange({startDate, endDate, key: 'selection'})
            setRoomClass(getRoomCategory(roomCategory))
            setMealClass(getMealPlan(mealPlan))
        }
    }, [editHotel, editIndex])

    useEffect(() => {
        const stars = hotelStay?.stars
        if (stars) {
            const hotelStars = getHotelCategory(stars)
            if (hotelStars.value !== zeroStar.value && hotelStars.value !== hotelClass?.value) {
                if (hotelStay) setHotelStay({
                    ...hotelStay,
                    hotel: emptyHotelResponse
                })
                setGiataId('')
            }
        }
    }, [hotelClass])


    useEffect(() => {
        if (errorHotelByGiataId) setNetworkError(true);
    }, [errorHotelByGiataId]);

    useEffect(() => {
        _fillCalculationRequest()
        if (disableCalculateButton) setDisableCalculateButton(false)
    }, [hotelStay, hotelClass, roomClass, roomCount, roomCount, nightCount, peopleCount, mealClass])

    useEffect(() => {
        if (isCalculatedHotelEmissionsErrorResponse(errorCalculatingEmissions)) {
            const {data} = errorCalculatingEmissions
            const {status, emissionKgCO2eTotal, hotelActivities, errorCodes} = data
            let informativeToastShowed = false;

            const errorStrings: string[] = []
            hotelActivities.forEach(hotel => {
                if (hotel.errorCodes) hotel.errorCodes.forEach(error => {
                    const {code} = error
                    switch (code) {
                        case 701:
                            errorStrings.push(t(`ERRORS.BACKEND_ERRORS.${relevantErrorCodes[code]}`));
                            break;
                        case 702:
                            errorStrings.push(t(`ERRORS.BACKEND_ERRORS.${relevantErrorCodes[code]}`));
                            break;
                        case 703:
                            errorStrings.push(t(`ERRORS.BACKEND_ERRORS.${relevantErrorCodes[code]}`));
                            break;
                        case 704:
                            errorStrings.push(t(`ERRORS.BACKEND_ERRORS.${relevantErrorCodes[code]}`));
                            break;
                        case 817:
                            errorStrings.push(t(`ERRORS.BACKEND_ERRORS.${relevantErrorCodes[code]}`));
                            break;
                        default:
                            errorStrings.push(t(`ERRORS.BACKEND_ERRORS.${relevantErrorCodes[code as keyof typeof relevantErrorCodes]}`))
                    }
                })
            })

            if (errorStrings.length > 0) {
                _renderToast(errorStrings.join("\n \n"), "warning")
                informativeToastShowed = true
            }

            if (!informativeToastShowed) toast(<ToastWithTextOnly
                    message={t("ERRORS.GENERAL_NETWORK_ERROR_BODY")}/>,
                {progress: undefined, type: "error"}
            );

            getCalculatedActivity({
                common: {
                    activityType: ACTIVITY_TYPE.hotel,
                    travelOptionType: TRAVEL_OPTION_TYPE.accommodation,
                    editIndex: null,
                    totalEmissions: {
                        data: hotelActivities,
                        status,
                        emissionKgCO2eTotal,
                        errorCodes,
                    },
                    id: uuidv4(),
                    request: hotelForRequestFormat.current
                },
                hotel: hotelStay !== null ? hotelStay?.hotel : null,
                city: hotelStay !== null ? hotelStay?.city : null,
                country: hotelStay !== null ? hotelStay?.country : null,
                hotelClass: hotelClass ? hotelClass.value : 0,
                roomCategory: roomClass ? roomClass.value : null,
                numberOfNights: nightCount,
                startDate: range.startDate,
                endDate: range.endDate,
                numberOfPeople: peopleCount,
                numberOfRooms: roomCount,
                mealPlan: mealClass ? mealClass.value : null,
            })
        }
    }, [errorCalculatingEmissions]);

    useEffect(() => {
        if (resultCalculatingEmissions) {
            setDisableCalculateButton(true)
            const {data, emissionKgCO2eTotal} = resultCalculatingEmissions
            const calculatedHotel = data[0]
            if (isHotelCalculatedEmissionsResponsePartial(calculatedHotel)) {
                const scopeOfCalculation = t(getScopeTranslationKey(calculatedHotel.scope))
                if (emissionKgCO2eTotal) _renderToast(t("GENERAL.EMISSIONS_CALCULATED_WITH_SCOPE", {scope: scopeOfCalculation.toLowerCase()}), "success")
                getCalculatedActivity({
                    common: {
                        activityType: ACTIVITY_TYPE.hotel,
                        travelOptionType: TRAVEL_OPTION_TYPE.accommodation,
                        editIndex: null,
                        totalEmissions: resultCalculatingEmissions,
                        id: uuidv4(),
                        request: hotelForRequestFormat.current,
                    },
                    hotel: hotelStay !== null ? hotelStay?.hotel : null,
                    city: hotelStay !== null ? hotelStay?.city : null,
                    country: hotelStay !== null ? hotelStay?.country : null,
                    hotelClass: hotelClass ? hotelClass.value : 0,
                    roomCategory: roomClass ? roomClass.value : null,
                    numberOfNights: nightCount,
                    startDate: range.startDate,
                    endDate: range.endDate,
                    numberOfPeople: peopleCount,
                    numberOfRooms: roomCount,
                    mealPlan: mealClass ? mealClass.value : null,
                })
            }
        }

    }, [resultCalculatingEmissions]);

    const _validateGiataId = () => giataId !== null && giataId.length > 0

    const handleRangePickerChange = (ranges: RangeKeyDict) => {
        const selectedRange = ranges.selection;
        const startDate = selectedRange.startDate || new Date();
        const endDate = selectedRange.endDate || new Date();
        const newRange: DaysRange = {startDate, endDate, key: 'selection'};
        setRange(newRange);

        const diffTime = endDate.getTime() - startDate.getTime();
        let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        setNightCount(diffDays);
    };

    const handleNightCountChange = (newDuration: number) => {
        setNumberOfNightsError(false)
        setNightCount(newDuration)
        if (newDuration && range.startDate) {
            let date1 = new Date(range.startDate);
            date1.setDate(date1.getDate() + newDuration);
            const newRange = {...range, endDate: date1}
            setRange(newRange)
        }

    };

    const _setCommonHotelRequestProps = (index: number) => ({
        index,
        type: CALCULATION_TYPE.hotel,
        hotelClass: hotelClass ? hotelClass.value : null,
        roomCategory: roomClass ? roomClass.value : null,
        numberOfRooms: roomCount,
        numberOfNights: nightCount,
        numberOfPeople: peopleCount,
        mealPlan: mealClass ? mealClass.value : null
    });

    const _fillCalculationRequest = () => {
        let request: HotelCalculationRequest | null = null
        setCalculationRequest(null);
        const common = _setCommonHotelRequestProps(0)
        if (
            hotelStay !== null &&
            hotelStay.city !== null &&
            !isEmptyCityResponse(hotelStay.city as CityResponse) &&
            hotelStay.country !== null
        ) {
            const hotel = (hotelStay.hotel && !isEmptyHotelResponse(hotelStay.hotel as HotelResponse)) ? hotelStay.hotel : null
            const city = hotelStay.city
            request = {
                hotelId: hotel ? hotel.hotelId : null,
                hotelName: hotel ? hotel.name : null,
                city: city.name,
                region: city.region,
                country: hotelStay.country.alpha3,
                ...common
            }
        }
        setCalculationRequest(request)
    }

    const _evaluateForm = () => {
        // general form errors
        const {
            minNumberPeople,
            maxNumberPeople,
            minNumberNights,
            maxNumberNights,
            minNumberRooms,
            maxNumberRooms
        } = HOTEL_PROPERTIES

        const numberOfPeopleError = !(peopleCount >= minNumberPeople && peopleCount <= maxNumberPeople)
        const numberOfRoomsError = !(roomCount >= minNumberRooms && roomCount <= maxNumberRooms)
        const numberOfNightsError = !(nightCount >= minNumberNights && nightCount <= maxNumberNights)
        const cityError = (hotelStay?.city == null || isEmptyCityResponse(hotelStay.city))
        const countryError = (hotelStay?.country == null)
        setNumberPeopleError(numberOfPeopleError)
        setNumberOfRoomsError(numberOfRoomsError)
        setNumberOfNightsError(numberOfNightsError)
        setCityError(cityError)
        setCountryError(countryError)

        if (!(
            peopleCount > maxNumberPeople ||
            roomCount > maxNumberRooms ||
            nightCount > maxNumberNights ||
            cityError ||
            countryError
        )) _calculateEmissions();
    }

    const _calculateEmissions = async () => {
        try {
            if (calculationRequest !== null) {
                const filteredCalculationRequest = Object.fromEntries(
                    Object.entries(calculationRequest).filter(([_, value]) => value !== null)
                ) as HotelCalculationRequest;
                const hotelActivities = [filteredCalculationRequest]
                hotelForRequestFormat.current = hotelActivities
                calculateHotelEmissions({hotelActivities: hotelForRequestFormat.current})
            }
        } catch (error) {
            console.error(`Something went wrong when calculating hotel emissions: ${error}`);
        }
    }

    const _renderToast = (message: string, type: TypeOptions) => toast(<ToastWithTextOnly
            message={t(message)}/>,
        {progress: undefined, type}
    );

    // The result of the api call is handled here and not in a useEffect hook because if the hotel is not found twice in a row and is null then the useEffect would not be triggered
    const _getHotelsByGiataId = async () => {
        if (!_validateGiataId()) {
            setGiataIdError(true)
        } else {
            setGiataIdError(false)
            const result = await getHotelByGiataId(giataId!!)
            const hotelData = result?.data
            if (hotelData) {
                let toastMessage = 'TOASTS.SUCCESS.HOTEL_FOUND_VIA_GIATA_ID'
                if (hotelStay !== null) toastMessage = `${t(toastMessage)} ${t('TOASTS.SUCCESS.HOTEL_FOUND_VIA_GIATA_ID_REPLACED_PREVIOUS')}`
                toast(
                    <ToastWithTextOnly
                        message={toastMessage}/>,
                    {progress: undefined, type: "success"}
                )
                const newHotelClass = getHotelCategory(hotelData.stars);
                const copyResultHotelByGiataId = cloneDeep(hotelData)
                copyResultHotelByGiataId.hotelId = giataId
                _setHotel(copyResultHotelByGiataId);
                setHotelClass(newHotelClass);
                setNetworkError(false);
            } else {
                if (_validateGiataId()) {
                    toast(
                        <ToastWithTextOnly
                            message={t("ERRORS.HOTEL_NOT_FOUND")}/>,
                        {progress: undefined, type: "error"}
                    )
                    if (hotelStay) setHotelStay({
                        ...hotelStay,
                        hotel: emptyHotelResponse
                    })
                    setGiataId('')
                }
            }
        }
    };

    const _renderRoomFields = () => {
        const {minNumberRooms, maxNumberRooms} = HOTEL_PROPERTIES
        const WIDTH_INPUT = 30;
        return <Fieldset
            legend={"SCREENS.HOTEL.ROOM_CATEGORY"}
            showLegend={false}
            fieldsetClasses={'choose-options-wrapper'}>
            <DropdownInput
                labelColor={"primary"}
                label={"SCREENS.HOTEL.ROOM_CATEGORY"}
                placeholder={"GENERAL.OPTION_OPTIONAL"}
                options={convertRoomClassesToDropdownFormat(ROOM_CLASSES)}
                setSelection={value => setRoomClass(convertRoomClassesFromDropdownFormat(value))}
                style={{width: "10%"}}
                showDeleteIcon={true}
                defaultValue={roomClass?.id || null}/>
            <NumberInput
                labelColor={"primary"}
                min={minNumberRooms}
                max={maxNumberRooms}
                error={numberOfRoomsError}
                ariaErrorMessage={t("ACTIVITIES.HOTEL.ARIA_LABELS.MIN_MAX_ROOMS_EXCEEDED_ERROR", {
                    min: minNumberRooms,
                    max: maxNumberRooms
                })}
                injectMinimum={true}
                required={true}
                label={"SCREENS.HOTEL.ROOMS"}
                onBlur={(value: number) => setRoomCount(value)}
                onChange={(value: number) => {
                    setNumberOfRoomsError(false)
                    setRoomCount(value)
                }}
                containerStyle={{width: `${WIDTH_INPUT}px`}}
                value={roomCount}/>
        </Fieldset>
    }

    const _renderMealFields = () => {
        const {minNumberPeople, maxNumberPeople} = HOTEL_PROPERTIES
        const WIDTH_INPUT = 30;
        return <Fieldset
            legend={"ACTIVITIES.HOTEL.ARIA_LABELS.MEAL_PLAN"}
            showLegend={false}
            fieldsetClasses={'choose-options-wrapper'}>
            <DropdownInput
                labelColor={"primary"}
                label={"SCREENS.HOTEL.MEAL_PLAN"}
                placeholder={"GENERAL.OPTION_OPTIONAL"}
                options={convertMealClassesToDropdownFormat(MEAL_CLASSES)}
                setSelection={value => setMealClass(convertMealClassesFromDropdownFormat(value))}
                style={{width: "10%"}}
                showDeleteIcon={true}
                defaultValue={mealClass?.id || null}/>
            <NumberInput
                labelColor={"primary"}
                min={minNumberPeople}
                max={maxNumberPeople}
                error={numberPeopleError}
                ariaErrorMessage={t("ACTIVITIES.HOTEL.ARIA_LABELS.MIN_MAX_PEOPLE_EXCEEDED_ERROR", {
                    min: minNumberPeople,
                    max: maxNumberPeople
                })}
                injectMinimum={true}
                required={true}
                label={"SCREENS.HOTEL.PEOPLE"}
                onBlur={(value: number) => setPeopleCount(value)}
                onChange={(value: number) => {
                    setNumberPeopleError(false)
                    setPeopleCount(value)
                }}
                containerStyle={{width: `${WIDTH_INPUT}px`}}
                value={peopleCount}/>
        </Fieldset>
    }

    const _renderNumberOfNights = () => {
        const {minNumberNights, maxNumberNights} = HOTEL_PROPERTIES
        const WIDTH_INPUT = 30;
        return <Fieldset
            legend={"ACTIVITIES.FLIGHT.ARIA_LABELS.NUMBER_OF_NIGHTS"}
            showLegend={false}
            fieldsetClasses={'choose-options-wrapper'}>
            <NumberInput
                labelColor={"primary"}
                min={minNumberNights}
                max={maxNumberNights}
                error={numberOfNightsError}
                ariaErrorMessage={t("ACTIVITIES.HOTEL.ARIA_LABELS.MIN_MAX_NIGHTS_EXCEEDED_ERROR", {
                    min: minNumberNights,
                    max: maxNumberNights
                })}
                injectMinimum={true}
                required={true}
                label={"SCREENS.HOTEL.NIGHTS"}
                onBlur={(value: number) => setNightCount(value)}
                onChange={(value: number) => {
                    handleNightCountChange(value)
                }}
                containerStyle={{width: `${WIDTH_INPUT}px`}}
                value={nightCount}/>
            <div className="calendarWrap">
                <div className="choose-hotel-calendar-input-fields">
                    <input
                        readOnly
                        name="startDate"
                        type="text"
                        className={clsx("choose-hotel-input-box choose-hotel-start-date-box")}
                        onClick={() => setOpen(open => !open)}
                        placeholder={t('GENERAL.START_DATE')}
                        value={range.startDate ? format(new Date(range.startDate), datePattern) : ""}
                    />
                    <input
                        readOnly
                        name="endDate"
                        type="text"
                        className="choose-hotel-input-box"
                        onClick={() => setOpen(open => !open)}
                        placeholder={t('GENERAL.END_DATE')}
                        value={range.endDate ? format(new Date(range.endDate), datePattern) : ""}
                    />
                    <div className={clsx("choose-hotel-icon", "choose-hotel-calendar-icon")}>
                        <IconButton
                            tooltip={"SCREENS.HOTEL.CHOOSE_DATES"}
                            color={"primary"}
                            size={"medium"}
                            icon={!open ? "caretDown" : "caretUp"}
                            onlyIcon={true}
                            onClick={() => setOpen(open => !open)}/>
                    </div>
                </div>
                {open &&
                    <DateRange
                        onChange={handleRangePickerChange}
                        editableDateInputs={true}
                        moveRangeOnFirstSelection={false}
                        months={1}
                        ranges={[range]}
                        direction="horizontal"
                        className="choose-hotel-calendar-element"
                        locale={locale}
                        rangeColors={["#0f5b88"]}
                    />
                }
            </div>
        </Fieldset>
    }

    const _renderResultString = (
        status: CalculatedEmissionsResponseTotal["status"],
        emissionKgCO2eTotal: CalculatedEmissionsResponseTotal["emissionKgCO2eTotal"]
    ) => <Text variant={"headlineMedium"}>
        {
            status !== CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.failed ?
                formatCo2ETotalForHotel(emissionKgCO2eTotal!, currentLanguage) :
                `${NO_DATA_STRING} ${E_CO2}`
        }
    </Text>

    const _renderResult = () => {
        if (resultCalculatingEmissions) {
            const {status, emissionKgCO2eTotal} = resultCalculatingEmissions
            return _renderResultString(status, emissionKgCO2eTotal)
        }

        if (isCalculatedHotelEmissionsErrorResponse(errorCalculatingEmissions)) {
            const {data} = errorCalculatingEmissions
            const {status, emissionKgCO2eTotal} = data
            return _renderResultString(status, emissionKgCO2eTotal)
        }

        return null
    }

    const _setHotel = (hotel: HotelResponse | null) => {
        if (hotel) {
            if (!(hotelStay?.hotel && areHotelsEqual(hotel, hotelStay.hotel))) {
                setHotelStay({
                    hotel,
                    city: {
                        id: 1,
                        name: hotel.city,
                        region: hotel.region,
                        alpha2: hotel.alpha2,
                        alpha3: hotel.alpha3,
                        countryName: hotel.countryName
                    },
                    country: {
                        id: 1,
                        alpha2: hotel.alpha2,
                        alpha3: hotel.alpha3,
                        name: hotel.countryName
                    },
                    stars: hotel.stars
                })
                setGiataId(hotel.hotelId ? hotel.hotelId : '')
                setHotelClass(getHotelCategory(hotel.stars))
            }
            setCityError(false);
            setCountryError(false)

        } else {
            // empty hotel fields 
            if (hotelStay) setHotelStay({
                ...hotelStay,
                hotel: emptyHotelResponse
            })
            setGiataId('')
        }
    }

    const _setCity = (city: CityResponse | null) => {
        if (city) {
            // do not empty other fields if city is the same city of the already filled-in data
            if (
                (hotelStay?.hotel && hotelStay.hotel.city === city?.name) &&
                (hotelStay?.city && hotelStay.city.region === city.region) &&
                (hotelStay?.country && hotelStay.country.alpha2 === city.alpha2)
            ) {
                setHotelStay({
                    ...hotelStay,
                    city
                })
            } else {
                setHotelStay({
                    hotel: emptyHotelResponse,
                    city,
                    country: {
                        id: 1,
                        alpha2: city.alpha2,
                        alpha3: city.alpha3,
                        name: city.countryName
                    },
                    stars: hotelStay ? hotelStay.stars : null
                })
                setGiataId('')
            }
            setCityError(false);
            setCountryError(false)

        } else {
            // empty hotel and city fields
            if (hotelStay) setHotelStay({
                ...hotelStay,
                hotel: emptyHotelResponse,
                city: emptyCityResponse
            })
            setGiataId('')
        }
    }

    const _setCountry = (country: CountryResponse | null) => {
        if ((hotelStay?.hotel && hotelStay.hotel.alpha2 === country?.alpha2) || (hotelStay?.city && hotelStay.city.alpha2 === country?.alpha2)) {
            setHotelStay({
                ...hotelStay,
                country
            })
        } else {
            setHotelStay({
                hotel: emptyHotelResponse,
                city: emptyCityResponse,
                country,
                stars: hotelStay ? hotelStay.stars : null
            })
            setGiataId('')
        }
    }

    return <>
        <Text style={{marginBottom: theme.spacer.large}}>
            ACTIVITIES.HOTEL.DESCRIPTION
        </Text>
        {userData?.userPreferences?.showGiataIdField && <Fieldset
            legend={"SCREENS.HOTEL.GIATA_ID"}
            showLegend={false}
            fieldsetClasses={'choose-options-wrapper'}>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <TextInput
                    error={giataIdError}
                    ariaErrorMessage={'SCREENS.HOTEL.ARIA_LABELS.GIATA_ID_ERROR'}
                    labelColor={"primary"}
                    required={false}
                    label={"SCREENS.HOTEL.GIATA_ID"}
                    placeholder={"GENERAL.OPTION_OPTIONAL"}
                    onBlur={value => setGiataId(value)}
                    onChange={value => setGiataId(value)}
                    value={giataId}
                />
            </div>
            <IconButton
                classes={'choose-hotel-icon'}
                tooltip={"SCREENS.HOTEL.SEARCH"}
                color={"primary"}
                size={"medium"}
                icon={"hotel"}
                onlyIcon={true}
                onClick={() => _getHotelsByGiataId()}/>
        </Fieldset>}
        <div
            role="form"
            className="choose-hotel-container">
            <Fieldset
                role="group"
                fieldsetClasses="choose-hotel-container"
                showLegend={true}
                required={true}
                legend="SCREENS.HOTEL.HOTEL_INFO">
                <div className={'hotel-selector-container'}>
                    <AutocompleteQuery
                        placeholder={"GENERAL.HOTEL"}
                        type={AUTOCOMPLETE_ACTIVITY_TYPE.hotel}
                        labelColor={"primary"}
                        label={"GENERAL.HOTEL"}
                        required={false}
                        containerStyle={{width: "100%"}}
                        queryType={AUTOCOMPLETE_QUERY_TYPE.hotel}
                        setSelection={hotel => _setHotel(hotel as HotelResponse)}
                        defaultValue={hotelStay?.hotel}
                        secondSearchParam={hotelStay?.city}
                        thirdSearchParam={hotelStay?.country}
                    />
                    <AutocompleteQuery
                        placeholder={"GENERAL.COUNTRY"}
                        ariaErrorMessage={"SCREENS.HOTEL.ARIA_LABELS.COUNTRY_ERROR"}
                        type={AUTOCOMPLETE_ACTIVITY_TYPE.hotel}
                        labelColor={"primary"}
                        label={"GENERAL.COUNTRY"}
                        required={true}
                        containerStyle={{width: "100%"}}
                        error={countryError}
                        queryType={AUTOCOMPLETE_QUERY_TYPE.country}
                        setSelection={(country) => _setCountry(country as CountryResponse)}
                        defaultValue={hotelStay?.country}
                    />
                    <AutocompleteQuery
                        placeholder={"GENERAL.CITY"}
                        ariaErrorMessage={"SCREENS.HOTEL.ARIA_LABELS.CITY_ERROR"}
                        type={AUTOCOMPLETE_ACTIVITY_TYPE.hotel}
                        labelColor={"primary"}
                        label={"GENERAL.CITY"}
                        required={true}
                        containerStyle={{width: "100%"}}
                        error={cityError}
                        queryType={AUTOCOMPLETE_QUERY_TYPE.city}
                        setSelection={(city) => _setCity(city as CityResponse)}
                        defaultValue={hotelStay?.city}
                        secondSearchParam={hotelStay?.country}
                    />
                    <DropdownInput
                        labelColor={"primary"}
                        label={"SCREENS.HOTEL.STARS"}
                        placeholder={"GENERAL.OPTION_OPTIONAL"}
                        options={convertHotelClassesToDropdownFormat(HOTEL_CLASSES)}
                        setSelection={value => setHotelClass(convertHotelClassesFromDropdownFormat(value))}
                        style={{width: "10%"}}
                        showDeleteIcon={true}
                        defaultValue={hotelClass?.id || null}/>
                </div>
            </Fieldset>
            <Fieldset
                role="group"
                fieldsetClasses="choose-stay-container"
                showLegend={true}
                required={true}
                legend="SCREENS.HOTEL.STAY_INFO">
                <div className={'stay-selector-container'}>
                    {_renderRoomFields()}
                    {_renderMealFields()}
                    {_renderNumberOfNights()}
                </div>
            </Fieldset>
            <div className={'choose-hotel-position-button'}>
                {_renderResult()}
                <div className={'choose-hotel-buttons-wrapper'}>
                    <TextButton
                        onClick={() => {
                            if (editIndex !== null) window.history.replaceState({}, document.title, `${_getDomain()}${PATH_STRINGS.addActivity}/${ACTIVITY_TYPE.hotel}`);
                            resetHotelForm()
                        }}>
                        GENERAL.RESET
                    </TextButton>
                    <TextButton
                        disabled={disableCalculateButton}
                        onClick={() => _evaluateForm()}>
                        {editIndex !== null ? "GENERAL.CALCULATE_AGAIN" : "GENERAL.CALCULATE"}
                    </TextButton>
                </div>
                {isCalculatingEmissions && <DelayedSpinner fullscreen={true} delayMs={500}/>}
            </div>
        </div>
    </>
};

export default ChooseHotel;
