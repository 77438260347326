import React from "react"
import "./styles.css"
import {Text} from "atmosfair-ui";
import clsx from "clsx";
import {textColor} from "../styles";

interface ToastWithTextOnlyProps {
    message: string
}

const ToastWithTextOnly = ({
                               message,
                           }: ToastWithTextOnlyProps) => {
    return <Text
        color={textColor}
        classes={clsx("toast-with-text-only-container", "toasts-text-color")}>
        {message}
    </Text>
}

export default ToastWithTextOnly