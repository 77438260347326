import React, {useEffect, useRef, useState} from "react"
import {DataGrid, DataGridDataRowProps, IconAndText, IconButton, Modal, Text} from "atmosfair-ui"
import "../styles.css"
import AgenciesCompanyForm, {
    AgenciesCompanyFormProps,
    AgenciesCompanyProps
} from "../../../forms/agencies-company-form/AgenciesCompanyForm";
import {toast} from "react-toastify";
import ToastWithTextOnly from "../../../../molecules/toasts/toast-with-text-only/ToastWithTextOnly";
import {useTranslation} from "react-i18next";
import {
    useCreateAgencyMutation,
    useCreateCompanyMutation,
    useDeleteAgencyMutation,
    useInviteUserMutation,
    useUpdateAgencyMutation,
    useUpdateCompanyMutation
} from "../../../../redux/reducer/api";
import {AgencyProps, CreateAgencyRequest} from "../../../../api/model/agency";
import {CompanyProps} from "../../../../api/model/company";
import {theme} from "../../../../styles/theme";
import {GENERAL_BACKEND_RESPONSE} from "../../../../api/model/common";
import {isEqual} from "lodash";
import SectionTitle from "../../section-title/SectionTitle";
import {v4 as uuidv4} from "uuid";
import {useAppSelector} from "../../../../redux/reducer/store";
import {QueryStatus} from "@reduxjs/toolkit/query";
import {useFusionAuth} from "@fusionauth/react-sdk";
import {INITIAL_SELECTED_COUNTRY, INITIAL_SELECTED_INTERNATIONAL_PHONE_PREFIX} from "../../../../utils/default-values";
import {AddressProps} from "../../../forms/address-form/AddressForm";
import {PhoneNumberProps} from "../../../forms/phone-number-form/PhoneNumberForm";
import {VALIDATE_FORM_STARTING_NUMBER} from "../../../../constants";
import {ContactPersonProps} from "../../../forms/contact-person-form/ContactPersonForm";

interface AgenciesCompanyDataGridProps {
    whichType: AgenciesCompanyFormProps["whichType"]
    company?: CompanyProps,
    agencies?: AgencyProps[],
    allUsers: AgenciesCompanyFormProps['allUsers']
    isCompanyAdmin: boolean,
}

interface AngeciesCompanyDataGridFormProps {
    inviteFirstContact: boolean,
    firstContactFirstName: string,
    firstContactLastName: string,
    firstContactEmail?: string | null,
    firstContactAdmin?: boolean
    inviteSecondContact: boolean,
    secondContactFirstName?: string | null,
    secondContactLastName?: string | null,
    secondContactEmail?: string | null,
    secondContactAdmin?: boolean,
}

export interface OriginalAgencyCompanyProps extends AngeciesCompanyDataGridFormProps, AgenciesCompanyProps, PhoneNumberProps, AddressProps {
}

export interface FormPropsAgency extends AngeciesCompanyDataGridFormProps, AgenciesCompanyProps, PhoneNumberProps, AddressProps {
}

export const initialAgencyFormData: FormPropsAgency = {
    agencyCompanyName: "",
    agencyCompanyEmail: "",
    street: "",
    houseNumber: "",
    zipCode: "",
    city: "",
    additionalAddressInformation: "",
    country: INITIAL_SELECTED_COUNTRY?.name ?? null,
    internationalPhonePrefix: INITIAL_SELECTED_INTERNATIONAL_PHONE_PREFIX,
    phoneNumber: "",
    inviteSecondContact: false,
    inviteFirstContact: false,
    firstContactFirstName: "",
    firstContactLastName: "",
    firstContactEmail: "",
    firstContactAdmin: false,
    secondContactFirstName: "",
    secondContactLastName: "",
    secondContactEmail: '',
    secondContactAdmin: false,
}

interface ContactProps {
    firstName: string,
    lastName: string,
    isAdmin: boolean
    email: string,
}

interface ContactsToInviteProps {
    agencyToInviteTo: string | null,
    firstContact: ContactProps | null
    secondContact: ContactProps | null,
}

const toAgencyDataGrid = (agencies: AgenciesCompanyDataGridProps["agencies"], allUsers: AgenciesCompanyDataGridProps["allUsers"]) => {
    const agencyDataGridRows: DataGridDataRowProps["rows"] = []
    if (agencies) agencies.forEach(agency => {
        const members = allUsers.filter(user => user.agencyIds.includes(agency.id))
        const {name} = agency
        const membersLength = members.length
        agencyDataGridRows.push({
            data: [
                {
                    text: name!!,
                    value: name!!,
                },
                {
                    text: membersLength.toString(),
                    value: membersLength
                },
            ],
            rawData: {...agency} as AgencyProps,
            key: uuidv4(),

        })
    })
    return agencyDataGridRows
}

const AgenciesCompanyDataGrid = ({
                                     whichType,
                                     company,
                                     agencies,
                                     isCompanyAdmin,
                                     allUsers,
                                 }: AgenciesCompanyDataGridProps) => {
    const [previousCompanyAgencyName, setPreviousCompanyAgencyName] = useState("")
    const [contactsToInvite, setContactsToInvite] = useState<ContactsToInviteProps | null>(null)
    const [agencyNameAlreadyGivenError, setAgencyNameAlreadyGivenError] = useState(false)
    const [originalAgencyCompany, setOriginalAgencyCompany] = useState({} as OriginalAgencyCompanyProps)
    const [formData, setFormData] = useState<FormPropsAgency>({...initialAgencyFormData})
    const [validateForm, setValidateForm] = useState(VALIDATE_FORM_STARTING_NUMBER)
    const [editAddModalVisible, setEditAddModalVisible] = useState(false)
    const [deleteModalVisible, setDeleteModalVisible] = useState(false)
    const [agencyToUpdate, setAgencyToUpdate] = useState<AgencyProps | null>(null)
    const [agencyToDelete, setAgencyToDelete] = useState<AgencyProps | null>(null)
    const [companyToDelete, setCompanyToDelete] = useState<CompanyProps | null>(null)
    const [companyToUpdate, setCompanyToUpdate] = useState<CompanyProps | null>(null)
    const isEdit = useRef(false)
    const {t, i18n} = useTranslation()
    const {resolvedLanguage} = i18n
    const {logout} = useFusionAuth()
    const agenciesDataGridRows = toAgencyDataGrid(agencies, allUsers)
    const {
        agencyCompanyName,
        agencyCompanyEmail,
        street,
        houseNumber,
        zipCode,
        city,
        additionalAddressInformation,
        country,
        internationalPhonePrefix,
        phoneNumber,
        firstContactAdmin,
        secondContactAdmin,
        secondContactFirstName,
        secondContactLastName,
        firstContactFirstName,
        firstContactLastName,
        firstContactEmail,
        secondContactEmail,
    } = formData

    const isLoadingGetUsersAgency = useAppSelector(state => {
        return Object.values(state.api.queries).some(query => {
            if (query && query.endpointName === "getUsersAgencies") return query.status === QueryStatus.pending;
        });
    });

    const isLoadingGetUsersCompany = useAppSelector(state => {
        return Object.values(state.api.queries).some(query => {
            if (query && query.endpointName === "getUsersCompany") return query.status === QueryStatus.pending;
        });
    });

    const isErrorGetUsersAgency = useAppSelector(state => {
        return Object.values(state.api.queries).some(query => {
            if (query && query.endpointName === "getUsersAgencies") return query.status === QueryStatus.rejected;
        });
    });

    const isErrorGetUsersCompany = useAppSelector(state => {
        return Object.values(state.api.queries).some(query => {
            if (query && query.endpointName === "getUsersCompany") return query.status === QueryStatus.rejected;
        });
    });

    const [inviteUserRequest, {
        data: inviteUserResponse,
        isLoading: isLoadingInviteUser,
        isError: isErrorInviteUser
    }] = useInviteUserMutation()

    const [createCompany, {
        data: createCompanyResponse,
        isLoading: isLoadingCreateCompanyRequest,
        isError: isErrorCreateCompanyRequest,
    }] = useCreateCompanyMutation()

    const [createAgency, {
        data: createAgencyResponse,
        isLoading: isLoadingCreateAgencyRequest,
        isError: isErrorCreateAgencyRequest,
    }] = useCreateAgencyMutation()

    const [updateAgency, {
        data: updateAgencyResponse,
        isLoading: isLoadingUpdateAgencyRequest,
        isError: isErrorUpdateAgencyRequest
    }] = useUpdateAgencyMutation()

    const [updateCompany, {
        data: updateCompanyResponse,
        isLoading: isLoadingUpdateCompanyRequest,
        isError: isErrorUpdateCompanyRequest
    }] = useUpdateCompanyMutation()

    const [deleteAgency, {
        data: deleteAgencyResponse,
        isLoading: isLoadingDeleteAgencyRequest,
        isError: isErrorDeleteAgencyRequest
    }] = useDeleteAgencyMutation()

    const _deletingOwnAgency = () => agencies?.filter(agency => agency.isAdmin).some(agency => agency.id === agencyToDelete?.id)

    useEffect(() => {
        if (deleteAgencyResponse?.status === GENERAL_BACKEND_RESPONSE.success) {
            if (!isCompanyAdmin && _deletingOwnAgency()) logout()
            setAgencyToDelete(null)
        }
    }, [deleteAgencyResponse]);

    useEffect(() => {
        if (isErrorDeleteAgencyRequest) setAgencyToDelete(null)
    }, [isErrorDeleteAgencyRequest]);

    useEffect(() => {
        if (isErrorGetUsersAgency) toast(<ToastWithTextOnly
                message={t("SCREENS.COMMON.AUTOMATED_REFETCHING_ERROR",)}/>,
            {progress: undefined, type: "error"}
        );
    }, [isErrorGetUsersAgency, isErrorGetUsersCompany]);

    useEffect(() => {
        if (
            updateAgencyResponse?.status === GENERAL_BACKEND_RESPONSE.success ||
            updateCompanyResponse?.status === GENERAL_BACKEND_RESPONSE.success
        ) setEditAddModalVisible(false)
    }, [updateAgencyResponse, updateCompanyResponse])

    useEffect(() => {
        if (isErrorInviteUser) {
            toast(<ToastWithTextOnly
                    message={"SCREENS.SETTINGS.INVITE_CONTACT_PERSON_FAIL"}/>,
                {progress: undefined, type: "error"}
            );
        }
    }, [isErrorInviteUser]);

    useEffect(() => {
        if (contactsToInvite && agencies && agencies.length > 0) {
            const {agencyToInviteTo} = contactsToInvite
            const newlyCreatedAgency = agencies.find(agency => agency.name === agencyToInviteTo)
            const {firstContact, secondContact} = contactsToInvite
            if (newlyCreatedAgency) {
                const {id} = newlyCreatedAgency
                if (firstContact) _inviteUser(firstContact, id)
                if (secondContact) _inviteUser(secondContact, id)
                _resetCreateOrUpdateForm()
            }
        }
    }, [agencies]);

    useEffect(() => {
        if (
            createCompanyResponse?.status === GENERAL_BACKEND_RESPONSE.success ||
            createAgencyResponse?.status === GENERAL_BACKEND_RESPONSE.success
        ) {
            setEditAddModalVisible(false)
            if (!contactsToInvite) _resetCreateOrUpdateForm()
        }
    }, [createCompanyResponse, createAgencyResponse]);

    const _inviteUser = (userToInvite: ContactProps, agencyId: number) => {
        const {email, firstName, lastName, isAdmin} = userToInvite
        if (Boolean(allUsers.find(user => user.email === email))) {
            toast(<ToastWithTextOnly
                    message={t("SCREENS.SETTINGS.ADD_USER_EMAIL_ALREADY_IN_USE", {email})}/>,
                {progress: undefined, type: "error"}
            );
        } else {
            inviteUserRequest({
                users: [{
                    firstName,
                    lastName,
                    email,
                    isAdmin,
                    language: resolvedLanguage ?? "de",
                }],
                agencyId
            })
        }
    }

    const _createCompany = (firstContactPersonData: ContactPersonProps, secondContactPersonData: ContactPersonProps, addressData: AddressProps, phoneData: PhoneNumberProps, formData: AgenciesCompanyProps) => {
        const {street, houseNumber, zipCode, city, country, additionalAddressInformation} = addressData
        const {phoneNumber, internationalPhonePrefix} = phoneData
        const {
            agencyCompanyName,
            agencyCompanyEmail,
        } = formData
        return createCompany({
            name: agencyCompanyName,
            address: {
                street,
                houseNumber,
                zipCode,
                city,
                country: country!!,
                additionalInformation: additionalAddressInformation,
                phoneNumber,
                internationalPhonePrefix: internationalPhonePrefix!!,
            },
            generalEmail: agencyCompanyEmail,
            firstContactFirstName: firstContactPersonData.firstName,
            firstContactLastName: firstContactPersonData.lastName,
            firstContactEmail: firstContactPersonData.email,
            secondContactFirstName: secondContactPersonData.firstName,
            secondContactLastName: secondContactPersonData.lastName,
            secondContactEmail: secondContactPersonData.email
        })
    }

    const _renderAddIcon = () => <IconAndText
        iconColor={theme.colors.primary}
        text={`SCREENS.SETTINGS.${whichType === "agencies" ? "ADD_NEW_AGENCY" : "ADD_NEW_COMPANY"}`}
        onClick={() => setEditAddModalVisible(true)}
        icon={"plus"}/>

    const _renderAddButton = () => {
        if (whichType === "agencies") return _renderAddIcon()
        if (!company) return _renderAddIcon()
        return null
    }

    const _onEditCompanyAgency = (value: AgencyProps | CompanyProps) => {
        isEdit.current = true
        const {
            name,
            firstContactFirstName,
            firstContactLastName,
            firstContactEmail,
            secondContactFirstName,
            secondContactLastName,
            secondContactEmail,
            address,
            generalEmail,
        } = value
        const {
            street,
            houseNumber,
            zipCode,
            city,
            additionalInformation,
            country,
            internationalPhonePrefix,
            phoneNumber,
        } = address
        setOriginalAgencyCompany({
            agencyCompanyName,
            firstContactFirstName,
            firstContactLastName,
            secondContactFirstName: secondContactFirstName ?? "",
            secondContactLastName: secondContactLastName ?? "",
            firstContactEmail: firstContactEmail ?? "",
            secondContactEmail: secondContactEmail ?? "",
            firstContactAdmin: Boolean(allUsers.find(user => user.email === "firstContactEmail")),
            secondContactAdmin: Boolean(allUsers.find(user => user.email === 'secondContactEmail')),
            agencyCompanyEmail,
            street,
            houseNumber,
            zipCode,
            city,
            additionalAddressInformation: additionalInformation,
            country,
            internationalPhonePrefix,
            phoneNumber,
            inviteFirstContact: false,
            inviteSecondContact: false,
        })
        setPreviousCompanyAgencyName(name)
        setFormData({
            agencyCompanyName: name,
            street,
            houseNumber,
            zipCode,
            city,
            additionalAddressInformation: additionalInformation ?? "",
            agencyCompanyEmail: generalEmail,
            country,
            internationalPhonePrefix,
            phoneNumber,
            inviteFirstContact: false,
            inviteSecondContact: false,
            firstContactFirstName,
            firstContactLastName,
            secondContactFirstName: secondContactFirstName ?? "",
            secondContactLastName: secondContactLastName ?? "",
            firstContactEmail: firstContactEmail ?? "",
            secondContactEmail: secondContactEmail ?? "",
        })
        setEditAddModalVisible(true)
        if (whichType === "agencies") setAgencyToUpdate(value as AgencyProps)
        if (whichType === "company") setCompanyToUpdate(value as CompanyProps)
    }

    const _createAgencyRequestObject = (firstContactPersonData: ContactPersonProps, secondContactPersonData: ContactPersonProps, addressData: AddressProps, phoneData: PhoneNumberProps, formData: AgenciesCompanyProps): CreateAgencyRequest => {
        const {street, houseNumber, zipCode, city, country, additionalAddressInformation} = addressData
        const {phoneNumber, internationalPhonePrefix} = phoneData
        const {
            agencyCompanyName,
            agencyCompanyEmail,
        } = formData
        return {
            name: agencyCompanyName,
            address: {
                street,
                houseNumber,
                zipCode,
                city,
                country: country!!,
                additionalInformation: additionalAddressInformation,
                phoneNumber,
                internationalPhonePrefix: internationalPhonePrefix!!,
            },
            generalEmail: agencyCompanyEmail,
            firstContactFirstName: firstContactPersonData.firstName,
            firstContactLastName: firstContactPersonData.lastName,
            firstContactEmail: firstContactPersonData.email,
            secondContactFirstName: secondContactPersonData.firstName,
            secondContactLastName: secondContactPersonData.lastName,
            secondContactEmail: secondContactPersonData.email
        }
    }

    const _updateAgency = (firstContactPersonData: ContactPersonProps, secondContactPersonData: ContactPersonProps, addressData: AddressProps, phoneData: PhoneNumberProps, formData: AgenciesCompanyProps) => {
        if (agencyToUpdate) {
            const {id} = agencyToUpdate
            const updatedAgencyObject = _createAgencyRequestObject(firstContactPersonData, secondContactPersonData, addressData, phoneData, formData)
            if (!isEqual(originalAgencyCompany, updatedAgencyObject)) updateAgency({
                agency: updatedAgencyObject,
                agencyId: id,
            })
        }
    }

    const _updateCompany = (addressData: AddressProps, phoneData: PhoneNumberProps, formData: AgenciesCompanyProps) => {
        if (companyToUpdate) {
            const {street, houseNumber, zipCode, city, country, additionalAddressInformation} = addressData
            const {phoneNumber, internationalPhonePrefix} = phoneData
            const {agencyCompanyName, agencyCompanyEmail} = formData
            const updatedCompanyObject = {
                name: agencyCompanyName,
                address: {
                    street: street,
                    houseNumber: houseNumber,
                    zipCode: zipCode,
                    city: city,
                    country: country!!,
                    additionalInformation: additionalAddressInformation,
                    phoneNumber: phoneNumber,
                    internationalPhonePrefix: internationalPhonePrefix ?? undefined!!,
                },
                generalEmail: agencyCompanyEmail,
                firstContactFirstName: firstContactFirstName,
                firstContactLastName: firstContactLastName,
                firstContactEmail: firstContactEmail ? firstContactEmail : null,
                secondContactFirstName: secondContactFirstName ? secondContactFirstName : null,
                secondContactLastName: secondContactLastName ? secondContactLastName : null,
                secondContactEmail: secondContactEmail ? secondContactEmail : null
            }
            if (!isEqual(updatedCompanyObject, originalAgencyCompany)) updateCompany(updatedCompanyObject)
        }
    }

    const _resetCreateOrUpdateForm = () => {
        isEdit.current = false
        setFormData(initialAgencyFormData)
        setAgencyNameAlreadyGivenError(false)
        setCompanyToUpdate(null)
        setAgencyToUpdate(null)
        setContactsToInvite(null)
        setPreviousCompanyAgencyName("")
        setOriginalAgencyCompany({} as OriginalAgencyCompanyProps)
    }

    const _agencyNameAlreadyGiven = (newAgencyName: string) => agencies?.map(agency => agency.name).includes(newAgencyName) && newAgencyName !== previousCompanyAgencyName

    const _showAgencyNameAlreadyGivenError = (agencyName: string) => toast(<ToastWithTextOnly
            message={t('SCREENS.SETTINGS.AGENCY_NAME_ALREADY_GIVEN',
                {agencyName, interpolation: {escapeValue: false}},
            )}/>,
        {progress: undefined, type: "error"}
    );

    const _createOrUpdateCompanyOrAgency = (firstContactPersonData: ContactPersonProps, secondContactPersonData: ContactPersonProps, addressData: AddressProps, phoneData: PhoneNumberProps, formData: AgenciesCompanyProps) => {
        const {agencyCompanyName} = formData
        const newAgencyName = agencyCompanyName
        const duplicatedName = Boolean(_agencyNameAlreadyGiven(newAgencyName))
        setAgencyNameAlreadyGivenError(duplicatedName)
        if (duplicatedName) _showAgencyNameAlreadyGivenError(newAgencyName)
        if (agencyToUpdate && !duplicatedName) {
            _updateAgency(firstContactPersonData, secondContactPersonData, addressData, phoneData, formData)
            setValidateForm(VALIDATE_FORM_STARTING_NUMBER)
        } else if (companyToUpdate) {
            _updateCompany(addressData, phoneData, formData)
            setValidateForm(VALIDATE_FORM_STARTING_NUMBER)
        } else {
            if (whichType === "agencies" && !duplicatedName) {
                createAgency(_createAgencyRequestObject(firstContactPersonData, secondContactPersonData, addressData, phoneData, formData))
                setValidateForm(VALIDATE_FORM_STARTING_NUMBER)
            }
            if (whichType === "company") {
                _createCompany(firstContactPersonData, secondContactPersonData, addressData, phoneData, formData)
                setValidateForm(VALIDATE_FORM_STARTING_NUMBER)
            }
            let firstContact: null | ContactProps = null
            let secondContact: null | ContactProps = null
            if (firstContactPersonData.inviteContact) firstContact = {
                isAdmin: Boolean(firstContactPersonData.isAdmin),
                firstName: firstContactPersonData.firstName,
                lastName: firstContactPersonData.lastName,
                email: firstContactPersonData.email,
            }

            if (secondContactPersonData.inviteContact) secondContact = {
                isAdmin: Boolean(secondContactPersonData.isAdmin),
                firstName: secondContactPersonData.firstName,
                lastName: secondContactPersonData.lastName,
                email: secondContactPersonData.email,
            }
            setContactsToInvite({
                agencyToInviteTo: agencyCompanyName,
                firstContact,
                secondContact
            })
        }
    }

    const _onDeleteCompanyAgency = () => {
        if (whichType === "agencies" && agencyToDelete) {
            deleteAgency(agencyToDelete.id)
            setDeleteModalVisible(false)
        }
        // if (whichType === "company") {
        // setCompanyToDelete(null)
        // setDeleteModalVisible(false)
    }

    return <section>
        <SectionTitle
            isLoading={
                (whichType === "agencies" && isLoadingCreateAgencyRequest) ||
                (whichType === "company" && isLoadingCreateCompanyRequest) ||
                (whichType === "agencies" && isLoadingUpdateAgencyRequest) ||
                (whichType === "company" && isLoadingUpdateCompanyRequest) ||
                (whichType === "agencies" && isLoadingGetUsersAgency) ||
                (whichType === "company" && isLoadingGetUsersCompany) ||
                (whichType === "agencies" && isLoadingDeleteAgencyRequest)
            }
            title={whichType === "agencies" ? "SCREENS.SETTINGS.AGENCIES_TITLE" : "SCREENS.SETTINGS.COMPANY_TITLE"}/>
        <div className={"agencies-company-button-wrapper"}>
            {_renderAddButton()}
            {company && <div className={"agencies-company-company-wrapper"}>
                <Text>{company.name}</Text>
                <div className={"agencies-company-company-button-wrapper"}>
                    {isCompanyAdmin && <IconButton
                        tooltip={`SCREENS.SETTINGS.${whichType === "agencies" ? "EDIT_AGENCY_TOOLTIP" : "EDIT_COMPANY_TOOLTIP"}`}
                        size={"small"}
                        color={"primary"}
                        onClick={() => _onEditCompanyAgency(company)}
                        icon={"penToSquare"}/>}
                    {/*{isCompanyAdmin && <IconButton*/}
                    {/*    tooltip={`SCREENS.SETTINGS.${whichType === "agencies" ? "DELETE_AGENCY_TOOLTIP" : "DELETE_COMPANY_TOOLTIP"}`}*/}
                    {/*    size={"small"}*/}
                    {/*    color={"primary"}*/}
                    {/*    onClick={() => _onDeleteCompanyAgency(company)}*/}
                    {/*    icon={"trash"}/>}*/}
                </div>
            </div>}
            {agencies && agencies.length > 0 && <DataGrid
                look={"primaryLight"}
                cellWeights={[1, 1]}
                headers={[
                    {text: t("GENERAL.AGENCY_NAME")},
                    {text: t("SCREENS.SETTINGS.USERS_COUNT")},
                ]}
                rows={agenciesDataGridRows}
                rowSize={"medium"}
                onEditTooltip={`SCREENS.SETTINGS.${whichType === "agencies" ? "EDIT_AGENCY_TOOLTIP" : "EDIT_COMPANY_TOOLTIP"}`}
                onEditRow={(_, value) => _onEditCompanyAgency(value)}
                onDeleteTooltip={`SCREENS.SETTINGS.${whichType === "agencies" ? "DELETE_AGENCY_TOOLTIP" : "DELETE_COMPANY_TOOLTIP"}`}
                onDeleteRow={(_, value) => {
                    setDeleteModalVisible(true)
                    setAgencyToDelete(value)
                }}
            />
            }
        </div>
        <Modal
            headline={(agencyToUpdate || companyToUpdate) ?
                `SCREENS.SETTINGS.${whichType === "agencies" ? "EDIT_AGENCY" : "EDIT_COMPANY"}` :
                `SCREENS.SETTINGS.${whichType === "agencies" ? "ADD_NEW_AGENCY" : "ADD_NEW_COMPANYY"}`
            }
            show={editAddModalVisible}
            closeButton={"GENERAL.ABORT"}
            confirmButton={"GENERAL.OK"}
            onClickConfirm={() => setValidateForm(validateForm + 1)}
            onClickClose={() => {
                setEditAddModalVisible(false)
                setValidateForm(VALIDATE_FORM_STARTING_NUMBER)
                _resetCreateOrUpdateForm()
            }}
            showSpinner={isLoadingUpdateAgencyRequest || isLoadingCreateAgencyRequest}
            showPaper={true}>
            <AgenciesCompanyForm
                nameAlreadyGivenError={agencyNameAlreadyGivenError}
                showMakeAdminCheckboxes={true}
                allUsers={allUsers}
                isEdit={isEdit.current}
                whichType={whichType}
                validateForm={validateForm}
                onFormIsValid={_createOrUpdateCompanyOrAgency}
                defaultValues={{
                    country,
                    street,
                    houseNumber,
                    zipCode,
                    city,
                    additionalAddressInformation,
                    internationalPhonePrefix,
                    phoneNumber,
                    agencyCompanyEmail,
                    agencyCompanyName,
                    firstContactPerson: {
                        email: firstContactEmail ?? "",
                        firstName: firstContactFirstName,
                        lastName: firstContactLastName
                    },
                    secondContactPerson: {
                        firstName: secondContactFirstName ?? "",
                        lastName: secondContactLastName ?? "",
                        email: secondContactEmail ?? ""
                    }
                }}/>
        </Modal>
        <Modal
            headline={t(`SCREENS.SETTINGS.${whichType === "agencies" ? "DELETE_AGENCY" : "DELETE_COMPANY"}`, {
                name: whichType === "agencies" ? agencyToDelete?.name : companyToDelete?.name,
                interpolation: {escapeValue: false}
            })}
            show={deleteModalVisible}
            closeButton={"GENERAL.ABORT"}
            confirmButton={"GENERAL.DELETE"}
            confirmButtonColor={"warningMain"}
            onClickConfirm={_onDeleteCompanyAgency}
            onClickClose={() => {
                setAgencyToDelete(null)
                setCompanyToDelete(null)
                setDeleteModalVisible(false)
            }}
            showSpinner={isLoadingDeleteAgencyRequest}
            showPaper={true}>
            <Text>{t(
                `SCREENS.SETTINGS.${whichType === "agencies" ? 'DELETE_AGENCY_TEXT' : 'DELETE_COMPANY_TEXT'}`,
                (whichType === "agencies" ?
                    {
                        agency: agencyToDelete?.name,
                        interpolation: {escapeValue: false}
                    } :
                    {
                        company: companyToDelete?.name,
                        interpolation: {escapeValue: false}
                    }))
            }
            </Text>
            {!isCompanyAdmin && _deletingOwnAgency() && <Text>SCREENS.SETTINGS.DELETE_OWN_AGENCY_TEXT</Text>}
        </Modal>
    </section>
}

export default AgenciesCompanyDataGrid