import {allInternationalPhonePrefixesFormattedForDropdownAlpha2CountryCode} from "../../components/settings/utils";
import {FlightActivity} from "./activity-flight";
import {HotelActivity} from "./activity-hotel";

enum BACKEND_ERROR_TYPE {
    VALIDATION = "Validation",
    FLIGHT_CALCULATION = "Flight calculation",
    HOTEL_CALCULATION = "Hotel calculation",
    FUSIONAUTH = "FusionAuth",
    ROLEMANAGEMENT = "RoleManagement",
    REQUEST = "Request",
}

export enum GENERAL_BACKEND_RESPONSE {
    success = 'SUCCESS',
    failure = 'FAILED'
}

export interface ContactPersonsProps {
    firstContactFirstName: string,
    firstContactLastName: string,
    firstContactEmail?: string | null,
    secondContactFirstName?: string | null,
    secondContactLastName?: string | null,
    secondContactEmail?: string | null,
}

export interface GeneralBackendResponse {
    status: GENERAL_BACKEND_RESPONSE,
    errorCode?: number,
    type: BACKEND_ERROR_TYPE,
    message: string,
    problematicValues?: string[],
}

export enum CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL {
    success = 'SUCCESS',
    failed = 'FAILED'
}

export enum CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL {
    success = 'SUCCESS',
    success_with_errors = 'SUCCESS_WITH_ERRORS',
    failed = 'FAILED'
}

export enum CALCULATION_TYPE {
    flight = 'flight',
    hotel = 'hotel'
}

export interface CompanyAgencyAddress {
    street: string,
    houseNumber: string,
    zipCode: string,
    city: string,
    country: string,
    additionalInformation: string,
    phoneNumber: string,
    internationalPhonePrefix: keyof typeof allInternationalPhonePrefixesFormattedForDropdownAlpha2CountryCode
}

export enum NON_CALCULATION_ERROR_TYPES {
    USER_MANAGEMENT,
    FUSION_AUTH
}

export type AllActivities = FlightActivity | HotelActivity

export const relevantErrorCodes = {
    501: 'CODE_501',
    504: 'CODE_504',
    508: 'CODE_508',
    514: 'CODE_514',
    518: 'CODE_518',

    // flight
    601: 'CODE_601',
    602: 'CODE_602',
    603: 'CODE_603',
    604: 'CODE_604',
    605: 'CODE_605',
    606: 'CODE_606',
    607: 'CODE_607',
    608: 'CODE_608',
    609: 'CODE_609',
    610: 'CODE_610',
    611: 'CODE_611',
    612: 'CODE_612',
    613: 'CODE_613',
    614: 'CODE_614',
    615: 'CODE_615',
    616: 'CODE_616',
    617: 'CODE_617',
    618: 'CODE_618',
    619: 'CODE_619',
    620: 'CODE_620',
    621: 'CODE_621',
    622: 'CODE_622',
    623: 'CODE_623',
    624: 'CODE_624',
    625: 'CODE_625',

    // Hotel
    701: 'CODE_701',
    702: 'CODE_702',
    703: 'CODE_703',
    704: 'CODE_704',

    802: 'CODE_802',
    817: 'CODE_817',
    830: 'CODE_830',

    906: 'CODE_906',
    907: 'CODE_907',
    910: 'CODE_910',
    912: 'CODE_912',
    917: 'CODE_917',
}

export interface NonCalculationResponseError {
    status: number,
    data: {
        errorCode: keyof typeof relevantErrorCodes,
        message: string
        problematicValues: string[],
        type: keyof typeof NON_CALCULATION_ERROR_TYPES,
    }
}