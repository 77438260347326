import React, {CSSProperties, useEffect, useState} from 'react'
import {TextInput} from "atmosfair-ui";
import {
    validateAdditionalAddressInformation,
    validateCity,
    validateCountry,
    validateHouseNumber,
    validateStreet,
    validateZipCode
} from "../utils";
import CountriesDropdown, {CountriesDropdownProps} from "../../../molecules/dropdowns/CountriesDropdown";
import {VALIDATE_FORM_STARTING_NUMBER} from "../../../constants";
import {INITIAL_SELECTED_COUNTRY} from "../../../utils/default-values";
import usePrevious from "../../../hooks/usePrevious";
import {isEqual} from "lodash";

export interface AddressProps {
    country?: string | null,
    street: string,
    houseNumber: string,
    zipCode: string,
    city: string,
    additionalAddressInformation: string,
}

interface AddressFormProps {
    defaultValues?: AddressProps,
    countryRequired: CountriesDropdownProps["required"],
    countryStyle?: CSSProperties,
    streetRequired: boolean,
    streetStyle?: CSSProperties,
    houseNumberRequired: boolean,
    houseNumberStyle?: CSSProperties,
    zipCodeRequired: boolean,
    zipCodeStyle?: CSSProperties,
    cityRequired: boolean,
    cityStyle?: CSSProperties,
    additionalAddressInformationStyle?: CSSProperties,
    additionalAddressInformationRequired: boolean
    validateForm: number,
    onFormIsValid: (data: AddressProps) => void,
    disableForm?: boolean,
    showCountry: boolean,
}

interface FormErrorProps {
    streetError: boolean,
    houseNumberError: boolean,
    zipCodeError: boolean,
    cityError: boolean,
    additionalAddressInformationError: boolean,
    countryError: boolean,
}

const AddressForm = ({
                         countryRequired,
                         countryStyle = {flex: 2},
                         streetRequired,
                         streetStyle = {flex: 3},
                         houseNumberRequired,
                         houseNumberStyle = {flex: 1},
                         zipCodeRequired,
                         zipCodeStyle = {flex: 2},
                         cityRequired,
                         cityStyle = {flex: 2},
                         additionalAddressInformationRequired,
                         additionalAddressInformationStyle = {flex: 2},
                         validateForm,
                         onFormIsValid,
                         defaultValues,
                         disableForm,
                         showCountry,
                     }: AddressFormProps) => {
    const [formData, setFormData] = useState<AddressProps>({
        country: defaultValues?.country ?? INITIAL_SELECTED_COUNTRY?.name ?? null,
        street: defaultValues?.street ?? "",
        houseNumber: defaultValues?.houseNumber ?? "",
        zipCode: defaultValues?.zipCode ?? "",
        city: defaultValues?.city ?? "",
        additionalAddressInformation: defaultValues?.additionalAddressInformation ?? ""
    })
    const [formErrorData, setFormErrorData] = useState<FormErrorProps>({
        streetError: false,
        houseNumberError: false,
        zipCodeError: false,
        cityError: false,
        additionalAddressInformationError: false,
        countryError: false,
    })
    const {
        country,
        street,
        houseNumber,
        zipCode,
        city,
        additionalAddressInformation
    } = formData
    const {
        streetError,
        houseNumberError,
        zipCodeError,
        cityError,
        additionalAddressInformationError,
        countryError,
    } = formErrorData
    const previousDefaultValues = usePrevious(defaultValues)

    useEffect(() => {
        if (validateForm > VALIDATE_FORM_STARTING_NUMBER && _validateForm()) {
            let formData: AddressProps = {
                street: street.trim(),
                houseNumber: houseNumber.trim(),
                zipCode: zipCode.trim(),
                city: city.trim(),
                additionalAddressInformation: additionalAddressInformation.trim()
            }
            if (showCountry) formData['country'] = country
            onFormIsValid(formData)
        }
    }, [validateForm]);

    useEffect(() => {
        if (defaultValues && !isEqual(defaultValues, previousDefaultValues)) {
            const {country, street, houseNumber, zipCode, additionalAddressInformation, city} = defaultValues
            setFormData({country, street, houseNumber, zipCode, additionalAddressInformation, city})
        }
    }, [defaultValues]);

    const _updateFormData = <K extends keyof AddressProps>(key: K, payload: AddressProps[K]) => setFormData({
        ...formData,
        [key]: payload
    })

    const _validateForm = () => {
        let validStreet = true
        let validHouseNumber = true
        let validZipCode = true
        let validCity = true
        let validAdditionalAddressInformation = true
        let validCountry = true

        if (streetRequired) {
            validStreet = validateStreet(street)
        } else {
            if (street.length > 0) validStreet = validateStreet(street)
        }

        if (houseNumberRequired) {
            validHouseNumber = validateHouseNumber(houseNumber)
        } else {
            if (houseNumber.length > 0) validHouseNumber = validateHouseNumber(houseNumber)
        }

        if (zipCodeRequired) {
            validZipCode = validateZipCode(zipCode)
        } else {
            if (zipCode.length > 0) validZipCode = validateZipCode(zipCode)
        }

        if (cityRequired) {
            validCity = validateCity(city)
        } else {
            if (city.length > 0) validCity = validateCity(city)
        }

        if (additionalAddressInformationRequired) {
            validAdditionalAddressInformation = validateAdditionalAddressInformation(additionalAddressInformation)
        } else {
            if (additionalAddressInformation.length > 0) validAdditionalAddressInformation = validateAdditionalAddressInformation(additionalAddressInformation)
        }

        if (countryRequired) validCountry = validateCountry(country)

        setFormErrorData({
            streetError: !validStreet,
            houseNumberError: !validHouseNumber,
            zipCodeError: !validZipCode,
            cityError: !validCity,
            additionalAddressInformationError: !validAdditionalAddressInformation,
            countryError: !validCountry
        })

        return [
            validStreet,
            validHouseNumber,
            validZipCode,
            validCity,
            validAdditionalAddressInformation,
            validCity,
        ].every(Boolean)
    }

    return <div className={'form-row'}>
        <TextInput
            readOnly={disableForm}
            labelColor={"primary"}
            ariaErrorMessage={"SCREENS.COMMON.ARIA_LABELS.STREET_ERROR"}
            error={streetError}
            value={street}
            onBlur={value => _updateFormData("street", value)}
            onChange={value => _updateFormData("street", value)}
            containerStyle={streetStyle}
            required={streetRequired}
            placeholder={"GENERAL.STREET"}
            label={"GENERAL.STREET"}/>
        <TextInput
            readOnly={disableForm}
            labelColor={"primary"}
            ariaErrorMessage={"SCREENS.COMMON.ARIA_LABELS.HOUSE_NUMBER_ERROR"}
            error={houseNumberError}
            value={houseNumber}
            onBlur={value => _updateFormData("houseNumber", value)}
            onChange={value => _updateFormData("houseNumber", value)}
            containerStyle={houseNumberStyle}
            required={houseNumberRequired}
            placeholder={"GENERAL.HOUSE_NUMBER"}
            label={"GENERAL.HOUSE_NUMBER"}/>
        <TextInput
            readOnly={disableForm}
            labelColor={"primary"}
            ariaErrorMessage={"SCREENS.COMMON.ARIA_LABELS.ZIP_CODE_ERROR"}
            error={zipCodeError}
            value={zipCode}
            onBlur={value => _updateFormData("zipCode", value)}
            onChange={value => _updateFormData("zipCode", value)}
            containerStyle={zipCodeStyle}
            required={zipCodeRequired}
            placeholder={"GENERAL.ZIP_CODE"}
            label={"GENERAL.ZIP_CODE"}/>
        <TextInput
            readOnly={disableForm}
            labelColor={"primary"}
            ariaErrorMessage={"SCREENS.COMMON.ARIA_LABELS.CITY_ERROR"}
            error={cityError}
            value={city}
            onBlur={value => _updateFormData("city", value)}
            onChange={value => _updateFormData("city", value)}
            containerStyle={cityStyle}
            required={cityRequired}
            placeholder={"GENERAL.CITY"}
            label={"GENERAL.CITY"}/>
        <TextInput
            readOnly={disableForm}
            required={additionalAddressInformationRequired}
            labelColor={"primary"}
            ariaErrorMessage={"SCREENS.COMMON.ARIA_LABELS.ADDITIONAL_ADDRESS_INFORMATION_ERROR"}
            error={additionalAddressInformationError}
            onBlur={value => _updateFormData("additionalAddressInformation", value)}
            onChange={value => _updateFormData("additionalAddressInformation", value)}
            containerStyle={additionalAddressInformationStyle}
            value={additionalAddressInformation}
            placeholder={"GENERAL.ADDITIONAL_ADDRESS_INFORMATION"}
            label={"GENERAL.ADDITIONAL_ADDRESS_INFORMATION"}/>
        {showCountry && <CountriesDropdown
            readOnly={disableForm}
            country={country}
            required={countryRequired}
            error={countryError}
            style={countryStyle}
            setSelection={selection => _updateFormData("country", selection)}/>}
    </div>
}

export default AddressForm