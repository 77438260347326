import {CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL} from "../api/model/common";
import {isActivityFlightState} from "./type-guards";
import {AllActivityTypes} from "../types-enums/activity";

export const hasPartialCalculationError = (activity: AllActivityTypes) => {
    if (isActivityFlightState(activity)) {
        const {common} = activity
        const {totalEmissions} = common
        if (totalEmissions) {
            const {data} = totalEmissions
            if (data?.length === 1) return data[0].status === CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.failed
            return data.some(stop => stop.status === CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.failed)
        }
    }
    return false
}