import React, {useEffect, useState} from "react"
import {Text, TextButton, TextInput} from "atmosfair-ui"
import './styles.css'
import {validateEmail, validateFirstAndLastName} from "../../forms/utils";
import {CompanyProps} from "../../../api/model/company";
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import {UserProps} from "../../../api/model/user";
import {useFusionAuth} from "@fusionauth/react-sdk";
import RegistrationWrapper from "../registration-wrapper/RegistrationWrapper";
import PhoneNumberForm, {PhoneNumberProps} from "../../forms/phone-number-form/PhoneNumberForm";
import AddressForm, {AddressProps} from "../../forms/address-form/AddressForm";
import {VALIDATE_FORM_STARTING_NUMBER} from "../../../constants";
import {INITIAL_SELECTED_INTERNATIONAL_PHONE_PREFIX} from "../../../utils/default-values";

export interface UserPropsForRegistration {
    phoneNumber: UserProps["phoneNumber"],
    internationalPhonePrefix: UserProps["internationalPhonePrefix"],
    registrationComplete: UserProps["registrationComplete"]
}

export interface CompanyPropsForRegistration extends Omit<CompanyProps, "id" | "name" | "members" | "agencies" | "isKlimaLink" | "isAdmin" | "adminIds"> {
}

interface CompleteUserAdminRegistration {
    company: CompanyProps,
    user: UserProps,
    isAdmin: boolean,
    onRegister: (user: UserPropsForRegistration, company?: CompanyPropsForRegistration) => void
}

interface CompanyFormProps {
    companyEmail: CompanyProps["generalEmail"],
    companyFirstContactFirstName: CompanyProps["firstContactFirstName"],
    companyFirstContactLastName: CompanyProps["firstContactLastName"],
}

interface CompanyFormErrorProps {
    companyEmailError: boolean
    companyFirstContactFirstNameError: boolean
    companyFirstContactLastNameError: boolean
}

const CompleteUserAdminRegistration = ({
                                           company,
                                           user,
                                           isAdmin,
                                           onRegister,
                                       }: CompleteUserAdminRegistration) => {
    const {firstName, lastName, email} = user
    const {name} = company
    const {logout} = useFusionAuth()
    const [validateForms, setValidateForms] = useState(VALIDATE_FORM_STARTING_NUMBER)
    const [companyAddressData, setCompanyAddressData] = useState<AddressProps | null>(null)
    const [userPhoneData, setUserPhoneData] = useState<PhoneNumberProps | null>(null)
    const [companyPhoneData, setCompanyPhoneData] = useState<PhoneNumberProps | null>(null)
    const [companyFormData, setCompanyFormData] = useState<CompanyFormProps>({
        companyEmail: '',
        companyFirstContactFirstName: '',
        companyFirstContactLastName: '',
    })
    const [companyFormErrorData, setCompanyFormErrorData] = useState<CompanyFormErrorProps>({
        companyEmailError: false,
        companyFirstContactFirstNameError: false,
        companyFirstContactLastNameError: false,
    })
    const {
        companyEmailError,
        companyFirstContactFirstNameError,
        companyFirstContactLastNameError,
    } = companyFormErrorData
    const {
        companyEmail,
        companyFirstContactFirstName,
        companyFirstContactLastName
    } = companyFormData
    const {t} = useTranslation()

    useEffect(() => {
        if (isAdmin) {
            if (companyPhoneData && companyAddressData) _onCompleteRegistration()
        } else {
            if (userPhoneData) _onCompleteRegistration()
        }
    }, [companyAddressData, userPhoneData, companyPhoneData]);

    const _updateCompanyFormData = <K extends keyof CompanyFormProps>(key: K, payload: CompanyFormProps[K]) => setCompanyFormData({
        ...companyFormData,
        [key]: payload
    })

    const _onCompleteRegistration = () => {
        if (userPhoneData) {
            const {phoneNumber, internationalPhonePrefix} = userPhoneData
            const userObject = {
                phoneNumber,
                internationalPhonePrefix: internationalPhonePrefix === null || typeof internationalPhonePrefix === 'undefined' ? INITIAL_SELECTED_INTERNATIONAL_PHONE_PREFIX : internationalPhonePrefix,
                registrationComplete: false,
            }
            if (isAdmin && companyAddressData && companyPhoneData) {
                const {companyEmail, companyFirstContactFirstName, companyFirstContactLastName} = companyFormData
                const {street, houseNumber, zipCode, city, country, additionalAddressInformation} = companyAddressData
                const {phoneNumber, internationalPhonePrefix} = companyPhoneData
                const companyObject: CompanyPropsForRegistration = {
                    generalEmail: companyEmail,
                    firstContactFirstName: companyFirstContactFirstName,
                    firstContactLastName: companyFirstContactLastName,
                    address: {
                        street,
                        houseNumber,
                        zipCode,
                        city,
                        country: country!!,
                        additionalInformation: additionalAddressInformation,
                        phoneNumber,
                        internationalPhonePrefix: internationalPhonePrefix!!
                    }
                }
                onRegister(userObject, companyObject)
            } else {
                onRegister({...userObject, registrationComplete: true})
            }
        }
    }

    const _validateCompanyForm = () => {
        const validFirstContactFirstName = validateFirstAndLastName(companyFirstContactFirstName)
        const validFirstContactLastName = validateFirstAndLastName(companyFirstContactLastName)
        const validEmail = validateEmail(companyEmail)
        setCompanyFormErrorData({
            companyEmailError: !validEmail,
            companyFirstContactFirstNameError: !validFirstContactFirstName,
            companyFirstContactLastNameError: !validFirstContactLastName,
        })
        return [
            validFirstContactFirstName,
            validFirstContactLastName,
            validEmail,
        ].every(Boolean)
    }

    return <RegistrationWrapper title={"SCREENS.REGISTRATION.COMPANY_USER_TITLE"}>
        <>
            <div
                className={clsx(
                    isAdmin && "complete-admin-user-registration-spacer",
                    "complete-user-admin-registration-missing-information-wrapper")}>
                <Text
                    classes={"complete-user-admin-registration-company-title"}
                    variant={"headlineMedium"}>
                    {t("SCREENS.REGISTRATION.MISSING_USER_INFORMATION_TITLE", {
                        userName: `${firstName} ${lastName}`,
                        email: email
                    })}
                </Text>
                <div className={'complete-user-admin-registration-missing-user-information-wrapper'}>
                    <PhoneNumberForm
                        showInternationalPhonePrefix={true}
                        internationalPhonePrefixRequired={true}
                        phoneNumberRequired={true}
                        validateForm={validateForms}
                        onFormIsValid={setUserPhoneData}/>
                </div>
            </div>
            {isAdmin && <div className={"complete-user-admin-registration-missing-information-wrapper"}>
                <Text
                    variant={"headlineMedium"}
                    classes={'complete-user-admin-registration-company-title'}>
                    {t("SCREENS.REGISTRATION.COMPANY_ADMIN.MISSING_COMPANY_INFORMATION_TITLE", {company: name})}
                </Text>
                <div className={"complete-user-admin-registration-row-element"}>
                    <TextInput
                        ariaErrorMessage={"SCREENS.COMMON.ARIA_LABELS.E_MAIL_ERROR"}
                        error={companyEmailError}
                        onBlur={value => _updateCompanyFormData('companyEmail', value)}
                        onChange={value => _updateCompanyFormData('companyEmail', value)}
                        containerStyle={{flex: 1}}
                        required={true}
                        placeholder={"GENERAL.EMAIL"}
                        label={"GENERAL.COMPANY_EMAIL"}
                        labelColor={"primary"}
                        value={companyEmail}/>
                    <TextInput
                        ariaErrorMessage={"SCREENS.COMMON.ARIA_LABELS.FIRST_CONTACT_PERSON_FIRST_NAME_ERROR"}
                        error={companyFirstContactFirstNameError}
                        value={companyFirstContactFirstName}
                        onBlur={value => _updateCompanyFormData('companyFirstContactFirstName', value)}
                        onChange={value => _updateCompanyFormData('companyFirstContactFirstName', value)}
                        containerStyle={{flex: 1}}
                        required={true}
                        labelColor={"primary"}
                        placeholder={"GENERAL.FIRST_NAME"}
                        label={"GENERAL.FIRST_CONTACT_PERSON_FIRST_NAME"}/>
                    <TextInput
                        required={true}
                        labelColor={"primary"}
                        ariaErrorMessage={"SCREENS.COMMON.ARIA_LABELS.FIRST_CONTACT_PERSON_LAST_NAME_ERROR"}
                        error={companyFirstContactLastNameError}
                        value={companyFirstContactLastName}
                        onBlur={value => _updateCompanyFormData('companyFirstContactFirstName', value)}
                        onChange={value => _updateCompanyFormData('companyFirstContactLastName', value)}
                        containerStyle={{flex: 1}}
                        placeholder={"GENERAL.FIRST_NAME"}
                        label={"GENERAL.FIRST_CONTACT_PERSON_LAST_NAME"}/>
                </div>
                <div className={"complete-user-admin-registration-row-element"}>
                    <AddressForm
                        showCountry={true}
                        countryRequired={true}
                        streetRequired={true}
                        houseNumberRequired={true}
                        zipCodeRequired={true}
                        cityRequired={true}
                        additionalAddressInformationRequired={false}
                        validateForm={validateForms}
                        onFormIsValid={setCompanyAddressData}/>
                </div>
                <PhoneNumberForm
                    showInternationalPhonePrefix={true}
                    internationalPhonePrefixRequired={true}
                    phoneNumberRequired={true}
                    validateForm={validateForms}
                    onFormIsValid={setCompanyPhoneData}/>
            </div>}
            <div className={"complete-admin-user-registration-language-selector-button-wrapper"}>
                <TextButton variant={"abort"} onClick={() => logout()}>GENERAL.ABORT</TextButton>
                <TextButton
                    classes={"complete-user-admin-registration-proceed-button"}
                    onClick={() => {
                        _validateCompanyForm()
                        setValidateForms(validateForms + 1)
                    }}>
                    GENERAL.PROCEED
                </TextButton>
            </div>
        </>
    </RegistrationWrapper>
}

export default CompleteUserAdminRegistration