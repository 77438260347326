import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {
    CalculatedFlightEmissionsResponseSuccess,
    CalculatedHotelEmissionsResponseSuccess,
    CalculateFlightEmissionsRequest,
    CalculateHotelEmissionsRequest
} from "../../api/model/emissions";
import {
    ChangeUsersAgencyRequest,
    DeleteUser,
    InviteCompanyAdminRequest,
    InviteUserRequest,
    UserAdminRequest,
    UserProps,
    UserRequest
} from "../../api/model/user";
import {CompanyProps, CreateCompanyRequest, UpdateCompanyRequest} from "../../api/model/company";
import Cookies from "js-cookie";
import {AgencyProps, CreateAgencyRequest, UpdateAgencyRequest, UploadLogoRequest} from "../../api/model/agency";
import {sortBy} from "lodash"
import {BASE_ENDPOINT} from "../../api/endpoints";
import {COOKIE_NAME} from "../../constants";
import {GENERAL_BACKEND_RESPONSE, GeneralBackendResponse, relevantErrorCodes} from "../../api/model/common";
import {getAgencyLogo} from "../../utils/get-agency-logo";
import {setAgencies, setAgencyLogo, setAllUsersForAdmin, setCompany, setUserData} from "./user";
import {HotelResponse} from "../../api/model/activity-hotel";
import {Customer, SaveBundleCustomerRequest} from "../../api/model/customer";
import {setApiError, setApiSuccess} from "./settings";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {BundledActivities, CreateBundle, CreateBundleRequest} from "../../api/model/bundle";
import {isDefined, isGeneralBackendResponseWithErrorCode} from "../../utils/type-guards";
import {ACTIVITY_TYPE, TransformedEmissionsResponseTotal} from "../../types-enums/activity";
import {v4 as uuidv4} from "uuid";

enum SUCCESS_TYPES {
    UPDATE_USER_ADMIN,
    MAKE_USER_TO_AGENCY_ADMIN,
    UNMAKE_USER_FROM_AGENCY_ADMIN,
    CREATE_COMPANY,
    UPDATE_COMPANY,
    CREATE_AGENCY,
    UPDATE_AGENCY,
    INVITE_COMPANY_ADMIN,
    INVITE_USER,
    DELETE_USER,
    DELETE_AGENCY,
    SET_AGENCY_LOGO,
    DELETE_AGENCY_LOGO,
    CHANGE_USERS_AGENCY,
    CHANGE_PASSWORD_EMAIL,
    CREATE_BUNDLE
}

enum ERROR_TYPES {
    UPDATE_USER_SELF,
    UPDATE_USER_ADMIN,
    MAKE_USER_TO_AGENCY_ADMIN,
    UNMAKE_USER_FROM_AGENCY_ADMIN,
    CREATE_COMPANY,
    UPDATE_COMPANY,
    CREATE_AGENCY,
    UPDATE_AGENCY,
    INVITE_COMPANY_ADMIN,
    INVITE_USER,
    DELETE_USER,
    DELETE_AGENCY,
    SET_AGENCY_LOGO,
    DELETE_AGENCY_LOGO,
    CHANGE_USERS_AGENCY,
    CHANGE_PASSWORD_EMAIL,
    CREATE_BUNDLE
}

const generalSuccessHandling = (response: GeneralBackendResponse, dispatch: ThunkDispatch<any, any, any>, successType: SUCCESS_TYPES) => {
    if (response.status === GENERAL_BACKEND_RESPONSE.success) {
        let message = ''
        switch (successType) {
            case SUCCESS_TYPES.UPDATE_USER_ADMIN:
                message = "TOASTS.SUCCESS.UPDATE_USER_SUCCESS_ADMIN"
                break
            case SUCCESS_TYPES.MAKE_USER_TO_AGENCY_ADMIN:
                message = "TOASTS.SUCCESS.MAKE_USER_AGENCY_ADMIN"
                break;
            case SUCCESS_TYPES.UNMAKE_USER_FROM_AGENCY_ADMIN:
                message = "TOASTS.SUCCESS.UNMAKE_USER_AGENCY_ADMIN"
                break;
            case SUCCESS_TYPES.CREATE_COMPANY:
                message = "SCREENS.SETTINGS.ADD_COMPANY"
                break;
            case SUCCESS_TYPES.UPDATE_COMPANY:
                message = "TOASTS.SUCCESS.EDIT_COMPANY"
                break;
            case SUCCESS_TYPES.CREATE_AGENCY:
                message = "TOASTS.SUCCESS.ADD_AGENCY"
                break;
            case SUCCESS_TYPES.UPDATE_AGENCY:
                message = "TOASTS.SUCCESS.EDIT_AGENCY"
                break;
            case SUCCESS_TYPES.INVITE_COMPANY_ADMIN:
                message = "TOASTS.SUCCESS.INVITE_COMPANY_ADMIN"
                break;
            case SUCCESS_TYPES.INVITE_USER:
                message = "TOASTS.SUCCESS.INVITE_USER"
                break
            case SUCCESS_TYPES.DELETE_USER:
                message = "TOASTS.DELETE_USER_SUCCESS.DELETE_USER"
                break;
            case SUCCESS_TYPES.DELETE_AGENCY:
                message = "TOASTS.SUCCESS.DELETE_AGENCY"
                break
            case SUCCESS_TYPES.SET_AGENCY_LOGO:
                message = "TOASTS.SUCCESS.UPLOAD_AGENCY_LOGO"
                break;
            case SUCCESS_TYPES.DELETE_AGENCY_LOGO:
                message = "TOASTS.SUCCESS.DELETE_AGENCY_LOGO"
                break;
            case SUCCESS_TYPES.CHANGE_USERS_AGENCY:
                message = "TOASTS.SUCCESS.CHANGE_USERS_AGENCY"
                break;
            case SUCCESS_TYPES.CHANGE_PASSWORD_EMAIL:
                message = "TOASTS.SUCCESS.SEND_PASSWORD_RESET_EMAIL"
                break;
            case SUCCESS_TYPES.CREATE_BUNDLE:
                message = "TOASTS.SUCCESS.CREATE_BUNDLE"
                break;
            default:
                message = ""
        }
        dispatch(setApiSuccess({
            toastType: "success",
            message
        }))
    }

}

const generalErrorHandling = (error: unknown, dispatch: ThunkDispatch<any, any, any>, errorType: ERROR_TYPES) => {
    let message = ''
    switch (errorType) {
        case ERROR_TYPES.UPDATE_USER_SELF:
            message = "TOASTS.ERROR.UPDATE_USER_SELF"
            break
        case ERROR_TYPES.UPDATE_USER_ADMIN:
            message = "TOASTS.ERROR.UPDATE_USER_ADMIN"
            break
        case ERROR_TYPES.MAKE_USER_TO_AGENCY_ADMIN:
            message = "TOASTS.ERROR.MAKE_USER_ADMIN"
            break;
        case ERROR_TYPES.UNMAKE_USER_FROM_AGENCY_ADMIN:
            message = "TOASTS.ERROR.UNSET_USER_ADMIN"
            break;
        case ERROR_TYPES.CREATE_COMPANY:
            message = "TOASTS.ERROR.ADD_COMPANY"
            break;
        case ERROR_TYPES.UPDATE_COMPANY:
            message = "TOASTS.ERROR.EDIT_COMPANY"
            break;
        case ERROR_TYPES.CREATE_AGENCY:
            message = "TOASTS.ERROR.ADD_AGENCY"
            break;
        case ERROR_TYPES.UPDATE_AGENCY:
            message = "TOASTS.ERROR.EDIT_AGENCY"
            break;
        case ERROR_TYPES.INVITE_COMPANY_ADMIN:
            message = "TOASTS.ERROR.INVITE_COMPANY_ADMIN"
            break;
        case ERROR_TYPES.INVITE_USER:
            message = "TOASTS.ERROR.INVITE_USER"
            break
        case ERROR_TYPES.DELETE_USER:
            message = "TOASTS.ERROR.DELETE_USER"
            break;
        case ERROR_TYPES.DELETE_AGENCY:
            message = "TOASTS.ERROR.DELETE_AGENCY"
            break
        case ERROR_TYPES.SET_AGENCY_LOGO:
            message = "TOASTS.ERROR.UPLOAD_AGENCY_LOGO"
            break;
        case ERROR_TYPES.DELETE_AGENCY_LOGO:
            message = "TOASTS.ERROR.DELETE_AGENCY_LOGO"
            break;
        case ERROR_TYPES.CHANGE_USERS_AGENCY:
            message = "TOASTS.ERROR.CHANGE_USERS_AGENCY"
            break;
        case ERROR_TYPES.CHANGE_PASSWORD_EMAIL:
            message = "TOASTS.ERROR.SEND_PASSWORD_RESET_EMAIL"
            break;
        case ERROR_TYPES.CREATE_BUNDLE:
            message = "TOASTS.ERROR.CREATE_BUNDLE"
            break;
        default:
            message = "ERRORS.GENERAL_NETWORK_ERROR_BODY"
    }

    if (typeof error === "object" && error !== null && "error" in error) {
        const {error: errorResponse} = error
        if (typeof errorResponse === "object" && errorResponse !== null) {
            if ("data" in errorResponse) {
                const {data} = errorResponse
                if (isGeneralBackendResponseWithErrorCode(data)) {
                    const {errorCode} = data
                    if (errorCode && errorCode in relevantErrorCodes) message = `ERRORS.BACKEND_ERRORS.CODE_${errorCode}`
                }
            }
        }
    }

    dispatch(setApiError({
        toastType: "error",
        message
    }))
}

export const apiSlice = createApi({
    reducerPath: 'api',
    tagTypes: ['User', 'Agencies', 'Company'],
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_ENDPOINT,
        prepareHeaders: headers => {
            const token = Cookies.get(COOKIE_NAME)
            if (token) headers.set('authorization', `Bearer ${token}`);
            return headers;
        },
    }),
    endpoints: builder => ({
        calculateFlightEmissions: builder.mutation<TransformedEmissionsResponseTotal, CalculateFlightEmissionsRequest>({
            query: (payload) => ({
                method: 'POST',
                body: payload,
                url: "/api/v1/calculateEmissionFrontend",
                headers: {
                    'Content-Type': 'application/json',
                }
            }),
            transformResponse: (response: CalculatedFlightEmissionsResponseSuccess): TransformedEmissionsResponseTotal => {
                const {flightActivities, errorCodes, emissionKgCO2eTotal, status} = response
                return {
                    data: flightActivities,
                    errorCodes,
                    emissionKgCO2eTotal,
                    status
                }
            },
            // transformErrorResponse: (response: CalculatedFlightEmissionsResponseError): TransformedEmissionsResponseTotal => {
            //     const {data} = response
            //     const {flightActivities, status, emissionKgCO2eTotal, errorCodes} = data
            //     return {
            //         data: flightActivities,
            //         status,
            //         emissionKgCO2eTotal,
            //         errorCodes,
            //     }
            // },
        }),
        calculateHotelEmissions: builder.mutation<TransformedEmissionsResponseTotal, CalculateHotelEmissionsRequest>({
            query: (payload) => ({
                method: 'POST',
                body: payload,
                url: "/api/v1/calculateEmissionFrontend",
                headers: {
                    'Content-Type': 'application/json',
                }
            }),
            transformResponse: (response: CalculatedHotelEmissionsResponseSuccess): TransformedEmissionsResponseTotal => {
                const {hotelActivities, errorCodes, emissionKgCO2eTotal, status} = response
                return {
                    data: hotelActivities,
                    errorCodes,
                    emissionKgCO2eTotal,
                    status
                }
            },
        }),
        updateUserInfoSelf: builder.mutation<GeneralBackendResponse, UserRequest>({
            query: payload => ({
                method: 'PUT',
                body: payload,
                url: "/api/v1/userInfo",
                headers: {
                    'Content-Type': 'application/json',
                }
            }),
            invalidatesTags: ['User', 'Agencies'],
            async onQueryStarted(id, {queryFulfilled, dispatch}) {
                try {
                    await queryFulfilled
                } catch (error) {
                    generalErrorHandling(error, dispatch, ERROR_TYPES.UPDATE_USER_SELF)
                }
            },
        }),
        updateUserInfoAdmin: builder.mutation<GeneralBackendResponse, { userObject: UserRequest, userEmail: string }>({
            query: payload => ({
                method: 'PUT',
                body: payload.userObject,
                url: `/api/v1/userInfo/${payload.userEmail}`,
                headers: {
                    'Content-Type': 'application/json',
                }
            }),
            invalidatesTags: ['Agencies'],
            async onQueryStarted(id, {queryFulfilled, dispatch}) {
                try {
                    const response = await queryFulfilled
                    generalSuccessHandling(response.data, dispatch, SUCCESS_TYPES.UPDATE_USER_ADMIN)
                } catch (error) {
                    generalErrorHandling(error, dispatch, ERROR_TYPES.UPDATE_USER_ADMIN)
                }
            },
        }),
        makeUserToAgencyAdmin: builder.mutation<GeneralBackendResponse, UserAdminRequest>({
            query: payload => ({
                method: 'POST',
                body: payload,
                url: `/api/v1/agency/${payload.agencyId}/admin/${payload.userEmail}`,
                headers: {
                    'Content-Type': 'application/json',
                }
            }),
            invalidatesTags: ['Agencies'],
            async onQueryStarted(id, {queryFulfilled, dispatch}) {
                try {
                    const response = await queryFulfilled
                    generalSuccessHandling(response.data, dispatch, SUCCESS_TYPES.MAKE_USER_TO_AGENCY_ADMIN)
                } catch (error) {
                    generalErrorHandling(error, dispatch, ERROR_TYPES.MAKE_USER_TO_AGENCY_ADMIN)
                }
            },
        }),
        unmakeUserFromAgencyAdmin: builder.mutation<GeneralBackendResponse, UserAdminRequest>({
            query: payload => ({
                method: 'DELETE',
                body: payload,
                url: `/api/v1/agency/${payload.agencyId}/admin/${payload.userEmail}`,
                headers: {
                    'Content-Type': 'application/json',
                }
            }),
            invalidatesTags: ['Agencies'],
            async onQueryStarted(id, {queryFulfilled, dispatch}) {
                try {
                    const response = await queryFulfilled
                    generalSuccessHandling(response.data, dispatch, SUCCESS_TYPES.UNMAKE_USER_FROM_AGENCY_ADMIN)
                } catch (error) {
                    generalErrorHandling(error, dispatch, ERROR_TYPES.UNMAKE_USER_FROM_AGENCY_ADMIN)
                }
            },
        }),
        createCompany: builder.mutation<GeneralBackendResponse, CreateCompanyRequest>({
            query: payload => ({
                method: 'POST',
                body: payload,
                url: "/api/v1/company",
                headers: {
                    'Content-Type': 'application/json',
                }
            }),
            invalidatesTags: ['Company', 'User'],
            async onQueryStarted(id, {queryFulfilled, dispatch}) {
                try {
                    const response = await queryFulfilled
                    generalSuccessHandling(response.data, dispatch, SUCCESS_TYPES.CREATE_COMPANY)
                } catch (error) {
                    generalErrorHandling(error, dispatch, ERROR_TYPES.CREATE_COMPANY)
                }
            },
        }),
        updateCompany: builder.mutation<GeneralBackendResponse, UpdateCompanyRequest>({
            query: payload => ({
                method: 'PUT',
                body: payload,
                url: `/api/v1/company`,
                headers: {
                    'Content-Type': 'application/json',
                }
            }),
            invalidatesTags: ['Company'],
            async onQueryStarted(id, {queryFulfilled, dispatch}) {
                try {
                    const response = await queryFulfilled
                    generalSuccessHandling(response.data, dispatch, SUCCESS_TYPES.UPDATE_COMPANY)
                } catch (error) {
                    generalErrorHandling(error, dispatch, ERROR_TYPES.UPDATE_COMPANY)
                }
            },
        }),
        createAgency: builder.mutation<GeneralBackendResponse, CreateAgencyRequest>({
            query: payload => ({
                method: 'POST',
                body: payload,
                url: "/api/v1/agency",
                headers: {
                    'Content-Type': 'application/json',
                }
            }),
            invalidatesTags: ['Agencies', 'User'],
            async onQueryStarted(id, {queryFulfilled, dispatch}) {
                try {
                    const response = await queryFulfilled
                    generalSuccessHandling(response.data, dispatch, SUCCESS_TYPES.CREATE_AGENCY)
                } catch (error) {
                    generalErrorHandling(error, dispatch, ERROR_TYPES.CREATE_AGENCY)
                }
            },
        }),
        updateAgency: builder.mutation<GeneralBackendResponse, UpdateAgencyRequest>({
            query: payload => ({
                method: "PUT",
                body: payload.agency,
                url: `/api/v1/agency/${payload.agencyId}`,
                headers: {
                    'Content-Type': 'application/json',
                }
            }),
            invalidatesTags: ['Agencies'],
            async onQueryStarted(id, {queryFulfilled, dispatch}) {
                try {
                    const response = await queryFulfilled
                    generalSuccessHandling(response.data, dispatch, SUCCESS_TYPES.UPDATE_AGENCY)
                } catch (error) {
                    generalErrorHandling(error, dispatch, ERROR_TYPES.UPDATE_AGENCY)
                }
            },
        }),
        inviteCompanyAdmin: builder.mutation<GeneralBackendResponse, InviteCompanyAdminRequest>({
            query: payload => ({
                method: "POST",
                url: '/api/v1/company/createWithUser',
                body: payload,
                headers: {
                    'Content-Type': 'application/json',
                }
            }),
            async onQueryStarted(id, {queryFulfilled, dispatch}) {
                try {
                    const response = await queryFulfilled
                    generalSuccessHandling(response.data, dispatch, SUCCESS_TYPES.INVITE_COMPANY_ADMIN)
                } catch (error) {
                    generalErrorHandling(error, dispatch, ERROR_TYPES.INVITE_COMPANY_ADMIN)
                }
            },
        }),
        inviteUser: builder.mutation<GeneralBackendResponse, InviteUserRequest>({
            query: payload => ({
                method: "POST",
                url: `/api/v1/agency/${payload.agencyId}/member/add`,
                body: payload.users,
                headers: {
                    'Content-Type': 'application/json',
                }
            }),
            invalidatesTags: ['Agencies'],
            async onQueryStarted(id, {queryFulfilled, dispatch}) {
                try {
                    const response = await queryFulfilled
                    generalSuccessHandling(response.data, dispatch, SUCCESS_TYPES.INVITE_USER)
                } catch (error) {
                    generalErrorHandling(error, dispatch, ERROR_TYPES.INVITE_USER)
                }
            },
        }),
        deleteUser: builder.mutation<GeneralBackendResponse, DeleteUser>({
            query: payload => ({
                method: "DELETE",
                url: `/api/v1/agency/${payload.agencyId}/member/${payload.userEmail}`,
                headers: {
                    'Content-Type': 'application/json',
                }
            }),
            invalidatesTags: ['Agencies'],
            async onQueryStarted(id, {queryFulfilled, dispatch}) {
                try {
                    const response = await queryFulfilled
                    generalSuccessHandling(response.data, dispatch, SUCCESS_TYPES.DELETE_USER)
                } catch (error) {
                    generalErrorHandling(error, dispatch, ERROR_TYPES.DELETE_USER)
                }
            },
        }),
        deleteAgency: builder.mutation<GeneralBackendResponse, number>({
            query: payload => ({
                method: "DELETE",
                url: `/api/v1/agency/${payload}`,
                headers: {
                    'Content-Type': 'application/json',
                }
            }),
            invalidatesTags: ['Agencies'],
            async onQueryStarted(id, {queryFulfilled, dispatch}) {
                try {
                    const response = await queryFulfilled
                    generalSuccessHandling(response.data, dispatch, SUCCESS_TYPES.DELETE_AGENCY)
                } catch (error) {
                    generalErrorHandling(error, dispatch, ERROR_TYPES.DELETE_AGENCY)
                }
            },
        }),
        setAgencyLogo: builder.mutation<GeneralBackendResponse, UploadLogoRequest>({
            query: payload => ({
                method: "POST",
                body: payload.logo,
                url: `/api/v1/agency/${payload.agencyId}/logo`,
            }),
            invalidatesTags: ['Agencies'],
            async onQueryStarted(id, {queryFulfilled, dispatch}) {
                try {
                    const response = await queryFulfilled
                    generalSuccessHandling(response.data, dispatch, SUCCESS_TYPES.SET_AGENCY_LOGO)
                } catch (error) {
                    generalErrorHandling(error, dispatch, ERROR_TYPES.SET_AGENCY_LOGO)
                }
            },
        }),
        deleteAgencyLogo: builder.mutation<GeneralBackendResponse, number>({
            query: payload => ({
                method: "DELETE",
                url: `/api/v1/agency/${payload}/logo`,
                headers: {
                    'Content-Type': 'application/json',
                }
            }),
            invalidatesTags: ['Agencies'],
            async onQueryStarted(id, {queryFulfilled, dispatch}) {
                try {
                    const response = await queryFulfilled
                    generalSuccessHandling(response.data, dispatch, SUCCESS_TYPES.DELETE_AGENCY_LOGO)
                } catch (error) {
                    generalErrorHandling(error, dispatch, ERROR_TYPES.DELETE_AGENCY_LOGO)
                }
            },
        }),
        changeUsersAgency: builder.mutation<GeneralBackendResponse, ChangeUsersAgencyRequest>({
            query: payload => ({
                method: "PUT",
                url: `/api/v1/agency/${payload.oldAgencyId}/${payload.newAgencyId}/member/${payload.userEmail}`,
            }),
            invalidatesTags: ['Agencies'],
            async onQueryStarted(id, {queryFulfilled, dispatch}) {
                try {
                    const response = await queryFulfilled
                    generalSuccessHandling(response.data, dispatch, SUCCESS_TYPES.CHANGE_USERS_AGENCY)
                } catch (error) {
                    generalErrorHandling(error, dispatch, ERROR_TYPES.CHANGE_USERS_AGENCY)
                }
            },
        }),
        sendChangePasswordEmail: builder.mutation<GeneralBackendResponse, void>({
            query: () => ({
                method: "POST",
                url: '/api/v1/user/me/password/reset',
            }),
            async onQueryStarted(id, {queryFulfilled, dispatch}) {
                try {
                    const response = await queryFulfilled
                    generalSuccessHandling(response.data, dispatch, SUCCESS_TYPES.CHANGE_PASSWORD_EMAIL)
                } catch (error) {
                    generalErrorHandling(error, dispatch, ERROR_TYPES.CHANGE_PASSWORD_EMAIL)
                }
            },
        }),
        getUsersAgencies: builder.query<AgencyProps[], void>({
            query: () => ({
                url: "/api/v1/agencies",
                headers: {
                    'Content-Type': 'application/json',
                }
            }),
            providesTags: ['Agencies'],
            transformResponse: (response: AgencyProps[]): AgencyProps[] => sortBy(response, 'name'),
            async onQueryStarted(id, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled
                    if (data) {
                        dispatch(setAgencies(data))
                        const promises = data.map(async agency => {
                            const {id} = agency;
                            const agencyLogo = await getAgencyLogo(id);
                            if (agencyLogo?.status === GENERAL_BACKEND_RESPONSE.success) {
                                if (agencyLogo && agencyLogo.arrayBuffer && agencyLogo.arrayBuffer.byteLength > 0) {
                                    const {imageBlob, arrayBuffer, fileType} = agencyLogo
                                    dispatch(setAgencyLogo({
                                        agencyId: id,
                                        agencyLogo: {
                                            imageBlob: imageBlob!!,
                                            arrayBuffer: arrayBuffer!!,
                                            fileType: fileType!!
                                        }
                                    }))
                                }
                            }
                        });
                        await Promise.all(promises);
                    }
                } catch (error) {
                    console.error(`Something went wrong getting users agencies: ${JSON.stringify(error)}`)
                }
            },
        }),
        getUsersCompany: builder.query<CompanyProps[], void>({
            query: () => ({
                url: "/api/v1/company",
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
            providesTags: ['Company'],
            async onQueryStarted(id, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled
                    if (data) dispatch(setCompany(data))
                } catch (error) {
                    console.error(`Something went wrong getting users company: ${JSON.stringify(error)}`)
                }
            },
        }),
        getUserInfo: builder.query<UserProps, void>({
            query: () => ({
                url: "/api/v1/userInfo",
                headers: {
                    'Content-Type': 'application/json',
                }
            }),
            providesTags: ['User'],
            async onQueryStarted(id, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled
                    if (data) dispatch(setUserData(data))
                } catch (error) {
                    console.error(`Something went wrong getting user info: ${JSON.stringify(error)}`)
                }
            },
        }),
        getAllUsersForAdminUser: builder.query<UserProps[], void>({
            query: () => ({
                url: "/api/v1/userInfo/getUsers",
                headers: {
                    'Content-Type': 'application/json',
                }
            }),
            providesTags: ['Agencies'],
            transformResponse: (response: UserProps[]): UserProps[] => sortBy(response, 'firstName'),
            async onQueryStarted(id, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled
                    if (data) dispatch(setAllUsersForAdmin(data))
                } catch (error) {
                    console.error(`Something went wrong getting all users for admin: ${JSON.stringify(error)}`)
                }
            },
        }),
        getHotelByGiataId: builder.query<HotelResponse | null, string>({
            query: payload => ({
                method: "GET",
                url: `/api/v1/hotel/queryHotelByGiataId?query=${payload}`,
                headers: {
                    'Content-Type': 'application/json',
                }
            }),
        }),
        getCustomerOnSavingBundle: builder.query<Customer[], SaveBundleCustomerRequest>({
            query: payload => ({
                method: "GET",
                url: `/api/v1/customer`,
                body: payload,
                headers: {
                    'Content-Type': 'application/json',
                }
            }),
        }),
        createBundle: builder.mutation<GeneralBackendResponse, CreateBundle>({
            query: payload => {
                const {data} = payload
                const travelComponents: BundledActivities[] = data.flatMap(travelComponent => {
                    const groupId = uuidv4();
                    return travelComponent.common.request.map((request, index) => {
                        switch (travelComponent.common.activityType) {
                            case ACTIVITY_TYPE.flight:
                                return {
                                    ...request,
                                    co2KgE: travelComponent.common.totalEmissions?.data[index].emissionKgCO2e ?? undefined,
                                    activityType: ACTIVITY_TYPE.flight,
                                    groupId,
                                }
                            case ACTIVITY_TYPE.hotel:
                                return {
                                    ...request,
                                    co2KgE: travelComponent.common.totalEmissions?.data[index].emissionKgCO2e ?? undefined,
                                    activityType: ACTIVITY_TYPE.hotel,
                                    groupId,
                                }
                            default:
                                return undefined;
                        }
                    }).filter(isDefined)
                });

                const body: CreateBundleRequest = {
                    ...payload,
                    data: travelComponents
                }

                return {
                    method: 'POST',
                    body,
                    url: "/api/v1/bundle",
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
            },
            async onQueryStarted(id, {queryFulfilled, dispatch}) {
                try {
                    const response = await queryFulfilled
                    generalSuccessHandling(response.data, dispatch, SUCCESS_TYPES.CREATE_BUNDLE)
                } catch (error) {
                    generalErrorHandling(error, dispatch, ERROR_TYPES.CREATE_BUNDLE)
                }
            },
        }),
    })
})

export const {
    useGetUserInfoQuery,
    useGetUsersAgenciesQuery,
    useGetUsersCompanyQuery,
    useGetAllUsersForAdminUserQuery,
    useUpdateAgencyMutation,
    useUpdateCompanyMutation,
    useDeleteUserMutation,
    useDeleteAgencyMutation,
    useCalculateFlightEmissionsMutation,
    useCalculateHotelEmissionsMutation,
    useMakeUserToAgencyAdminMutation,
    useUnmakeUserFromAgencyAdminMutation,
    useUpdateUserInfoAdminMutation,
    useUpdateUserInfoSelfMutation,
    useCreateCompanyMutation,
    useCreateAgencyMutation,
    useSetAgencyLogoMutation,
    useDeleteAgencyLogoMutation,
    useInviteCompanyAdminMutation,
    useInviteUserMutation,
    useChangeUsersAgencyMutation,
    useSendChangePasswordEmailMutation,
    useGetHotelByGiataIdQuery,
    useCreateBundleMutation
} = apiSlice