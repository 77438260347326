import React, {useEffect, useState} from "react"
import {CheckboxInput, DropdownInput, Icon} from "atmosfair-ui"
import {validateAgency} from "../utils";
import "../styles.css"
import {Tooltip} from "@mui/material";
import {theme} from "../../../styles/theme";
import {useTranslation} from "react-i18next";
import {AgencyProps} from "../../../api/model/agency";
import "./styles.css"
import {convertAgenciesFromDropdownFormat, convertAgenciesToDropdownFormat,} from "../../../utils/dropdown-converter";
import PhoneNumberForm, {PhoneNumberProps} from "../phone-number-form/PhoneNumberForm";
import {VALIDATE_FORM_STARTING_NUMBER} from "../../../constants";
import NameEmailForm, {NameEmailProps} from "../name-email-form/NameEmailForm";
import {INITIAL_SELECTED_INTERNATIONAL_PHONE_PREFIX} from "../../../utils/default-values";

export interface UserProps {
    agency?: AgencyProps | null,
    isAdmin?: boolean,
}

export interface UserFormProps extends UserProps, Omit<NameEmailProps, "email"> {
    email?: string,
    internationalPhonePrefix: PhoneNumberProps["internationalPhonePrefix"]
    phoneNumber: PhoneNumberProps["phoneNumber"]
}

export interface UsersFormProps {
    showEmailField: boolean,
    agencies: AgencyProps[],
    defaultValues?: UserFormProps,
    validateForm: number,
    onFormIsValid: (data: UserFormProps) => void,
    showIsAdminCheckbox: boolean,
    showAgencySelection: boolean
    showPhoneNumberFields: boolean
}

interface FormErrorProps {
    agencyError: boolean,
}

const UsersForm = ({
                       showEmailField,
                       defaultValues,
                       agencies,
                       validateForm,
                       onFormIsValid,
                       showIsAdminCheckbox,
                       showAgencySelection,
                       showPhoneNumberFields,
                   }: UsersFormProps) => {
    const [formData, setFormData] = useState<UserProps>({
        isAdmin: defaultValues?.isAdmin ?? false,
        agency: defaultValues?.agency ?? null,
    })
    const [nameEmailFormData, setNameEmailFormData] = useState<NameEmailProps | null>(null)
    const [phoneNumberFormData, setPhoneNumberFormData] = useState<PhoneNumberProps | null>(null)
    const [formErrorData, setFormErrorData] = useState<FormErrorProps>({agencyError: false,})
    let firstName: NameEmailProps["firstName"] = ""
    let lastName: NameEmailProps["lastName"] = ""
    let email: NameEmailProps["email"] = ""
    let phoneNumber: PhoneNumberProps["phoneNumber"] = ""
    let internationalPhonePrefix: PhoneNumberProps["internationalPhonePrefix"] = INITIAL_SELECTED_INTERNATIONAL_PHONE_PREFIX
    if (defaultValues) {
        firstName = defaultValues.firstName
        lastName = defaultValues.lastName
        internationalPhonePrefix = defaultValues.internationalPhonePrefix ?? INITIAL_SELECTED_INTERNATIONAL_PHONE_PREFIX
        phoneNumber = defaultValues.phoneNumber ?? ""
        email = defaultValues.email ?? ""
    }
    const {isAdmin, agency} = formData
    const {agencyError} = formErrorData
    const {t} = useTranslation()

    useEffect(() => {
        if (isAdmin && !agency) throw new Error("If isAdmin is true you must provide an agency.")
    }, []);

    useEffect(() => {
        let validPhoneNumber = true
        if (showPhoneNumberFields) validPhoneNumber = Boolean(phoneNumberFormData)
        if (validateForm > VALIDATE_FORM_STARTING_NUMBER) {
            const validForm = _validateForm()
            if (validPhoneNumber && nameEmailFormData && validForm) {
                const {firstName, lastName, email} = nameEmailFormData
                let phoneNumber = ""
                let internationalPhonePrefix: string | number = INITIAL_SELECTED_INTERNATIONAL_PHONE_PREFIX
                if (phoneNumberFormData !== null) {
                    internationalPhonePrefix = phoneNumberFormData.internationalPhonePrefix ?? INITIAL_SELECTED_INTERNATIONAL_PHONE_PREFIX
                    phoneNumber = phoneNumberFormData.phoneNumber
                }
                console.log(agency)
                onFormIsValid({
                    firstName,
                    lastName,
                    email,
                    agency,
                    isAdmin,
                    internationalPhonePrefix,
                    phoneNumber
                })
            }
        }
    }, [validateForm, nameEmailFormData, phoneNumberFormData]);

    const _updateFormData = <K extends keyof UserFormProps>(key: K, payload: UserFormProps[K]) => setFormData({
        ...formData,
        [key]: payload
    })

    const _validateForm = () => {
        let validAgency = true
        if (showAgencySelection) validAgency = validateAgency(agency)
        setFormErrorData({agencyError: !validAgency})
        return validAgency
    }

    return <div className={"form-row"}>
        <div className={"form-row"}>
            <NameEmailForm
                defaultValues={{firstName, lastName, email}}
                firstNameRequired={true}
                firstNameStyle={{flex: 1}}
                lastNameRequired={true}
                lastNameStyle={{flex: 1}}
                emailRequired={showEmailField}
                showEmailField={showEmailField}
                emailStyle={{flex: 1}}
                validateForm={validateForm}
                onFormIsValid={setNameEmailFormData}/>
            {showIsAdminCheckbox && <div className={"users-form-is-admin-wrapper"}>
                <CheckboxInput
                    labelColor={"primary"}
                    checked={Boolean(isAdmin)}
                    onChange={() => _updateFormData("isAdmin", !isAdmin)}
                    label={"SCREENS.SETTINGS.IS_ADMIN_LABEL"}/>
                <Tooltip
                    title={t("SCREENS.SETTINGS.IS_ADMIN_TOOLTIP")}>
                    <div style={{marginTop: '13px'}}>
                        <Icon
                            color={theme.colors.primary}
                            size={"medium"}
                            icon={"circleInfo"}/>
                    </div>
                </Tooltip>
            </div>}
        </div>
        <div className={"form-row"}>
            {showAgencySelection && <DropdownInput
                labelColor={"primary"}
                ariaErrorMessage={"SCREENS.COMMON.ARIA_LABELS.AGENCY_USER_ERROR"}
                formError={agencyError}
                style={{flex: 1}}
                required={true}
                label={"GENERAL.AGENCY"}
                options={convertAgenciesToDropdownFormat(agencies)}
                setSelection={selection => _updateFormData("agency", convertAgenciesFromDropdownFormat(selection))}
            />}
            {showPhoneNumberFields && <PhoneNumberForm
                showInternationalPhonePrefix={true}
                defaultValues={{phoneNumber, internationalPhonePrefix}}
                internationalPhonePrefixRequired={true}
                phoneNumberRequired={true}
                validateForm={validateForm}
                onFormIsValid={setPhoneNumberFormData}/>}
        </div>
    </div>
}

export default UsersForm