import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AllActivityTypes, AllActivityTypesArrayDiscriminating} from "../../types-enums/activity";

export interface TravelComponentsState {
    activities: AllActivityTypesArrayDiscriminating,
    queueForDeleteActivity: { [key: string]: { index: number, activity: AllActivityTypesArrayDiscriminating } },
}

const initialState: TravelComponentsState = {
    // activities: [
    // {
    //     common: {
    //         id: uuidv4(),
    //         activityType: ACTIVITY_TYPE.flight,
    //         travelOptionType: TRAVEL_OPTION_TYPE.transport,
    //         editIndex: null,
    //         totalEmissions: {
    //             "status": CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success,
    //             "emissionKgCO2eTotal": 2889,
    //             errorCodes: null,
    //         }
    //     },
    //     "legs": [
    //         {
    //             "airline": null,
    //             "airport": {"id": 302902, "name": "New York", "iata": "WAS", "country": "US"},
    //             "travelClass": null,
    //             "aircraftType": null,
    //             "date": DateTime.now(),
    //             "flightNumber": "Q221",
    //             ownAirportTransfer: false,
    //         },
    //         {
    //             "airline": null,
    //             "airport": {"id": 302902, "name": "Chicago", "iata": "CHC", "country": "US"},
    //             "travelClass": null,
    //             "aircraftType": null,
    //             "date": DateTime.now(),
    //             "flightNumber": "Q221",
    //             ownAirportTransfer: false,
    //         }
    //     ],
    //     "passengerCount": 2,
    //     "returnFlight": true,
    //     "emissions": [{
    //         index: 0,
    //         status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //         emissionKgCO2e: 2889,
    //         errorCodes: null,
    //         type: CALCULATION_TYPE.flight,
    //         errors: null,
    //     }]
    // },
    // {
    //     common: {
    //         id: uuidv4(),
    //         activityType: ACTIVITY_TYPE.flight,
    //         travelOptionType: TRAVEL_OPTION_TYPE.transport,
    //         editIndex: null,
    //         totalEmissions: {
    //             "status": CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success,
    //             "emissionKgCO2eTotal": 4571,
    //             errorCodes: null
    //         }
    //     },
    //     "legs": [{
    //         "airline": null,
    //         "airport": {"id": 302902, "name": "Moskow", "iata": "MOS", "country": "DEU"},
    //         "travelClass": null,
    //         "aircraftType": null,
    //         "date": DateTime.now(),
    //         "flightNumber": "",
    //         ownAirportTransfer: false
    //     },
    //         {
    //             "airline": null,
    //             "airport": {"id": 300939, "name": "Berlin", "iata": "BER", "country": "DEU"},
    //             "travelClass": null,
    //             "aircraftType": null,
    //             "date": DateTime.now(),
    //             "flightNumber": "",
    //             ownAirportTransfer: false
    //         }],
    //     "passengerCount": 5,
    //     "returnFlight": true,
    //     "emissions": [{
    //         index: 0,
    //         status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //         emissionKgCO2e: 4571,
    //         errorCodes: null,
    //         type: CALCULATION_TYPE.flight,
    //         errors: null
    //     }]
    // },
    // {
    //     common: {
    //         id: uuidv4(),
    //         activityType: ACTIVITY_TYPE.flight,
    //         travelOptionType: TRAVEL_OPTION_TYPE.transport,
    //         editIndex: null,
    //         totalEmissions: {
    //             "status": CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.failed,
    //             "emissionKgCO2eTotal": null,
    //             errorCodes: null
    //         }
    //     },
    //     "legs": [{
    //         "airline": {"id": 302902, "name": "Quantas", "iata": "RY"},
    //         "airport": {"id": 302902, "name": "Doha", "iata": "DHO", "country": "CAT"},
    //         "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //         "aircraftType": {"name": "Airbus A310-200", "iata": "312", "id": 37},
    //         "date": DateTime.now(),
    //         "flightNumber": "Q381",
    //         ownAirportTransfer: false
    //     },
    //         {
    //             "airline": {"id": 302902, "name": "Quantas", "iata": "RY"},
    //             "airport": {"id": 302902, "name": "Melbourne", "iata": "MEL", "country": "CAT"},
    //             "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //             "aircraftType": {"name": "Airbus A310-200", "iata": "312", "id": 37},
    //             "date": DateTime.now(),
    //             "flightNumber": "Q381",
    //             ownAirportTransfer: false
    //         }],
    //     "passengerCount": 5,
    //     "returnFlight": true,
    //     emissions: [{
    //         index: 0,
    //         status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.failed,
    //         emissionKgCO2e: null,
    //         errorCodes: null,
    //         type: CALCULATION_TYPE.flight,
    //         errors: null
    //     }]
    // },
    // {
    //     common: {
    //         id: uuidv4(),
    //         activityType: ACTIVITY_TYPE.flight,
    //         travelOptionType: TRAVEL_OPTION_TYPE.transport,
    //         editIndex: null,
    //         totalEmissions: {
    //             "status": CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success_with_errors,
    //             "emissionKgCO2eTotal": 3850,
    //             errorCodes: null
    //         }
    //     },
    //     "legs": [
    //         {
    //             "airline": {"id": 302902, "name": "Quantas", "iata": "AI"},
    //             "airport": {"id": 302822, "name": "Barcelona", "iata": "BCN", "country": "ESP"},
    //             "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //             "aircraftType": {"name": "Airbus A319", "iata": "319", "id": 40},
    //             "date": DateTime.now(),
    //             "flightNumber": "Q991",
    //             ownAirportTransfer: false
    //         },
    //         {
    //             "airline": {"id": 302902, "name": "Quantas", "iata": "AI"},
    //             "airport": {"id": 302822, "name": "Kiev", "iata": "KIV", "country": "ESP"},
    //             "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //             "aircraftType": {"name": "Airbus A319", "iata": "319", "id": 40},
    //             "date": DateTime.now(),
    //             "flightNumber": "Q13",
    //             ownAirportTransfer: false
    //         },
    //         {
    //             "airline": {"id": 302902, "name": "Quantas", "iata": "Q"},
    //             "airport": {"id": 302822, "name": "Roma", "iata": "ROM", "country": "ESP"},
    //             "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //             "aircraftType": {"name": "Airbus A319", "iata": "319", "id": 40},
    //             "date": DateTime.now(),
    //             "flightNumber": "",
    //             ownAirportTransfer: false
    //         },
    //         {
    //             "airline": {"id": 302902, "name": "Quantas", "iata": "LH"},
    //             "airport": {"id": 302822, "name": "Doha", "iata": "DHO", "country": "ESP"},
    //             "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //             "aircraftType": {"name": "Airbus A319", "iata": "319", "id": 40},
    //             "date": DateTime.now(),
    //             "flightNumber": "",
    //             ownAirportTransfer: false
    //         },
    //         {
    //             "airline": {"id": 302902, "name": "Quantas", "iata": "PO"},
    //             "airport": {"id": 302822, "name": "Tokyo", "iata": "TYK", "country": "ESP"},
    //             "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //             "aircraftType": {"name": "Airbus A319", "iata": "319", "id": 40},
    //             "date": DateTime.now(),
    //             "flightNumber": "Q91",
    //             ownAirportTransfer: false
    //         },
    //         {
    //             "airline": {"id": 302902, "name": "Quantas", "iata": "PO"},
    //             "airport": {"id": 302822, "name": "Bejing", "iata": "BJI", "country": "ESP"},
    //             "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //             "aircraftType": {"name": "Airbus A319", "iata": "319", "id": 40},
    //             "date": DateTime.now(),
    //             "flightNumber": "Q91",
    //             ownAirportTransfer: false
    //         }
    //     ],
    //     "passengerCount": 11,
    //     "returnFlight": false,
    //     "emissions":
    //         [
    //             {
    //                 index: 0,
    //                 status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //                 emissionKgCO2e: 1000,
    //                 errorCodes: null,
    //                 type: CALCULATION_TYPE.flight,
    //                 errors: null
    //             },
    //             {
    //                 index: 1,
    //                 status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //                 emissionKgCO2e: 850,
    //                 errorCodes: null,
    //                 type: CALCULATION_TYPE.flight,
    //                 errors: null
    //             },
    //             {
    //                 index: 2,
    //                 status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.failed,
    //                 emissionKgCO2e: null,
    //                 errorCodes: null,
    //                 type: CALCULATION_TYPE.flight,
    //                 errors: null
    //             },
    //             {
    //                 index: 3,
    //                 status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.failed,
    //                 emissionKgCO2e: null,
    //                 errorCodes: null,
    //                 type: CALCULATION_TYPE.flight,
    //                 errors: null
    //             },
    //             {
    //                 index: 4,
    //                 status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //                 emissionKgCO2e: 2000,
    //                 errorCodes: null,
    //                 type: CALCULATION_TYPE.flight,
    //                 errors: null
    //             }]
    // },
    // {
    //     common: {
    //         id: uuidv4(),
    //         activityType: ACTIVITY_TYPE.flight,
    //         travelOptionType: TRAVEL_OPTION_TYPE.transport,
    //         editIndex: null,
    //         totalEmissions: {
    //             "status": CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success,
    //             "emissionKgCO2eTotal": 7267,
    //             errorCodes: null
    //         }
    //     },
    //     "legs": [{
    //         "airline": {"id": 302902, "name": "Quantas", "iata": "AA"},
    //         "airport": {"id": 302902, "name": "New York", "iata": "WAS", "country": "US"},
    //         "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 3},
    //         "aircraftType": {"name": "Airbus A310-200", "iata": "312", "id": 37},
    //         "date": DateTime.now(),
    //         "flightNumber": "Q221",
    //         ownAirportTransfer: false
    //     },
    //         {
    //             "airline": {"id": 302902, "name": "Quantas", "iata": "AA"},
    //             "airport": {"id": 300939, "name": "Helsinki", "iata": "SYD", "country": "FIN"},
    //             "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 3},
    //             "aircraftType": {"name": "Airbus A310-200", "iata": "312", "id": 37},
    //             "date": DateTime.now(),
    //             "flightNumber": "Q221",
    //             ownAirportTransfer: false
    //         }],
    //     "passengerCount": 3,
    //     "returnFlight": true,
    //     "emissions": [{
    //         index: 0,
    //         status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //         emissionKgCO2e: 7267,
    //         errorCodes: null,
    //         type: CALCULATION_TYPE.flight,
    //         errors: null
    //     }]
    // },
    // {
    //     common: {
    //         id: uuidv4(),
    //         activityType: ACTIVITY_TYPE.flight,
    //         travelOptionType: TRAVEL_OPTION_TYPE.transport,
    //         editIndex: null,
    //         totalEmissions: {
    //             "status": CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success,
    //             "emissionKgCO2eTotal": 19092,
    //             errorCodes: null
    //         }
    //     },
    //     "legs": [
    //         {
    //             "airline": null,
    //             "airport": {"id": 302902, "name": "Moskow", "iata": "WAS", "country": "DEU"},
    //             "travelClass": null,
    //             "aircraftType": null,
    //             "date": DateTime.now(),
    //             "flightNumber": "",
    //             ownAirportTransfer: false
    //         },
    //         {
    //             "airline": null,
    //             "airport": {"id": 300939, "name": "Berlin", "iata": "STO", "country": "DEU"},
    //             "travelClass": null,
    //             "aircraftType": null,
    //             "date": DateTime.now(),
    //             "flightNumber": "",
    //             ownAirportTransfer: false
    //         }],
    //
    //     "passengerCount": 9,
    //     "returnFlight": true,
    //     "emissions": [{
    //         index: 0,
    //         status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //         emissionKgCO2e: 19092,
    //         errorCodes: null,
    //         type: CALCULATION_TYPE.flight,
    //         errors: null
    //     }]
    // },
    // {
    //     common: {
    //         id: uuidv4(),
    //         activityType: ACTIVITY_TYPE.flight,
    //         travelOptionType: TRAVEL_OPTION_TYPE.transport,
    //         editIndex: null,
    //         totalEmissions: {
    //             "status": CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.failed,
    //             "emissionKgCO2eTotal": null,
    //             errorCodes: null
    //         }
    //     },
    //     "legs": [
    //         {
    //             "airline": null,
    //             "airport": {"id": 302902, "name": "Doha", "iata": "MEL", "country": "CAT"},
    //             "travelClass": null,
    //             "aircraftType": null,
    //             "date": DateTime.now(),
    //             "flightNumber": "Q381",
    //             ownAirportTransfer: false
    //         },
    //         {
    //             "airline": null,
    //             "airport": {"id": 300939, "name": "Sydney", "iata": "SYD", "country": "AUS"},
    //             "travelClass": null,
    //             "aircraftType": null,
    //             "date": DateTime.now(),
    //             "flightNumber": "Q381",
    //             ownAirportTransfer: false
    //         }],
    //
    //
    //     "passengerCount": 5,
    //     "returnFlight": true,
    //     emissions: [{
    //         index: 0,
    //         status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.failed,
    //         emissionKgCO2e: null,
    //         errorCodes: null,
    //         type: CALCULATION_TYPE.flight,
    //         errors: null
    //     }]
    // },
    // {
    //     common: {
    //         id: uuidv4(),
    //         activityType: ACTIVITY_TYPE.flight,
    //         travelOptionType: TRAVEL_OPTION_TYPE.transport,
    //         editIndex: null,
    //         totalEmissions: {
    //             "status": CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success_with_errors,
    //             "emissionKgCO2eTotal": 6200,
    //             errorCodes: null
    //         }
    //     },
    //     "legs": [{
    //         "airline": null,
    //         "airport": {"id": 302822, "name": "Barcelona", "iata": "BCN", "country": "ESP"},
    //         "travelClass": null,
    //         "aircraftType": null,
    //         "date": DateTime.now(),
    //         "flightNumber": "Q991",
    //         ownAirportTransfer: false
    //     },
    //         {
    //             "airline": null,
    //             "airport": {"id": 302822, "name": "Kiev", "iata": "BCN", "country": "ESP"},
    //             "travelClass": null,
    //             "aircraftType": null,
    //             "date": DateTime.now(),
    //             "flightNumber": "Q13",
    //             ownAirportTransfer: false
    //         },
    //         {
    //             "airline": {"id": 302902, "name": "Quantas", "iata": "Q"},
    //             "airport": {"id": 302822, "name": "Roma", "iata": "BCN", "country": "ESP"},
    //             "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //             "aircraftType": {"name": "Airbus A319", "iata": "319", "id": 40},
    //             "date": DateTime.now(),
    //             "flightNumber": "",
    //             ownAirportTransfer: false
    //         },
    //         {
    //             "airline": {"id": 302902, "name": "Quantas", "iata": "Q"},
    //             "airport": {"id": 302822, "name": "Barcelona", "iata": "BCN", "country": "ESP"},
    //             "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //             "aircraftType": {"name": "Airbus A319", "iata": "319", "id": 40},
    //             "date": DateTime.now(),
    //             "flightNumber": "",
    //             ownAirportTransfer: false
    //         },
    //         {
    //             "airline": {"id": 302902, "name": "Quantas", "iata": "Q"},
    //             "airport": {"id": 302822, "name": "Tokyo", "iata": "BCN", "country": "ESP"},
    //             "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //             "aircraftType": {"name": "Airbus A319", "iata": "319", "id": 40},
    //             "date": DateTime.now(),
    //             "flightNumber": "Q91",
    //             ownAirportTransfer: false
    //         },
    //         {
    //             "airline": {"id": 302902, "name": "Quantas", "iata": "Q"},
    //             "airport": {"id": 302902, "name": "Berlin", "iata": "BER", "country": "DEU"},
    //             "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //             "aircraftType": {"name": "Airbus A319", "iata": "319", "id": 40},
    //             "date": DateTime.now(),
    //             "flightNumber": "Q91",
    //             ownAirportTransfer: false
    //         }
    //     ],
    //     "passengerCount": 11,
    //     "returnFlight": true,
    //     "emissions":
    //         [
    //             {
    //                 index: 0,
    //                 status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //                 emissionKgCO2e: 2000,
    //                 errorCodes: null,
    //                 type: CALCULATION_TYPE.flight,
    //                 errors: null
    //             },
    //             {
    //                 index: 1,
    //                 status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //                 emissionKgCO2e: 1500,
    //                 errorCodes: null,
    //                 type: CALCULATION_TYPE.flight,
    //                 errors: null
    //             },
    //             {
    //                 index: 2,
    //                 status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.failed,
    //                 emissionKgCO2e: null,
    //                 errorCodes: null,
    //                 type: CALCULATION_TYPE.flight,
    //                 errors: null
    //             },
    //             {
    //                 index: 3,
    //                 status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.failed,
    //                 emissionKgCO2e: null,
    //                 errorCodes: null,
    //                 type: CALCULATION_TYPE.flight,
    //                 errors: null
    //             },
    //             {
    //                 index: 4,
    //                 status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //                 emissionKgCO2e: 2700,
    //                 errorCodes: null,
    //                 type: CALCULATION_TYPE.flight,
    //                 errors: null
    //             }]
    // },
    // {
    //     common: {
    //         id: uuidv4(),
    //         activityType: ACTIVITY_TYPE.flight,
    //         travelOptionType: TRAVEL_OPTION_TYPE.transport,
    //         editIndex: null,
    //         totalEmissions: {
    //             "status": CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success,
    //             "emissionKgCO2eTotal": 1274,
    //             errorCodes: null
    //         }
    //     },
    //     "legs": [
    //         {
    //             "airline": {"id": 302902, "name": "Quantas", "iata": "Q"},
    //             "airport": {"id": 302902, "name": "Detroit", "iata": "WAS", "country": "US"},
    //             "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 3},
    //             "aircraftType": {"name": "Airbus A310-200", "iata": "312", "id": 37},
    //             "date": DateTime.now(),
    //             "flightNumber": "Q221",
    //             ownAirportTransfer: false,
    //         },
    //         {
    //             "airline": {"id": 302902, "name": "Quantas", "iata": "Q"},
    //             "airport": {"id": 300939, "name": "Washington", "iata": "STO", "country": "FIN"},
    //             "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 3},
    //             "aircraftType": {"name": "Airbus A310-200", "iata": "312", "id": 37},
    //             "date": DateTime.now(),
    //             "flightNumber": "Q221",
    //             ownAirportTransfer: false,
    //         }],
    //
    //     "passengerCount": 1,
    //     "returnFlight": true,
    //     "emissions": [{
    //         index: 0,
    //         status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //         emissionKgCO2e: 1274,
    //         errorCodes: null,
    //         type: CALCULATION_TYPE.flight,
    //         errors: null
    //     }]
    // },
    // {
    //     common: {
    //         id: uuidv4(),
    //         activityType: ACTIVITY_TYPE.flight,
    //         travelOptionType: TRAVEL_OPTION_TYPE.transport,
    //         editIndex: null,
    //         totalEmissions: {
    //             "status": CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success,
    //             "emissionKgCO2eTotal": 3850,
    //             errorCodes: null
    //         }
    //     },
    //     "legs": [{
    //         "airline": {"id": 302902, "name": "Quantas", "iata": "AI"},
    //         "airport": {"id": 302822, "name": "Barcelona", "iata": "YYY", "country": "ESP"},
    //         "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //         "aircraftType": {"name": "Airbus A319", "iata": "319", "id": 40},
    //         "date": DateTime.now(),
    //         "flightNumber": "Q991",
    //         ownAirportTransfer: false
    //     },
    //         {
    //             "airline": {"id": 302902, "name": "Quantas", "iata": "AI"},
    //             "airport": {"id": 302822, "name": "Kiev", "iata": "BCN", "country": "ESP"},
    //             "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //             "aircraftType": {"name": "Airbus A319", "iata": "319", "id": 40},
    //             "date": DateTime.now(),
    //             "flightNumber": "Q13",
    //             ownAirportTransfer: false
    //         },
    //         {
    //             "airline": {"id": 302902, "name": "Quantas", "iata": "Q"},
    //             "airport": {"id": 302822, "name": "Roma", "iata": "AMM", "country": "ESP"},
    //             "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //             "aircraftType": {"name": "Airbus A319", "iata": "319", "id": 40},
    //             "date": DateTime.now(),
    //             "flightNumber": "",
    //             ownAirportTransfer: false
    //         },
    //         {
    //             "airline": {"id": 302902, "name": "Quantas", "iata": "LH"},
    //             "airport": {"id": 302822, "name": "Barcelona", "iata": "FCO", "country": "ESP"},
    //             "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //             "aircraftType": {"name": "Airbus A319", "iata": "319", "id": 40},
    //             "date": DateTime.now(),
    //             "flightNumber": "",
    //             ownAirportTransfer: false
    //         },
    //         {
    //             "airline": {"id": 302902, "name": "Quantas", "iata": "PO"},
    //             "airport": {"id": 302822, "name": "Tokyo", "iata": "LE", "country": "ESP"},
    //             "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //             "aircraftType": {"name": "Airbus A319", "iata": "319", "id": 40},
    //             "date": DateTime.now(),
    //             "flightNumber": "Q91",
    //             ownAirportTransfer: false
    //         },
    //         {
    //             "airline": {"id": 302902, "name": "Quantas", "iata": "PO"},
    //             "airport": {"id": 302902, "name": "Berlin", "iata": "XXX", "country": "DEU"},
    //             "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //             "aircraftType": {"name": "Airbus A319", "iata": "319", "id": 40},
    //             "date": DateTime.now(),
    //             "flightNumber": "Q91",
    //             ownAirportTransfer: false
    //         }],
    //     "passengerCount": 11,
    //     "returnFlight": false,
    //     "emissions":
    //         [
    //             {
    //                 index: 0,
    //                 status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //                 emissionKgCO2e: 1000,
    //                 errorCodes: null,
    //                 type: CALCULATION_TYPE.flight,
    //                 errors: null
    //             },
    //             {
    //                 index: 1,
    //                 status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //                 emissionKgCO2e: 850,
    //                 errorCodes: null,
    //                 type: CALCULATION_TYPE.flight,
    //                 errors: null
    //             },
    //             {
    //                 index: 2,
    //                 status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //                 emissionKgCO2e: 656,
    //                 errorCodes: null,
    //                 type: CALCULATION_TYPE.flight,
    //                 errors: null
    //             },
    //             {
    //                 index: 3,
    //                 status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //                 emissionKgCO2e: 213,
    //                 errorCodes: null,
    //                 type: CALCULATION_TYPE.flight,
    //                 errors: null
    //             },
    //             {
    //                 index: 4,
    //                 status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //                 emissionKgCO2e: 2000,
    //                 errorCodes: null,
    //                 type: CALCULATION_TYPE.flight,
    //                 errors: null
    //             }]
    // },
    // {
    //     common: {
    //         id: uuidv4(),
    //         activityType: ACTIVITY_TYPE.flight,
    //         travelOptionType: TRAVEL_OPTION_TYPE.transport,
    //         editIndex: null,
    //         totalEmissions: {
    //             "status": CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success,
    //             "emissionKgCO2eTotal": 8821,
    //             errorCodes: null
    //         }
    //     },
    //         "legs": {
    //             "firstLeg": {
    //                 "airline": {"id": 302902, "name": "Quantas", "iata": "Q"},
    //                 "airport": {"id": 302902, "name": "Moskow", "iata": "BER", "country": "DEU"},
    //                 "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //                 "aircraftType": {"name": "Airbus A310-200", "iata": "312", "id": 37},
    //                 "date": DateTime.now(),
    //                 "flightNumber": "",
    //                 ownAirportTransfer: false
    //             },
    //             "stopover": [],
    //             "lastLeg": {"id": 300939, "name": "Berlin", "iata": "BER", "country": "DEU"}
    //         },
    //
    //         "passengerCount": 5,
    //         "returnFlight": true,
    //         "emissions": [{
    //             index: 0,
    //             status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //             emissionKgCO2e: 8821,
    //             errorCodes: null,
    //             type: CALCULATION_TYPE.flight,
    //             errors: null
    //         }]
    //     },
    //     {
    //         common: {
    //             id: uuidv4(),
    //             activityType: ACTIVITY_TYPE.flight,
    //             travelOptionType: TRAVEL_OPTION_TYPE.transport,
    //             editIndex: null,
    //             totalEmissions: {
    //                 "status": CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success,
    //                 "emissionKgCO2eTotal": 4571,
    //                 errorCodes: null
    //             }
    //         },
    //         "legs": {
    //             "firstLeg": {
    //                 "airline": null,
    //                 "airport": {"id": 302902, "name": "Moskow", "iata": "BER", "country": "DEU"},
    //                 "travelClass": null,
    //                 "aircraftType": null,
    //                 "date": DateTime.now(),
    //                 "flightNumber": "",
    //                 ownAirportTransfer: false
    //             },
    //             "stopover": [],
    //             "lastLeg": {"id": 300939, "name": "Berlin", "iata": "SYD", "country": "DEU"}
    //         },
    //
    //         "passengerCount": 5,
    //         "returnFlight": true,
    //         "emissions": [{
    //             index: 0,
    //             status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //             emissionKgCO2e: 4571,
    //             errorCodes: null,
    //             type: CALCULATION_TYPE.flight,
    //             errors: null
    //         }]
    //     },
    //     {
    //         common: {
    //             id: uuidv4(),
    //             activityType: ACTIVITY_TYPE.flight,
    //             travelOptionType: TRAVEL_OPTION_TYPE.transport,
    //             editIndex: null,
    //             totalEmissions: {
    //                 "status": CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.failure,
    //                 "emissionKgCO2eTotal": null,
    //                 errorCodes: null
    //             }
    //         },
    //         "legs": {
    //             "firstLeg": {
    //                 "airline": {"id": 302902, "name": "Quantas", "iata": "RY"},
    //                 "airport": {"id": 302902, "name": "Doha", "iata": "DHO", "country": "CAT"},
    //                 "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //                 "aircraftType": {"name": "Airbus A310-200", "iata": "312", "id": 37},
    //                 "date": DateTime.now(),
    //                 "flightNumber": "Q381",
    //                 ownAirportTransfer: false
    //             },
    //             "stopover": [],
    //             "lastLeg": {"id": 300939, "name": "Sydney", "iata": "TYK", "country": "AUS"}
    //         },
    //
    //         "passengerCount": 5,
    //         "returnFlight": true,
    //         emissions: [{
    //             index: 0,
    //             status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.failure,
    //             emissionKgCO2e: null,
    //             errorCodes: null,
    //             type: CALCULATION_TYPE.flight,
    //             errors: null
    //         }]
    //     },
    //     {
    //         common: {
    //             id: uuidv4(),
    //             activityType: ACTIVITY_TYPE.flight,
    //             travelOptionType: TRAVEL_OPTION_TYPE.transport,
    //             editIndex: null,
    //             totalEmissions: {
    //                 "status": CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success_with_errors,
    //                 "emissionKgCO2eTotal": 3850,
    //                 errorCodes: null
    //             }
    //         },
    //         "legs": {
    //             "firstLeg": {
    //                 "airline": {"id": 302902, "name": "Quantas", "iata": "AI"},
    //                 "airport": {"id": 302822, "name": "Barcelona", "iata": "BCN", "country": "ESP"},
    //                 "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //                 "aircraftType": {"name": "Airbus A319", "iata": "319", "id": 40},
    //                 "date": DateTime.now(),
    //                 "flightNumber": "Q991",
    //                 ownAirportTransfer: false
    //             },
    //             "stopover": [
    //                 {
    //                     "airline": {"id": 302902, "name": "Quantas", "iata": "AI"},
    //                     "airport": {"id": 302822, "name": "Kiev", "iata": "BCN", "country": "ESP"},
    //                     "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //                     "aircraftType": {"name": "Airbus A319", "iata": "319", "id": 40},
    //                     "date": DateTime.now(),
    //                     "flightNumber": "Q13",
    //                     ownAirportTransfer: false
    //                 },
    //                 {
    //                     "airline": {"id": 302902, "name": "Quantas", "iata": "Q"},
    //                     "airport": {"id": 302822, "name": "Roma", "iata": "AMM", "country": "ESP"},
    //                     "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //                     "aircraftType": {"name": "Airbus A319", "iata": "319", "id": 40},
    //                     "date": DateTime.now(),
    //                     "flightNumber": "",
    //                     ownAirportTransfer: false
    //                 },
    //                 {
    //                     "airline": {"id": 302902, "name": "Quantas", "iata": "LH"},
    //                     "airport": {"id": 302822, "name": "Barcelona", "iata": "FCO", "country": "ESP"},
    //                     "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //                     "aircraftType": {"name": "Airbus A319", "iata": "319", "id": 40},
    //                     "date": DateTime.now(),
    //                     "flightNumber": "",
    //                     ownAirportTransfer: false
    //                 },
    //                 {
    //                     "airline": {"id": 302902, "name": "Quantas", "iata": "PO"},
    //                     "airport": {"id": 302822, "name": "Tokyo", "iata": "LE", "country": "ESP"},
    //                     "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //                     "aircraftType": {"name": "Airbus A319", "iata": "319", "id": 40},
    //                     "date": DateTime.now(),
    //                     "flightNumber": "Q91",
    //                     ownAirportTransfer: false
    //                 }
    //             ],
    //             "lastLeg": {"id": 302902, "name": "Berlin", "iata": "HH", "country": "DEU"}
    //         },
    //         "passengerCount": 11,
    //         "returnFlight": false,
    //         "emissions":
    //             [
    //                 {
    //                     index: 0,
    //                     status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //                     emissionKgCO2e: 1000,
    //                     errorCodes: null,
    //                     type: CALCULATION_TYPE.flight,
    //                     errors: null
    //                 },
    //                 {
    //                     index: 1,
    //                     status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //                     emissionKgCO2e: 850,
    //                     errorCodes: null,
    //                     type: CALCULATION_TYPE.flight,
    //                     errors: null
    //                 },
    //                 {
    //                     index: 2,
    //                     status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.failure,
    //                     emissionKgCO2e: null,
    //                     errorCodes: null,
    //                     type: CALCULATION_TYPE.flight,
    //                     errors: null
    //                 },
    //                 {
    //                     index: 3,
    //                     status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.failure,
    //                     emissionKgCO2e: null,
    //                     errorCodes: null,
    //                     type: CALCULATION_TYPE.flight,
    //                     errors: null
    //                 },
    //                 {
    //                     index: 4,
    //                     status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //                     emissionKgCO2e: 2000,
    //                     errorCodes: null,
    //                     type: CALCULATION_TYPE.flight,
    //                     errors: null
    //                 }]
    //     },
    //     {
    //         common: {
    //             id: uuidv4(),
    //             activityType: ACTIVITY_TYPE.flight,
    //             travelOptionType: TRAVEL_OPTION_TYPE.transport,
    //             editIndex: null,
    //             totalEmissions: {
    //                 "status": CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success,
    //                 "emissionKgCO2eTotal": 7267,
    //                 errorCodes: null
    //             }
    //         },
    //         "legs": {
    //             "firstLeg": {
    //                 "airline": {"id": 302902, "name": "Quantas", "iata": "AA"},
    //                 "airport": {"id": 302902, "name": "New York", "iata": "WAS", "country": "US"},
    //                 "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 3},
    //                 "aircraftType": {"name": "Airbus A310-200", "iata": "312", "id": 37},
    //                 "date": DateTime.now(),
    //                 "flightNumber": "Q221",
    //                 ownAirportTransfer: false
    //             },
    //             "stopover": [],
    //             "lastLeg": {"id": 300939, "name": "Helsinki", "iata": "SYD", "country": "FIN"}
    //         },
    //
    //         "passengerCount": 3,
    //         "returnFlight": true,
    //         "emissions": [{
    //             index: 0,
    //             status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //             emissionKgCO2e: 7267,
    //             errorCodes: null,
    //             type: CALCULATION_TYPE.flight,
    //             errors: null
    //         }]
    //     },
    //     {
    //         common: {
    //             id: uuidv4(),
    //             activityType: ACTIVITY_TYPE.flight,
    //             travelOptionType: TRAVEL_OPTION_TYPE.transport,
    //             editIndex: null,
    //             totalEmissions: {
    //                 "status": CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success,
    //                 "emissionKgCO2eTotal": 19092,
    //                 errorCodes: null
    //             }
    //         },
    //         "legs": {
    //             "firstLeg": {
    //                 "airline": null,
    //                 "airport": {"id": 302902, "name": "Moskow", "iata": "WAS", "country": "DEU"},
    //                 "travelClass": null,
    //                 "aircraftType": null,
    //                 "date": DateTime.now(),
    //                 "flightNumber": "",
    //                 ownAirportTransfer: false
    //             },
    //             "stopover": [],
    //             "lastLeg": {"id": 300939, "name": "Berlin", "iata": "STO", "country": "DEU"}
    //         },
    //
    //         "passengerCount": 9,
    //         "returnFlight": true,
    //         "emissions": [{
    //             index: 0,
    //             status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //             emissionKgCO2e: 19092,
    //             errorCodes: null,
    //             type: CALCULATION_TYPE.flight,
    //             errors: null
    //         }]
    //     },
    //     {
    //         common: {
    //             id: uuidv4(),
    //             activityType: ACTIVITY_TYPE.flight,
    //             travelOptionType: TRAVEL_OPTION_TYPE.transport,
    //             editIndex: null,
    //             totalEmissions: {
    //                 "status": CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.failure,
    //                 "emissionKgCO2eTotal": null,
    //                 errorCodes: null
    //             }
    //         },
    //         "legs": {
    //             "firstLeg": {
    //                 "airline": null,
    //                 "airport": {"id": 302902, "name": "Doha", "iata": "MEL", "country": "CAT"},
    //                 "travelClass": null,
    //                 "aircraftType": null,
    //                 "date": DateTime.now(),
    //                 "flightNumber": "Q381",
    //                 ownAirportTransfer: false
    //             },
    //             "stopover": [],
    //             "lastLeg": {"id": 300939, "name": "Sydney", "iata": "SYD", "country": "AUS"}
    //         },
    //
    //         "passengerCount": 5,
    //         "returnFlight": true,
    //         emissions: [{
    //             index: 0,
    //             status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.failure,
    //             emissionKgCO2e: null,
    //             errorCodes: null,
    //             type: CALCULATION_TYPE.flight,
    //             errors: null
    //         }]
    //     },
    //     {
    //         common: {
    //             id: uuidv4(),
    //             activityType: ACTIVITY_TYPE.flight,
    //             travelOptionType: TRAVEL_OPTION_TYPE.transport,
    //             editIndex: null,
    //             totalEmissions: {
    //                 "status": CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success_with_errors,
    //                 "emissionKgCO2eTotal": 6200,
    //                 errorCodes: null
    //             }
    //         },
    //         "legs": {
    //             "firstLeg": {
    //                 "airline": null,
    //                 "airport": {"id": 302822, "name": "Barcelona", "iata": "BCN", "country": "ESP"},
    //                 "travelClass": null,
    //                 "aircraftType": null,
    //                 "date": DateTime.now(),
    //                 "flightNumber": "Q991",
    //                 ownAirportTransfer: false
    //             },
    //             "stopover": [
    //                 {
    //                     "airline": null,
    //                     "airport": {"id": 302822, "name": "Kiev", "iata": "BCN", "country": "ESP"},
    //                     "travelClass": null,
    //                     "aircraftType": null,
    //                     "date": DateTime.now(),
    //                     "flightNumber": "Q13",
    //                     ownAirportTransfer: false
    //                 },
    //                 {
    //                     "airline": {"id": 302902, "name": "Quantas", "iata": "Q"},
    //                     "airport": {"id": 302822, "name": "Roma", "iata": "BCN", "country": "ESP"},
    //                     "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //                     "aircraftType": {"name": "Airbus A319", "iata": "319", "id": 40},
    //                     "date": DateTime.now(),
    //                     "flightNumber": "",
    //                     ownAirportTransfer: false
    //                 },
    //                 {
    //                     "airline": {"id": 302902, "name": "Quantas", "iata": "Q"},
    //                     "airport": {"id": 302822, "name": "Barcelona", "iata": "BCN", "country": "ESP"},
    //                     "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //                     "aircraftType": {"name": "Airbus A319", "iata": "319", "id": 40},
    //                     "date": DateTime.now(),
    //                     "flightNumber": "",
    //                     ownAirportTransfer: false
    //                 },
    //                 {
    //                     "airline": {"id": 302902, "name": "Quantas", "iata": "Q"},
    //                     "airport": {"id": 302822, "name": "Tokyo", "iata": "BCN", "country": "ESP"},
    //                     "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //                     "aircraftType": {"name": "Airbus A319", "iata": "319", "id": 40},
    //                     "date": DateTime.now(),
    //                     "flightNumber": "Q91",
    //                     ownAirportTransfer: false
    //                 }
    //             ],
    //             "lastLeg": {"id": 302902, "name": "Berlin", "iata": "BER", "country": "DEU"}
    //         },
    //         "passengerCount": 11,
    //         "returnFlight": true,
    //         "emissions":
    //             [
    //                 {
    //                     index: 0,
    //                     status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //                     emissionKgCO2e: 2000,
    //                     errorCodes: null,
    //                     type: CALCULATION_TYPE.flight,
    //                     errors: null
    //                 },
    //                 {
    //                     index: 1,
    //                     status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //                     emissionKgCO2e: 1500,
    //                     errorCodes: null,
    //                     type: CALCULATION_TYPE.flight,
    //                     errors: null
    //                 },
    //                 {
    //                     index: 2,
    //                     status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.failure,
    //                     emissionKgCO2e: null,
    //                     errorCodes: null,
    //                     type: CALCULATION_TYPE.flight,
    //                     errors: null
    //                 },
    //                 {
    //                     index: 3,
    //                     status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.failure,
    //                     emissionKgCO2e: null,
    //                     errorCodes: null,
    //                     type: CALCULATION_TYPE.flight,
    //                     errors: null
    //                 },
    //                 {
    //                     index: 4,
    //                     status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //                     emissionKgCO2e: 2700,
    //                     errorCodes: null,
    //                     type: CALCULATION_TYPE.flight,
    //                     errors: null
    //                 }]
    //     },
    //     {
    //         common: {
    //             id: uuidv4(),
    //             activityType: ACTIVITY_TYPE.flight,
    //             travelOptionType: TRAVEL_OPTION_TYPE.transport,
    //             editIndex: null,
    //             totalEmissions: {
    //                 "status": CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success,
    //                 "emissionKgCO2eTotal": 1274,
    //                 errorCodes: null
    //             }
    //         },
    //         "legs": {
    //             "firstLeg": {
    //                 "airline": {"id": 302902, "name": "Quantas", "iata": "Q"},
    //                 "airport": {"id": 302902, "name": "Detroit", "iata": "WAS", "country": "US"},
    //                 "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 3},
    //                 "aircraftType": {"name": "Airbus A310-200", "iata": "312", "id": 37},
    //                 "date": DateTime.now(),
    //                 "flightNumber": "Q221",
    //                 ownAirportTransfer: false,
    //             },
    //             "stopover": [],
    //             "lastLeg": {"id": 300939, "name": "Washington", "iata": "STO", "country": "FIN"}
    //         },
    //
    //         "passengerCount": 1,
    //         "returnFlight": true,
    //         "emissions": [{
    //             index: 0,
    //             status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //             emissionKgCO2e: 1274,
    //             errorCodes: null,
    //             type: CALCULATION_TYPE.flight,
    //             errors: null
    //         }]
    //     },
    //     {
    //         common: {
    //             id: uuidv4(),
    //             activityType: ACTIVITY_TYPE.flight,
    //             travelOptionType: TRAVEL_OPTION_TYPE.transport,
    //             editIndex: null,
    //             totalEmissions: {
    //                 "status": CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success,
    //                 "emissionKgCO2eTotal": 8821,
    //                 errorCodes: null
    //             }
    //         },
    //         "legs": {
    //             "firstLeg": {
    //                 "airline": {"id": 302902, "name": "Quantas", "iata": "Q"},
    //                 "airport": {"id": 302902, "name": "Moskow", "iata": "BER", "country": "DEU"},
    //                 "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //                 "aircraftType": {"name": "Airbus A310-200", "iata": "312", "id": 37},
    //                 "date": DateTime.now(),
    //                 "flightNumber": "",
    //                 ownAirportTransfer: false
    //             },
    //             "stopover": [],
    //             "lastLeg": {"id": 300939, "name": "Berlin", "iata": "BER", "country": "DEU"}
    //         },
    //
    //         "passengerCount": 5,
    //         "returnFlight": true,
    //         "emissions": [{
    //             index: 0,
    //             status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //             emissionKgCO2e: 8821,
    //             errorCodes: null,
    //             type: CALCULATION_TYPE.flight,
    //             errors: null
    //         }]
    //     },
    //     {
    //         common: {
    //             id: uuidv4(),
    //             activityType: ACTIVITY_TYPE.flight,
    //             travelOptionType: TRAVEL_OPTION_TYPE.transport,
    //             editIndex: null,
    //             totalEmissions: {
    //                 "status": CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success,
    //                 "emissionKgCO2eTotal": 4571,
    //                 errorCodes: null
    //             }
    //         },
    //         "legs": {
    //             "firstLeg": {
    //                 "airline": null,
    //                 "airport": {"id": 302902, "name": "Moskow", "iata": "BER", "country": "DEU"},
    //                 "travelClass": null,
    //                 "aircraftType": null,
    //                 "date": DateTime.now(),
    //                 "flightNumber": "",
    //                 ownAirportTransfer: false
    //             },
    //             "stopover": [],
    //             "lastLeg": {"id": 300939, "name": "Berlin", "iata": "SYD", "country": "DEU"}
    //         },
    //
    //         "passengerCount": 5,
    //         "returnFlight": true,
    //         "emissions": [{
    //             index: 0,
    //             status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //             emissionKgCO2e: 4571,
    //             errorCodes: null,
    //             type: CALCULATION_TYPE.flight,
    //             errors: null
    //         }]
    //     },
    //     {
    //         common: {
    //             id: uuidv4(),
    //             activityType: ACTIVITY_TYPE.flight,
    //             travelOptionType: TRAVEL_OPTION_TYPE.transport,
    //             editIndex: null,
    //             totalEmissions: {
    //                 "status": CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.failure,
    //                 "emissionKgCO2eTotal": null,
    //                 errorCodes: null
    //             }
    //         },
    //         "legs": {
    //             "firstLeg": {
    //                 "airline": {"id": 302902, "name": "Quantas", "iata": "RY"},
    //                 "airport": {"id": 302902, "name": "Doha", "iata": "DHO", "country": "CAT"},
    //                 "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //                 "aircraftType": {"name": "Airbus A310-200", "iata": "312", "id": 37},
    //                 "date": DateTime.now(),
    //                 "flightNumber": "Q381",
    //                 ownAirportTransfer: false
    //             },
    //             "stopover": [],
    //             "lastLeg": {"id": 300939, "name": "Sydney", "iata": "TYK", "country": "AUS"}
    //         },
    //
    //         "passengerCount": 5,
    //         "returnFlight": true,
    //         emissions: [{
    //             index: 0,
    //             status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.failure,
    //             emissionKgCO2e: null,
    //             errorCodes: null,
    //             type: CALCULATION_TYPE.flight,
    //             errors: null
    //         }]
    //     },
    //     {
    //         common: {
    //             id: uuidv4(),
    //             activityType: ACTIVITY_TYPE.flight,
    //             travelOptionType: TRAVEL_OPTION_TYPE.transport,
    //             editIndex: null,
    //             totalEmissions: {
    //                 "status": CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success_with_errors,
    //                 "emissionKgCO2eTotal": 3850,
    //                 errorCodes: null
    //             }
    //         },
    //         "legs": {
    //             "firstLeg": {
    //                 "airline": {"id": 302902, "name": "Quantas", "iata": "AI"},
    //                 "airport": {"id": 302822, "name": "Barcelona", "iata": "BCN", "country": "ESP"},
    //                 "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //                 "aircraftType": {"name": "Airbus A319", "iata": "319", "id": 40},
    //                 "date": DateTime.now(),
    //                 "flightNumber": "Q991",
    //                 ownAirportTransfer: false
    //             },
    //             "stopover": [
    //                 {
    //                     "airline": {"id": 302902, "name": "Quantas", "iata": "AI"},
    //                     "airport": {"id": 302822, "name": "Kiev", "iata": "BCN", "country": "ESP"},
    //                     "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //                     "aircraftType": {"name": "Airbus A319", "iata": "319", "id": 40},
    //                     "date": DateTime.now(),
    //                     "flightNumber": "Q13",
    //                     ownAirportTransfer: false
    //                 },
    //                 {
    //                     "airline": {"id": 302902, "name": "Quantas", "iata": "Q"},
    //                     "airport": {"id": 302822, "name": "Roma", "iata": "AMM", "country": "ESP"},
    //                     "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //                     "aircraftType": {"name": "Airbus A319", "iata": "319", "id": 40},
    //                     "date": DateTime.now(),
    //                     "flightNumber": "",
    //                     ownAirportTransfer: false
    //                 },
    //                 {
    //                     "airline": {"id": 302902, "name": "Quantas", "iata": "LH"},
    //                     "airport": {"id": 302822, "name": "Barcelona", "iata": "FCO", "country": "ESP"},
    //                     "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //                     "aircraftType": {"name": "Airbus A319", "iata": "319", "id": 40},
    //                     "date": DateTime.now(),
    //                     "flightNumber": "",
    //                     ownAirportTransfer: false
    //                 },
    //                 {
    //                     "airline": {"id": 302902, "name": "Quantas", "iata": "PO"},
    //                     "airport": {"id": 302822, "name": "Tokyo", "iata": "LE", "country": "ESP"},
    //                     "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //                     "aircraftType": {"name": "Airbus A319", "iata": "319", "id": 40},
    //                     "date": DateTime.now(),
    //                     "flightNumber": "Q91",
    //                     ownAirportTransfer: false
    //                 }
    //             ],
    //             "lastLeg": {"id": 302902, "name": "Berlin", "iata": "HH", "country": "DEU"}
    //         },
    //         "passengerCount": 11,
    //         "returnFlight": false,
    //         "emissions":
    //             [
    //                 {
    //                     index: 0,
    //                     status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //                     emissionKgCO2e: 1000,
    //                     errorCodes: null,
    //                     type: CALCULATION_TYPE.flight,
    //                     errors: null
    //                 },
    //                 {
    //                     index: 1,
    //                     status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //                     emissionKgCO2e: 850,
    //                     errorCodes: null,
    //                     type: CALCULATION_TYPE.flight,
    //                     errors: null
    //                 },
    //                 {
    //                     index: 2,
    //                     status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.failure,
    //                     emissionKgCO2e: null,
    //                     errorCodes: null,
    //                     type: CALCULATION_TYPE.flight,
    //                     errors: null
    //                 },
    //                 {
    //                     index: 3,
    //                     status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.failure,
    //                     emissionKgCO2e: null,
    //                     errorCodes: null,
    //                     type: CALCULATION_TYPE.flight,
    //                     errors: null
    //                 },
    //                 {
    //                     index: 4,
    //                     status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //                     emissionKgCO2e: 2000,
    //                     errorCodes: null,
    //                     type: CALCULATION_TYPE.flight,
    //                     errors: null
    //                 }]
    //     },
    //     {
    //         common: {
    //             id: uuidv4(),
    //             activityType: ACTIVITY_TYPE.flight,
    //             travelOptionType: TRAVEL_OPTION_TYPE.transport,
    //             editIndex: null,
    //             totalEmissions: {
    //                 "status": CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success,
    //                 "emissionKgCO2eTotal": 7267,
    //                 errorCodes: null
    //             }
    //         },
    //         "legs": {
    //             "firstLeg": {
    //                 "airline": {"id": 302902, "name": "Quantas", "iata": "AA"},
    //                 "airport": {"id": 302902, "name": "New York", "iata": "WAS", "country": "US"},
    //                 "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 3},
    //                 "aircraftType": {"name": "Airbus A310-200", "iata": "312", "id": 37},
    //                 "date": DateTime.now(),
    //                 "flightNumber": "Q221",
    //                 ownAirportTransfer: false
    //             },
    //             "stopover": [],
    //             "lastLeg": {"id": 300939, "name": "Helsinki", "iata": "SYD", "country": "FIN"}
    //         },
    //
    //         "passengerCount": 3,
    //         "returnFlight": true,
    //         "emissions": [{
    //             index: 0,
    //             status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //             emissionKgCO2e: 7267,
    //             errorCodes: null,
    //             type: CALCULATION_TYPE.flight,
    //             errors: null
    //         }]
    //     },
    //     {
    //         common: {
    //             id: uuidv4(),
    //             activityType: ACTIVITY_TYPE.flight,
    //             travelOptionType: TRAVEL_OPTION_TYPE.transport,
    //             editIndex: null,
    //             totalEmissions: {
    //                 "status": CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success,
    //                 "emissionKgCO2eTotal": 19092,
    //                 errorCodes: null
    //             }
    //         },
    //         "legs": {
    //             "firstLeg": {
    //                 "airline": null,
    //                 "airport": {"id": 302902, "name": "Moskow", "iata": "WAS", "country": "DEU"},
    //                 "travelClass": null,
    //                 "aircraftType": null,
    //                 "date": DateTime.now(),
    //                 "flightNumber": "",
    //                 ownAirportTransfer: false
    //             },
    //             "stopover": [],
    //             "lastLeg": {"id": 300939, "name": "Berlin", "iata": "STO", "country": "DEU"}
    //         },
    //
    //         "passengerCount": 9,
    //         "returnFlight": true,
    //         "emissions": [{
    //             index: 0,
    //             status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //             emissionKgCO2e: 19092,
    //             errorCodes: null,
    //             type: CALCULATION_TYPE.flight,
    //             errors: null
    //         }]
    //     },
    //     {
    //         common: {
    //             id: uuidv4(),
    //             activityType: ACTIVITY_TYPE.flight,
    //             travelOptionType: TRAVEL_OPTION_TYPE.transport,
    //             editIndex: null,
    //             totalEmissions: {
    //                 "status": CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.failure,
    //                 "emissionKgCO2eTotal": null,
    //                 errorCodes: null
    //             }
    //         },
    //         "legs": {
    //             "firstLeg": {
    //                 "airline": null,
    //                 "airport": {"id": 302902, "name": "Doha", "iata": "MEL", "country": "CAT"},
    //                 "travelClass": null,
    //                 "aircraftType": null,
    //                 "date": DateTime.now(),
    //                 "flightNumber": "Q381",
    //                 ownAirportTransfer: false
    //             },
    //             "stopover": [],
    //             "lastLeg": {"id": 300939, "name": "Sydney", "iata": "SYD", "country": "AUS"}
    //         },
    //
    //         "passengerCount": 5,
    //         "returnFlight": true,
    //         emissions: [{
    //             index: 0,
    //             status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.failure,
    //             emissionKgCO2e: null,
    //             errorCodes: null,
    //             type: CALCULATION_TYPE.flight,
    //             errors: null
    //         }]
    //     },
    //     {
    //         common: {
    //             id: uuidv4(),
    //             activityType: ACTIVITY_TYPE.flight,
    //             travelOptionType: TRAVEL_OPTION_TYPE.transport,
    //             editIndex: null,
    //             totalEmissions: {
    //                 "status": CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success_with_errors,
    //                 "emissionKgCO2eTotal": 6200,
    //                 errorCodes: null
    //             }
    //         },
    //         "legs": {
    //             "firstLeg": {
    //                 "airline": null,
    //                 "airport": {"id": 302822, "name": "Barcelona", "iata": "BCN", "country": "ESP"},
    //                 "travelClass": null,
    //                 "aircraftType": null,
    //                 "date": DateTime.now(),
    //                 "flightNumber": "Q991",
    //                 ownAirportTransfer: false
    //             },
    //             "stopover": [
    //                 {
    //                     "airline": null,
    //                     "airport": {"id": 302822, "name": "Kiev", "iata": "BCN", "country": "ESP"},
    //                     "travelClass": null,
    //                     "aircraftType": null,
    //                     "date": DateTime.now(),
    //                     "flightNumber": "Q13",
    //                     ownAirportTransfer: false
    //                 },
    //                 {
    //                     "airline": {"id": 302902, "name": "Quantas", "iata": "Q"},
    //                     "airport": {"id": 302822, "name": "Roma", "iata": "BCN", "country": "ESP"},
    //                     "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //                     "aircraftType": {"name": "Airbus A319", "iata": "319", "id": 40},
    //                     "date": DateTime.now(),
    //                     "flightNumber": "",
    //                     ownAirportTransfer: false
    //                 },
    //                 {
    //                     "airline": {"id": 302902, "name": "Quantas", "iata": "Q"},
    //                     "airport": {"id": 302822, "name": "Barcelona", "iata": "BCN", "country": "ESP"},
    //                     "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //                     "aircraftType": {"name": "Airbus A319", "iata": "319", "id": 40},
    //                     "date": DateTime.now(),
    //                     "flightNumber": "",
    //                     ownAirportTransfer: false
    //                 },
    //                 {
    //                     "airline": {"id": 302902, "name": "Quantas", "iata": "Q"},
    //                     "airport": {"id": 302822, "name": "Tokyo", "iata": "BCN", "country": "ESP"},
    //                     "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //                     "aircraftType": {"name": "Airbus A319", "iata": "319", "id": 40},
    //                     "date": DateTime.now(),
    //                     "flightNumber": "Q91",
    //                     ownAirportTransfer: false
    //                 }
    //             ],
    //             "lastLeg": {"id": 302902, "name": "Berlin", "iata": "BER", "country": "DEU"}
    //         },
    //         "passengerCount": 11,
    //         "returnFlight": true,
    //         "emissions":
    //             [
    //                 {
    //                     index: 0,
    //                     status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //                     emissionKgCO2e: 2000,
    //                     errorCodes: null,
    //                     type: CALCULATION_TYPE.flight,
    //                     errors: null
    //                 },
    //                 {
    //                     index: 1,
    //                     status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //                     emissionKgCO2e: 1500,
    //                     errorCodes: null,
    //                     type: CALCULATION_TYPE.flight,
    //                     errors: null
    //                 },
    //                 {
    //                     index: 2,
    //                     status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.failure,
    //                     emissionKgCO2e: null,
    //                     errorCodes: null,
    //                     type: CALCULATION_TYPE.flight,
    //                     errors: null
    //                 },
    //                 {
    //                     index: 3,
    //                     status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.failure,
    //                     emissionKgCO2e: null,
    //                     errorCodes: null,
    //                     type: CALCULATION_TYPE.flight,
    //                     errors: null
    //                 },
    //                 {
    //                     index: 4,
    //                     status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //                     emissionKgCO2e: 2700,
    //                     errorCodes: null,
    //                     type: CALCULATION_TYPE.flight,
    //                     errors: null
    //                 }]
    //     },
    //     {
    //         common: {
    //             id: uuidv4(),
    //             activityType: ACTIVITY_TYPE.flight,
    //             travelOptionType: TRAVEL_OPTION_TYPE.transport,
    //             editIndex: null,
    //             totalEmissions: {
    //                 "status": CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success,
    //                 "emissionKgCO2eTotal": 1274,
    //                 errorCodes: null
    //             }
    //         },
    //         "legs": {
    //             "firstLeg": {
    //                 "airline": {"id": 302902, "name": "Quantas", "iata": "Q"},
    //                 "airport": {"id": 302902, "name": "Detroit", "iata": "WAS", "country": "US"},
    //                 "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 3},
    //                 "aircraftType": {"name": "Airbus A310-200", "iata": "312", "id": 37},
    //                 "date": DateTime.now(),
    //                 "flightNumber": "Q221",
    //                 ownAirportTransfer: false,
    //             },
    //             "stopover": [],
    //             "lastLeg": {"id": 300939, "name": "Washington", "iata": "STO", "country": "FIN"}
    //         },
    //
    //         "passengerCount": 1,
    //         "returnFlight": true,
    //         "emissions": [{
    //             index: 0,
    //             status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //             emissionKgCO2e: 1274,
    //             errorCodes: null,
    //             type: CALCULATION_TYPE.flight,
    //             errors: null
    //         }]
    //     },
    //     {
    //         common: {
    //             id: uuidv4(),
    //             activityType: ACTIVITY_TYPE.flight,
    //             travelOptionType: TRAVEL_OPTION_TYPE.transport,
    //             editIndex: null,
    //             totalEmissions: {
    //                 "status": CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success,
    //                 "emissionKgCO2eTotal": 8821,
    //                 errorCodes: null
    //             }
    //         },
    //         "legs": {
    //             "firstLeg": {
    //                 "airline": {"id": 302902, "name": "Quantas", "iata": "Q"},
    //                 "airport": {"id": 302902, "name": "Moskow", "iata": "BER", "country": "DEU"},
    //                 "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //                 "aircraftType": {"name": "Airbus A310-200", "iata": "312", "id": 37},
    //                 "date": DateTime.now(),
    //                 "flightNumber": "",
    //                 ownAirportTransfer: false
    //             },
    //             "stopover": [],
    //             "lastLeg": {"id": 300939, "name": "Berlin", "iata": "BER", "country": "DEU"}
    //         },
    //
    //         "passengerCount": 5,
    //         "returnFlight": true,
    //         "emissions": [{
    //             index: 0,
    //             status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //             emissionKgCO2e: 8821,
    //             errorCodes: null,
    //             type: CALCULATION_TYPE.flight,
    //             errors: null
    //         }]
    //     },
    //     {
    //         common: {
    //             id: uuidv4(),
    //             activityType: ACTIVITY_TYPE.flight,
    //             travelOptionType: TRAVEL_OPTION_TYPE.transport,
    //             editIndex: null,
    //             totalEmissions: {
    //                 "status": CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success,
    //                 "emissionKgCO2eTotal": 4571,
    //                 errorCodes: null
    //             }
    //         },
    //         "legs": {
    //             "firstLeg": {
    //                 "airline": null,
    //                 "airport": {"id": 302902, "name": "Moskow", "iata": "BER", "country": "DEU"},
    //                 "travelClass": null,
    //                 "aircraftType": null,
    //                 "date": DateTime.now(),
    //                 "flightNumber": "",
    //                 ownAirportTransfer: false
    //             },
    //             "stopover": [],
    //             "lastLeg": {"id": 300939, "name": "Berlin", "iata": "SYD", "country": "DEU"}
    //         },
    //
    //         "passengerCount": 5,
    //         "returnFlight": true,
    //         "emissions": [{
    //             index: 0,
    //             status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //             emissionKgCO2e: 4571,
    //             errorCodes: null,
    //             type: CALCULATION_TYPE.flight,
    //             errors: null
    //         }]
    //     },
    //     {
    //         common: {
    //             id: uuidv4(),
    //             activityType: ACTIVITY_TYPE.flight,
    //             travelOptionType: TRAVEL_OPTION_TYPE.transport,
    //             editIndex: null,
    //             totalEmissions: {
    //                 "status": CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.failure,
    //                 "emissionKgCO2eTotal": null,
    //                 errorCodes: null
    //             }
    //         },
    //         "legs": {
    //             "firstLeg": {
    //                 "airline": {"id": 302902, "name": "Quantas", "iata": "RY"},
    //                 "airport": {"id": 302902, "name": "Doha", "iata": "DHO", "country": "CAT"},
    //                 "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //                 "aircraftType": {"name": "Airbus A310-200", "iata": "312", "id": 37},
    //                 "date": DateTime.now(),
    //                 "flightNumber": "Q381",
    //                 ownAirportTransfer: false
    //             },
    //             "stopover": [],
    //             "lastLeg": {"id": 300939, "name": "Sydney", "iata": "TYK", "country": "AUS"}
    //         },
    //
    //         "passengerCount": 5,
    //         "returnFlight": true,
    //         emissions: [{
    //             index: 0,
    //             status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.failure,
    //             emissionKgCO2e: null,
    //             errorCodes: null,
    //             type: CALCULATION_TYPE.flight,
    //             errors: null
    //         }]
    //     },
    //     {
    //         common: {
    //             id: uuidv4(),
    //             activityType: ACTIVITY_TYPE.flight,
    //             travelOptionType: TRAVEL_OPTION_TYPE.transport,
    //             editIndex: null,
    //             totalEmissions: {
    //                 "status": CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success_with_errors,
    //                 "emissionKgCO2eTotal": 3850,
    //                 errorCodes: null
    //             }
    //         },
    //         "legs": {
    //             "firstLeg": {
    //                 "airline": {"id": 302902, "name": "Quantas", "iata": "AI"},
    //                 "airport": {"id": 302822, "name": "Barcelona", "iata": "BCN", "country": "ESP"},
    //                 "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //                 "aircraftType": {"name": "Airbus A319", "iata": "319", "id": 40},
    //                 "date": DateTime.now(),
    //                 "flightNumber": "Q991",
    //                 ownAirportTransfer: false
    //             },
    //             "stopover": [
    //                 {
    //                     "airline": {"id": 302902, "name": "Quantas", "iata": "AI"},
    //                     "airport": {"id": 302822, "name": "Kiev", "iata": "BCN", "country": "ESP"},
    //                     "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //                     "aircraftType": {"name": "Airbus A319", "iata": "319", "id": 40},
    //                     "date": DateTime.now(),
    //                     "flightNumber": "Q13",
    //                     ownAirportTransfer: false
    //                 },
    //                 {
    //                     "airline": {"id": 302902, "name": "Quantas", "iata": "Q"},
    //                     "airport": {"id": 302822, "name": "Roma", "iata": "AMM", "country": "ESP"},
    //                     "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //                     "aircraftType": {"name": "Airbus A319", "iata": "319", "id": 40},
    //                     "date": DateTime.now(),
    //                     "flightNumber": "",
    //                     ownAirportTransfer: false
    //                 },
    //                 {
    //                     "airline": {"id": 302902, "name": "Quantas", "iata": "LH"},
    //                     "airport": {"id": 302822, "name": "Barcelona", "iata": "FCO", "country": "ESP"},
    //                     "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //                     "aircraftType": {"name": "Airbus A319", "iata": "319", "id": 40},
    //                     "date": DateTime.now(),
    //                     "flightNumber": "",
    //                     ownAirportTransfer: false
    //                 },
    //                 {
    //                     "airline": {"id": 302902, "name": "Quantas", "iata": "PO"},
    //                     "airport": {"id": 302822, "name": "Tokyo", "iata": "LE", "country": "ESP"},
    //                     "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //                     "aircraftType": {"name": "Airbus A319", "iata": "319", "id": 40},
    //                     "date": DateTime.now(),
    //                     "flightNumber": "Q91",
    //                     ownAirportTransfer: false
    //                 }
    //             ],
    //             "lastLeg": {"id": 302902, "name": "Berlin", "iata": "HH", "country": "DEU"}
    //         },
    //         "passengerCount": 11,
    //         "returnFlight": false,
    //         "emissions":
    //             [
    //                 {
    //                     index: 0,
    //                     status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //                     emissionKgCO2e: 1000,
    //                     errorCodes: null,
    //                     type: CALCULATION_TYPE.flight,
    //                     errors: null
    //                 },
    //                 {
    //                     index: 1,
    //                     status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //                     emissionKgCO2e: 850,
    //                     errorCodes: null,
    //                     type: CALCULATION_TYPE.flight,
    //                     errors: null
    //                 },
    //                 {
    //                     index: 2,
    //                     status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.failure,
    //                     emissionKgCO2e: null,
    //                     errorCodes: null,
    //                     type: CALCULATION_TYPE.flight,
    //                     errors: null
    //                 },
    //                 {
    //                     index: 3,
    //                     status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.failure,
    //                     emissionKgCO2e: null,
    //                     errorCodes: null,
    //                     type: CALCULATION_TYPE.flight,
    //                     errors: null
    //                 },
    //                 {
    //                     index: 4,
    //                     status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //                     emissionKgCO2e: 2000,
    //                     errorCodes: null,
    //                     type: CALCULATION_TYPE.flight,
    //                     errors: null
    //                 }]
    //     },
    //     {
    //         common: {
    //             id: uuidv4(),
    //             activityType: ACTIVITY_TYPE.flight,
    //             travelOptionType: TRAVEL_OPTION_TYPE.transport,
    //             editIndex: null,
    //             totalEmissions: {
    //                 "status": CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success,
    //                 "emissionKgCO2eTotal": 7267,
    //                 errorCodes: null
    //             }
    //         },
    //         "legs": {
    //             "firstLeg": {
    //                 "airline": {"id": 302902, "name": "Quantas", "iata": "AA"},
    //                 "airport": {"id": 302902, "name": "New York", "iata": "WAS", "country": "US"},
    //                 "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 3},
    //                 "aircraftType": {"name": "Airbus A310-200", "iata": "312", "id": 37},
    //                 "date": DateTime.now(),
    //                 "flightNumber": "Q221",
    //                 ownAirportTransfer: false
    //             },
    //             "stopover": [],
    //             "lastLeg": {"id": 300939, "name": "Helsinki", "iata": "SYD", "country": "FIN"}
    //         },
    //
    //         "passengerCount": 3,
    //         "returnFlight": true,
    //         "emissions": [{
    //             index: 0,
    //             status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //             emissionKgCO2e: 7267,
    //             errorCodes: null,
    //             type: CALCULATION_TYPE.flight,
    //             errors: null
    //         }]
    //     },
    //     {
    //         common: {
    //             id: uuidv4(),
    //             activityType: ACTIVITY_TYPE.flight,
    //             travelOptionType: TRAVEL_OPTION_TYPE.transport,
    //             editIndex: null,
    //             totalEmissions: {
    //                 "status": CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success,
    //                 "emissionKgCO2eTotal": 19092,
    //                 errorCodes: null
    //             }
    //         },
    //         "legs": {
    //             "firstLeg": {
    //                 "airline": null,
    //                 "airport": {"id": 302902, "name": "Moskow", "iata": "WAS", "country": "DEU"},
    //                 "travelClass": null,
    //                 "aircraftType": null,
    //                 "date": DateTime.now(),
    //                 "flightNumber": "",
    //                 ownAirportTransfer: false
    //             },
    //             "stopover": [],
    //             "lastLeg": {"id": 300939, "name": "Berlin", "iata": "STO", "country": "DEU"}
    //         },
    //
    //         "passengerCount": 9,
    //         "returnFlight": true,
    //         "emissions": [{
    //             index: 0,
    //             status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //             emissionKgCO2e: 19092,
    //             errorCodes: null,
    //             type: CALCULATION_TYPE.flight,
    //             errors: null
    //         }]
    //     },
    //     {
    //         common: {
    //             id: uuidv4(),
    //             activityType: ACTIVITY_TYPE.flight,
    //             travelOptionType: TRAVEL_OPTION_TYPE.transport,
    //             editIndex: null,
    //             totalEmissions: {
    //                 "status": CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.failure,
    //                 "emissionKgCO2eTotal": null,
    //                 errorCodes: null
    //             }
    //         },
    //         "legs": {
    //             "firstLeg": {
    //                 "airline": null,
    //                 "airport": {"id": 302902, "name": "Doha", "iata": "MEL", "country": "CAT"},
    //                 "travelClass": null,
    //                 "aircraftType": null,
    //                 "date": DateTime.now(),
    //                 "flightNumber": "Q381",
    //                 ownAirportTransfer: false
    //             },
    //             "stopover": [],
    //             "lastLeg": {"id": 300939, "name": "Sydney", "iata": "SYD", "country": "AUS"}
    //         },
    //
    //         "passengerCount": 5,
    //         "returnFlight": true,
    //         emissions: [{
    //             index: 0,
    //             status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.failure,
    //             emissionKgCO2e: null,
    //             errorCodes: null,
    //             type: CALCULATION_TYPE.flight,
    //             errors: null
    //         }]
    //     },
    //     {
    //         common: {
    //             id: uuidv4(),
    //             activityType: ACTIVITY_TYPE.flight,
    //             travelOptionType: TRAVEL_OPTION_TYPE.transport,
    //             editIndex: null,
    //             totalEmissions: {
    //                 "status": CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success_with_errors,
    //                 "emissionKgCO2eTotal": 6200,
    //                 errorCodes: null
    //             }
    //         },
    //         "legs": {
    //             "firstLeg": {
    //                 "airline": null,
    //                 "airport": {"id": 302822, "name": "Barcelona", "iata": "BCN", "country": "ESP"},
    //                 "travelClass": null,
    //                 "aircraftType": null,
    //                 "date": DateTime.now(),
    //                 "flightNumber": "Q991",
    //                 ownAirportTransfer: false
    //             },
    //             "stopover": [
    //                 {
    //                     "airline": null,
    //                     "airport": {"id": 302822, "name": "Kiev", "iata": "BCN", "country": "ESP"},
    //                     "travelClass": null,
    //                     "aircraftType": null,
    //                     "date": DateTime.now(),
    //                     "flightNumber": "Q13",
    //                     ownAirportTransfer: false
    //                 },
    //                 {
    //                     "airline": {"id": 302902, "name": "Quantas", "iata": "Q"},
    //                     "airport": {"id": 302822, "name": "Roma", "iata": "BCN", "country": "ESP"},
    //                     "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //                     "aircraftType": {"name": "Airbus A319", "iata": "319", "id": 40},
    //                     "date": DateTime.now(),
    //                     "flightNumber": "",
    //                     ownAirportTransfer: false
    //                 },
    //                 {
    //                     "airline": {"id": 302902, "name": "Quantas", "iata": "Q"},
    //                     "airport": {"id": 302822, "name": "Barcelona", "iata": "BCN", "country": "ESP"},
    //                     "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //                     "aircraftType": {"name": "Airbus A319", "iata": "319", "id": 40},
    //                     "date": DateTime.now(),
    //                     "flightNumber": "",
    //                     ownAirportTransfer: false
    //                 },
    //                 {
    //                     "airline": {"id": 302902, "name": "Quantas", "iata": "Q"},
    //                     "airport": {"id": 302822, "name": "Tokyo", "iata": "BCN", "country": "ESP"},
    //                     "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //                     "aircraftType": {"name": "Airbus A319", "iata": "319", "id": 40},
    //                     "date": DateTime.now(),
    //                     "flightNumber": "Q91",
    //                     ownAirportTransfer: false
    //                 }
    //             ],
    //             "lastLeg": {"id": 302902, "name": "Berlin", "iata": "BER", "country": "DEU"}
    //         },
    //         "passengerCount": 11,
    //         "returnFlight": true,
    //         "emissions":
    //             [
    //                 {
    //                     index: 0,
    //                     status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //                     emissionKgCO2e: 2000,
    //                     errorCodes: null,
    //                     type: CALCULATION_TYPE.flight,
    //                     errors: null
    //                 },
    //                 {
    //                     index: 1,
    //                     status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //                     emissionKgCO2e: 1500,
    //                     errorCodes: null,
    //                     type: CALCULATION_TYPE.flight,
    //                     errors: null
    //                 },
    //                 {
    //                     index: 2,
    //                     status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.failure,
    //                     emissionKgCO2e: null,
    //                     errorCodes: null,
    //                     type: CALCULATION_TYPE.flight,
    //                     errors: null
    //                 },
    //                 {
    //                     index: 3,
    //                     status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.failure,
    //                     emissionKgCO2e: null,
    //                     errorCodes: null,
    //                     type: CALCULATION_TYPE.flight,
    //                     errors: null
    //                 },
    //                 {
    //                     index: 4,
    //                     status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //                     emissionKgCO2e: 2700,
    //                     errorCodes: null,
    //                     type: CALCULATION_TYPE.flight,
    //                     errors: null
    //                 }]
    //     },
    //     {
    //         common: {
    //             id: uuidv4(),
    //             activityType: ACTIVITY_TYPE.flight,
    //             travelOptionType: TRAVEL_OPTION_TYPE.transport,
    //             editIndex: null,
    //             totalEmissions: {
    //                 "status": CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success,
    //                 "emissionKgCO2eTotal": 1274,
    //                 errorCodes: null
    //             }
    //         },
    //         "legs": {
    //             "firstLeg": {
    //                 "airline": {"id": 302902, "name": "Quantas", "iata": "Q"},
    //                 "airport": {"id": 302902, "name": "Detroit", "iata": "WAS", "country": "US"},
    //                 "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 3},
    //                 "aircraftType": {"name": "Airbus A310-200", "iata": "312", "id": 37},
    //                 "date": DateTime.now(),
    //                 "flightNumber": "Q221",
    //                 ownAirportTransfer: false,
    //             },
    //             "stopover": [],
    //             "lastLeg": {"id": 300939, "name": "Washington", "iata": "STO", "country": "FIN"}
    //         },
    //
    //         "passengerCount": 1,
    //         "returnFlight": true,
    //         "emissions": [{
    //             index: 0,
    //             status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //             emissionKgCO2e: 1274,
    //             errorCodes: null,
    //             type: CALCULATION_TYPE.flight,
    //             errors: null
    //         }]
    //     },
    //     {
    //         common: {
    //             id: uuidv4(),
    //             activityType: ACTIVITY_TYPE.flight,
    //             travelOptionType: TRAVEL_OPTION_TYPE.transport,
    //             editIndex: null,
    //             totalEmissions: {
    //                 "status": CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success,
    //                 "emissionKgCO2eTotal": 8821,
    //                 errorCodes: null
    //             }
    //         },
    //         "legs": {
    //             "firstLeg": {
    //                 "airline": {"id": 302902, "name": "Quantas", "iata": "Q"},
    //                 "airport": {"id": 302902, "name": "Moskow", "iata": "BER", "country": "DEU"},
    //                 "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //                 "aircraftType": {"name": "Airbus A310-200", "iata": "312", "id": 37},
    //                 "date": DateTime.now(),
    //                 "flightNumber": "",
    //                 ownAirportTransfer: false
    //             },
    //             "stopover": [],
    //             "lastLeg": {"id": 300939, "name": "Berlin", "iata": "BER", "country": "DEU"}
    //         },
    //
    //         "passengerCount": 5,
    //         "returnFlight": true,
    //         "emissions": [{
    //             index: 0,
    //             status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //             emissionKgCO2e: 8821,
    //             errorCodes: null,
    //             type: CALCULATION_TYPE.flight,
    //             errors: null
    //         }]
    //     },
    //     {
    //         common: {
    //             id: uuidv4(),
    //             activityType: ACTIVITY_TYPE.flight,
    //             travelOptionType: TRAVEL_OPTION_TYPE.transport,
    //             editIndex: null,
    //             totalEmissions: {
    //                 "status": CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success,
    //                 "emissionKgCO2eTotal": 4571,
    //                 errorCodes: null
    //             }
    //         },
    //         "legs": {
    //             "firstLeg": {
    //                 "airline": null,
    //                 "airport": {"id": 302902, "name": "Moskow", "iata": "BER", "country": "DEU"},
    //                 "travelClass": null,
    //                 "aircraftType": null,
    //                 "date": DateTime.now(),
    //                 "flightNumber": "",
    //                 ownAirportTransfer: false
    //             },
    //             "stopover": [],
    //             "lastLeg": {"id": 300939, "name": "Berlin", "iata": "SYD", "country": "DEU"}
    //         },
    //
    //         "passengerCount": 5,
    //         "returnFlight": true,
    //         "emissions": [{
    //             index: 0,
    //             status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //             emissionKgCO2e: 4571,
    //             errorCodes: null,
    //             type: CALCULATION_TYPE.flight,
    //             errors: null
    //         }]
    //     },
    //     {
    //         common: {
    //             id: uuidv4(),
    //             activityType: ACTIVITY_TYPE.flight,
    //             travelOptionType: TRAVEL_OPTION_TYPE.transport,
    //             editIndex: null,
    //             totalEmissions: {
    //                 "status": CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.failure,
    //                 "emissionKgCO2eTotal": null,
    //                 errorCodes: null
    //             }
    //         },
    //         "legs": {
    //             "firstLeg": {
    //                 "airline": {"id": 302902, "name": "Quantas", "iata": "RY"},
    //                 "airport": {"id": 302902, "name": "Doha", "iata": "DHO", "country": "CAT"},
    //                 "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //                 "aircraftType": {"name": "Airbus A310-200", "iata": "312", "id": 37},
    //                 "date": DateTime.now(),
    //                 "flightNumber": "Q381",
    //                 ownAirportTransfer: false
    //             },
    //             "stopover": [],
    //             "lastLeg": {"id": 300939, "name": "Sydney", "iata": "TYK", "country": "AUS"}
    //         },
    //
    //         "passengerCount": 5,
    //         "returnFlight": true,
    //         emissions: [{
    //             index: 0,
    //             status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.failure,
    //             emissionKgCO2e: null,
    //             errorCodes: null,
    //             type: CALCULATION_TYPE.flight,
    //             errors: null
    //         }]
    //     },
    //     {
    //         common: {
    //             id: uuidv4(),
    //             activityType: ACTIVITY_TYPE.flight,
    //             travelOptionType: TRAVEL_OPTION_TYPE.transport,
    //             editIndex: null,
    //             totalEmissions: {
    //                 "status": CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success_with_errors,
    //                 "emissionKgCO2eTotal": 3850,
    //                 errorCodes: null
    //             }
    //         },
    //         "legs": {
    //             "firstLeg": {
    //                 "airline": {"id": 302902, "name": "Quantas", "iata": "AI"},
    //                 "airport": {"id": 302822, "name": "Barcelona", "iata": "BCN", "country": "ESP"},
    //                 "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //                 "aircraftType": {"name": "Airbus A319", "iata": "319", "id": 40},
    //                 "date": DateTime.now(),
    //                 "flightNumber": "Q991",
    //                 ownAirportTransfer: false
    //             },
    //             "stopover": [
    //                 {
    //                     "airline": {"id": 302902, "name": "Quantas", "iata": "AI"},
    //                     "airport": {"id": 302822, "name": "Kiev", "iata": "BCN", "country": "ESP"},
    //                     "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //                     "aircraftType": {"name": "Airbus A319", "iata": "319", "id": 40},
    //                     "date": DateTime.now(),
    //                     "flightNumber": "Q13",
    //                     ownAirportTransfer: false
    //                 },
    //                 {
    //                     "airline": {"id": 302902, "name": "Quantas", "iata": "Q"},
    //                     "airport": {"id": 302822, "name": "Roma", "iata": "AMM", "country": "ESP"},
    //                     "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //                     "aircraftType": {"name": "Airbus A319", "iata": "319", "id": 40},
    //                     "date": DateTime.now(),
    //                     "flightNumber": "",
    //                     ownAirportTransfer: false
    //                 },
    //                 {
    //                     "airline": {"id": 302902, "name": "Quantas", "iata": "LH"},
    //                     "airport": {"id": 302822, "name": "Barcelona", "iata": "FCO", "country": "ESP"},
    //                     "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //                     "aircraftType": {"name": "Airbus A319", "iata": "319", "id": 40},
    //                     "date": DateTime.now(),
    //                     "flightNumber": "",
    //                     ownAirportTransfer: false
    //                 },
    //                 {
    //                     "airline": {"id": 302902, "name": "Quantas", "iata": "PO"},
    //                     "airport": {"id": 302822, "name": "Tokyo", "iata": "LE", "country": "ESP"},
    //                     "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //                     "aircraftType": {"name": "Airbus A319", "iata": "319", "id": 40},
    //                     "date": DateTime.now(),
    //                     "flightNumber": "Q91",
    //                     ownAirportTransfer: false
    //                 }
    //             ],
    //             "lastLeg": {"id": 302902, "name": "Berlin", "iata": "HH", "country": "DEU"}
    //         },
    //         "passengerCount": 11,
    //         "returnFlight": false,
    //         "emissions":
    //             [
    //                 {
    //                     index: 0,
    //                     status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //                     emissionKgCO2e: 1000,
    //                     errorCodes: null,
    //                     type: CALCULATION_TYPE.flight,
    //                     errors: null
    //                 },
    //                 {
    //                     index: 1,
    //                     status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //                     emissionKgCO2e: 850,
    //                     errorCodes: null,
    //                     type: CALCULATION_TYPE.flight,
    //                     errors: null
    //                 },
    //                 {
    //                     index: 2,
    //                     status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.failure,
    //                     emissionKgCO2e: null,
    //                     errorCodes: null,
    //                     type: CALCULATION_TYPE.flight,
    //                     errors: null
    //                 },
    //                 {
    //                     index: 3,
    //                     status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.failure,
    //                     emissionKgCO2e: null,
    //                     errorCodes: null,
    //                     type: CALCULATION_TYPE.flight,
    //                     errors: null
    //                 },
    //                 {
    //                     index: 4,
    //                     status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //                     emissionKgCO2e: 2000,
    //                     errorCodes: null,
    //                     type: CALCULATION_TYPE.flight,
    //                     errors: null
    //                 }]
    //     },
    //     {
    //         common: {
    //             id: uuidv4(),
    //             activityType: ACTIVITY_TYPE.flight,
    //             travelOptionType: TRAVEL_OPTION_TYPE.transport,
    //             editIndex: null,
    //             totalEmissions: {
    //                 "status": CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success,
    //                 "emissionKgCO2eTotal": 7267,
    //                 errorCodes: null
    //             }
    //         },
    //         "legs": {
    //             "firstLeg": {
    //                 "airline": {"id": 302902, "name": "Quantas", "iata": "AA"},
    //                 "airport": {"id": 302902, "name": "New York", "iata": "WAS", "country": "US"},
    //                 "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 3},
    //                 "aircraftType": {"name": "Airbus A310-200", "iata": "312", "id": 37},
    //                 "date": DateTime.now(),
    //                 "flightNumber": "Q221",
    //                 ownAirportTransfer: false
    //             },
    //             "stopover": [],
    //             "lastLeg": {"id": 300939, "name": "Helsinki", "iata": "SYD", "country": "FIN"}
    //         },
    //
    //         "passengerCount": 3,
    //         "returnFlight": true,
    //         "emissions": [{
    //             index: 0,
    //             status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //             emissionKgCO2e: 7267,
    //             errorCodes: null,
    //             type: CALCULATION_TYPE.flight,
    //             errors: null
    //         }]
    //     },
    //     {
    //         common: {
    //             id: uuidv4(),
    //             activityType: ACTIVITY_TYPE.flight,
    //             travelOptionType: TRAVEL_OPTION_TYPE.transport,
    //             editIndex: null,
    //             totalEmissions: {
    //                 "status": CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success,
    //                 "emissionKgCO2eTotal": 19092,
    //                 errorCodes: null
    //             }
    //         },
    //         "legs": {
    //             "firstLeg": {
    //                 "airline": null,
    //                 "airport": {"id": 302902, "name": "Moskow", "iata": "WAS", "country": "DEU"},
    //                 "travelClass": null,
    //                 "aircraftType": null,
    //                 "date": DateTime.now(),
    //                 "flightNumber": "",
    //                 ownAirportTransfer: false
    //             },
    //             "stopover": [],
    //             "lastLeg": {"id": 300939, "name": "Berlin", "iata": "STO", "country": "DEU"}
    //         },
    //
    //         "passengerCount": 9,
    //         "returnFlight": true,
    //         "emissions": [{
    //             index: 0,
    //             status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //             emissionKgCO2e: 19092,
    //             errorCodes: null,
    //             type: CALCULATION_TYPE.flight,
    //             errors: null
    //         }]
    //     },
    //     {
    //         common: {
    //             id: uuidv4(),
    //             activityType: ACTIVITY_TYPE.flight,
    //             travelOptionType: TRAVEL_OPTION_TYPE.transport,
    //             editIndex: null,
    //             totalEmissions: {
    //                 "status": CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.failure,
    //                 "emissionKgCO2eTotal": null,
    //                 errorCodes: null
    //             }
    //         },
    //         "legs": {
    //             "firstLeg": {
    //                 "airline": null,
    //                 "airport": {"id": 302902, "name": "Doha", "iata": "MEL", "country": "CAT"},
    //                 "travelClass": null,
    //                 "aircraftType": null,
    //                 "date": DateTime.now(),
    //                 "flightNumber": "Q381",
    //                 ownAirportTransfer: false
    //             },
    //             "stopover": [],
    //             "lastLeg": {"id": 300939, "name": "Sydney", "iata": "SYD", "country": "AUS"}
    //         },
    //
    //         "passengerCount": 5,
    //         "returnFlight": true,
    //         emissions: [{
    //             index: 0,
    //             status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.failure,
    //             emissionKgCO2e: null,
    //             errorCodes: null,
    //             type: CALCULATION_TYPE.flight,
    //             errors: null
    //         }]
    //     },
    //     {
    //         common: {
    //             id: uuidv4(),
    //             activityType: ACTIVITY_TYPE.flight,
    //             travelOptionType: TRAVEL_OPTION_TYPE.transport,
    //             editIndex: null,
    //             totalEmissions: {
    //                 "status": CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success_with_errors,
    //                 "emissionKgCO2eTotal": 6200,
    //                 errorCodes: null
    //             }
    //         },
    //         "legs": {
    //             "firstLeg": {
    //                 "airline": null,
    //                 "airport": {"id": 302822, "name": "Barcelona", "iata": "BCN", "country": "ESP"},
    //                 "travelClass": null,
    //                 "aircraftType": null,
    //                 "date": DateTime.now(),
    //                 "flightNumber": "Q991",
    //                 ownAirportTransfer: false
    //             },
    //             "stopover": [
    //                 {
    //                     "airline": null,
    //                     "airport": {"id": 302822, "name": "Kiev", "iata": "BCN", "country": "ESP"},
    //                     "travelClass": null,
    //                     "aircraftType": null,
    //                     "date": DateTime.now(),
    //                     "flightNumber": "Q13",
    //                     ownAirportTransfer: false
    //                 },
    //                 {
    //                     "airline": {"id": 302902, "name": "Quantas", "iata": "Q"},
    //                     "airport": {"id": 302822, "name": "Roma", "iata": "BCN", "country": "ESP"},
    //                     "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //                     "aircraftType": {"name": "Airbus A319", "iata": "319", "id": 40},
    //                     "date": DateTime.now(),
    //                     "flightNumber": "",
    //                     ownAirportTransfer: false
    //                 },
    //                 {
    //                     "airline": {"id": 302902, "name": "Quantas", "iata": "Q"},
    //                     "airport": {"id": 302822, "name": "Barcelona", "iata": "BCN", "country": "ESP"},
    //                     "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //                     "aircraftType": {"name": "Airbus A319", "iata": "319", "id": 40},
    //                     "date": DateTime.now(),
    //                     "flightNumber": "",
    //                     ownAirportTransfer: false
    //                 },
    //                 {
    //                     "airline": {"id": 302902, "name": "Quantas", "iata": "Q"},
    //                     "airport": {"id": 302822, "name": "Tokyo", "iata": "BCN", "country": "ESP"},
    //                     "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //                     "aircraftType": {"name": "Airbus A319", "iata": "319", "id": 40},
    //                     "date": DateTime.now(),
    //                     "flightNumber": "Q91",
    //                     ownAirportTransfer: false
    //                 }
    //             ],
    //             "lastLeg": {"id": 302902, "name": "Berlin", "iata": "BER", "country": "DEU"}
    //         },
    //         "passengerCount": 11,
    //         "returnFlight": true,
    //         "emissions":
    //             [
    //                 {
    //                     index: 0,
    //                     status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //                     emissionKgCO2e: 2000,
    //                     errorCodes: null,
    //                     type: CALCULATION_TYPE.flight,
    //                     errors: null
    //                 },
    //                 {
    //                     index: 1,
    //                     status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //                     emissionKgCO2e: 1500,
    //                     errorCodes: null,
    //                     type: CALCULATION_TYPE.flight,
    //                     errors: null
    //                 },
    //                 {
    //                     index: 2,
    //                     status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.failure,
    //                     emissionKgCO2e: null,
    //                     errorCodes: null,
    //                     type: CALCULATION_TYPE.flight,
    //                     errors: null
    //                 },
    //                 {
    //                     index: 3,
    //                     status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.failure,
    //                     emissionKgCO2e: null,
    //                     errorCodes: null,
    //                     type: CALCULATION_TYPE.flight,
    //                     errors: null
    //                 },
    //                 {
    //                     index: 4,
    //                     status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //                     emissionKgCO2e: 2700,
    //                     errorCodes: null,
    //                     type: CALCULATION_TYPE.flight,
    //                     errors: null
    //                 }]
    //     },
    //     {
    //         common: {
    //             id: uuidv4(),
    //             activityType: ACTIVITY_TYPE.flight,
    //             travelOptionType: TRAVEL_OPTION_TYPE.transport,
    //             editIndex: null,
    //             totalEmissions: {
    //                 "status": CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success,
    //                 "emissionKgCO2eTotal": 1274,
    //                 errorCodes: null
    //             }
    //         },
    //         "legs": {
    //             "firstLeg": {
    //                 "airline": {"id": 302902, "name": "Quantas", "iata": "Q"},
    //                 "airport": {"id": 302902, "name": "Detroit", "iata": "WAS", "country": "US"},
    //                 "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 3},
    //                 "aircraftType": {"name": "Airbus A310-200", "iata": "312", "id": 37},
    //                 "date": DateTime.now(),
    //                 "flightNumber": "Q221",
    //                 ownAirportTransfer: false,
    //             },
    //             "stopover": [],
    //             "lastLeg": {"id": 300939, "name": "Washington", "iata": "STO", "country": "FIN"}
    //         },
    //
    //         "passengerCount": 1,
    //         "returnFlight": true,
    //         "emissions": [{
    //             index: 0,
    //             status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //             emissionKgCO2e: 1274,
    //             errorCodes: null,
    //             type: CALCULATION_TYPE.flight,
    //             errors: null
    //         }]
    //     },
    //     {
    //         common: {
    //             id: uuidv4(),
    //             activityType: ACTIVITY_TYPE.flight,
    //             travelOptionType: TRAVEL_OPTION_TYPE.transport,
    //             editIndex: null,
    //             totalEmissions: {
    //                 "status": CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success,
    //                 "emissionKgCO2eTotal": 8821,
    //                 errorCodes: null
    //             }
    //         },
    //         "legs": {
    //             "firstLeg": {
    //                 "airline": {"id": 302902, "name": "Quantas", "iata": "Q"},
    //                 "airport": {"id": 302902, "name": "Moskow", "iata": "BER", "country": "DEU"},
    //                 "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //                 "aircraftType": {"name": "Airbus A310-200", "iata": "312", "id": 37},
    //                 "date": DateTime.now(),
    //                 "flightNumber": "",
    //                 ownAirportTransfer: false
    //             },
    //             "stopover": [],
    //             "lastLeg": {"id": 300939, "name": "Berlin", "iata": "BER", "country": "DEU"}
    //         },
    //
    //         "passengerCount": 5,
    //         "returnFlight": true,
    //         "emissions": [{
    //             index: 0,
    //             status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //             emissionKgCO2e: 8821,
    //             errorCodes: null,
    //             type: CALCULATION_TYPE.flight,
    //             errors: null
    //         }]
    //     },
    //     {
    //         common: {
    //             id: uuidv4(),
    //             activityType: ACTIVITY_TYPE.flight,
    //             travelOptionType: TRAVEL_OPTION_TYPE.transport,
    //             editIndex: null,
    //             totalEmissions: {
    //                 "status": CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success,
    //                 "emissionKgCO2eTotal": 7263,
    //                 errorCodes: null
    //             }
    //         },
    //         "legs": {
    //             "firstLeg": {
    //                 "airline": {"id": 302902, "name": "Quantas", "iata": "Q"},
    //                 "airport": {"id": 302902, "name": "Doha", "iata": "WAS", "country": "CAT"},
    //                 "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //                 "aircraftType": {"name": "Airbus A310-200", "iata": "312", "id": 37},
    //                 "date": DateTime.now(),
    //                 "flightNumber": "Q381",
    //                 ownAirportTransfer: false
    //             },
    //             "stopover": [],
    //             "lastLeg": {"id": 300939, "name": "Sydney", "iata": "SYD", "country": "AUS"}
    //         },
    //
    //         "passengerCount": 5,
    //         "returnFlight": true,
    //         emissions: [{
    //             index: 0,
    //             status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //             emissionKgCO2e: 7263,
    //             errorCodes: null,
    //             type: CALCULATION_TYPE.flight,
    //             errors: null
    //         }]
    //     },
    //     {
    //         common: {
    //             id: uuidv4(),
    //             activityType: ACTIVITY_TYPE.flight,
    //             travelOptionType: TRAVEL_OPTION_TYPE.transport,
    //             editIndex: null,
    //             totalEmissions: {
    //                 "status": CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success,
    //                 "emissionKgCO2eTotal": 12563,
    //                 errorCodes: null
    //             }
    //         },
    //         "legs": {
    //             "firstLeg": {
    //                 "airline": {"id": 302902, "name": "Quantas", "iata": "Q"},
    //                 "airport": {"id": 302822, "name": "Barcelona", "iata": "BCN", "country": "ESP"},
    //                 "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //                 "aircraftType": {"name": "Airbus A319", "iata": "319", "id": 40},
    //                 "date": DateTime.now(),
    //                 "flightNumber": "Q991",
    //                 ownAirportTransfer: false
    //
    //             },
    //             "stopover": [
    //                 {
    //                     "airline": {"id": 302902, "name": "Quantas", "iata": "Q"},
    //                     "airport": {"id": 302822, "name": "Kiev", "iata": "BCN", "country": "ESP"},
    //                     "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //                     "aircraftType": {"name": "Airbus A319", "iata": "319", "id": 40},
    //                     "date": DateTime.now(),
    //                     "flightNumber": "Q13",
    //                     ownAirportTransfer: false
    //                 },
    //                 {
    //                     "airline": {"id": 302902, "name": "Quantas", "iata": "Q"},
    //                     "airport": {"id": 302822, "name": "Roma", "iata": "BCN", "country": "ESP"},
    //                     "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //                     "aircraftType": {"name": "Airbus A319", "iata": "319", "id": 40},
    //                     "date": DateTime.now(),
    //                     "flightNumber": "",
    //                     ownAirportTransfer: false
    //                 },
    //                 {
    //                     "airline": {"id": 302902, "name": "Quantas", "iata": "Q"},
    //                     "airport": {"id": 302822, "name": "Barcelona", "iata": "BCN", "country": "ESP"},
    //                     "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //                     "aircraftType": {"name": "Airbus A319", "iata": "319", "id": 40},
    //                     "date": DateTime.now(),
    //                     "flightNumber": "",
    //                     ownAirportTransfer: false
    //                 },
    //                 {
    //                     "airline": {"id": 302902, "name": "Quantas", "iata": "Q"},
    //                     "airport": {"id": 302822, "name": "Tokyo", "iata": "BCN", "country": "ESP"},
    //                     "travelClass": {"name": "ACTIVITIES.FLIGHT.FLIGHT_CLASS_FIRST", "id": 1},
    //                     "aircraftType": {"name": "Airbus A319", "iata": "319", "id": 40},
    //                     "date": DateTime.now(),
    //                     "flightNumber": "Q91",
    //                     ownAirportTransfer: false
    //                 }
    //             ],
    //             "lastLeg": {"id": 302902, "name": "Berlin", "iata": "BER", "country": "DEU"}
    //         },
    //         "passengerCount": 11,
    //         "returnFlight": true,
    //         "emissions":
    //             [
    //                 {
    //                     index: 0,
    //                     status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //                     emissionKgCO2e: 3456,
    //                     errorCodes: null,
    //                     type: CALCULATION_TYPE.flight,
    //                     errors: null
    //                 },
    //                 {
    //                     index: 1,
    //                     status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //                     emissionKgCO2e: 4321,
    //                     errorCodes: null,
    //                     type: CALCULATION_TYPE.flight,
    //                     errors: null
    //                 },
    //                 {
    //                     index: 2,
    //                     status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.failure,
    //                     emissionKgCO2e: null,
    //                     errorCodes: null,
    //                     type: CALCULATION_TYPE.flight,
    //                     errors: null
    //                 },
    //                 {
    //                     index: 3,
    //                     status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.failure,
    //                     emissionKgCO2e: null,
    //                     errorCodes: null,
    //                     type: CALCULATION_TYPE.flight,
    //                     errors: null
    //                 },
    //                 {
    //                     index: 4,
    //                     status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
    //                     emissionKgCO2e: 4876,
    //                     errorCodes: null,
    //                     type: CALCULATION_TYPE.flight,
    //                     errors: null
    //                 }]
    //     },
    // ],
    activities: [],
    /* activities: [
        {
            common: {
                activityType: ACTIVITY_TYPE.hotel,
                travelOptionType: TRAVEL_OPTION_TYPE.accommodation,
                editIndex: null,
                totalEmissions: {
                    errorCodes: null,
                    status: CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success,
                    emissionKgCO2eTotal: 0.45
                },
                id: 'b5a8f8cb-ea41-4f1b-bb69-08f3ac92997b'
            },
            hotel: {
                id: 2,
                name: 'Grand Mirage Palace',
                city: 'Zenith',
                alpha2: 'ZZ',
                alpha3: 'ZEN',
                region: null,
                stars: 'FOUR',
                countryName: 'Zentoria',
                hotelId: '10'
            },
            city: {
                id: 2,
                name: 'Zenith',
                alpha2: 'ZZ',
                region: null,
                alpha3: 'ZEN',
                countryName: 'Zentoria'
            },
            country: {
                id: 2,
                alpha2: 'ZZ',
                alpha3: 'ZEN',
                name: 'Zentoria'
            },
            hotelClass: 4,
            roomCategory: null,
            numberOfNights: 2,
            numberOfPeople: 2,
            numberOfRooms: 1,
            mealPlan: null,
            emissions: [
                {
                    index: 0,
                    status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
                    emissionKgCO2e: 0.45,
                    scope: Scope.country,
                    errorCodes: null,
                    type: CALCULATION_TYPE.hotel,
                    errors: null
                }
            ]
        },
        {
            common: {
                activityType: ACTIVITY_TYPE.hotel,
                travelOptionType: TRAVEL_OPTION_TYPE.accommodation,
                editIndex: null,
                totalEmissions: {
                    errorCodes: null,
                    status: CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success,
                    emissionKgCO2eTotal: 0.38
                },
                id: 'd4f3e18b-b9a8-4f5d-b27a-dfe8a727d9ea'
            },
            hotel: {
                id: 3,
                name: 'Lunar Oasis Resort',
                city: 'Naria',
                alpha2: 'NR',
                alpha3: 'NAR',
                region: null,
                stars: 'THREE',
                countryName: 'Narland',
                hotelId: '25'
            },
            city: {
                id: 3,
                name: 'Naria',
                alpha2: 'NR',
                region: null,
                alpha3: 'NAR',
                countryName: 'Narland'
            },
            country: {
                id: 3,
                alpha2: 'NR',
                alpha3: 'NAR',
                name: 'Narland'
            },
            hotelClass: 3,
            roomCategory: null,
            numberOfNights: 1,
            numberOfPeople: 1,
            numberOfRooms: 1,
            mealPlan: null,
            emissions: [
                {
                    index: 0,
                    status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
                    emissionKgCO2e: 0.38,
                    scope: Scope.country,
                    errorCodes: null,
                    type: CALCULATION_TYPE.hotel,
                    errors: null
                }
            ]
        },
        {
            common: {
                activityType: ACTIVITY_TYPE.hotel,
                travelOptionType: TRAVEL_OPTION_TYPE.accommodation,
                editIndex: null,
                totalEmissions: {
                    errorCodes: null,
                    status: CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success,
                    emissionKgCO2eTotal: 0.72
                },
                id: '8a9f1234-1df6-4f3a-9316-fd7e6ef9b5c9'
            },
            hotel: {
                id: 4,
                name: 'Serenity Suites',
                city: 'Arcadia',
                alpha2: 'AC',
                alpha3: 'ARC',
                region: null,
                stars: 'FIVE',
                countryName: 'Arcadian Republic',
                hotelId: '7'
            },
            city: {
                id: 4,
                name: 'Arcadia',
                alpha2: 'AC',
                region: null,
                alpha3: 'ARC',
                countryName: 'Arcadian Republic'
            },
            country: {
                id: 4,
                alpha2: 'AC',
                alpha3: 'ARC',
                name: 'Arcadian Republic'
            },
            hotelClass: 5,
            roomCategory: null,
            numberOfNights: 3,
            numberOfPeople: 2,
            numberOfRooms: 1,
            mealPlan: null,
            emissions: [
                {
                    index: 0,
                    status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
                    emissionKgCO2e: 0.72,
                    scope: Scope.country,
                    errorCodes: null,
                    type: CALCULATION_TYPE.hotel,
                    errors: null
                }
            ]
        },
        {
            common: {
                activityType: ACTIVITY_TYPE.hotel,
                travelOptionType: TRAVEL_OPTION_TYPE.accommodation,
                editIndex: null,
                totalEmissions: {
                    errorCodes: null,
                    status: CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success,
                    emissionKgCO2eTotal: 0.54
                },
                id: '55f8eb56-3ae2-41b3-946a-9f83b52a8945'
            },
            hotel: {
                id: 5,
                name: 'Echo Valley Inn',
                city: 'Celestia',
                alpha2: 'CL',
                alpha3: 'CLS',
                region: null,
                stars: 'FOUR',
                countryName: 'Celestian Empire',
                hotelId: '15'
            },
            city: {
                id: 5,
                name: 'Celestia',
                alpha2: 'CL',
                region: null,
                alpha3: 'CLS',
                countryName: 'Celestian Empire'
            },
            country: {
                id: 5,
                alpha2: 'CL',
                alpha3: 'CLS',
                name: 'Celestian Empire'
            },
            hotelClass: 4,
            roomCategory: null,
            numberOfNights: 2,
            numberOfPeople: 1,
            numberOfRooms: 1,
            mealPlan: null,
            emissions: [
                {
                    index: 0,
                    status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
                    emissionKgCO2e: 0.54,
                    scope: Scope.country,
                    errorCodes: null,
                    type: CALCULATION_TYPE.hotel,
                    errors: null
                }
            ]
        },
        {
            common: {
                activityType: ACTIVITY_TYPE.hotel,
                travelOptionType: TRAVEL_OPTION_TYPE.accommodation,
                editIndex: null,
                totalEmissions: {
                    errorCodes: null,
                    status: CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success,
                    emissionKgCO2eTotal: 0.65
                },
                id: '743eab56-88d2-4148-a848-dbb2f663a6db'
            },
            hotel: {
                id: 6,
                name: 'Harmony Heights Hotel',
                city: 'Valoria',
                alpha2: 'VA',
                alpha3: 'VAL',
                region: null,
                stars: 'FIVE',
                countryName: 'Valoria Kingdom',
                hotelId: '30'
            },
            city: {
                id: 6,
                name: 'Valoria',
                alpha2: 'VA',
                region: null,
                alpha3: 'VAL',
                countryName: 'Valoria Kingdom'
            },
            country: {
                id: 6,
                alpha2: 'VA',
                alpha3: 'VAL',
                name: 'Valoria Kingdom'
            },
            hotelClass: 5,
            roomCategory: null,
            numberOfNights: 3,
            numberOfPeople: 2,
            numberOfRooms: 2,
            mealPlan: null,
            emissions: [
                {
                    index: 0,
                    status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
                    emissionKgCO2e: 0.65,
                    scope: Scope.country,
                    errorCodes: null,
                    type: CALCULATION_TYPE.hotel,
                    errors: null
                }
            ]
        },
        {
            common: {
                activityType: ACTIVITY_TYPE.hotel,
                travelOptionType: TRAVEL_OPTION_TYPE.accommodation,
                editIndex: null,
                totalEmissions: {
                    errorCodes: null,
                    status: CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success,
                    emissionKgCO2eTotal: 0.47
                },
                id: '9a3fd56e-0c2e-4f2d-9b69-8c9a3d8f6d9a'
            },
            hotel: {
                id: 7,
                name: 'Sunset Bay Lodge',
                city: 'Port Lumina',
                alpha2: 'PL',
                alpha3: 'PLM',
                region: null,
                stars: 'THREE',
                countryName: 'Lumina Islands',
                hotelId: '12'
            },
            city: {
                id: 7,
                name: 'Port Lumina',
                alpha2: 'PL',
                region: null,
                alpha3: 'PLM',
                countryName: 'Lumina Islands'
            },
            country: {
                id: 7,
                alpha2: 'PL',
                alpha3: 'PLM',
                name: 'Lumina Islands'
            },
            hotelClass: 3,
            roomCategory: null,
            numberOfNights: 1,
            numberOfPeople: 1,
            numberOfRooms: 1,
            mealPlan: null,
            emissions: [
                {
                    index: 0,
                    status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
                    emissionKgCO2e: 0.47,
                    scope: Scope.country,
                    errorCodes: null,
                    type: CALCULATION_TYPE.hotel,
                    errors: null
                }
            ]
        },
        {
            common: {
                activityType: ACTIVITY_TYPE.hotel,
                travelOptionType: TRAVEL_OPTION_TYPE.accommodation,
                editIndex: null,
                totalEmissions: {
                    errorCodes: null,
                    status: CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success,
                    emissionKgCO2eTotal: 0.55
                },
                id: '6c2f75f6-4f24-4f9d-9f68-8b9a3d8e6d9b'
            },
            hotel: {
                id: 8,
                name: 'Crystal Waters Hotel',
                city: 'Oceanica',
                alpha2: 'OC',
                alpha3: 'OCN',
                region: null,
                stars: 'FOUR',
                countryName: 'Oceanic Republic',
                hotelId: '18'
            },
            city: {
                id: 8,
                name: 'Oceanica',
                alpha2: 'OC',
                region: null,
                alpha3: 'OCN',
                countryName: 'Oceanic Republic'
            },
            country: {
                id: 8,
                alpha2: 'OC',
                alpha3: 'OCN',
                name: 'Oceanic Republic'
            },
            hotelClass: 4,
            roomCategory: null,
            numberOfNights: 2,
            numberOfPeople: 1,
            numberOfRooms: 1,
            mealPlan: null,
            emissions: [
                {
                    index: 0,
                    status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
                    emissionKgCO2e: 0.55,
                    scope: Scope.country,
                    errorCodes: null,
                    type: CALCULATION_TYPE.hotel,
                    errors: null
                }
            ]
        },
        {
            common: {
                activityType: ACTIVITY_TYPE.hotel,
                travelOptionType: TRAVEL_OPTION_TYPE.accommodation,
                editIndex: null,
                totalEmissions: {
                    errorCodes: null,
                    status: CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success,
                    emissionKgCO2eTotal: 0.62
                },
                id: '7d2e86g7-5g25-5g0e-0g79-9d0a4d9f7d8c'
            },
            hotel: {
                id: 9,
                name: 'Mountain Peak Retreat',
                city: 'Alpina',
                alpha2: 'AL',
                alpha3: 'ALP',
                region: null,
                stars: 'FIVE',
                countryName: 'Alpinia',
                hotelId: '22'
            },
            city: {
                id: 9,
                name: 'Alpina',
                alpha2: 'AL',
                region: null,
                alpha3: 'ALP',
                countryName: 'Alpinia'
            },
            country: {
                id: 9,
                alpha2: 'AL',
                alpha3: 'ALP',
                name: 'Alpinia'
            },
            hotelClass: 5,
            roomCategory: null,
            numberOfNights: 3,
            numberOfPeople: 2,
            numberOfRooms: 1,
            mealPlan: null,
            emissions: [
                {
                    index: 0,
                    status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
                    emissionKgCO2e: 0.62,
                    scope: Scope.country,
                    errorCodes: null,
                    type: CALCULATION_TYPE.hotel,
                    errors: null
                }
            ]
        },
        {
            common: {
                activityType: ACTIVITY_TYPE.hotel,
                travelOptionType: TRAVEL_OPTION_TYPE.accommodation,
                editIndex: null,
                totalEmissions: {
                    errorCodes: null,
                    status: CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success,
                    emissionKgCO2eTotal: 0.52
                },
                id: '8e3g97h8-6h36-6h1f-1h80-0e1b5e0g8h9d'
            },
            hotel: {
                id: 10,
                name: 'Desert Rose Hotel',
                city: 'Mirage',
                alpha2: 'MR',
                alpha3: 'MRG',
                region: null,
                stars: 'FOUR',
                countryName: 'Miragia',
                hotelId: '5'
            },
            city: {
                id: 10,
                name: 'Mirage',
                alpha2: 'MR',
                region: null,
                alpha3: 'MRG',
                countryName: 'Miragia'
            },
            country: {
                id: 10,
                alpha2: 'MR',
                alpha3: 'MRG',
                name: 'Miragia'
            },
            hotelClass: 4,
            roomCategory: null,
            numberOfNights: 2,
            numberOfPeople: 1,
            numberOfRooms: 1,
            mealPlan: null,
            emissions: [
                {
                    index: 0,
                    status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
                    emissionKgCO2e: 0.52,
                    scope: Scope.country,
                    errorCodes: null,
                    type: CALCULATION_TYPE.hotel,
                    errors: null
                }
            ]
        },
        {
            common: {
                activityType: ACTIVITY_TYPE.hotel,
                travelOptionType: TRAVEL_OPTION_TYPE.accommodation,
                editIndex: null,
                totalEmissions: {
                    errorCodes: null,
                    status: CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success,
                    emissionKgCO2eTotal: 0.43
                },
                id: '9f4h08i9-7i47-7i2g-2i91-1f2c6f1h9i0e'
            },
            hotel: {
                id: 11,
                name: 'Tropical Breeze Resort',
                city: 'Palmaris',
                alpha2: 'PL',
                alpha3: 'PAL',
                region: null,
                stars: 'THREE',
                countryName: 'Palmeria',
                hotelId: '14'
            },
            city: {
                id: 11,
                name: 'Palmaris',
                alpha2: 'PL',
                region: null,
                alpha3: 'PAL',
                countryName: 'Palmeria'
            },
            country: {
                id: 11,
                alpha2: 'PL',
                alpha3: 'PAL',
                name: 'Palmeria'
            },
            hotelClass: 3,
            roomCategory: null,
            numberOfNights: 1,
            numberOfPeople: 1,
            numberOfRooms: 1,
            mealPlan: null,
            emissions: [
                {
                    index: 0,
                    status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
                    emissionKgCO2e: 0.43,
                    scope: Scope.country,
                    errorCodes: null,
                    type: CALCULATION_TYPE.hotel,
                    errors: null
                }
            ]
        },
        {
            common: {
                activityType: ACTIVITY_TYPE.hotel,
                travelOptionType: TRAVEL_OPTION_TYPE.accommodation,
                editIndex: null,
                totalEmissions: {
                    errorCodes: null,
                    status: CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success,
                    emissionKgCO2eTotal: 0.59
                },
                id: '0a5i19j0-8j58-8j3h-3j02-2g3d7g2i0j1f'
            },
            hotel: {
                id: 12,
                name: 'Riverside Garden Inn',
                city: 'Floralis',
                alpha2: 'FL',
                alpha3: 'FLR',
                region: null,
                stars: 'FOUR',
                countryName: 'Floralia',
                hotelId: '17'
            },
            city: {
                id: 12,
                name: 'Floralis',
                alpha2: 'FL',
                region: null,
                alpha3: 'FLR',
                countryName: 'Floralia'
            },
            country: {
                id: 12,
                alpha2: 'FL',
                alpha3: 'FLR',
                name: 'Floralia'
            },
            hotelClass: 4,
            roomCategory: null,
            numberOfNights: 2,
            numberOfPeople: 1,
            numberOfRooms: 1,
            mealPlan: null,
            emissions: [
                {
                    index: 0,
                    status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
                    emissionKgCO2e: 0.59,
                    scope: Scope.country,
                    errorCodes: null,
                    type: CALCULATION_TYPE.hotel,
                    errors: null
                }
            ]
        },
        {
            common: {
                activityType: ACTIVITY_TYPE.hotel,
                travelOptionType: TRAVEL_OPTION_TYPE.accommodation,
                editIndex: null,
                totalEmissions: {
                    errorCodes: null,
                    status: CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success,
                    emissionKgCO2eTotal: 0.48
                },
                id: '1b6j20k1-9k69-9k4i-4k13-3h4e8h3j1k2g'
            },
            hotel: {
                id: 13,
                name: 'Cedar Grove Lodge',
                city: 'Sylvia',
                alpha2: 'SY',
                alpha3: 'SYL',
                region: null,
                stars: 'THREE',
                countryName: 'Sylvaria',
                hotelId: '20'
            },
            city: {
                id: 13,
                name: 'Sylvia',
                alpha2: 'SY',
                region: null,
                alpha3: 'SYL',
                countryName: 'Sylvaria'
            },
            country: {
                id: 13,
                alpha2: 'SY',
                alpha3: 'SYL',
                name: 'Sylvaria'
            },
            hotelClass: 3,
            roomCategory: null,
            numberOfNights: 1,
            numberOfPeople: 1,
            numberOfRooms: 1,
            mealPlan: null,
            emissions: [
                {
                    index: 0,
                    status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
                    emissionKgCO2e: 0.48,
                    scope: Scope.country,
                    errorCodes: null,
                    type: CALCULATION_TYPE.hotel,
                    errors: null
                }
            ]
        },
        {
            common: {
                activityType: ACTIVITY_TYPE.hotel,
                travelOptionType: TRAVEL_OPTION_TYPE.accommodation,
                editIndex: null,
                totalEmissions: {
                    errorCodes: null,
                    status: CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success,
                    emissionKgCO2eTotal: 0.50
                },
                id: '2c7k31l2-0l70-0l5j-5l24-4i5f9i4k2l3h'
            },
            hotel: {
                id: 14,
                name: 'Emerald Isle Hotel Hotel Hotel Hotel Hotel Hotel HotelHotel Hotel Hotel Hotel Hotel Hotel Hotel Hotel Hotel Hotel Hotel Hotel Hotel Hotel Hotel Hotel HotelHotel Hotel Hotel Hotel Hotel Hotel Hotel Hotel Hotel Hotel',
                city: 'Atlantis',
                alpha2: 'AT',
                alpha3: 'ATL',
                region: null,
                stars: 'FOUR',
                countryName: 'Atlantica',
                hotelId: '19'
            },
            city: {
                id: 14,
                name: 'Atlantis',
                alpha2: 'AT',
                region: null,
                alpha3: 'ATL',
                countryName: 'Atlantica'
            },
            country: {
                id: 14,
                alpha2: 'AT',
                alpha3: 'ATL',
                name: 'Atlantica'
            },
            hotelClass: 4,
            roomCategory: null,
            numberOfNights: 2,
            numberOfPeople: 1,
            numberOfRooms: 1,
            mealPlan: null,
            emissions: [
                {
                    index: 0,
                    status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
                    emissionKgCO2e: 0.57,
                    scope: Scope.country,
                    errorCodes: null,
                    type: CALCULATION_TYPE.hotel,
                    errors: null
                }
            ]
        },
        {
            common: {
                activityType: ACTIVITY_TYPE.hotel,
                travelOptionType: TRAVEL_OPTION_TYPE.accommodation,
                editIndex: null,
                totalEmissions: {
                    errorCodes: null,
                    status: CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success,
                    emissionKgCO2eTotal: 0.61
                },
                id: 'zxcvbnm-1234-qwert-5678-asdfgh'
            },
            hotel: {
                id: 23,
                name: 'Hôtel Étoile',
                city: 'Évian',
                alpha2: 'EV',
                alpha3: 'EVI',
                region: null,
                stars: 'FIVE',
                countryName: 'Évorine',
                hotelId: '30'
            },
            city: {
                id: 23,
                name: 'Évian',
                alpha2: 'EV',
                region: null,
                alpha3: 'EVI',
                countryName: 'Évorine'
            },
            country: {
                id: 23,
                alpha2: 'EV',
                alpha3: 'EVI',
                name: 'Évorine'
            },
            hotelClass: 5,
            roomCategory: null,
            numberOfNights: 2,
            numberOfPeople: 2,
            numberOfRooms: 1,
            mealPlan: null,
            emissions: [
                {
                    index: 0,
                    status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
                    emissionKgCO2e: 0.61,
                    scope: Scope.country,
                    errorCodes: null,
                    type: CALCULATION_TYPE.hotel,
                    errors: null
                }
            ]
        },
        {
            common: {
                activityType: ACTIVITY_TYPE.hotel,
                travelOptionType: TRAVEL_OPTION_TYPE.accommodation,
                editIndex: null,
                totalEmissions: {
                    errorCodes: null,
                    status: CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success,
                    emissionKgCO2eTotal: 0.55
                },
                id: 'asdfgh-5678-qwert-1234-zxcvbnm'
            },
            hotel: {
                id: 24,
                name: 'Château Hôtel',
                city: 'Montreuil',
                alpha2: 'MO',
                alpha3: 'MNT',
                region: null,
                stars: 'FOUR',
                countryName: 'Montrelia',
                hotelId: '31'
            },
            city: {
                id: 24,
                name: 'Montreuil',
                alpha2: 'MO',
                region: null,
                alpha3: 'MNT',
                countryName: 'Montrelia'
            },
            country: {
                id: 24,
                alpha2: 'MO',
                alpha3: 'MNT',
                name: 'Montrelia'
            },
            hotelClass: 4,
            roomCategory: null,
            numberOfNights: 1,
            numberOfPeople: 1,
            numberOfRooms: 1,
            mealPlan: null,
            emissions: [
                {
                    index: 0,
                    status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
                    emissionKgCO2e: 0.55,
                    scope: Scope.country,
                    errorCodes: null,
                    type: CALCULATION_TYPE.hotel,
                    errors: null
                }
            ]
        },
        {
            common: {
                activityType: ACTIVITY_TYPE.hotel,
                travelOptionType: TRAVEL_OPTION_TYPE.accommodation,
                editIndex: null,
                totalEmissions: {
                    errorCodes: null,
                    status: CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success,
                    emissionKgCO2eTotal: 0.59
                },
                id: 'qwerty-7890-asdfgh-1234-zxcvbnm'
            },
            hotel: {
                id: 25,
                name: 'Starlight & Moon Hotel',
                city: 'Nebula City',
                alpha2: 'NC',
                alpha3: 'NBL',
                region: null,
                stars: 'FOUR',
                countryName: 'Nebulandia',
                hotelId: '32'
            },
            city: {
                id: 25,
                name: 'Nebula City',
                alpha2: 'NC',
                region: null,
                alpha3: 'NBL',
                countryName: 'Nebulandia'
            },
            country: {
                id: 25,
                alpha2: 'NC',
                alpha3: 'NBL',
                name: 'Nebulandia'
            },
            hotelClass: 4,
            roomCategory: null,
            numberOfNights: 2,
            numberOfPeople: 1,
            numberOfRooms: 1,
            mealPlan: null,
            emissions: [
                {
                    index: 0,
                    status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
                    emissionKgCO2e: 0.59,
                    scope: Scope.country,
                    errorCodes: null,
                    type: CALCULATION_TYPE.hotel,
                    errors: null
                }
            ]
        },
        {
            common: {
                activityType: ACTIVITY_TYPE.hotel,
                travelOptionType: TRAVEL_OPTION_TYPE.accommodation,
                editIndex: null,
                totalEmissions: {
                    errorCodes: null,
                    status: CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success,
                    emissionKgCO2eTotal: 0.62
                },
                id: 'zxcvbnm-6789-asdfgh-1234-qwerty'
            },
            hotel: {
                id: 26,
                name: 'Hotel @ The Plaza',
                city: 'Centralia',
                alpha2: 'CT',
                alpha3: 'CTL',
                region: null,
                stars: 'FIVE',
                countryName: 'Centria',
                hotelId: '33'
            },
            city: {
                id: 26,
                name: 'Centralia',
                alpha2: 'CT',
                region: null,
                alpha3: 'CTL',
                countryName: 'Centria'
            },
            country: {
                id: 26,
                alpha2: 'CT',
                alpha3: 'CTL',
                name: 'Centria'
            },
            hotelClass: 5,
            roomCategory: null,
            numberOfNights: 3,
            numberOfPeople: 2,
            numberOfRooms: 1,
            mealPlan: null,
            emissions: [
                {
                    index: 0,
                    status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
                    emissionKgCO2e: 0.62,
                    scope: Scope.country,
                    errorCodes: null,
                    type: CALCULATION_TYPE.hotel,
                    errors: null
                }
            ]
        },
        {
            common: {
                activityType: ACTIVITY_TYPE.hotel,
                travelOptionType: TRAVEL_OPTION_TYPE.accommodation,
                editIndex: null,
                totalEmissions: {
                    errorCodes: null,
                    status: CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success,
                    emissionKgCO2eTotal: 0.67
                },
                id: 'asdfgh-5678-qwert-6789-zxcvbnm'
            },
            hotel: {
                id: 27,
                name: 'Südstrand Hotel',
                city: 'Küstenstadt',
                alpha2: 'KS',
                alpha3: 'KST',
                region: null,
                stars: 'FOUR',
                countryName: 'Küstenland',
                hotelId: '34'
            },
            city: {
                id: 27,
                name: 'Küstenstadt',
                alpha2: 'KS',
                region: null,
                alpha3: 'KST',
                countryName: 'Küstenland'
            },
            country: {
                id: 27,
                alpha2: 'KS',
                alpha3: 'KST',
                name: 'Küstenland'
            },
            hotelClass: 4,
            roomCategory: null,
            numberOfNights: 2,
            numberOfPeople: 1,
            numberOfRooms: 1,
            mealPlan: null,
            emissions: [
                {
                    index: 0,
                    status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
                    emissionKgCO2e: 0.67,
                    scope: Scope.country,
                    errorCodes: null,
                    type: CALCULATION_TYPE.hotel,
                    errors: null
                }
            ]
        },
        {
            common: {
                activityType: ACTIVITY_TYPE.hotel,
                travelOptionType: TRAVEL_OPTION_TYPE.accommodation,
                editIndex: null,
                totalEmissions: {
                    errorCodes: null,
                    status: CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success,
                    emissionKgCO2eTotal: 0.64
                },
                id: 'qwerty-1234-asdfgh-5678-zxcvbnm'
            },
            hotel: {
                id: 28,
                name: 'Hôtel Älpenrose',
                city: 'Glarus',
                alpha2: 'GL',
                alpha3: 'GLS',
                region: null,
                stars: 'FIVE',
                countryName: 'Glariand',
                hotelId: '35'
            },
            city: {
                id: 28,
                name: 'Glarus',
                alpha2: 'GL',
                region: null,
                alpha3: 'GLS',
                countryName: 'Glariand'
            },
            country: {
                id: 28,
                alpha2: 'GL',
                alpha3: 'GLS',
                name: 'Glariand'
            },
            hotelClass: 5,
            roomCategory: null,
            numberOfNights: 4,
            numberOfPeople: 2,
            numberOfRooms: 1,
            mealPlan: null,
            emissions: [
                {
                    index: 0,
                    status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
                    emissionKgCO2e: 0.64,
                    scope: Scope.country,
                    errorCodes: null,
                    type: CALCULATION_TYPE.hotel,
                    errors: null
                }
            ]
        },
        {
            common: {
                activityType: ACTIVITY_TYPE.hotel,
                travelOptionType: TRAVEL_OPTION_TYPE.accommodation,
                editIndex: null,
                totalEmissions: {
                    errorCodes: null,
                    status: CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success,
                    emissionKgCO2eTotal: 0.56
                },
                id: 'zxcvbnm-5678-asdfgh-1234-qwerty'
            },
            hotel: {
                id: 29,
                name: 'Château Hôtel ëtoile',
                city: 'Évian',
                alpha2: 'EV',
                alpha3: 'EVI',
                region: null,
                stars: 'FIVE',
                countryName: 'Évorine',
                hotelId: '36'
            },
            city: {
                id: 29,
                name: 'Évian',
                alpha2: 'EV',
                region: null,
                alpha3: 'EVI',
                countryName: 'Évorine'
            },
            country: {
                id: 29,
                alpha2: 'EV',
                alpha3: 'EVI',
                name: 'Évorine'
            },
            hotelClass: 5,
            roomCategory: null,
            numberOfNights: 2,
            numberOfPeople: 2,
            numberOfRooms: 1,
            mealPlan: null,
            emissions: [
                {
                    index: 0,
                    status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
                    emissionKgCO2e: 0.56,
                    scope: Scope.country,
                    errorCodes: null,
                    type: CALCULATION_TYPE.hotel,
                    errors: null
                }
            ]
        },
        {
            common: {
                activityType: ACTIVITY_TYPE.hotel,
                travelOptionType: TRAVEL_OPTION_TYPE.accommodation,
                editIndex: null,
                totalEmissions: {
                    errorCodes: null,
                    status: CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success,
                    emissionKgCO2eTotal: 0.63
                },
                id: 'asdfgh-6789-qwert-1234-zxcvbnm'
            },
            hotel: {
                id: 30,
                name: 'Hôtel Soleil & Lune',
                city: 'Île de la Lune',
                alpha2: 'IL',
                alpha3: 'ILU',
                region: null,
                stars: 'FOUR',
                countryName: 'Lunaria',
                hotelId: '37'
            },
            city: {
                id: 30,
                name: 'Île de la Lune',
                alpha2: 'IL',
                region: null,
                alpha3: 'ILU',
                countryName: 'Lunaria'
            },
            country: {
                id: 30,
                alpha2: 'IL',
                alpha3: 'ILU',
                name: 'Lunaria'
            },
            hotelClass: 4,
            roomCategory: null,
            numberOfNights: 3,
            numberOfPeople: 1,
            numberOfRooms: 1,
            mealPlan: null,
            emissions: [
                {
                    index: 0,
                    status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
                    emissionKgCO2e: 0.63,
                    scope: Scope.country,
                    errorCodes: null,
                    type: CALCULATION_TYPE.hotel,
                    errors: null
                }
            ]
        },
        {
            common: {
                activityType: ACTIVITY_TYPE.hotel,
                travelOptionType: TRAVEL_OPTION_TYPE.accommodation,
                editIndex: null,
                totalEmissions: {
                    errorCodes: null,
                    status: CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success,
                    emissionKgCO2eTotal: 0.64
                },
                id: 'qwerty-5678-asdfgh-1234-zxcvbnm'
            },
            hotel: {
                id: 31,
                name: 'Hôtel Cité d’Ivresse',
                city: 'Ivresse',
                alpha2: 'IV',
                alpha3: 'IVR',
                region: null,
                stars: 'FOUR',
                countryName: 'Ivressia',
                hotelId: '38'
            },
            city: {
                id: 31,
                name: 'Ivresse',
                alpha2: 'IV',
                region: null,
                alpha3: 'IVR',
                countryName: 'Ivressia'
            },
            country: {
                id: 31,
                alpha2: 'IV',
                alpha3: 'IVR',
                name: 'Ivressia'
            },
            hotelClass: 4,
            roomCategory: null,
            numberOfNights: 1,
            numberOfPeople: 1,
            numberOfRooms: 1,
            mealPlan: null,
            emissions: [
                {
                    index: 0,
                    status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
                    emissionKgCO2e: 0.64,
                    scope: Scope.country,
                    errorCodes: null,
                    type: CALCULATION_TYPE.hotel,
                    errors: null
                }
            ]
        },
        {
            common: {
                activityType: ACTIVITY_TYPE.hotel,
                travelOptionType: TRAVEL_OPTION_TYPE.accommodation,
                editIndex: null,
                totalEmissions: {
                    errorCodes: null,
                    status: CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success,
                    emissionKgCO2eTotal: 0.68
                },
                id: 'asdfgh-1234-qwert-5678-zxcvbnm'
            },
            hotel: {
                id: 32,
                name: 'Starlight & Moon Hotel',
                city: 'Nebula City',
                alpha2: 'NC',
                alpha3: 'NBL',
                region: null,
                stars: 'FOUR',
                countryName: 'Nebulandia',
                hotelId: '39'
            },
            city: {
                id: 32,
                name: 'Nebula City',
                alpha2: 'NC',
                region: null,
                alpha3: 'NBL',
                countryName: 'Nebulandia'
            },
            country: {
                id: 32,
                alpha2: 'NC',
                alpha3: 'NBL',
                name: 'Nebulandia'
            },
            hotelClass: 4,
            roomCategory: null,
            numberOfNights: 2,
            numberOfPeople: 1,
            numberOfRooms: 1,
            mealPlan: null,
            emissions: [
                {
                    index: 0,
                    status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
                    emissionKgCO2e: 0.68,
                    scope: Scope.country,
                    errorCodes: null,
                    type: CALCULATION_TYPE.hotel,
                    errors: null
                }
            ]
        },
        {
            common: {
                activityType: ACTIVITY_TYPE.hotel,
                travelOptionType: TRAVEL_OPTION_TYPE.accommodation,
                editIndex: null,
                totalEmissions: {
                    errorCodes: null,
                    status: CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success,
                    emissionKgCO2eTotal: 0.62
                },
                id: 'zxcvbnm-1234-asdfgh-5678-qwerty'
            },
            hotel: {
                id: 33,
                name: 'Cañon View Hotel',
                city: 'Cañon City',
                alpha2: 'CC',
                alpha3: 'CCT',
                region: null,
                stars: 'FOUR',
                countryName: 'Cañonia',
                hotelId: '40'
            },
            city: {
                id: 33,
                name: 'Cañon City',
                alpha2: 'CC',
                region: null,
                alpha3: 'CCT',
                countryName: 'Cañonia'
            },
            country: {
                id: 33,
                alpha2: 'CC',
                alpha3: 'CCT',
                name: 'Cañonia'
            },
            hotelClass: 4,
            roomCategory: null,
            numberOfNights: 3,
            numberOfPeople: 1,
            numberOfRooms: 2,
            mealPlan: null,
            emissions: [
                {
                    index: 0,
                    status: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success,
                    emissionKgCO2e: 0.62,
                    scope: Scope.country,
                    errorCodes: null,
                    type: CALCULATION_TYPE.hotel,
                    errors: null
                }
            ]
        }

    ], */
    queueForDeleteActivity: {},
};

export const travelComponents = createSlice({
    name: 'travelComponents',
    initialState,
    reducers: {
        deleteActivityFromTravelComponents: (state, action: PayloadAction<number>) => {
            const activity = state.activities.at(action.payload)
            if (activity) {
                const index = action.payload
                const {id} = activity.common
                //@ts-ignore
                if (activity) state.queueForDeleteActivity[id] = {index, activity}
                state.activities.splice(index, 1)
            }
        },
        undoDeleteActivityFromTravelComponents: (state, action: PayloadAction<{ index: number, id: string }>) => {
            const {id, index} = action.payload
            const item = state.queueForDeleteActivity[id]
            // @ts-ignore
            state.activities.splice(index, 0, item.activity)
            delete state.queueForDeleteActivity[id]
        },
        deleteActivityFromTravelComponentsQueue: (state, action: PayloadAction<string>) => {
            delete state.queueForDeleteActivity[action.payload]
        },
        addActivityToTravelComponents: (state, action: PayloadAction<AllActivityTypes>) => {
            state.activities.push(action.payload)
        },
        updateActivityInTravelComponents: (state, action: PayloadAction<{
            activity: AllActivityTypes,
            index: number
        }>) => {
            const {index, activity} = action.payload
            state.activities[index] = activity
        }
    }
});

export const {
    deleteActivityFromTravelComponents,
    deleteActivityFromTravelComponentsQueue,
    undoDeleteActivityFromTravelComponents,
    addActivityToTravelComponents,
    updateActivityInTravelComponents
} = travelComponents.actions;
export default travelComponents.reducer;