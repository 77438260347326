import React from "react"
import {Spinner, Text} from "atmosfair-ui";
import {SETTINGS_SPINNER_SIZE} from "../utils";
import "./styles.css"

interface SectionTitleProps {
    isLoading?: boolean,
    title: string,
}

const SectionTitle = ({
                          isLoading,
                          title
                      }: SectionTitleProps) => <div className={"section-title-container"}>
    {isLoading && <Spinner size={SETTINGS_SPINNER_SIZE} fullPage={false}/>}
    <Text
        style={{width: "100%"}}
        variant={"headlineMedium"}>
        {title}
    </Text>
</div>

export default SectionTitle