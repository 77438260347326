import i18next from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import german from './locales/german';
import english from './locales/english';
import {LANGUAGE_ABBREVATION} from "./locales/locales";

i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        debug: false,
        lng: LANGUAGE_ABBREVATION.de,
        fallbackLng: LANGUAGE_ABBREVATION.de,
        resources: {
            [LANGUAGE_ABBREVATION.de]: {translation: german},
            [LANGUAGE_ABBREVATION.en]: {translation: english}
        }
    });

export default i18next;