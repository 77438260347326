import React, {useEffect, useState} from "react"
import {CheckboxInput, TextInput} from "atmosfair-ui"
import {validateEmail, validateFirstAndLastName, validateName} from "../utils";
import "../styles.css"
import {UserProps} from "../../../api/model/user";
import AddressForm, {AddressProps} from "../address-form/AddressForm";
import PhoneNumberForm, {PhoneNumberProps} from "../phone-number-form/PhoneNumberForm";
import {VALIDATE_FORM_STARTING_NUMBER} from "../../../constants";
import {FormPropsAgency} from "../../settings/agencies-company/agencies-company-data-grid/AgenciesCompanyDataGrid";
import ContactPersonForm, {ContactPersonProps} from "../contact-person-form/ContactPersonForm";
import "./styles.css"
import clsx from "clsx";

export interface AgenciesCompanyProps {
    agencyCompanyName: string,
    agencyCompanyEmail: string,
}

interface AgenciesCompanyContactPersonProps {
    firstContactPerson: ContactPersonProps,
    secondContactPerson: ContactPersonProps
}

export interface AgenciesCompanyFormProps {
    isEdit: boolean,
    allUsers: UserProps[],
    showMakeAdminCheckboxes: boolean,
    nameAlreadyGivenError?: boolean,
    defaultValues?: AgenciesCompanyContactPersonProps & AgenciesCompanyProps & AddressProps & PhoneNumberProps,
    validateForm: number,
    onFormIsValid: (firstContactPersonData: ContactPersonProps, secondContactPersonData: ContactPersonProps, addressData: AddressProps, phoneNumberData: PhoneNumberProps, agencyCompanyData: AgenciesCompanyProps) => void,
    whichType: 'agencies' | "company",
    setInviteContact?: <K extends keyof FormPropsAgency>(key: K, value: FormPropsAgency[K], firstName: string, lastName: string, email: string) => void,
    onChangeContact?: <K extends keyof FormPropsAgency>(key: K, value: FormPropsAgency[K]) => void,
    onShowSecondContact?: (value: boolean) => void
}

export interface AgencyFormDataProps {
    addressData: AddressProps,
    phoneData: PhoneNumberProps,
    agencyCompanyData: AgenciesCompanyProps,
    firstContactPersonData: ContactPersonProps,
    secondContactPersonData: ContactPersonProps,
}

interface FormErrorProps {
    agencyCompanyNameError: boolean,
    agencyCompanyEmailError: boolean,
}

const AgenciesCompanyForm = ({
                                 nameAlreadyGivenError,
                                 showMakeAdminCheckboxes,
                                 validateForm,
                                 onFormIsValid,
                                 whichType,
                                 isEdit,
                                 defaultValues,
                                 setInviteContact,
                                 onChangeContact,
                                 allUsers,
                                 onShowSecondContact
                             }: AgenciesCompanyFormProps) => {
    const [formData, setFormData] = useState<AgenciesCompanyProps>({
        agencyCompanyEmail: defaultValues?.agencyCompanyEmail ?? "",
        agencyCompanyName: defaultValues?.agencyCompanyName ?? "",
    })
    const [firstContactPersonFormData, setFirstContactPersonFormData] = useState<ContactPersonProps | null>(null)
    const [secondContactPersonFormData, setSecondContactPersonFormData] = useState<ContactPersonProps | null>(null)
    const [addressFormData, setAddressFormData] = useState<AddressProps | null>(null)
    const [phoneNumberFormData, setPhoneNumberFormData] = useState<PhoneNumberProps | null>(null)
    const [formErrorData, setFormErrorData] = useState<FormErrorProps>({
        agencyCompanyNameError: false,
        agencyCompanyEmailError: false,
    })
    const [showSecondContact, setShowSecondContact] = useState(false)
    const {
        agencyCompanyEmail,
        agencyCompanyName,
    } = formData
    const {
        agencyCompanyNameError,
        agencyCompanyEmailError,
    } = formErrorData
    
    useEffect(() => {
        if (validateForm > VALIDATE_FORM_STARTING_NUMBER) {
            const validForm = _validateForm()
            if (validForm && addressFormData && phoneNumberFormData && firstContactPersonFormData && secondContactPersonFormData)
                onFormIsValid(firstContactPersonFormData, secondContactPersonFormData, addressFormData, phoneNumberFormData, {
                    agencyCompanyName: agencyCompanyName.trim(),
                    agencyCompanyEmail: agencyCompanyEmail.trim(),
                })
        }
    }, [validateForm, addressFormData, phoneNumberFormData, firstContactPersonFormData]);

    useEffect(() => {
        if (
            validateFirstAndLastName(defaultValues?.secondContactPerson.firstName) &&
            validateFirstAndLastName(defaultValues?.secondContactPerson.lastName)
        ) setShowSecondContact(true)
    }, []);

    const _updateFormData = <K extends keyof AgenciesCompanyProps>(key: K, payload: AgenciesCompanyProps[K]) => setFormData({
        ...formData,
        [key]: payload
    })

    const _validateForm = () => {
        const validAgencyName = validateName(agencyCompanyName)
        const validEmail = validateEmail(agencyCompanyEmail)

        setFormErrorData({
            agencyCompanyNameError: !validAgencyName,
            agencyCompanyEmailError: !validEmail,
        })

        return [
            validAgencyName,
            validEmail,
        ].every(Boolean)
    }

    return <div className={"form-row"}>
        <div className={"agencies-company-button-wrapper"}>
            <div className={"form-row"}>
                <TextInput
                    labelColor={"primary"}
                    ariaErrorMessage={`SCREENS.COMMON.ARIA_LABELS.${whichType === "agencies" ? "AGENCY_ERROR" : "COMPANY_ERROR"}`}
                    error={agencyCompanyNameError || nameAlreadyGivenError}
                    onBlur={value => _updateFormData("agencyCompanyName", value)}
                    onChange={value => _updateFormData("agencyCompanyName", value)}
                    containerStyle={{width: "100%"}}
                    required={true}
                    value={agencyCompanyName}
                    placeholder={`GENERAL.${whichType === "agencies" ? "AGENCY_NAME" : "COMPANY_NAME"}`}
                    label={`GENERAL.${whichType === "agencies" ? "AGENCY_NAME" : "COMPANY_NAME"}`}/>
                <TextInput
                    labelColor={"primary"}
                    ariaErrorMessage={"SCREENS.COMMON.ARIA_LABELS.E_MAIL_ERROR"}
                    error={agencyCompanyEmailError}
                    onBlur={value => _updateFormData("agencyCompanyEmail", value)}
                    onChange={value => _updateFormData("agencyCompanyEmail", value)}
                    containerStyle={{flex: 2}}
                    required={true}
                    value={agencyCompanyEmail}
                    placeholder={whichType === 'company' ? "GENERAL.COMPANY_EMAIL" : "GENERAL.AGENCY_EMAIL"}
                    label={whichType === 'company' ? "GENERAL.COMPANY_EMAIL" : "GENERAL.AGENCY_EMAIL"}/>
            </div>
            <div className={"form-row"}>
                <AddressForm
                    showCountry={true}
                    defaultValues={defaultValues}
                    countryRequired={true}
                    countryStyle={{flex: 1}}
                    streetRequired={true}
                    houseNumberRequired={true}
                    zipCodeRequired={true}
                    cityRequired={true}
                    additionalAddressInformationRequired={false}
                    validateForm={validateForm}
                    onFormIsValid={setAddressFormData}/>
            </div>
            <div className={"form-row"}>
                <PhoneNumberForm
                    showInternationalPhonePrefix={true}
                    defaultValues={defaultValues}
                    internationalPhonePrefixRequired={true}
                    phoneNumberRequired={true}
                    validateForm={validateForm}
                    onFormIsValid={setPhoneNumberFormData}/>
            </div>
            <div className={"form-row"}>
                <ContactPersonForm
                    whichContactPerson={"first"}
                    defaultValues={{
                        email: defaultValues?.firstContactPerson.email ?? "",
                        firstName: defaultValues?.firstContactPerson.firstName ?? "",
                        lastName: defaultValues?.firstContactPerson.lastName ?? "",
                        isAdmin: Boolean(defaultValues?.firstContactPerson.isAdmin),
                    }}
                    firstNameRequired={true}
                    lastNameRequired={true}
                    emailRequired={true}
                    whichType={whichType}
                    allUsers={allUsers}
                    isEdit={isEdit}
                    functionValueContactPerson={"inviteFirstContact"}
                    validateForm={validateForm}
                    onFormIsValid={setFirstContactPersonFormData}
                    onInviteContact={setInviteContact}
                    onChangeContact={onChangeContact}
                    showMakeAdminCheckboxes={showMakeAdminCheckboxes}/>
            </div>
            {!isEdit && <CheckboxInput
                checked={showSecondContact}
                onChange={() => {
                    onShowSecondContact && onShowSecondContact(!showSecondContact)
                    setShowSecondContact(!showSecondContact)
                }}
                label={'SCREENS.SETTINGS.ADD_SECOND_CONTACT'}
                hideLabel={true}
                text={'SCREENS.SETTINGS.ADD_SECOND_CONTACT'}
                textPosition={'right'}
            />}
            <ContactPersonForm
                containerClasses={clsx(!showSecondContact && "agencies-company-form-contact-person-visibility")}
                whichContactPerson={"second"}
                defaultValues={{
                    email: defaultValues?.secondContactPerson.email ?? "",
                    firstName: defaultValues?.secondContactPerson.firstName ?? "",
                    lastName: defaultValues?.secondContactPerson.lastName ?? "",
                    isAdmin: Boolean(defaultValues?.secondContactPerson.isAdmin),
                }}
                onInviteContact={setInviteContact}
                onChangeContact={onChangeContact}
                firstNameRequired={false}
                lastNameRequired={false}
                emailRequired={false}
                whichType={whichType}
                allUsers={allUsers}
                isEdit={isEdit}
                functionValueContactPerson={"inviteSecondContact"}
                validateForm={validateForm}
                onFormIsValid={setSecondContactPersonFormData}
                showMakeAdminCheckboxes={showMakeAdminCheckboxes}/>
        </div>
    </div>
}

export default AgenciesCompanyForm