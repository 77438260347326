import {DropdownOption} from "atmosfair-ui";
import {AgencyProps} from "../api/model/agency";
import {CountryAlpha2Props, CountryProps} from "../components/settings/utils";

export const convertInternationalPhonePrefixesToDropdownFormat = (internationalPhonePrefixes: CountryAlpha2Props): DropdownOption[] => {
    return (Object.keys(internationalPhonePrefixes) as Array<keyof CountryAlpha2Props>).map((key, index) => {
        const name = internationalPhonePrefixes[key]
        return {
            label: name,
            value: index,
            raw: {name, id: index, key: key as string}
        }
    });
}

export const convertCountriesToDropdownFormat = (countries: string[]): DropdownOption[] => {
    return countries.map((country, index) => {
        return {
            label: country,
            value: index,
            raw: {name: country, id: index}
        }
    });
}

export const convertAgenciesToDropdownFormat = (agencies: AgencyProps[]): DropdownOption[] => {
    return agencies.map(agency => {
        const {name, id} = agency
        return {
            label: name,
            value: id,
            raw: agency
        }
    })
}

export const convertCountriesFromDropdownFormat = (countries: DropdownOption[] | null): CountryProps | null => {
    if (countries === null) return null
    return countries.map(country => country.raw)[0]
}

export const convertAgenciesFromDropdownFormat = (agencies: DropdownOption[] | null): AgencyProps | null => {
    if (agencies === null) return null
    return agencies.map(agency => agency.raw)[0]
}