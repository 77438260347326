import React, {createContext, CSSProperties, useContext, useMemo} from 'react'
import './styles.css'
import {JourneyState} from "../../redux/reducer/journey";
import {ActivityCommonProperties} from "../../types-enums/activity-common-properties";
import {getActivitiesByDay, getActivitiesByTravelOptionTypeAndDay} from "../../utils/activities-getter";
import {generateProfessionalFlightRouteStringWithoutCo2} from "../../utils/generate-route-strings";
import {getCo2EForDayOfJourney} from "../../utils/co2-getter";
import {useTranslation} from "react-i18next";
import {useResizeDetector} from "react-resize-detector";
import {formatCo2ETotal} from "../../utils/string-mutation";
import {useNavigate} from "react-router-dom";
import {PATH_STRINGS} from "../../routes/route-props";
import {TRAVEL_OPTION_TYPE} from "../../types-enums/travel-option";
import {useSortable} from "@dnd-kit/sortable";
import {DraggableSyntheticListeners} from "@dnd-kit/core";
import {CSS} from "@dnd-kit/utilities";
import {TruncatedWordList} from "atmosfair-ui"
import {isActivityFlightState} from "../../utils/type-guards";
import {AllActivityTypesArrayDiscriminating} from "../../types-enums/activity";
import {getLastLeg} from "../../utils/flight-getter";


interface DayCardProps {
    travelOptions: JourneyState["travelOptions"],
    day: ActivityCommonProperties["common"]["day"],
    deleteDayFromJourney: () => void,
    deleteActivityFromJourney: (activityType: ActivityCommonProperties["common"]["activityType"], travelOptionType: TRAVEL_OPTION_TYPE, index: number) => void,
    editActivity: (activityType: ActivityCommonProperties["common"]["activityType"], travelOptionType: TRAVEL_OPTION_TYPE, index: number) => void,
    dndId: string
}

const DISPLAY_ORDER_TRAVEL_OPTIONS = [TRAVEL_OPTION_TYPE.transport, TRAVEL_OPTION_TYPE.accommodation, TRAVEL_OPTION_TYPE.undertaking]

interface Context {
    attributes: Record<string, any>;
    listeners: DraggableSyntheticListeners;

    ref(node: HTMLElement | null): void;
}

const SortableItemContext = createContext<Context>({
    attributes: {},
    listeners: undefined,
    ref() {
    }
});

/**
 * A component that displays each day in a journey. The order of how the travel options are displayed is set by the constant DISPLAY_ORDER_TRAVEL_OPTIONS
 * @param travelOptions all the available travel options from the store.
 * @param deleteDayFromJourney callback to delete a day from the journey
 * @param deleteActivityFromJourney callback to delete an activity from a given day
 * @param editActivity callback to edit an activity from a given day
 * @param day the corresponding day of the displayed journey
 * @param dndId id used for react dnd
 * **/

const DayCard = ({
                     dndId,
                     travelOptions,
                     day,
                     deleteDayFromJourney,
                     deleteActivityFromJourney,
                     editActivity,
                 }: DayCardProps) => {
    const navigate = useNavigate()
    const {i18n} = useTranslation();
    const currentLanguage = i18n.resolvedLanguage;
    const {ref: resizeRef, width} = useResizeDetector();
    const {
        attributes,
        isDragging,
        listeners,
        setNodeRef,
        setActivatorNodeRef,
        transform,
        transition,
    } = useSortable({
        id: dndId
    })
    const {ref} = useContext(SortableItemContext)

    const context = useMemo(
        () => ({
            attributes,
            listeners,
            ref: setActivatorNodeRef
        }),
        [attributes, listeners, setActivatorNodeRef]
    );
    const style: CSSProperties = {
        opacity: isDragging ? 0.4 : undefined,
        transform: CSS.Translate.toString(transform),
        // transition
    };

    const _renderButtons = (activityType: ActivityCommonProperties["common"]["activityType"], travelOptionType: TRAVEL_OPTION_TYPE, index: number) =>
        <div className={"day-card-activity-button-wrapper"}>
            <button onClick={() => deleteActivityFromJourney(activityType, travelOptionType, index)}>DELETE</button>
            <button onClick={() => editActivity(activityType, travelOptionType, index)}>EDIT</button>
        </div>

    const _renderActivities = (activities: AllActivityTypesArrayDiscriminating, travelOptionType: TRAVEL_OPTION_TYPE) => {
        const alreadyAddedActivities = activities.map((activity, index) => {
            if (isActivityFlightState(activity)) {
                const {legs, common} = activity
                const {activityType} = common
                return <span key={index} className={"day-card-travel-option-selection-button-wrapper"}>
                    <TruncatedWordList
                        triggerReRender={width}
                        key={index}>
                        {generateProfessionalFlightRouteStringWithoutCo2(legs, i18n).flightRouteString}
                    </TruncatedWordList>
                    {_renderButtons(activityType, travelOptionType, index)}
                </span>
            }
        })

        return <>
            {alreadyAddedActivities}
            {_renderActivityAddButton(travelOptionType)}
        </>
    }

    const _renderActivityAddButton = (travelOptionType: TRAVEL_OPTION_TYPE) =>
        <button onClick={() => navigate(`${PATH_STRINGS.addTravelOption}/${travelOptionType}/`)}>GENERAL.ADD</button>


    const _renderTravelOptions = () =>
        <div
            ref={resizeRef}
            className={'day-card-travel-options-selections-wrapper'}>
            {DISPLAY_ORDER_TRAVEL_OPTIONS.map((travelOptionType, index) => {
                const activities = travelOptions[travelOptionType]
                const activitiesByDay = getActivitiesByDay(activities, day)
                return <div key={index} className={'day-card-travel-options'}>
                <span className={"day-card-travel-option-string"}>
                    <p style={{fontWeight: "bold"}}>{`GENERAL.${travelOptionType.toUpperCase()}`}</p>
                </span>
                    <span className={"day-card-travel-option-selection"}>
                        {
                            activitiesByDay.length > 0 ?
                                _renderActivities(activitiesByDay, travelOptionType) :
                                _renderActivityAddButton(travelOptionType)
                        }
                    </span>
                </div>
            })}
        </div>

    const _renderHeadlineRow = () => {
        const co2EPerDay = getCo2EForDayOfJourney(travelOptions, day)
        const accommodationActivities = getActivitiesByTravelOptionTypeAndDay(travelOptions, TRAVEL_OPTION_TYPE.accommodation, day)
        const transportActivities = getActivitiesByTravelOptionTypeAndDay(travelOptions, TRAVEL_OPTION_TYPE.transport, day)
        let place = ''
        if (accommodationActivities.length > 0) {
            // SEE ELSE STATEMENT
        } else {
            if (transportActivities.length > 0) {
                const lastElement = transportActivities[transportActivities.length - 1]
                if (isActivityFlightState(lastElement)) {
                    const {legs} = lastElement
                    const lastLeg = getLastLeg(legs)
                    place = `${lastLeg?.airport?.name} (${lastLeg?.airport?.country})`
                }

            }
        }

        return <div className={"day-card-title-row"}>
            <div className={"day-card-title-wrapper"}>
                <button className={'day-card-grip-handle'} {...attributes} {...listeners} ref={ref}>
                    <svg viewBox="0 0 20 20" width="15">
                        <path
                            d="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z"></path>
                    </svg>
                </button>
                <h2 style={{color: "white"}}>{`GENERAL.DAY ${day}`}</h2>
                <button onClick={deleteDayFromJourney}>GENERAL.DELETE</button>
            </div>
            <p style={{color: "white"}}>{place && co2EPerDay && `${place}, GENERAL.TOTAL: ${formatCo2ETotal(co2EPerDay, currentLanguage)}`}</p>
        </div>
    }

    return <SortableItemContext.Provider value={context}>
        <li
            ref={setNodeRef}
            className={"day-card-container"}
            style={style}>
            {_renderHeadlineRow()}
            {_renderTravelOptions()}
        </li>
    </SortableItemContext.Provider>
}

export default DayCard