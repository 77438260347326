import React from "react"
import "./styles.css"
import {Text} from "atmosfair-ui"

interface ActivityCardProps {
    title: string,
    onClick: () => void
}

const ActivityCard = ({
                          title,
                          onClick,
                      }: ActivityCardProps) => {

    return <button
        onClick={onClick}
        className={"activity-card-container"}>
        <Text
            style={{marginBottom: 0}}
            color={"white"}
            variant={"headlineMedium"}>
            {title}
        </Text>
    </button>
}

export default ActivityCard