import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { persistor, store } from './redux/reducer/store';
import { PersistGate } from "redux-persist/integration/react";
import { RouterProvider, } from "react-router-dom";
import router from "./routes/Routes";
import "./styles/normalize.css"
import "./styles/general.css"
import "./styles.css"
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { toast, ToastContainer } from "react-toastify";
import { config } from "./fusion-auth-cors-config/fusion-auth-config";
import { FusionAuthProvider } from "@fusionauth/react-sdk";
import { ThemeProvider } from "atmosfair-ui";
import { theme } from "./styles/theme";
import './i18';
import "./styles/override-atmosfair-ui-styles.css"
import "./styles/override-react-toastify-styles.css"
import "./styles/override-react-date-range-styles.css"

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <FusionAuthProvider {...config} >
                    <ThemeProvider {...theme}>
                        <RouterProvider router={router} />
                        <ToastContainer
                            pauseOnFocusLoss={false}
                            pauseOnHover={true}
                            newestOnTop={false}
                            closeOnClick={false}
                            position={toast.POSITION.TOP_CENTER}
                            autoClose={5000}
                            hideProgressBar={false}
                            draggable={false}
                            theme={"colored"}
                            icon={false}
                            rtl={false} />
                        <div id={"modal-container-anchor"} />
                    </ThemeProvider>
                </FusionAuthProvider>
            </PersistGate>
        </Provider>
    </>
);

reportWebVitals();
