import {ActivityCommonProperties} from "../../types-enums/activity-common-properties";
import {getActivities} from "../../utils/activities-getter";
import {TravelOptionToActivitiesMap} from "../../types-enums/activity";

export const deleteItem = (travelOptions: TravelOptionToActivitiesMap, payload: {
    activityType: ActivityCommonProperties["common"]["activityType"],
    index: number,
    day: ActivityCommonProperties["common"]["day"],
}) => {
    const payloadActivityType = payload.activityType
    const payloadDay = payload.day
    const payloadIndex = payload.index
    getActivities(travelOptions, (activities, travelOptionType) => {
        activities.forEach((activity, index) => {
            const {activityType} = activity.common
            const activityDay = activity.common.day
            if (
                activityDay === payloadDay &&
                payloadActivityType === activityType &&
                index === payloadIndex
            ) travelOptions[travelOptionType].splice(index, 1)
        });
    });
    return travelOptions
}