import React, {useEffect, useState} from "react"
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../redux/reducer/store";
import {
    generateCustomerFlightRouteStringForClipboard,
    generateFailedOrSuccessfulFlightLegsString,
    generateProfessionalFlightRouteStringWithoutCo2
} from "../../utils/generate-route-strings";
import {isActivityFlightState, isActivityHotelState} from "../../utils/type-guards";
import {
    COMMA_SEPARATOR,
    formatCo2EPerPerson,
    formatCo2EPerPersonForHotels,
    formatCo2ETotal,
    formatCo2ETotalForHotel,
    formatDate,
    NO_DATA_STRING,
    ROUTE_SEPARATOR
} from "../../utils/string-mutation";
import {useTranslation} from "react-i18next";
import "./styles.css"
import {
    deleteActivityFromTravelComponents,
    deleteActivityFromTravelComponentsQueue,
    undoDeleteActivityFromTravelComponents
} from "../../redux/reducer/travel-components";
import {toast, ToastItem} from "react-toastify";
import ToastWithTextOnly from "../../molecules/toasts/toast-with-text-only/ToastWithTextOnly";
import download from "downloadjs";
import {createTravelComponentsPdf} from "../../pdf-creation/create-travel-components-pdf";
import {get, isEqual, orderBy} from "lodash"
import {
    DataGrid,
    DataGridDataRowProps,
    DataGridHeaderRowProps,
    getSortProps,
    Icon,
    IconButton,
    Modal,
    RadioButtonGroup,
    SORT_ORDER,
    SortElementProps,
    Text,
    TextButton,
    TruncatedWordList,
} from "atmosfair-ui"
import {PATH_STRINGS} from "../../routes/route-props";
import ToastWithButton from "../../molecules/toasts/toast-with-button/ToastWithButton";
import {
    CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL,
    CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL,
    GENERAL_BACKEND_RESPONSE
} from "../../api/model/common";
import clsx from "clsx";
import {Tooltip} from "@mui/material";
import {hasPartialCalculationError} from "../../utils/emissions";
import {AllActivityTypes, AllActivityTypesArrayDiscriminating} from "../../types-enums/activity";
import CommonTableButtons from "../../molecules/common-table-buttons/CommonTableButtons";
import {DateTime} from "luxon";
import {
    createRoundtripPassengersCountString,
    generateFlightTypeString,
    generatePassengerString
} from "../../components/activity/flight/choose-flight-connection";
import {roundCo2EPerPerson} from "../../utils/round-values";
import cloneDeep from "lodash/cloneDeep";
import {theme} from "../../styles/theme";
import {AgenciesLogosValuesProps} from "../../redux/reducer/user";
import {allInternationalPhonePrefixesFormattedForDropdownAlpha2CountryCode} from "../../components/settings/utils";
import {ActivityFlightState} from "../../types-enums/activity-flight";
import {generateCustomerHotelLocationString} from "../../utils/generate-location-strings";
import format from 'date-fns/format';
import {getDatePattern} from "../../components/activity/hotel/choose-hotel/utils";
import {getFirstLeg, getLastLeg} from "../../utils/flight-getter";
import SaveTravelComponentsDialogue
    from "../../components/save-travel-components-dialogue/SaveTravelComponentsDialogue";
import {useCreateBundleMutation} from "../../redux/reducer/api";
import {VALIDATE_FORM_STARTING_NUMBER} from "../../constants";

enum KEYPATH_TO_VALUE {
    calculatedEmissions = "calculatedEmissions.co2",
    iata = "legs.firstLeg.airport.iata",
    passengerCount = "passengerCount",
    city = "city.name"
}

const TravelComponentsTable = () => {
    const {userData, agencies, agenciesLogos} = useAppSelector(state => state.user)
    const [sortOrderName, setSortOrderName] = useState<SortElementProps["active"]>(null)
    const [validateSaveTravelComponentsForm, setValidateSaveTravelComponentsForm] = useState(VALIDATE_FORM_STARTING_NUMBER)
    const [sortOrderEmissions, setSortOrderEmissions] = useState<SortElementProps["active"]>(null)
    const [sortOrderTravelersCount, setSortOrderTravelersCount] = useState<SortElementProps["active"]>(null)
    const [selectedAgency, setSelectedAgency] = useState(0)
    const [saveTravelComponentsModalVisible, setSaveTravelComponentsModalVisible] = useState(false)
    const [headers, setHeaders] = useState<DataGridHeaderRowProps["headers"]>([])
    const [rows, setRows] = useState<DataGridDataRowProps["rows"]>([])
    const [selectedRows, setSelectedRows] = useState<number[]>([])
    const {activities} = useAppSelector(state => state.travelComponents)
    const [groupRows, setGroupRows] = useState(false)
    const [selectAgencyModalVisible, setSelectAgencyModalVisible] = useState(false)
    const navigate = useNavigate()
    const {i18n, t} = useTranslation();
    const currentLanguage = i18n.resolvedLanguage;
    const dispatch = useAppDispatch()
    const datePattern = getDatePattern(i18n.language === 'en' ? 'en-GB' : i18n.language);

    const [createBundle, {
        data: createBundleResponse,
        isLoading: isLoadingCreateBundle,
        isError: isErrorCreateBundle
    }] = useCreateBundleMutation()

    useEffect(() => {
        setHeaders(_getHeaders())
    }, [sortOrderEmissions, sortOrderName, sortOrderTravelersCount]);

    useEffect(() => {
        setRows(_getRows())
    }, [activities, currentLanguage]);

    useEffect(() => {
        if (createBundleResponse?.status === GENERAL_BACKEND_RESPONSE.success) {
            setValidateSaveTravelComponentsForm(VALIDATE_FORM_STARTING_NUMBER)
            setSaveTravelComponentsModalVisible(false)
        }
    }, [createBundleResponse])

    const _deleteActivity = (index: number, rawData: ActivityFlightState) => {
        const queueId = rawData.common.id
        dispatch(deleteActivityFromTravelComponents(index))
        _renderDeleteActivityToast(queueId, index)
    }

    const _getHeaders = () => [
        {
            text: "GENERAL.NAME",
            sort: {
                onCaretDown: () => {
                    setSortOrderName(SORT_ORDER.ASC)
                    setSortOrderEmissions(SORT_ORDER.ASC)
                    setSortOrderTravelersCount(null)
                },
                onCaretUp: () => {
                    setSortOrderName(SORT_ORDER.DESC)
                    setSortOrderEmissions(SORT_ORDER.DESC)
                    setSortOrderTravelersCount(null)
                },
                disabled: false,
                sortOrder: sortOrderName,
            },
        },
        {
            text: "GENERAL.TRAVELERS_COUNT",
            sort: {
                onCaretDown: () => {
                    setSortOrderTravelersCount(SORT_ORDER.ASC)
                    setSortOrderEmissions(null)
                    setSortOrderName(null)
                },
                onCaretUp: () => {
                    setSortOrderTravelersCount(SORT_ORDER.DESC)
                    setSortOrderEmissions(null)
                    setSortOrderName(null)
                },
                disabled: false,
                sortOrder: sortOrderTravelersCount,
            }
        },
        {text: "GENERAL.DATE_OF_ACTIVITY"},
        {
            text: "GENERAL.EMISSIONS_PER_PERSON",
            sort: {
                onCaretDown: () => {
                    setSortOrderEmissions(SORT_ORDER.ASC)
                    setSortOrderTravelersCount(null)
                    setSortOrderName(null)
                },
                onCaretUp: () => {
                    setSortOrderEmissions(SORT_ORDER.DESC)
                    setSortOrderTravelersCount(null)
                    setSortOrderName(null)
                },
                sortOrder: sortOrderEmissions,
                disabled: false,
            },
        }
    ]

    const _renderDeleteActivityToast = (
        queueId: string,
        index: number,
    ) => {
        let undoClicked = false;
        const toastId = toast(
            <ToastWithButton
                message={"GENERAL.UNDO"}
                onClick={() => {
                    undoClicked = true
                    dispatch(undoDeleteActivityFromTravelComponents({
                        id: queueId,
                        index,
                    }))
                    toast.dismiss(toastId)
                }}/>
            ,
            {
                progress: undefined,
                type: 'success',
            }
        );

        const unsubscribe = toast.onChange((payload: ToastItem) => {
            switch (payload.status) {
                case "added":
                    break;
                case "updated":
                    break;
                case "removed":
                    !undoClicked && payload.id === toastId && dispatch(deleteActivityFromTravelComponentsQueue(queueId))
                    unsubscribe()
                    break;
            }
        })
    }

    const _getRows = () => {
        return activities.map((activity, index): DataGridDataRowProps["rows"][0] => {
            if (isActivityFlightState(activity)) {
                let lastCell;
                const {legs} = activity
                const {common, passengerCount} = activity
                const {totalEmissions, id} = common
                const firstLeg = getFirstLeg(legs)
                const lastLeg = getLastLeg(legs)
                const departureAirportIata = firstLeg.airport?.iata
                const arrivalAirportIata = lastLeg?.airport?.iata
                const {date} = firstLeg
                const status = totalEmissions?.status
                const co2ETotal = activity.common.totalEmissions?.emissionKgCO2eTotal
                if (status !== CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success) {
                    let failureClass = ''
                    let failureComponent
                    if (status === CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.failed) {
                        failureClass = "travel-components-table-cell-complete-failure"
                        failureComponent = <Text
                            variant={"subtitleLarge"}
                            color={"white"}
                            ellipsis={true}
                            classes={"travel-components-table-cell-failure-text"}>
                            ERRORS.CALCULATION_COMPLETE_FAILURE
                        </Text>
                    } else {
                        failureClass = 'travel-components-table-cell-partial-failure'
                        failureComponent = <Tooltip
                            title={generateFailedOrSuccessfulFlightLegsString(activity, CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.failed, i18n)}>
                            <div>
                                <Text
                                    variant={"subtitleLarge"}
                                    color={"white"}
                                    ellipsis={true}
                                    classes={"travel-components-table-cell-failure-text"}>
                                    ERRORS.CALCULATION_PARTIAL_FAILURE
                                </Text>
                            </div>
                        </Tooltip>
                    }

                    lastCell = {
                        customCell: <div
                            className={clsx(
                                "travel-components-table-cell-failure",
                                failureClass
                            )}>
                            {failureComponent}
                        </div>,
                        value: status === CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success_with_errors ? roundCo2EPerPerson(co2ETotal!, passengerCount) : -1,
                        keyPathToValue: status === CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success_with_errors ? "calculatedEmissions.co2" : undefined,
                    }
                } else {
                    lastCell = {
                        text: `${formatCo2EPerPerson(co2ETotal!, passengerCount, currentLanguage)} (${formatCo2ETotal(co2ETotal!, currentLanguage)} ${i18n.t("GENERAL.TOTAL")})`,
                        value: roundCo2EPerPerson(co2ETotal!, passengerCount),
                        keyPathToValue: KEYPATH_TO_VALUE.calculatedEmissions,
                    }
                }

                const {flightRouteString, showFootnote} = generateProfessionalFlightRouteStringWithoutCo2(legs, i18n)
                return {
                    data: [
                        {
                            customCell: <>
                                <div className={"travel-components-table-flight-route-string-icon-wrapper"}>
                                    <div style={{maxWidth: "90%"}}>
                                        <TruncatedWordList separator={ROUTE_SEPARATOR} key={index}>
                                            {flightRouteString}
                                        </TruncatedWordList>
                                    </div>
                                    {showFootnote && <Tooltip
                                        title={t("SCREENS.COMMON.HINT_BETTER_FLIGHT_EMISSION_CALCULATION_GENERAL")}>
                                        <div style={{marginTop: '2px'}}>
                                            <Icon
                                                color={theme.colors.warningLight}
                                                size={"verySmall"} icon={"exclamationCircle"}/>
                                        </div>
                                    </Tooltip>}
                                </div>
                            </>,
                            value: `${departureAirportIata}${arrivalAirportIata}`,
                            keyPathToValue: KEYPATH_TO_VALUE.iata
                        },
                        {
                            text: passengerCount,
                            value: passengerCount,
                            keyPathToValue: KEYPATH_TO_VALUE.passengerCount
                        },
                        {
                            text: date ? formatDate(date, currentLanguage) : NO_DATA_STRING,
                            //@ts-ignore
                            value: date ? DateTime.fromISO(date).toMillis() : -1,
                        },
                        lastCell,
                    ],
                    group: `ACTIVITIES.${activity.common.activityType.toUpperCase()}.TITLE`,
                    key: id,
                    rawData: activity
                }
            }
            if (isActivityHotelState(activity)) {
                let lastCell;
                const {hotel, city, country, numberOfPeople, startDate, endDate} = activity
                const formattedStartDate = startDate ? format(new Date(startDate), datePattern) : ""
                const formattedEndDate = endDate ? format(new Date(endDate), datePattern) : ""
                const {common} = activity
                const {totalEmissions, id} = common
                const status = totalEmissions?.status
                const co2ETotal = activity.common.totalEmissions?.emissionKgCO2eTotal
                if (status !== CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success) {
                    let failureClass = ''
                    let failureComponent
                    if (status === CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.failed) {
                        failureClass = "travel-components-table-cell-complete-failure"
                        failureComponent = <Text
                            variant={"subtitleLarge"}
                            color={"white"}
                            ellipsis={true}
                            classes={"travel-components-table-cell-failure-text"}>
                            ERRORS.CALCULATION_COMPLETE_FAILURE
                        </Text>
                    }
                    lastCell = {
                        customCell: <div
                            className={clsx(
                                "travel-components-table-cell-failure",
                                failureClass
                            )}>
                            {failureComponent}
                        </div>,
                        value: status === CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success_with_errors ? (co2ETotal! / numberOfPeople) : -1,
                        keyPathToValue: status === CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success_with_errors ? "calculatedEmissions.co2" : undefined,
                    }
                } else {
                    lastCell = {
                        text: `${formatCo2EPerPersonForHotels(co2ETotal!, numberOfPeople, currentLanguage)} (${formatCo2ETotalForHotel(co2ETotal!, currentLanguage)} ${i18n.t("GENERAL.TOTAL")})`,
                        value: (co2ETotal! / numberOfPeople),
                        keyPathToValue: KEYPATH_TO_VALUE.calculatedEmissions,
                    }
                }

                const {locationString} = generateCustomerHotelLocationString(activity, i18n, true)
                return {
                    data: [
                        {
                            customCell: <>
                                <div className={"travel-components-table-flight-route-string-icon-wrapper"}>
                                    <div style={{maxWidth: "90%"}}>
                                        <TruncatedWordList separator={COMMA_SEPARATOR} key={index}>
                                            {locationString}
                                        </TruncatedWordList>
                                    </div>
                                </div>
                            </>,
                            value: `${hotel ? `${hotel.name}` : ''}${city?.name}${country?.alpha3}`,
                            keyPathToValue: KEYPATH_TO_VALUE.city
                        },
                        {
                            text: numberOfPeople,
                            value: numberOfPeople
                        },
                        {
                            text: `${formattedStartDate}-${formattedEndDate}`,
                            value: formattedStartDate
                        },
                        lastCell,
                    ],
                    group: `ACTIVITIES.${activity.common.activityType.toUpperCase()}.TITLE`,
                    key: id,
                    rawData: activity
                }
            }
        })
    }

    const _isActivitySelected = (activity: AllActivityTypes) => {
        if (selectedRows.length > 0) return selectedRows.some(selectedRowIndex => isEqual(activities[selectedRowIndex], activity))
        return true
    }

    const _copyToClipboard = async () => {
        let text = ''
        let addFootnote;
        _sortActivities().forEach(activity => {
            if (_isActivitySelected(activity)) {
                if (isActivityFlightState(activity)) {
                    if (activity.common?.totalEmissions?.status !== CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.failed) {
                        const {common, passengerCount, returnFlight} = activity
                        const co2E = common.totalEmissions?.emissionKgCO2eTotal
                        const {
                            flightRouteString,
                            showFootnote
                        } = generateCustomerFlightRouteStringForClipboard(activity, i18n, false)
                        if (showFootnote) addFootnote = true
                        const commonText = `${text}${flightRouteString}\n${createRoundtripPassengersCountString({
                            flightCount: 0,
                            passengerCount,
                            forString: t("GENERAL.FOR"),
                            passengerCountString: t(generatePassengerString(passengerCount)),
                            returnOrOnewayString: t(generateFlightTypeString(1, returnFlight))
                        })}`
                        if (co2E) {
                            const co2EPerPerson = `${formatCo2EPerPerson(co2E, passengerCount, currentLanguage)}`
                            const co2EPerPersonString = `${t('GENERAL.PER_PERSON')} ${co2EPerPerson}`
                            const co2ETotalString = `(${t('GENERAL.TOTAL')} ${formatCo2ETotal(co2E, currentLanguage)})`
                            if (hasPartialCalculationError(activity)) {
                                text = `${commonText}\n${generateFailedOrSuccessfulFlightLegsString(activity, CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL.success, i18n)}`
                            } else {
                                text = `${commonText}\n${co2EPerPersonString} ${co2ETotalString}`
                            }
                        }
                    }
                }
                if (isActivityHotelState(activity)) {
                    if (activity.common?.totalEmissions?.status !== CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.failed) {
                        const {common, numberOfPeople} = activity
                        const co2E = common.totalEmissions?.emissionKgCO2eTotal
                        const {
                            locationString,
                            returnStayString
                        } = generateCustomerHotelLocationString(activity, i18n, false)
                        const commonText = `${text}${locationString}\n${returnStayString}`
                        if (co2E) {
                            const co2EPerPerson = `${formatCo2EPerPersonForHotels(co2E, numberOfPeople, currentLanguage)}`
                            const co2EPerPersonString = `${t('GENERAL.PER_PERSON')} ${co2EPerPerson}`
                            const co2ETotalString = `(${t('GENERAL.TOTAL')} ${formatCo2ETotalForHotel(co2E, currentLanguage)})`
                            const totalNumberOfPeopleString = `${numberOfPeople} ${t(generatePassengerString(numberOfPeople))}`
                            text = `${commonText}\n${totalNumberOfPeopleString}, ${co2EPerPersonString} ${co2ETotalString}\n\n`
                        }
                    }
                }
            }
        })
        if (addFootnote) text = `${text}\n*${t("SCREENS.COMMON.HINT_BETTER_FLIGHT_EMISSION_CALCULATION_SPECIFIC")}`
        await navigator.clipboard.writeText(text)
        toast(<ToastWithTextOnly message={`${t("SCREENS.TRAVEL_COMPONENTS_OVERVIEW.TRAVEL_COMPONENTS_COPIED")}`}/>,
            {progress: undefined, type: 'success',});
    }

    const _getPersonCount = (item: AllActivityTypes) => {
        if (isActivityFlightState(item)) return item.passengerCount
        if (isActivityHotelState(item)) return item.numberOfNights
        return 1
    }

    const _sortActivities = () => {
        const sortProps = getSortProps(headers);
        let activitiesSorted = cloneDeep(activities) as AllActivityTypesArrayDiscriminating;

        if (sortProps) {
            const {indexesOfColumnsToSort, sortOrders} = sortProps;
            const rowForDataGrid = _getRows()[0];

            if (rowForDataGrid && rowForDataGrid.data) {
                const sortByFunctions = indexesOfColumnsToSort.map((indexOfColumnToSort) => {
                    const {keyPathToValue} = rowForDataGrid.data![indexOfColumnToSort];

                    if (keyPathToValue) {
                        if (keyPathToValue === KEYPATH_TO_VALUE.passengerCount) return (item: AllActivityTypes) =>
                            get(item, KEYPATH_TO_VALUE.passengerCount)!;

                        if (keyPathToValue === KEYPATH_TO_VALUE.calculatedEmissions) return (item: AllActivityTypes) =>
                            get(item, KEYPATH_TO_VALUE.calculatedEmissions)! /
                            _getPersonCount(item);

                        if (keyPathToValue === KEYPATH_TO_VALUE.iata) return (item: AllActivityTypes) => [
                            get(item, 'legs.firstLeg.airport.iata')!,
                            get(item, 'legs.lastLeg.iata')!,
                            get(item, KEYPATH_TO_VALUE.calculatedEmissions)! /
                            _getPersonCount(item),
                        ];
                        if (keyPathToValue === KEYPATH_TO_VALUE.city) return (item: AllActivityTypes) => [
                            get(item, 'city.name')!,
                            get(item, KEYPATH_TO_VALUE.calculatedEmissions)! /
                            _getPersonCount(item),
                        ];
                    }

                    // Default function if no match is found
                    return (item: AllActivityTypes) => item;
                });

                activitiesSorted = orderBy(
                    activitiesSorted,
                    sortByFunctions,
                    indexesOfColumnsToSort.map(index => sortOrders[index] === SORT_ORDER.DESC ? 'desc' : 'asc')
                );
            }
        }

        return activitiesSorted;
    };

    const _createPDF = async (
        userName: string,
        agencyName: string,
        agencyAddress: string,
        email: string,
        completePhoneNumber: string,
        pathToLogo: AgenciesLogosValuesProps["arrayBuffer"] | null,
        logoFileType: AgenciesLogosValuesProps["fileType"] | null,
    ) => {
        const activitiesForPdf: AllActivityTypesArrayDiscriminating = []
        _sortActivities().forEach(activity => _isActivitySelected(activity) && activitiesForPdf.push(activity))
        const pdfBytes = await createTravelComponentsPdf(activitiesForPdf, i18n, agencyName, agencyAddress, userName, email, completePhoneNumber, {
            pathToLogo: pathToLogo,
            fileType: logoFileType
        })
        download(pdfBytes, `${userName} ${agencyName} ${t("GENERAL.TRAVEL_COMPONENTS")}.pdf`, "application/pdf");
    }

    const _noAgenciesToast = () => toast(<ToastWithTextOnly
            message={`${t("SCREENS.TRAVEL_COMPONENTS_OVERVIEW.NO_AGENCY_PDF")}`}/>,
        {progress: undefined, type: 'error'})

    const _onSaveTravelComponents = async () => {
        if (agencies?.length === 0) {
            _noAgenciesToast()
        } else {
            setSaveTravelComponentsModalVisible(true)
        }
    }

    const _onDownloadPdf = async () => {
        if (userData && agencies) {
            if (agencies.length === 1) {
                const userNameAgencyInfo = _getUserNameAndAgency(0)
                if (userNameAgencyInfo) {
                    const {
                        userName,
                        agencyName,
                        agencyAddress,
                        pathLogo,
                        agencyLogoFileType,
                        email,
                        completePhoneNumber
                    } = userNameAgencyInfo
                    _createPDF(userName, agencyName, agencyAddress, email, completePhoneNumber, pathLogo, agencyLogoFileType)
                }
            } else if (agencies.length === 0) {
                _noAgenciesToast()
            } else {
                setSelectAgencyModalVisible(true)
            }
        }
    }

    const _selectRow = (index: number) => {
        if (selectedRows.includes(index)) {
            setSelectedRows(selectedRows.filter(row => row !== index));
        } else {
            setSelectedRows([...selectedRows, index])
        }
    }

    const _getUserNameAndAgency = (index: number) => {
        if (userData && agencies) {
            const {firstName, lastName, email, phoneNumber, internationalPhonePrefix} = userData
            setSelectAgencyModalVisible(false)
            const {name, address, id} = agencies[index]
            let pathLogo: ArrayBuffer | null = null
            let agencyLogoFileType: string | null = null
            if (agenciesLogos && agenciesLogos[id]) {
                const {arrayBuffer, fileType} = agenciesLogos[id]
                if (arrayBuffer) pathLogo = arrayBuffer
                if (fileType) agencyLogoFileType = fileType
            }
            const {street, zipCode, city, houseNumber} = address
            const agencyName = name
            const agencyAddress = `${street} ${houseNumber}, ${city} ${zipCode}`
            const userName = `${firstName} ${lastName}`
            const completePhoneNumber = `${allInternationalPhonePrefixesFormattedForDropdownAlpha2CountryCode[internationalPhonePrefix]} ${phoneNumber}`
            return {userName, agencyName, agencyAddress, pathLogo, agencyLogoFileType, email, completePhoneNumber}
        }
    }

    return <>
        {activities.length > 0 ?
            <>
                <div className={"travel-components-table-buttons-wrapper"}>
                    <CommonTableButtons
                        onClickGroupRows={() => setGroupRows(!groupRows)}
                        onClickSelectAll={() => selectedRows.length === 0 ? setSelectedRows(activities.map((_, index) => index)) : setSelectedRows([])}/>
                    <div className={"travel-components-table-share-buttons-wrapper"}>
                        <IconButton
                            tooltip={"SCREENS.TRAVEL_COMPONENTS_OVERVIEW.COPY_CLIPBOARD_TOOLTIP"}
                            color={"primary"}
                            size={"large"}
                            icon={"copy"}
                            onlyIcon={true}
                            onClick={_copyToClipboard}/>
                        <IconButton
                            tooltip={"SCREENS.TRAVEL_COMPONENTS_OVERVIEW.CREATE_PDF_TOOLTIP"}
                            color={"primary"}
                            size={"large"}
                            onlyIcon={true}
                            icon={"pdf"}
                            onClick={_onDownloadPdf}/>
                        {/*<IconButton*/}
                        {/*    tooltip={"SCREENS.TRAVEL_COMPONENTS_OVERVIEW.SAVE_TRAVEL_COMPONENTS"}*/}
                        {/*    color={"primary"}*/}
                        {/*    size={"large"}*/}
                        {/*    onlyIcon={true}*/}
                        {/*    icon={"save"}*/}
                        {/*    onClick={_onSaveTravelComponents}/>*/}
                        {/*<IconButton*/}
                        {/*    tooltip={"SCREENS.TRAVEL_COMPONENTS_OVERVIEW.CREATE_JOURNEY"}*/}
                        {/*    color={"primary"}*/}
                        {/*    size={"large"}*/}
                        {/*    onlyIcon={true}*/}
                        {/*    icon={"suitcase"}*/}
                        {/*    onClick={() => {*/}
                        {/*    }}/>*/}
                    </div>
                </div>
                {headers.length > 0 && rows.length > 0 && <DataGrid
                    checkboxLabel={"SCREENS.TRAVEL_COMPONENTS_OVERVIEW.SAVE_TRAVEL_COMPONENTS_MODAL.CHECKBOX_LABEL"}
                    look={"primaryLight"}
                    groupRows={groupRows}
                    cellWeights={[3, 1, 1, 2]}
                    headers={headers}
                    rows={rows}
                    rowSize={"medium"}
                    selectedRows={selectedRows}
                    lastRowText={"SCREENS.TRAVEL_OPTIONS_OVERVIEW.ADD"}
                    onEditTooltip={"SCREENS.TRAVEL_COMPONENTS_OVERVIEW.EDIT_TRAVEL_COMPONENT_TOOLTIP"}
                    onDeleteTooltip={"SCREENS.TRAVEL_COMPONENTS_OVERVIEW.DELETE_TRAVEL_COMPONENT_TOOLTIP"}
                    onClickLastRow={() => navigate(PATH_STRINGS.travelDatabase)}
                    onSelectRow={index => _selectRow(index)}
                    onEditRow={index => navigate(`${PATH_STRINGS.editActivity}/${activities[index].common.activityType}/${index}/`)}
                    onDeleteRow={_deleteActivity}/>}
                {agencies &&
                    <Modal
                        closeButton={"GENERAL.ABORT"}
                        onClickClose={() => {
                            setSaveTravelComponentsModalVisible(false)
                            setValidateSaveTravelComponentsForm(VALIDATE_FORM_STARTING_NUMBER)
                        }}
                        headline={"SCREENS.TRAVEL_COMPONENTS_OVERVIEW.SAVE_TRAVEL_COMPONENTS_MODAL.TITLE"}
                        showPaper={true}
                        onClickConfirm={() => setValidateSaveTravelComponentsForm(validateSaveTravelComponentsForm + 1)}
                        confirmButton={"GENERAL.SAVE"}
                        showSpinner={isLoadingCreateBundle}
                        show={saveTravelComponentsModalVisible}>
                        <SaveTravelComponentsDialogue
                            setIsFormValid={createBundle}
                            validateForm={validateSaveTravelComponentsForm}
                            agencies={agencies}
                            travelComponents={cloneDeep(activities).filter(_isActivitySelected)}/>
                    </Modal>
                }
                <Modal
                    onClickClose={() => {
                        setSelectAgencyModalVisible(false)
                        setSelectedAgency(0)
                    }}
                    onClickConfirm={() => {
                        setSelectAgencyModalVisible(false)
                        const userNameAgencyInfo = _getUserNameAndAgency(selectedAgency)
                        if (userNameAgencyInfo) {
                            const {
                                userName,
                                agencyName,
                                agencyAddress,
                                pathLogo,
                                agencyLogoFileType,
                                email,
                                completePhoneNumber
                            } = userNameAgencyInfo
                            _createPDF(userName, agencyName, agencyAddress, email, completePhoneNumber, pathLogo, agencyLogoFileType)
                        }
                    }}
                    confirmButton={"GENERAL.OK"}
                    closeButton={"GENERAL.ABORT"}
                    headline={"SCREENS.TRAVEL_COMPONENTS_OVERVIEW.SELECT_AGENCY_MODAL_TITLE"}
                    showPaper={true}
                    show={selectAgencyModalVisible}>
                    <Text style={{marginBottom: theme.spacer.large}}>
                        SCREENS.TRAVEL_COMPONENTS_OVERVIEW.SELECT_AGENCY_MODAL_EXPLANATION
                    </Text>
                    <RadioButtonGroup
                        onChange={setSelectedAgency}
                        radioButtons={agencies?.map((agency, index) => ({label: agency.name, value: index}))!}
                        selected={selectedAgency}
                        name={"SCREENS.TRAVEL_COMPONENTS_OVERVIEW.RADIO_BUTTON_GROUP"}
                        orientation={"vertical"}
                        labelStyle={{fontSize: theme.font.fontSize.fontSizeRegular}}
                        hideLabel={false}/>

                </Modal>
            </> :
            <div className={"travel-components-table-no-data-wrapper"}>
                <Text variant={"headlineMedium"}>
                    SCREENS.TRAVEL_COMPONENTS_OVERVIEW.NO_TRAVEL_COMPONENTS
                </Text>
                <TextButton
                    onClick={() => navigate(PATH_STRINGS.travelDatabase)}>SCREENS.TRAVEL_COMPONENTS_OVERVIEW.ADD_TRAVEL_COMPONENTS</TextButton>
            </div>}
    </>
}

export default TravelComponentsTable