import React, {useEffect, useState} from "react";
import {TextButton, TextInput} from "atmosfair-ui";
import {validateCompanyName} from "../../forms/utils";
import "./styles.css"
import {theme} from "../../../styles/theme";
import {useTranslation} from "react-i18next";
import {useInviteCompanyAdminMutation} from "../../../redux/reducer/api";
import {GENERAL_BACKEND_RESPONSE} from "../../../api/model/common";
import SectionTitle from "../section-title/SectionTitle";
import NameEmailForm, {NameEmailProps} from "../../forms/name-email-form/NameEmailForm";
import {RESET_FORM_STARTING_NUMBER, VALIDATE_FORM_STARTING_NUMBER} from "../../../constants";

interface FormProps {
    companyName: string
}

interface FormErrorProps {
    companyNameError: boolean,
}

const KlimaLinkAdministration = () => {
    const [formData, setFormData] = useState<FormProps>({companyName: ''})
    const [validateForm, setValidateForm] = useState(VALIDATE_FORM_STARTING_NUMBER)
    const [resetForm, setResetForm] = useState(RESET_FORM_STARTING_NUMBER)
    const [nameEmailData, setNameEmailData] = useState<NameEmailProps | null>(null)
    const [formErrorData, setFormErrorData] = useState<FormErrorProps>({companyNameError: false})
    const {companyName,} = formData
    const {companyNameError} = formErrorData
    const {t, i18n} = useTranslation()
    const currentLanguage = i18n.resolvedLanguage

    const [inviteCompanyAdminRequest, {
        data: inviteCompanyAdminResponse,
        isLoading: isLoadingInviteCompanyAdmin,
        isError: isErrorInviteCompanyAdmin
    }] = useInviteCompanyAdminMutation()

    useEffect(() => {
        if (inviteCompanyAdminResponse?.status === GENERAL_BACKEND_RESPONSE.success) _resetForm()
    }, [inviteCompanyAdminResponse])

    useEffect(() => {
        if (validateForm > VALIDATE_FORM_STARTING_NUMBER) {
            const validForm = _validateForm()
            if (validForm && nameEmailData) {
                const {firstName, lastName, email} = nameEmailData
                inviteCompanyAdminRequest({
                    firstName,
                    lastName,
                    email,
                    companyName,
                    language: currentLanguage ?? "de",
                })
                _resetForm()
            }
        }
    }, [nameEmailData, validateForm]);

    const _updateFormData = <K extends keyof FormProps>(key: K, payload: FormProps[K]) => setFormData({
        ...formData,
        [key]: payload
    })

    const _resetForm = () => {
        setValidateForm(VALIDATE_FORM_STARTING_NUMBER)
        setResetForm(resetForm + 1)
        setFormData({companyName: "",})
    }

    const _validateForm = () => {
        const validCompanyName = validateCompanyName(companyName)
        setFormErrorData({companyNameError: !validCompanyName,})
        return [validCompanyName].every(Boolean)
    }

    return <section>
        <fieldset>
            <SectionTitle
                isLoading={isLoadingInviteCompanyAdmin}
                title={"SCREENS.SETTINGS.INVITE_COMPANY_ADMIN"}/>
            <div className={"klimalink-administration-input-wrapper"}>
                <TextInput
                    labelColor={"primary"}
                    ariaErrorMessage={"SCREENS.COMMON.ARIA_LABELS.COMPANY_ERROR"}
                    error={companyNameError}
                    onBlur={value => _updateFormData('companyName', value)}
                    onChange={value => _updateFormData('companyName', value)}
                    containerStyle={{flex: 1}}
                    required={true}
                    value={companyName}
                    placeholder={"GENERAL.COMPANY_NAME"}
                    label={"GENERAL.COMPANY_NAME"}/>
                <NameEmailForm
                    resetForm={resetForm}
                    firstNameRequired={true}
                    lastNameRequired={true}
                    emailRequired={true}
                    validateForm={validateForm}
                    onFormIsValid={setNameEmailData}/>
            </div>
            <TextButton
                containerStyle={{marginTop: theme.spacer.large}}
                onClick={() => setValidateForm(validateForm + 1)}>GENERAL.INVITE</TextButton>
        </fieldset>
    </section>
}

export default KlimaLinkAdministration