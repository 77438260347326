import React, {useEffect, useRef, useState} from "react";
import {DataGrid, DataGridDataRowProps, IconAndText, Modal, RadioButtonGroup, Text} from "atmosfair-ui"
import UsersForm, {UserFormProps, UsersFormProps} from "../../../forms/users-form/UsersForm";
import {useTranslation} from "react-i18next";
import {allInternationalPhonePrefixesFormattedForDataGridAlpha2CountryCode} from "../../utils";
import {AgencyProps} from "../../../../api/model/agency";
import ToastWithTextOnly from "../../../../molecules/toasts/toast-with-text-only/ToastWithTextOnly";
import {toast} from "react-toastify";
import {
    useChangeUsersAgencyMutation,
    useDeleteUserMutation,
    useInviteUserMutation,
    useMakeUserToAgencyAdminMutation,
    useUnmakeUserFromAgencyAdminMutation,
    useUpdateUserInfoAdminMutation
} from "../../../../redux/reducer/api";
import {PersonalUserInformation, UserProps} from "../../../../api/model/user";
import {theme} from "../../../../styles/theme";
import {CompanyAgencyAddress, GENERAL_BACKEND_RESPONSE} from "../../../../api/model/common";
import {isEqual} from "lodash";
import SectionTitle from "../../section-title/SectionTitle";
import {v4 as uuidv4} from "uuid";
import {useAppSelector} from "../../../../redux/reducer/store";
import {QueryStatus} from "@reduxjs/toolkit/query";
import {INITIAL_SELECTED_INTERNATIONAL_PHONE_PREFIX} from "../../../../utils/default-values";
import {convertAgenciesToDropdownFormat} from "../../../../utils/dropdown-converter";
import {VALIDATE_FORM_STARTING_NUMBER} from "../../../../constants";

interface AgencyMembersDataGridProps {
    agencies: UsersFormProps["agencies"],
    isCompanyAdmin: boolean,
    allMembers: UserProps[],
    adminUserId: string,
}

const HEADERS = [
    {text: "GENERAL.NAME"},
    {text: "GENERAL.EMAIL"},
    {text: "GENERAL.PHONE"},
    {text: "GENERAL.ADMIN"}
]

interface RawMemberAgencyData {
    user: UserProps,
    agency: AgencyProps,
}

interface OriginalUserObjectProps extends Omit<PersonalUserInformation, 'internationalPhonePrefix'> {
    isAdmin: boolean,
    internationalPhonePrefix: CompanyAgencyAddress["internationalPhonePrefix"] | null,
}

interface FormProps extends OriginalUserObjectProps {
    agency: AgencyProps | null
}

const AgencyMembersDataGrid = ({
                                   isCompanyAdmin,
                                   adminUserId,
                                   agencies,
                                   allMembers,
                               }: AgencyMembersDataGridProps) => {
    const [formData, setFormData] = useState<UserFormProps>({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        isAdmin: false,
        internationalPhonePrefix: "",
        agency: null,
    })
    const [originalUserObject, setOriginalUserObject] = useState<OriginalUserObjectProps>({} as OriginalUserObjectProps)
    const [userFromAgencyToDelete, setUserFromAgencyToDelete] = useState<RawMemberAgencyData | null>(null)
    const [validateForm, setValidateForm] = useState(VALIDATE_FORM_STARTING_NUMBER)
    const [editMemberModalVisible, setEditMemberModalVisible] = useState(false)
    const [addMemberModalVisible, setAddMemberModalVisible] = useState(false)
    const [deleteMemberModalVisible, setDeleteMemberModalVisible] = useState(false)
    const [makeUnmakeAdminModalVisible, setMakeUnmakeAdminModalVisible] = useState(false)
    const [changeMemberAgencyModalVisible, setChangeMemberAgencyModalVisible] = useState(false)
    const [member, setMember] = useState<UserProps | null>(null)
    const [showUsersRegistrationHint, setShowUsersRegistrationHint] = useState(false)
    const [newAgencyIndex, setNewAgencyIndex] = useState(0)
    const selectedUserFromDataGridFullName = `${member?.firstName} ${member?.lastName}`
    const userIsAgencyAdmin = useRef(false)
    const {t, i18n} = useTranslation()
    const {resolvedLanguage} = i18n
    const possibleAgenciesToMoveUserTo = agencies
        .filter(obj => !member?.agencyIds.includes(obj?.id))
        .map((filteredAgency, index) => ({
            label: filteredAgency.name,
            value: index,
            id: filteredAgency.id
        }))

    const _updateFormData = <K extends keyof FormProps>(key: K, payload: FormProps[K]) => setFormData({
        ...formData,
        [key]: payload
    })

    const isLoadingGetUsersAgency = useAppSelector(state => {
        return Object.values(state.api.queries).some(query => {
            if (query && query.endpointName === "getUsersAgencies") return query.status === QueryStatus.pending;
        });
    });

    const isErrorGetUsersAgency = useAppSelector(state => {
        return Object.values(state.api.queries).some(query => {
            if (query && query.endpointName === "getUsersAgencies") return query.status === QueryStatus.rejected;
        });
    });

    const [deleteUserRequest, {
        data: deleteUserResponse,
        isLoading: isLoadingDeleteUserRequest,
        isError: isErrorDeleteUserRequest
    }] = useDeleteUserMutation()

    const [inviteUserRequest, {
        data: inviteUserResponse,
        isLoading: isLoadingInviteUser,
        isError: isErrorInviteUser
    }] = useInviteUserMutation()

    const [updateUserInfoRequest, {
        data: updateUserInfoResponse,
        isLoading: isLoadingUpdateUserInfo,
        isError: isErrorUpdateUserInfo
    }] = useUpdateUserInfoAdminMutation()

    const [makeUserToAgencyAdmin, {
        data: makeUserToAgencyAdminResponse,
        isLoading: isLoadingMakeUserAdminToAgencyAdmin,
        isError: isErrorMakeUserToAgencyAdmin,
    }] = useMakeUserToAgencyAdminMutation()

    const [unmakeUserFromAgencyAdmin, {
        data: unmakeUserFromAgencyAdminResponse,
        isLoading: isLoadingUnmakeUserFromAgencyAdmin,
        isError: isErrorUnmakeUserFromAgencyAdmin,
    }] = useUnmakeUserFromAgencyAdminMutation()

    const [changeUsersAgency, {
        data: changeUsersAgencyResponse,
        isLoading: isLoadingChangeUsersAgency,
        isError: isErrorChangeUsersAgency,
    }] = useChangeUsersAgencyMutation()

    useEffect(() => {
        if (agencies.length === 1) _updateFormData('agency', agencies[0])
    }, [agencies]);

    useEffect(() => {
        if (isErrorGetUsersAgency) toast(<ToastWithTextOnly
                message={t("SCREENS.COMMON.AUTOMATED_REFETCHING_ERROR",)}/>,
            {progress: undefined, type: "error"}
        );
    }, [isErrorGetUsersAgency]);

    useEffect(() => {
        if (inviteUserResponse?.status === GENERAL_BACKEND_RESPONSE.success) {
            setAddMemberModalVisible(false)
            _resetAddEditForm()
        }
    }, [inviteUserResponse])

    useEffect(() => {
        if (updateUserInfoResponse?.status === GENERAL_BACKEND_RESPONSE.success) {
            _resetAddEditForm()
            setAddMemberModalVisible(false)
        }
    }, [updateUserInfoResponse]);

    const _toUsersDataGrid = () => {
        const usersDataGridRows: DataGridDataRowProps["rows"] = []
        agencies.forEach(agency => {
            const members = allMembers.filter(user => user.agencyIds.includes(agency.id))
            const {name} = agency
            members.forEach(member => {
                const {
                    firstName,
                    lastName,
                    email,
                    internationalPhonePrefix,
                    phoneNumber,
                    id,
                    registrationComplete
                } = member
                const memberIsAgencyAdmin = agency.adminIds.includes(id)
                let disabledAnyEditOtherAdmins = false
                if (memberIsAgencyAdmin) disabledAnyEditOtherAdmins = true
                if (isCompanyAdmin) disabledAnyEditOtherAdmins = false
                const hideButtons = id === adminUserId
                if (!registrationComplete && !showUsersRegistrationHint) setShowUsersRegistrationHint(true)
                const fullName = `${firstName} ${lastName}${!registrationComplete ? "*" : ""}`
                let completePhoneNumber
                if (allInternationalPhonePrefixesFormattedForDataGridAlpha2CountryCode[internationalPhonePrefix] && phoneNumber.length > 0) {
                    completePhoneNumber = `${allInternationalPhonePrefixesFormattedForDataGridAlpha2CountryCode[internationalPhonePrefix]} ${phoneNumber}`
                } else {
                    completePhoneNumber = null
                }
                const isAdminString = memberIsAgencyAdmin ? "GENERAL.YES" : "GENERAL.NO"
                let hideChangeAgency = false
                if (agencies.length === 1) {
                    hideChangeAgency = true
                } else {
                    hideChangeAgency = !isCompanyAdmin || hideButtons
                }
                usersDataGridRows.push({
                    data: [
                        {
                            text: fullName,
                            value: fullName,
                        },
                        {
                            text: email!!,
                            value: email!!
                        },
                        {
                            text: completePhoneNumber ?? " -- ",
                            value: completePhoneNumber ? phoneNumber : 0
                        },
                        {
                            text: isAdminString,
                            value: isAdminString,
                        }
                    ],
                    group: name!!,
                    key: uuidv4(),
                    disableEdit: disabledAnyEditOtherAdmins,
                    disableDelete: disabledAnyEditOtherAdmins,
                    hideEdit: hideButtons,
                    hideDelete: hideButtons,
                    additionalRowShortcutActions: [
                        {
                            hide: !isCompanyAdmin || hideButtons,
                            icon: memberIsAgencyAdmin ? "userTie" : "crown",
                            tooltip: memberIsAgencyAdmin ? "SCREENS.SETTINGS.REMOVE_ADMIN_TOOLTIP" : "SCREENS.SETTINGS.MAKE_ADMIN_TOOLTIP",
                            onClick: () => {
                                setMakeUnmakeAdminModalVisible(true)
                                setMember(member)
                                _updateFormData("agency", agency)
                                userIsAgencyAdmin.current = memberIsAgencyAdmin
                            }
                        },
                        {
                            hide: hideChangeAgency,
                            icon: "repeat",
                            tooltip: "SCREENS.SETTINGS.CHANGE_USERS_AGENCY_TOOLTIP",
                            onClick: () => {
                                setChangeMemberAgencyModalVisible(true)
                                setMember(member)
                                _updateFormData("agency", agency)
                            }
                        }
                    ],
                    rawData: {
                        user: member,
                        agency
                    } as RawMemberAgencyData,
                })
            })
        })
        return usersDataGridRows
    }

    const _inviteUser = (data: UserFormProps) => {
        const {email,} = data
        if (allMembers.some(user => user.email === email)) {
            toast(<ToastWithTextOnly
                    message={t("SCREENS.SETTINGS.ADD_USER_EMAIL_ALREADY_IN_USE",
                        {email}
                    )}/>,
                {progress: undefined, type: "error"}
            );
        } else {
            const {
                firstName,
                lastName,
                email,
                isAdmin,
                agency
            } = data
            if (email && agency) {
                inviteUserRequest({
                    users: [{
                        firstName,
                        lastName,
                        email,
                        isAdmin: Boolean(isAdmin),
                        language: resolvedLanguage ?? "de",
                    }],
                    agencyId: agency.id
                })
                setValidateForm(VALIDATE_FORM_STARTING_NUMBER)
            }
        }
    }

    const _deleteUser = () => {
        if (userFromAgencyToDelete != null) {
            deleteUserRequest({
                agencyId: userFromAgencyToDelete.agency.id,
                userEmail: userFromAgencyToDelete.user.email
            })
            setDeleteMemberModalVisible(false)
            _resetUserValues()
        }
    }

    const _editUser = (data: UserFormProps) => {
        const {
            firstName,
            lastName,
            email,
            phoneNumber,
            internationalPhonePrefix,
        } = data
        const updatedUserObject = {
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            phoneNumber: phoneNumber.trim(),
            internationalPhonePrefix: internationalPhonePrefix!!,
        }

        if (!isEqual(updatedUserObject, originalUserObject) && email) {
            updateUserInfoRequest({
                userObject: updatedUserObject,
                userEmail: email
            })
        }
        setValidateForm(VALIDATE_FORM_STARTING_NUMBER)
    }

    const _onEditRow = (index: number, userAgency: RawMemberAgencyData) => {
        const {agency, user} = userAgency
        const {firstName, lastName, email, phoneNumber, internationalPhonePrefix, id} = user
        const {isAdmin} = agency
        const userAgencyConverted = convertAgenciesToDropdownFormat(agencies).filter(dropdownOption => dropdownOption.label === agency.name)[0].raw // currently a user (unless its KlimaLink Admin) can only be in one agency at a time, that is why it is save to simply pick the first agency in the array
        userIsAgencyAdmin.current = isAdmin
        setValidateForm(VALIDATE_FORM_STARTING_NUMBER)
        setOriginalUserObject({
            firstName,
            lastName,
            email,
            isAdmin,
            phoneNumber,
            internationalPhonePrefix,
        })
        setFormData({
            firstName,
            lastName,
            phoneNumber,
            isAdmin,
            internationalPhonePrefix,
            email,
            agency: userAgencyConverted,
        })
        setEditMemberModalVisible(true)
    }

    const _resetAddEditForm = () => {
        setValidateForm(VALIDATE_FORM_STARTING_NUMBER)
        setFormData({
            firstName: '',
            lastName: '',
            email: '',
            isAdmin: false,
            internationalPhonePrefix: INITIAL_SELECTED_INTERNATIONAL_PHONE_PREFIX,
            phoneNumber: '',
            agency: null
        })
        setUserFromAgencyToDelete(null)
        setOriginalUserObject({} as OriginalUserObjectProps)
        userIsAgencyAdmin.current = false
    }

    const _resetUserValues = () => {
        setMember(null)
        _updateFormData('agency', null)
        setNewAgencyIndex(0)
        setUserFromAgencyToDelete(null)
    }

    return <section>
        <SectionTitle
            isLoading={
                isLoadingDeleteUserRequest ||
                isLoadingInviteUser ||
                isLoadingUpdateUserInfo ||
                isLoadingMakeUserAdminToAgencyAdmin ||
                isLoadingUnmakeUserFromAgencyAdmin ||
                isLoadingChangeUsersAgency ||
                isLoadingGetUsersAgency
            }
            title={"SCREENS.SETTINGS.USERS_TITLE"}/>
        <div className={"agencies-company-button-wrapper"}>
            {agencies.length > 0 ? <>
                    <IconAndText
                        iconColor={theme.colors.primary}
                        text={"SCREENS.SETTINGS.ADD_NEW_USER"}
                        onClick={() => setAddMemberModalVisible(true)}
                        icon={"plus"}/>
                    <DataGrid
                        look={"primaryLight"}
                        cellWeights={[1, 1, 1, 1]}
                        headers={HEADERS}
                        rows={_toUsersDataGrid()}
                        rowSize={"medium"}
                        onEditTooltip={"SCREENS.SETTINGS.EDIT_USER_TOOLTIP"}
                        onEditRow={_onEditRow}
                        groupRows={true}
                        onDeleteTooltip={"SCREENS.SETTINGS.DELETE_USER_TOOLTIP"}
                        onDeleteRow={(_, userAgency) => {
                            setUserFromAgencyToDelete(userAgency)
                            userIsAgencyAdmin.current = userAgency.agency.isAdmin
                            setDeleteMemberModalVisible(true)
                        }}
                    />
                    {showUsersRegistrationHint &&
                        <Text>{`*${t("SCREENS.SETTINGS.USER_REGISTRATION_NOT_COMPLETED")}`}</Text>}
                </> :
                <Text>SCREENS.SETTINGS.NO_AGENCY_HINT_ADD_USERS</Text>}
        </div>
        <Modal
            headline={`SCREENS.SETTINGS.${editMemberModalVisible ? "EDIT_USER" : "ADD_NEW_USER"}`}
            show={addMemberModalVisible || editMemberModalVisible}
            closeButton={"GENERAL.ABORT"}
            confirmButton={"GENERAL.OK"}
            onClickConfirm={() => setValidateForm(validateForm + 1)}
            onClickClose={() => {
                setAddMemberModalVisible(false)
                setEditMemberModalVisible(false)
                _resetAddEditForm()
            }}
            showSpinner={isLoadingInviteUser || isLoadingUpdateUserInfo}
            showPaper={true}>
            <UsersForm
                defaultValues={formData}
                showAgencySelection={editMemberModalVisible ? false : agencies.length > 1}
                showEmailField={addMemberModalVisible}
                showPhoneNumberFields={editMemberModalVisible}
                showIsAdminCheckbox={false}
                validateForm={validateForm}
                onFormIsValid={data => {
                    if (addMemberModalVisible) _inviteUser(data)
                    if (editMemberModalVisible) _editUser(data)
                }}
                agencies={agencies}/>
        </Modal>
        <Modal
            headline={t("SCREENS.SETTINGS.DELETE_USER", {
                user: `${userFromAgencyToDelete?.user.firstName} ${userFromAgencyToDelete?.user.lastName}`,
                interpolation: {escapeValue: false}
            })}
            show={deleteMemberModalVisible}
            closeButton={"GENERAL.ABORT"}
            confirmButton={"GENERAL.DELETE"}
            confirmButtonColor={"warningMain"}
            onClickConfirm={_deleteUser}
            onClickClose={() => {
                setDeleteMemberModalVisible(false)
                _resetUserValues()
            }}
            showSpinner={isLoadingDeleteUserRequest}
            showPaper={true}>
            <Text>{t("SCREENS.SETTINGS.DELETE_USER_TEXT", {
                user: `${userFromAgencyToDelete?.user.firstName} ${userFromAgencyToDelete?.user.lastName}`,
                interpolation: {escapeValue: false}
            })}</Text>
        </Modal>
        <Modal
            headline={t(
                `SCREENS.SETTINGS.${userIsAgencyAdmin.current ? "UNMAKE_ADMIN_MODAL_TITLE" : "MAKE_ADMIN_MODAL_TITLE"}`,
                {user: selectedUserFromDataGridFullName, interpolation: {escapeValue: false}}
            )}
            showSpinner={isLoadingMakeUserAdminToAgencyAdmin || isLoadingUnmakeUserFromAgencyAdmin}
            show={makeUnmakeAdminModalVisible}
            closeButton={"GENERAL.ABORT"}
            confirmButton={"GENERAL.OK"}
            onClickConfirm={() => {
                if (member) {
                    const {email, agencyIds} = member
                    const isAdmin = userIsAgencyAdmin.current
                    const agencyId = agencyIds[0] // currently a user (unless its KlimaLink Admin) can only be in one agency at a time, that is why it is save to simply pick the first agency in the array
                    if (!isAdmin) makeUserToAgencyAdmin({userEmail: email, agencyId})
                    if (isAdmin) unmakeUserFromAgencyAdmin({userEmail: email, agencyId})
                }
                _resetUserValues()
                setMakeUnmakeAdminModalVisible(false)
            }}
            onClickClose={() => {
                setMakeUnmakeAdminModalVisible(false)
                _resetUserValues()
            }}
            showPaper={true}>
            <Text>{t(
                `SCREENS.SETTINGS.${userIsAgencyAdmin.current ? "UNMAKE_USER_ADMIN_MODAL_TEXT" : "MAKE_USER_ADMIN_MODAL_TEXT"}`,
                {user: selectedUserFromDataGridFullName, interpolation: {escapeValue: false}}
            )}</Text>
        </Modal>
        <Modal
            headline={t(`SCREENS.SETTINGS.CHANGE_USERS_AGENCY_MODAL_TITLE`, {
                name: selectedUserFromDataGridFullName,
                interpolation: {escapeValue: false}
            })}
            showSpinner={isLoadingChangeUsersAgency}
            show={changeMemberAgencyModalVisible}
            closeButton={"GENERAL.ABORT"}
            confirmButton={"GENERAL.OK"}
            onClickConfirm={() => {
                changeUsersAgency({
                    oldAgencyId: member?.agencyIds[0]!!, // currently a user (unless its KlimaLink Admin) can only be in one agency at a time, that is why it is save to simply pick the first agency in the array
                    newAgencyId: agencies.find(agency => agency.id === possibleAgenciesToMoveUserTo[newAgencyIndex].id)?.id!!,
                    userEmail: member!!.email
                })
                _resetUserValues()
                setChangeMemberAgencyModalVisible(false)
            }}
            onClickClose={() => {
                setChangeMemberAgencyModalVisible(false)
                _resetUserValues()
            }}
            showPaper={true}>
            <>
                <Text>{`${t("SCREENS.SETTINGS.CHANGE_USERS_AGENCY_MODAL_TEXT", {
                    name: selectedUserFromDataGridFullName,
                    oldAgency: agencies?.find(agency => agency.id === member?.agencyIds[0])?.name, // currently a user (unless its KlimaLink Admin) can only be in one agency at a time, that is why it is save to simply pick the first agency in the array
                    interpolation: {escapeValue: false}
                })}`}</Text>
                {userIsAgencyAdmin.current &&
                    <Text>{`${t("SCREENS.SETTINGS.CHANGE_USERS_AGENCY_MODAL_TEXT_ADMIN", {
                        name: selectedUserFromDataGridFullName,
                        interpolation: {escapeValue: false}
                    })}`}</Text>}
                <RadioButtonGroup
                    containerStyle={{marginTop: theme.spacer.large}}
                    onChange={setNewAgencyIndex}
                    radioButtons={possibleAgenciesToMoveUserTo}
                    selected={newAgencyIndex}
                    name={"SCREENS.TRAVEL_COMPONENTS_OVERVIEW.RADIO_BUTTON_GROUP"}
                    orientation={"vertical"}
                    labelStyle={{fontSize: theme.font.fontSize.fontSizeRegular}}
                    hideLabel={false}/>
            </>
        </Modal>
    </section>
}

export default AgencyMembersDataGrid