import {createBrowserRouter} from "react-router-dom";
import {PATH_STRINGS} from "./route-props";
import ErrorPage from "../screens/functional/error-page/ErrorPage";
import LogIn from "../screens/log-in/LogIn";
import ProtectedLayout from "../components/protected-layout/ProtectedLayout";
import React from "react";
import SelectActivityForm from "../molecules/activity/select-activity-flight-form/SelectActivityForm";
import CreateJourney from "../screens/journey/create-journey/CreateJourney";
import LogInSuccessful from "../screens/functional/log-in-successful/LogInSuccessful";
import EditJourney from "../screens/journey/edit-joruney/EditJourney";
import TravelComponentsOverview from "../screens/travel-components-overview/TravelComponentsOverview";
import Settings from "../screens/settings/Settings";
import TermsAndConditionsAcceptAndPdf
    from "../screens/terms-and-conditions/terms-and-conditions-accept-and-pdf/TermsAndConditionsAcceptAndPdf";
import CompleteRegistration from "../screens/complete-registration/CompleteRegistration";
import Help from "../screens/help/Help";
import TermsAndConditionsNormalUser
    from "../screens/terms-and-conditions/terms-and-conditions-normal-user/TermsAndConditionsNormalUser";
import NoRoute from "../screens/functional/no-route/NoRoute";
import Home from "../screens/home/Home";
import App from "../App";

const router = createBrowserRouter([
    {
        path: PATH_STRINGS.base,
        element: <App/>,
        errorElement: <ErrorPage/>
    },
    {
        path: PATH_STRINGS.logIn,
        index: true,
        element: <LogIn/>,
        errorElement: <ErrorPage/>,
    },
    {
        path: PATH_STRINGS.redirectOnSuccessfulLogin,
        element: <LogInSuccessful/>,
        errorElement: <ErrorPage/>,
    },
    {
        path: PATH_STRINGS.register,
        element: <CompleteRegistration/>,
        errorElement: <ErrorPage/>
    },
    {
        path: PATH_STRINGS.termsAndConditionsAdmin,
        element: <TermsAndConditionsAcceptAndPdf/>,
        errorElement: <ErrorPage/>
    },
    {
        path: PATH_STRINGS.termsAndConditionsUser,
        element: <TermsAndConditionsNormalUser/>,
        errorElement: <ErrorPage/>
    },
    {
        element: <ProtectedLayout/>,
        errorElement: <ErrorPage/>,
        children: [
            {
                path: PATH_STRINGS.travelDatabase,
                element: <Home/>,
                errorElement: <ErrorPage/>,
            },
            {
                path: PATH_STRINGS.help,
                element: <Help/>,
                errorElement: <ErrorPage/>,
            },
            {
                path: PATH_STRINGS.newJourney,
                element: <CreateJourney/>,
                errorElement: <ErrorPage/>
            },
            {
                path: PATH_STRINGS.journey,
                element: <EditJourney/>,
                errorElement: <ErrorPage/>
            },
            {
                path: PATH_STRINGS.travelComponentsOverview,
                element: <TravelComponentsOverview/>,
                errorElement: <ErrorPage/>
            },
            {
                path: `${PATH_STRINGS.addActivity}/:activity/`,
                element: <SelectActivityForm/>,
                errorElement: <ErrorPage/>
            },
            {
                path: `${PATH_STRINGS.editActivity}/:activity/:index/`,
                element: <SelectActivityForm/>,
                errorElement: <ErrorPage/>
            },
            {
                path: PATH_STRINGS.settings,
                element: <Settings/>,
                errorElement: <ErrorPage/>
            },
        ]
    },
    {
        path: PATH_STRINGS.noRoute,
        element: <NoRoute/>,
    },
]);

export default router