import React, {CSSProperties, SyntheticEvent, useEffect, useMemo, useRef, useState} from 'react'
import clsx from 'clsx';
import './styles.css';
import debounce from 'lodash/debounce';
import {ErrorTextWithBox, ListBox, Text, ThemeProps} from "atmosfair-ui"
import {v4 as uuidv4} from 'uuid';
import {useTranslation} from "react-i18next";
import {AirlineResponse, AirportResponse} from "../../api/model/activity-flight";
import {GET_AIRLINES, GET_AIRPORTS, GET_CITIES, GET_COUNTRIES, GET_HOTEL_NAMES} from "../../api/endpoints";
import ErrorText from "../errors/error-text/ErrorText";
import {constructRequiredLabel} from "../../utils/string-mutation";
import {
    isAirlineResponse,
    isAirportResponse,
    isCityResponse,
    isCountryResponse,
    isCustomer,
    isCustomerCompany,
    isFormCustomer,
    isHotelResponse
} from "../../utils/type-guards";
import {useAutocomplete} from "@mui/base";
import {theme} from "../../styles/theme";
import Cookies from "js-cookie";
import {COOKIE_NAME, VALIDATE_FORM_STARTING_NUMBER} from "../../constants";
import {CityResponse, CountryResponse, HotelResponse} from "../../api/model/activity-hotel";
import {Customer, CustomerCompany, CustomerRequestType} from "../../api/model/customer";
import {CustomerFormProps} from "../../components/save-travel-components-dialogue/SaveTravelComponentsDialogue";

export enum AUTOCOMPLETE_ACTIVITY_TYPE {
    hotel,
    flight,
    customer
}

export enum AUTOCOMPLETE_QUERY_TYPE {
    airline,
    airport,
    city,
    country,
    hotel,
    customerFirstName,
    customerLastName,
    customerEmail,
    customerCompany
}

export interface CommonAutocompleteInputProps {
    id: string,
    "aria-invalid"?: "true" | "false",
    "aria-required": "true"
    className: string,
    type: 'search'
    "aria-errormessage"?: string,
    placeholder: string,
    readOnly: boolean,
}

type ValueProps =
    AirlineResponse
    | AirportResponse
    | HotelResponse
    | CityResponse
    | CountryResponse
    | CustomerFormProps
    | CustomerCompany
    | null

type ValuePropsArray =
    AirlineResponse[]
    | AirportResponse[]
    | HotelResponse[]
    | CityResponse[]
    | CountryResponse[]
    | CustomerFormProps[]

export interface AutocompleteQueryProps {
    ariaErrorMessage?: string,
    placeholder: CommonAutocompleteInputProps["placeholder"],
    defaultValue?: ValueProps,
    setSelection: (selection: ValueProps) => void,
    inputClasses?: string,
    containerClasses?: string,
    error?: boolean,
    label: string,
    listBoxClasses?: string,
    containerStyle?: CSSProperties,
    required: boolean,
    queryType: AUTOCOMPLETE_QUERY_TYPE,
    labelColor?: keyof ThemeProps["colors"],
    type: AUTOCOMPLETE_ACTIVITY_TYPE,
    secondSearchParam?: CountryResponse | CityResponse | null,
    thirdSearchParam?: CountryResponse | null,
    disabled?: CommonAutocompleteInputProps["readOnly"],
    setFreelyTypedString?: (value: string) => void,
    getFreelyTypedString?: number,
    closeListBoxOnBlur?: boolean,
}

const ariaForId = `:autocomplete-query-aria-for-id-${uuidv4()}:`
const MINIMUM_QUERY_LENGTH = 3
const AutocompleteQuery = ({
                               ariaErrorMessage,
                               inputClasses,
                               containerClasses,
                               defaultValue,
                               error,
                               label,
                               placeholder,
                               listBoxClasses,
                               containerStyle,
                               setSelection,
                               required,
                               queryType,
                               labelColor,
                               type,
                               secondSearchParam,
                               thirdSearchParam,
                               disabled,
                               setFreelyTypedString,
                               getFreelyTypedString,
                               closeListBoxOnBlur
                           }: AutocompleteQueryProps) => {
    const [value, setValue] = useState<ValueProps>(null);
    const [focused, setFocused] = useState(false)
    const [networkError, setNetworkError] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [queriedValues, setQueriedValues] = useState<ValuePropsArray>([]);
    const [showListBoxClickAwayText, setShowListBoxClickAwayText] = useState(false)
    const [showInputClickAwayText, setShowInputClickAwayText] = useState(false)
    const [showLoadingText, setShowLoadingText] = useState(false)
    const {t, i18n} = useTranslation()
    const keyHasBeenPressed = useRef(false)
    const currentLanguage = i18n.resolvedLanguage
    const abortControllerRef = useRef<AbortController | null>(null);

    const {
        getRootProps,
        getInputLabelProps,
        getInputProps,
        getListboxProps,
        getOptionProps,
        groupedOptions,
    } = useAutocomplete({
        autoComplete: false,
        blurOnSelect: true,
        clearOnBlur: false,
        clearOnEscape: true,
        filterOptions: x => x,
        getOptionLabel: option => {
            if (type === AUTOCOMPLETE_ACTIVITY_TYPE.flight && (isAirportResponse(option) || isAirlineResponse(option))) return `${option.name} (${option.iata})`
            if (type === AUTOCOMPLETE_ACTIVITY_TYPE.hotel && (isHotelResponse(option) || isCityResponse(option) || isCountryResponse(option))) return option.name
            if (type === AUTOCOMPLETE_ACTIVITY_TYPE.customer && (isCustomer(option))) {
                if (queryType === AUTOCOMPLETE_QUERY_TYPE.customerFirstName) return option.firstName ?? ""
                if (queryType === AUTOCOMPLETE_QUERY_TYPE.customerLastName) return option.lastName ?? ""
                if (queryType === AUTOCOMPLETE_QUERY_TYPE.customerCompany) return option.company?.name ?? ""
                if (queryType === AUTOCOMPLETE_QUERY_TYPE.customerEmail) return option.email ?? ""
            }
            return ''
        },
        isOptionEqualToValue: (option, value) => {
            if (isFormCustomer(option) && isFormCustomer(value)) {
                if (queryType === AUTOCOMPLETE_QUERY_TYPE.customerCompany) return option.company?.id === value.company?.id
            }
            return option.id === value.id
        },
        id: t(label),
        includeInputInList: true,
        filterSelectedOptions: true,
        options: queriedValues,
        // invoked whenever you select the display options in the popup.
        onChange: (event: SyntheticEvent, newValue: ValueProps) => _onChange(newValue),
        // invoked whenever you type in search field.
        onInputChange: (_, newInputValue: string) => {
            setValue(null)
            setShowListBoxClickAwayText(false)
            setShowInputClickAwayText(false)
            setShowLoadingText(false)
            _debouncedChangeHandler(newInputValue)
        },
        value,
        defaultValue
    });

    useEffect(() => {
        if (defaultValue !== undefined) setValue(defaultValue)
    }, [defaultValue]);

    useEffect(() => {
        if (inputValue === '') {
            if (value === null) {
                setQueriedValues([])
            } else if (isAirlineResponse(value)) {
                setQueriedValues([value]);
            } else if (isAirportResponse(value)) {
                setQueriedValues([value])
            } else if (isHotelResponse(value)) {
                setQueriedValues([value])
            } else if (isCountryResponse(value)) {
                setQueriedValues([value])
            } else if (isCityResponse(value)) {
                setQueriedValues([value])
            } else if (isFormCustomer(value)) {
                setQueriedValues([value])
            } else {
                setQueriedValues([])
            }
        } else {
            setQueriedValues([]);
            if (!disabled) {
                switch (queryType) {
                    case AUTOCOMPLETE_QUERY_TYPE.airline:
                        if ((isAirlineResponse(defaultValue) && defaultValue.name !== inputValue) || defaultValue === null) _getAirlines(inputValue)
                        break;
                    case AUTOCOMPLETE_QUERY_TYPE.airport:
                        if ((isAirportResponse(defaultValue) && defaultValue.name !== inputValue) || defaultValue === null) _getAirports(inputValue)
                        break
                    case AUTOCOMPLETE_QUERY_TYPE.city:
                        if ((isCityResponse(defaultValue) && defaultValue.name !== inputValue) || defaultValue === null) _getCities(inputValue, secondSearchParam?.alpha2)
                        break;
                    case AUTOCOMPLETE_QUERY_TYPE.country:
                        if ((isCountryResponse(defaultValue) && defaultValue.name !== inputValue) || defaultValue === null) _getCountries(inputValue)
                        break;
                    case AUTOCOMPLETE_QUERY_TYPE.hotel:
                        if ((isHotelResponse(defaultValue) && defaultValue.name !== inputValue) || defaultValue === null) _getHotelNames(inputValue, secondSearchParam?.name, thirdSearchParam?.alpha2)
                        break;
                    case AUTOCOMPLETE_QUERY_TYPE.customerFirstName:
                        if ((isFormCustomer(defaultValue) && defaultValue.firstName !== inputValue) || defaultValue === null) _getCustomers(inputValue, CustomerRequestType.firstName)
                        break;
                    case AUTOCOMPLETE_QUERY_TYPE.customerLastName:
                        if ((isFormCustomer(defaultValue) && defaultValue.lastName !== inputValue) || defaultValue === null) _getCustomers(inputValue, CustomerRequestType.lastName)
                        break;
                    case AUTOCOMPLETE_QUERY_TYPE.customerEmail:
                        if ((isFormCustomer(defaultValue) && defaultValue.email !== inputValue) || defaultValue === null) _getCustomers(inputValue, CustomerRequestType.email)
                        break;
                    case AUTOCOMPLETE_QUERY_TYPE.customerCompany:
                        if ((isFormCustomer(defaultValue) && defaultValue.company?.name !== inputValue) || defaultValue === null) _getCustomers(inputValue, CustomerRequestType.companyName)
                        break;
                }
            }
        }
    }, [inputValue])

    useEffect(() => {
        if (setFreelyTypedString && typeof getFreelyTypedString === "number" && getFreelyTypedString > VALIDATE_FORM_STARTING_NUMBER) {
            let triggered = false
            // values can come via defaultVaue or value
            // because of that, check both
            if (type === AUTOCOMPLETE_ACTIVITY_TYPE.customer && isFormCustomer(defaultValue)) {
                if (queryType === AUTOCOMPLETE_QUERY_TYPE.customerFirstName && defaultValue.firstName !== inputValue) {
                    setFreelyTypedString(inputValue)
                    triggered = true
                }
                if (queryType === AUTOCOMPLETE_QUERY_TYPE.customerLastName && defaultValue.lastName !== inputValue) {
                    setFreelyTypedString(inputValue)
                    triggered = true
                }
                if (queryType === AUTOCOMPLETE_QUERY_TYPE.customerCompany && defaultValue.company?.name !== inputValue) {
                    setFreelyTypedString(inputValue)
                    triggered = true
                }
                if (queryType === AUTOCOMPLETE_QUERY_TYPE.customerEmail && defaultValue.email !== inputValue) {
                    setFreelyTypedString(inputValue)
                    triggered = true
                }
            }

            if (
                (type === AUTOCOMPLETE_ACTIVITY_TYPE.flight && (isAirportResponse(defaultValue) || isAirlineResponse(defaultValue))) &&
                defaultValue.name !== inputValue
            ) {
                setFreelyTypedString(inputValue)
                triggered = true
            }

            if (
                (type === AUTOCOMPLETE_ACTIVITY_TYPE.hotel && (isHotelResponse(defaultValue) || isCityResponse(defaultValue) || isCountryResponse(defaultValue))) &&
                defaultValue.name !== inputValue
            ) {
                setFreelyTypedString(inputValue)
                triggered = true
            }


            if (type === AUTOCOMPLETE_ACTIVITY_TYPE.customer && isFormCustomer(value) && !triggered) {
                if (queryType === AUTOCOMPLETE_QUERY_TYPE.customerFirstName && value.firstName !== inputValue) setFreelyTypedString(inputValue)
                if (queryType === AUTOCOMPLETE_QUERY_TYPE.customerLastName && value.lastName !== inputValue) setFreelyTypedString(inputValue)
                if (queryType === AUTOCOMPLETE_QUERY_TYPE.customerCompany && value.company?.name !== inputValue) setFreelyTypedString(inputValue)
                if (queryType === AUTOCOMPLETE_QUERY_TYPE.customerEmail && value.email !== inputValue) setFreelyTypedString(inputValue)
            }
            if (
                (type === AUTOCOMPLETE_ACTIVITY_TYPE.flight && (isAirportResponse(value) || isAirlineResponse(value))) &&
                !triggered &&
                value.name !== inputValue
            ) setFreelyTypedString(inputValue)
            if (
                (type === AUTOCOMPLETE_ACTIVITY_TYPE.hotel && (isHotelResponse(value) || isCityResponse(value) || isCountryResponse(value))) &&
                !triggered &&
                value.name !== inputValue
            ) setFreelyTypedString(inputValue)
        }
    }, [getFreelyTypedString]);

    const _setHeaders = () => {
        let headers = new Headers();
        const token = Cookies.get(COOKIE_NAME)
        headers.set('Authorization', `Bearer ${token}`);
        return headers
    }

    const _getCustomers = async (query: string, type: CustomerRequestType) => {
        try {
            const response = await fetch(`http://localhost:8080/api/v1/customer/${type}?query=${query}`,
                {
                    method: "GET",
                    headers: _setHeaders()
                });

            if (response.ok) {
                const customers: Customer[] = await response.json();
                setQueriedValues(customers);
                setNetworkError(false)
            } else {
                setNetworkError(true);
                console.error(`Response not in range of 200 - 299. Response is ${response.status}`);
            }
        } catch (error) {
            setNetworkError(true)
            console.error(`Something went wrong with getting airports: ${error}`);
        }
    }

    const _getHotelNames = async (query: string, city?: string | null, alpha2?: string | null) => {
        const transformedQuery = query.trim().replace(/\s+/g, ' ')
        if (abortControllerRef.current) abortControllerRef.current.abort(); // Abort the previous request if it exists
        abortControllerRef.current = new AbortController();
        const signal = abortControllerRef.current.signal;
        let uri = `${GET_HOTEL_NAMES}`
        const params = [];
        if (city) {
            const transformedCityQuery = city.trim().replace(/\s+/g, ' ');
            params.push(`city=${transformedCityQuery}`);
        }
        if (alpha2) params.push(`alpha2=${alpha2}`);
        if (params.length > 0) uri = `${uri}?${params.join('&')}`;

        try {
            if (query.length > MINIMUM_QUERY_LENGTH) setShowLoadingText(true)
            const response = await fetch(uri,
                {
                    method: "POST",
                    headers: _setHeaders(),
                    body: transformedQuery,
                    signal
                });

            if (!signal.aborted) {
                if (response.ok) {
                    const hotels: HotelResponse[] = await response.json();
                    setQueriedValues(hotels);
                    setNetworkError(false)
                } else {
                    setNetworkError(true);
                    console.error(`Response not in range of 200 - 299. Response is ${response.status}`);
                }
            }
        } catch (error) {
            if (!signal.aborted) {
                setNetworkError(true)
                console.error(`Something went wrong with getting hotel names: ${error}`);
            }
        } finally {
            if (!signal.aborted) setShowLoadingText(false)
        }
    }

    const _getCountries = async (query: string) => {
        const encodedQuery = encodeURIComponent(query);
        if (abortControllerRef.current) {
            abortControllerRef.current.abort(); // Abort the previous request if it exists
        }
        abortControllerRef.current = new AbortController();
        const signal = abortControllerRef.current.signal;
        try {

            const response = await fetch(`${GET_COUNTRIES}?query=${encodedQuery}`,
                {
                    method: "GET",
                    headers: _setHeaders(),
                    signal
                });

            if (!signal.aborted) {
                if (response.ok) {
                    const countries: CountryResponse[] = await response.json();
                    setQueriedValues(countries);
                    setNetworkError(false)
                } else {
                    setNetworkError(true);
                    console.error(`Response not in range of 200 - 299. Response is ${response.status}`);
                }
            }
        } catch (error) {
            if (!signal.aborted) {
                setNetworkError(true)
                console.error(`Something went wrong with getting airlines: ${error}`);
            }
        }
    }

    const _getCities = async (query: string, alpha2?: string | null) => {
        const transformedQuery = query.trim().replace(/\s+/g, ' ')
        if (abortControllerRef.current) {
            abortControllerRef.current.abort(); // Abort the previous request if it exists
        }
        abortControllerRef.current = new AbortController();
        const signal = abortControllerRef.current.signal;
        let uri = `${GET_CITIES}?query=${transformedQuery}`
        if (alpha2) {
            uri = `${uri}&alpha2=${alpha2}`
        }
        try {
            setShowLoadingText(true)
            const response = await fetch(uri,
                {
                    method: "GET",
                    headers: _setHeaders(),
                    signal
                });
            if (!signal.aborted) {
                if (response.ok) {
                    const cities: CityResponse[] = await response.json();
                    setQueriedValues(cities);
                    setNetworkError(false)
                } else {
                    setNetworkError(true);
                    console.error(`Response not in range of 200 - 299. Response is ${response.status}`);
                }
            }
        } catch (error) {
            if (!signal.aborted) {
                setNetworkError(true)
                console.error(`Something went wrong with getting airlines: ${error}`);
            }
        } finally {
            if (!signal.aborted) setShowLoadingText(false)
        }
    };

    const _onInputChange = (newInputValue: string) => {
        if (!disabled) setInputValue(newInputValue)
    }

    const _debouncedChangeHandler = useMemo(
        () => debounce(_onInputChange, 100)
        , []);

    const _getAirports = async (query: string) => {
        try {
            const response = await fetch(`${GET_AIRPORTS}?query=${query}`,
                {
                    method: "GET",
                    headers: _setHeaders()
                });

            if (response.ok) {
                const airports: AirportResponse[] = await response.json();
                setQueriedValues(airports);
                setNetworkError(false)
            } else {
                setNetworkError(true);
                console.error(`Response not in range of 200 - 299. Response is ${response.status}`);
            }
        } catch (error) {
            setNetworkError(true)
            console.error(`Something went wrong with getting airports: ${error}`);
        }
    }

    const _getAirlines = async (query: string) => {
        try {
            const response = await fetch(`${GET_AIRLINES}?query=${query}`,
                {
                    method: "GET",
                    headers: _setHeaders()
                });

            if (response.ok) {
                const airlines: AirlineResponse[] = await response.json();
                setQueriedValues(airlines);
                setNetworkError(false)
            } else {
                setNetworkError(true);
                console.error(`Response not in range of 200 - 299. Response is ${response.status}`);
            }
        } catch (error) {
            setNetworkError(true)
            console.error(`Something went wrong with getting airlines: ${error}`);
        }
    }

    const _onChange = (newValue: ValueProps) => {
        setShowListBoxClickAwayText(false)
        setShowInputClickAwayText(false)
        // the ts-ignore should not be needed anymore when TS version 5.5 is available
        // https://devblogs.microsoft.com/typescript/announcing-typescript-5-5-beta/#inferred-type-predicates
        if (newValue === null) {
            setQueriedValues(queriedValues)
        } else if (
            isAirlineResponse(newValue) ||
            isAirportResponse(newValue) ||
            isHotelResponse(newValue) ||
            isCountryResponse(newValue) ||
            isCityResponse(newValue) ||
            isFormCustomer(newValue) ||
            isCustomerCompany(newValue)
        ) {
            //@ts-ignore
            setQueriedValues([newValue, ...queriedValues]);
        } else {
            setQueriedValues(queriedValues)
        }
        setValue(newValue);
        setSelection(newValue);
    }

    const _clickAwayTextConditionallyToLowerText = (noun: string) => {
        if (currentLanguage === 'en') return noun.toLowerCase()
        return noun
    }

    const _setClickAwayTextText = () => {
        switch (queryType) {
            case (AUTOCOMPLETE_QUERY_TYPE.airport):
                return t(
                    'ERRORS.CLICK_AWAY_AUTOCOMPLETE_QUERY',
                    {value: `${t('GENERAL.EINEN')} ${_clickAwayTextConditionallyToLowerText(t('GENERAL.AIRPORT'))}`}
                )
            case (AUTOCOMPLETE_QUERY_TYPE.hotel):
                return t(
                    'ERRORS.CLICK_AWAY_AUTOCOMPLETE_QUERY',
                    {value: `${t('GENERAL.EIN')} ${_clickAwayTextConditionallyToLowerText(t('GENERAL.HOTEL'))}`}
                )
            case (AUTOCOMPLETE_QUERY_TYPE.airline):
                return t(
                    'ERRORS.CLICK_AWAY_AUTOCOMPLETE_QUERY',
                    {value: `${t('GENERAL.EINE')} ${_clickAwayTextConditionallyToLowerText(t('GENERAL.AIRLINE'))}`}
                )
            case (AUTOCOMPLETE_QUERY_TYPE.country):
                return t(
                    'ERRORS.CLICK_AWAY_AUTOCOMPLETE_QUERY',
                    {value: `${t('GENERAL.EIN')} ${_clickAwayTextConditionallyToLowerText(t('GENERAL.COUNTRY'))}`}
                )
            case (AUTOCOMPLETE_QUERY_TYPE.city):
                return t(
                    'ERRORS.CLICK_AWAY_AUTOCOMPLETE_QUERY',
                    {value: `${t('GENERAL.EINE')} ${_clickAwayTextConditionallyToLowerText(t('GENERAL.CITY'))}`}
                )
            default:
                return null
        }
    }

    const _constructCostumerString = (customerOption: Customer) => {
        const {
            company,
            firstName,
            lastName,
            email
        } = customerOption

        return <div className={"autocomplete-query-customer-text-wrapper"}>
            {firstName && <Text
                weight={queryType === AUTOCOMPLETE_QUERY_TYPE.customerFirstName ? "bold" : "regular"}>{firstName}</Text>}
            {lastName && <Text
                weight={queryType === AUTOCOMPLETE_QUERY_TYPE.customerLastName ? "bold" : "regular"}>{lastName}</Text>}
            {email &&
                <Text weight={queryType === AUTOCOMPLETE_QUERY_TYPE.customerEmail ? "bold" : "regular"}>{email}</Text>}
            {company && <Text
                weight={queryType === AUTOCOMPLETE_QUERY_TYPE.customerCompany ? "bold" : "regular"}>{`(${company.name})`}</Text>}
        </div>
    }

    const _onClickAway = () => {
        if (
            queryType === AUTOCOMPLETE_QUERY_TYPE.customerFirstName ||
            queryType === AUTOCOMPLETE_QUERY_TYPE.customerLastName ||
            queryType === AUTOCOMPLETE_QUERY_TYPE.customerCompany ||
            queryType === AUTOCOMPLETE_QUERY_TYPE.customerEmail
        ) setQueriedValues([])
    }

    const _renderListBox = () => {
        const composedListBoxClasses = clsx(
            'input-field-result-list',
            'input-field-text-result-list-width',
            listBoxClasses
        )

        if (networkError && focused) return <ListBox
            unorderedListClasses={composedListBoxClasses}
            error={true}
            //@ts-ignore
            getProps={getListboxProps}
            label={true}>
            <ErrorText>ERRORS.GENERAL_NETWORK_ERROR_BODY</ErrorText>
        </ListBox>

        if (groupedOptions.length > 0) return <ListBox
            unorderedListClasses={composedListBoxClasses}
            label={true}
            onClickAway={_onClickAway}
            showClickAwayText={showListBoxClickAwayText}
            clickAwayText={_setClickAwayTextText()}
            //@ts-ignore
            getProps={getListboxProps}>
            {(groupedOptions as typeof queriedValues).map((option, index) => {
                if (isAirlineResponse(option) && queryType === AUTOCOMPLETE_QUERY_TYPE.airline) {
                    const airlineOption = option as AirlineResponse
                    return <li
                        role={'option'}
                        aria-selected={!!(value && isAirlineResponse(value) && value.iata === airlineOption.iata)}
                        {...getOptionProps({option, index})}
                        key={index}>
                        {`${airlineOption.iata} - ${airlineOption.name}`}
                    </li>
                }
                if (isAirportResponse(option) && queryType === AUTOCOMPLETE_QUERY_TYPE.airport) {
                    const airportOption = option as AirportResponse;
                    return <li
                        role={'option'}
                        aria-selected={!!(value && isAirportResponse(value) && value.iata === airportOption.iata)}
                        {...getOptionProps({option, index})}
                        key={index}>
                        {`${airportOption.iata} - ${airportOption.name} (${airportOption.country})`}
                    </li>
                }
                if (isHotelResponse(option) && queryType === AUTOCOMPLETE_QUERY_TYPE.hotel) {
                    const hotelOption = option as HotelResponse
                    return <li
                        role={'option'}
                        aria-selected={!!(value && isHotelResponse(value) && value.name === hotelOption.name)}
                        {...getOptionProps({option, index})}
                        key={index}>
                        {`${hotelOption.name} - ${hotelOption.city}${hotelOption.region ? ` - ${hotelOption.region}` : ''}`}

                    </li>
                }
                if (isCityResponse(option) && queryType === AUTOCOMPLETE_QUERY_TYPE.city) {
                    const cityOption = option as CityResponse;
                    return <li
                        role={'option'}
                        aria-selected={!!(value && isCityResponse(value) && value.name === cityOption.name)}
                        {...getOptionProps({option, index})}
                        key={index}>
                        {`${cityOption.name}${cityOption.region ? ` - ${cityOption.region}` : ''} - ${cityOption.alpha2}`}
                    </li>
                }
                if (isCountryResponse(option) && queryType === AUTOCOMPLETE_QUERY_TYPE.country) {
                    const countryOption = option as CountryResponse;
                    return <li
                        role={'option'}
                        aria-selected={!!(value && isCountryResponse(value) && value.name === countryOption.name)}
                        {...getOptionProps({option, index})}
                        key={index}>
                        {`${countryOption.name} - ${countryOption.alpha2}`}
                    </li>
                }

                if (isFormCustomer(option) &&
                    (
                        queryType === AUTOCOMPLETE_QUERY_TYPE.customerFirstName ||
                        queryType === AUTOCOMPLETE_QUERY_TYPE.customerLastName ||
                        queryType === AUTOCOMPLETE_QUERY_TYPE.customerEmail ||
                        queryType === AUTOCOMPLETE_QUERY_TYPE.customerCompany
                    )) {
                    const customerOption = option as Customer;
                    return <li
                        role={'option'}
                        aria-selected={!!(value && isCountryResponse(value) && value.name === customerOption.firstName)}
                        {...getOptionProps({option, index})}
                        key={index}>
                        {_constructCostumerString(customerOption)}
                    </li>
                }
                return null
            })}
        </ListBox>
        return null
    }

    const _renderEndAdornment = () => {
        const val = defaultValue || value;
        if (isCityResponse(val) && val.region) return <span className="end-adornment">({val?.region})</span>
    }

    const _calculateError = () => {
        if (error) return true
        return error ? !value : false
    }

    const _setLabelColor = () => {
        if (_calculateError()) return {color: theme.colors.warningMain}
        if (labelColor) return {color: theme.colors[labelColor]}
        return {color: theme.colors.secondaryDark}
    }

    const _onBlur = () => {
        if (closeListBoxOnBlur) {
            setQueriedValues([])
            setShowInputClickAwayText(false)
            setShowListBoxClickAwayText(false)
        } else if (value === null && keyHasBeenPressed.current) {
            if (groupedOptions.length === 0) {
                setShowInputClickAwayText(false)
            } else if (groupedOptions.length > 0) {
                setShowListBoxClickAwayText(true)
            } else {
                setShowInputClickAwayText(false)
                setShowListBoxClickAwayText(false)
            }
        } else {
            setShowInputClickAwayText(false)
            setShowListBoxClickAwayText(false)
        }
        setFocused(false)
    }

    const _renderInput = () => {
        const labelTranslated = t(label)
        const labelText = required ? constructRequiredLabel(labelTranslated) : labelTranslated
        const commonProps: CommonAutocompleteInputProps = {
            id: ariaForId,
            "aria-required": "true",
            className: clsx('input-field', _calculateError() && 'input-error', inputClasses),
            type: 'search',
            placeholder: t(placeholder),
            readOnly: Boolean(disabled),
        }

        if (networkError || error) {
            commonProps["aria-invalid"] = "true"
            if (error && ariaErrorMessage) commonProps["aria-errormessage"] = t(ariaErrorMessage)
            if (networkError) commonProps["aria-errormessage"] = t("ERRORS.GENERAL_NETWORK_ERROR_BODY")
        }

        return <>
            <label
                htmlFor={ariaForId}
                className={clsx('base-text-styles label-text-styles', disabled && "autocomplete-query-customer-label-disabled")}
                style={_setLabelColor()}
                {...getInputLabelProps()}>
                {labelText}
            </label>
            <div className={"input-container"}>
                <input
                    {...commonProps}
                    {...getInputProps()}
                    onKeyDown={() => keyHasBeenPressed.current = true}
                    onFocus={() => setFocused(true)}
                    onBlur={_onBlur}/>
                {_renderEndAdornment()}
            </div>
            {showInputClickAwayText && !showLoadingText &&
                <ErrorTextWithBox
                    classes={'autocomplete-query-error-text-with-box'}>{_setClickAwayTextText()}</ErrorTextWithBox>}
            {showLoadingText &&
                <ErrorTextWithBox
                    style={{backgroundColor: 'gray'}}
                    classes={'autocomplete-query-error-text-with-box'}>{t("GENERAL.LOADING")}</ErrorTextWithBox>}
        </>
    }

    return <div
        {...getRootProps()}
        className={clsx('input-container', containerClasses)}
        style={containerStyle}>
        {_renderInput()}
        {_renderListBox()}
    </div>
}

export default AutocompleteQuery

