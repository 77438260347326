import React, {CSSProperties} from 'react'
import clsx from "clsx";
import "./styles.css"
import {ButtonProps, TextButton} from "atmosfair-ui";
import {Tooltip} from "@mui/material";
import {useTranslation} from "react-i18next";

interface BottomButtonsProps {
    containerClass?: string | string[],
    containerStyles?: CSSProperties,
    buttons: {
        disabled?: boolean,
        text: string,
        tooltip?: string,
        onClick: () => void,
        variant?: ButtonProps["variant"]
    }[]
}

const BottomButtons = ({
                           containerClass,
                           containerStyles,
                           buttons
                       }: BottomButtonsProps) => {
    const {t} = useTranslation()

    return <div
        style={containerStyles}
        className={clsx("bottom-buttons-container", buttons.length >= 4 && "bottom-buttons-container-many-buttons-spacer", containerClass)}>
        {buttons?.map((button, index) => {
            const {text, onClick, variant, disabled, tooltip} = button
            const classes = clsx(buttons.length === 1 && buttons[0].variant !== "abort" && "bottom-buttons-button-align-right")
            const Button = <TextButton
                classes={classes}
                disabled={disabled}
                key={index}
                variant={variant}
                onClick={onClick}>{text}</TextButton>
            if (tooltip) return <Tooltip
                placement={"top"}
                key={index}
                title={t(tooltip)}>
                <div className={classes}>{Button}</div>
            </Tooltip>
            return Button
        })}
    </div>
}

export default BottomButtons