import React, {useEffect, useState} from "react";
import {ErrorText, IconButton, Spinner, Text, TextButton} from "atmosfair-ui"
import {useTranslation} from "react-i18next";
import "./styles.css"
import {theme} from "../../styles/theme";
import {useDeleteAgencyLogoMutation, useSetAgencyLogoMutation} from "../../redux/reducer/api";
import {SETTINGS_SPINNER_SIZE} from "../../components/settings/utils";
import {FileUploader} from "react-drag-drop-files"
import {getAgencyLogo} from "../../utils/get-agency-logo";
import {useAppDispatch} from "../../redux/reducer/store";
import {setAgencyLogo} from "../../redux/reducer/user";
import {GENERAL_BACKEND_RESPONSE} from "../../api/model/common";

const FILE_TYPES = ["JPG", "PNG",];
const ALLOWED_FILE_TYPES = ["image/png", "image/jpeg"];

interface UploadLogoProps {
    agencyId: number,
}

const UploadLogo = ({
                        agencyId
                    }: UploadLogoProps) => {
    const [showFileUploadOnEdit, setShowFileUploadOnEdit] = useState(false)
    const [fileUploaded, setFileUploaded] = useState(false)
    const [errorSelectingAgencyLogoViaForm, setErrorSelectingAgencyLogoViaForm] = useState(false)
    const [errorLoadingAgencyLogo, setErrorLoadingAgencyLogo] = useState(false)
    const [isLoadingAgencyLogo, setIsLoadingAgencyLogo] = useState(false)
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [agencyLogoBlob, setAgencyLogoBlob] = useState<string | undefined>(undefined)
    const [aspectRatioHint, setAspectRatioHint] = useState(false)
    const {t} = useTranslation()
    const dispatch = useAppDispatch()

    const [uploadAgencyLogo, {
        data: uploadAgencyLogoResponse,
        isLoading: isLoadingUploadAgencyLogo,
        isError: isErrorUploadAgencyLogo,
    }] = useSetAgencyLogoMutation()

    const [deleteAgencyLogo, {
        data: deleteAgencyLogoResponse,
        isLoading: isLoadingDeleteAgencyLogo,
        isError: isErrorDeleteAgencyLogo,
    }] = useDeleteAgencyLogoMutation()

    useEffect(() => {
        _getAgencyLogo(false)
    }, []);

    useEffect(() => {
        if (deleteAgencyLogoResponse?.status === GENERAL_BACKEND_RESPONSE.success) {
            dispatch(setAgencyLogo({agencyId, agencyLogo: null}))
            _resetForm()
        }
    }, [deleteAgencyLogoResponse]);

    useEffect(() => {
        if (uploadAgencyLogoResponse?.status === GENERAL_BACKEND_RESPONSE.success) {
            setFileUploaded(true)
            // This is a workaround: Because I need the uploaded image as blob and bufferArray in the store
            // but at this point in the code it is quite hard to convert the file into an image blob
            // I simply refetch the just uploaded image and use the downloaded file to convert it into the needed forms
            _getAgencyLogo(true)
        }
    }, [uploadAgencyLogoResponse]);

    const _resetForm = () => {
        setSelectedFile(null)
        setFileUploaded(false)
        setAgencyLogoBlob(undefined)
        setShowFileUploadOnEdit(false)
        setAspectRatioHint(false)
    }

    const _getAgencyLogo = async (silentlyGetLogo: boolean) => {
        !silentlyGetLogo && setIsLoadingAgencyLogo(true)
        const agencyLogo = await getAgencyLogo(agencyId)
        if (agencyLogo?.status === GENERAL_BACKEND_RESPONSE.success) {
            if (agencyLogo && agencyLogo.arrayBuffer && agencyLogo.arrayBuffer.byteLength > 0) {
                const {imageBlob, arrayBuffer, fileType} = agencyLogo
                !silentlyGetLogo && setAgencyLogoBlob(imageBlob!!)
                dispatch(setAgencyLogo({
                    agencyId,
                    agencyLogo: {imageBlob: imageBlob!!, arrayBuffer: arrayBuffer!!, fileType: fileType!!}
                }))
                !silentlyGetLogo && setErrorLoadingAgencyLogo(false)
            }
        } else {
            !silentlyGetLogo && setErrorLoadingAgencyLogo(true)
        }
        !silentlyGetLogo && setIsLoadingAgencyLogo(false)
    }

    const _handleChange = (file: File) => {
        if (ALLOWED_FILE_TYPES.includes(file.type)) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const dataURL = e.target?.result as string;
                const img = new Image();
                img.src = dataURL;
                img.onload = () => {
                    const dimensions = {
                        width: img.width,
                        height: img.height,
                        aspectRatio: img.width / img.height,
                    };
                    if (dimensions.aspectRatio > 1.3) setAspectRatioHint(true)
                    setSelectedFile(file);
                    setAgencyLogoBlob(dataURL);
                    const imageForm = new FormData()
                    imageForm.append("file", file)
                    uploadAgencyLogo({agencyId, logo: imageForm})
                };
            };
            reader.readAsDataURL(file);
        } else {
            setErrorSelectingAgencyLogoViaForm(true)
        }
    }

    const _renderFileUpload = () => <FileUploader
        classes={"upload-logo-drop-area"}
        label={t("SCREENS.SETTINGS.DROP_LOGO_TEXT")}
        hoverTitle={t("SCREENS.SETTINGS.DROP_LOGO_HOVER")}
        handleChange={(file: File) => _handleChange(file)}
        types={FILE_TYPES}/>

    const _renderLogo = () => <>
        <img
            className={"upload-logo-image"}
            src={agencyLogoBlob}
            alt={t("NAVIGATION.LOGO_ALT")}/>
        <div className={"upload-logo-icon-wrapper"}>
            <IconButton
                onClick={() => deleteAgencyLogo(agencyId)}
                icon={"trash"}/>
            <IconButton
                onClick={() => setShowFileUploadOnEdit(true)}
                icon={"penToSquare"}/>
        </div>
    </>

    const _renderContent = () => {
        if (isLoadingUploadAgencyLogo || isLoadingAgencyLogo || isLoadingDeleteAgencyLogo) return <Spinner
            fullPage={false} size={SETTINGS_SPINNER_SIZE}/>
        if (isErrorUploadAgencyLogo) return <Text>SCREENS.SETTINGS.UPLOAD_AGENCY_LOGO_ERROR</Text>
        if (errorLoadingAgencyLogo) return <Text>SCREENS.SETTINGS.LOADING_AGENCY_LOGO_ERROR</Text>
        if (showFileUploadOnEdit) return <div className={"upload-logo-upload-dialog-icon-wrapper"}>
            {_renderFileUpload()}
            <TextButton
                onClick={() => setShowFileUploadOnEdit(false)}
                containerStyle={{marginTop: theme.spacer.large}}
                variant={"abort"}>GENERAL.ABORT</TextButton>
        </div>
        if (selectedFile && fileUploaded) return <div style={{width: "100%"}}>
            <Text
                variant={"subtitleLarge"}
                classes={'upload-logo-confirmation-text'}>
                {`${t("SCREENS.SETTINGS.LOGO_UPLOADED_SUCCESS")}: ${selectedFile.name}`}
            </Text>
            <div className={"upload-logo-logo-wrapper"}>
                {_renderLogo()}
            </div>
            {aspectRatioHint && <Text variant={"subtitleLarge"}>SCREENS.SETTINGS.ASPECT_RATIO_LOGO_HINT</Text>}
        </div>
        if (agencyLogoBlob) return <div className={"upload-logo-logo-wrapper"}>{_renderLogo()}</div>
        if (errorSelectingAgencyLogoViaForm) return <ErrorText>ERRORS.GENERAL_NETWORK_ERROR_BODY</ErrorText>
        return _renderFileUpload()
    }

    return <>
        {_renderContent()}
    </>
}

export default UploadLogo;