import country from "country-list-js";

const countryCodes = require('country-codes-list')
export type CountryAlpha2Props = { [key: string]: string }
export const allCountryNamesAlpha2CountryCode: CountryAlpha2Props = countryCodes.customList('countryCode', '{countryNameEn}')
export const allInternationalPhonePrefixesFormattedForDropdownAlpha2CountryCode: CountryAlpha2Props = countryCodes.customList('countryCode', '[{countryCode}] {countryNameEn}: +{countryCallingCode}')
export const allInternationalPhonePrefixesFormattedForDataGridAlpha2CountryCode: CountryAlpha2Props = countryCodes.customList('countryCode', '+{countryCallingCode}')
export const allCountryNames: string[] = country.names();
export const SETTINGS_SPINNER_SIZE = 20

export interface CountryProps {
    name: string,
    id: number
}

