import React from 'react'
import {Text} from "atmosfair-ui";
import {RED} from "../../styles/additional-colors";
import clsx from "clsx";
import {useFusionAuth} from "@fusionauth/react-sdk";
import './styles.css'

const LogoutButton = () => {
    const {logout} = useFusionAuth()
    return <button className={'logout-button-container'}>
        <Text
            style={{color: RED}}
            classes={clsx("navigation-link", "navigation-logout")}
            onClick={logout}>Logout</Text>
    </button>
}

export default LogoutButton