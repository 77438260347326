import {TRAVEL_OPTION_TYPE} from "../types-enums/travel-option";
import {ACTIVITY_TYPE} from "../types-enums/activity";

export interface ActivityOptionsProps {
    title: string,
    activityType: ACTIVITY_TYPE,
    travelOptionType: TRAVEL_OPTION_TYPE
}

export const ACTIVITY_OPTIONS: ActivityOptionsProps[] = [
    {
        title: "ACTIVITIES.FLIGHT.TITLE",
        activityType: ACTIVITY_TYPE.flight,
        travelOptionType: TRAVEL_OPTION_TYPE.transport
    },
    {
        title: "ACTIVITIES.HOTEL.TITLE",
        activityType: ACTIVITY_TYPE.hotel,
        travelOptionType: TRAVEL_OPTION_TYPE.accommodation
    }
    // {
    //     title: "ACTIVITIES.CAR.TITLE",
    //     activityType: ACTIVITY_TYPE.car,
    //     travelOptionType: TRAVEL_OPTION_TYPE.transport
    // },
    // {
    //     title: "ACTIVITIES.TRAIN.TITLE",
    //     activityType: ACTIVITY_TYPE.train,
    //     travelOptionType: TRAVEL_OPTION_TYPE.transport
    // },
]

export const FLIGHT_PROPERTIES = {
    minNumberFlights: 1,
    maxNumberFlights: 1000,
    minNumberPassenger: 1,
    maxNumberPassenger: 1000,
    calculationMethod: "default",
}

export const INTERNATIONAL_PHONE_PREFIX_GERMANY = "+49"
export const INTERNATIONAL_PHONE_PREFIX_AUSTRIA = "+43"
export const INTERNATIONAL_PHONE_PREFIX_SWITZERLAND = "+41"
export const TOP_INTERNATIONAL_PHONE_PREFIXES = [INTERNATIONAL_PHONE_PREFIX_GERMANY, INTERNATIONAL_PHONE_PREFIX_AUSTRIA, INTERNATIONAL_PHONE_PREFIX_SWITZERLAND]
export const COUNTRY_NAME_GERMANY = "Germany"
export const COUNTRY_NAME_AUSTRIA = "Austria"
export const COUNTRY_NAME_SWITZERLAND = "Switzerland"
export const TOP_COUNTRY_NAMES = [COUNTRY_NAME_GERMANY, COUNTRY_NAME_AUSTRIA, COUNTRY_NAME_SWITZERLAND]
export const COOKIE_NAME = "app.at"
export const HOTEL_PROPERTIES = {
    minNumberRooms: 1,
    maxNumberRooms: 200,
    minNumberPeople: 1,
    maxNumberPeople: 700,
    minNumberNights: 1,
    maxNumberNights: 360,
    calculationMethod: "default",
}
export const VALIDATE_FORM_STARTING_NUMBER = 0
export const RESET_FORM_STARTING_NUMBER = 0