export enum PATH_STRINGS {
    base = '/',
    noRoute = '/*',
    logIn = '/log-in',
    redirectOnSuccessfulLogin = '/log-in/success',
    travelDatabase = '/travel-database',
    help = '/travel-database/help',
    termsAndConditionsAdmin = '/terms-and-conditions',
    termsAndConditionsUser = '/please-contact-your-company-admin',
    register = '/register',
    newJourney = '/travel-database/new-journey',
    addActivity = "/travel-database/add-new",
    editActivity = "/travel-database/edit",
    journey = '/travel-database/journey',
    travelComponentsOverview = '/travel-database/travel-components',
    addTravelOption = "/travel-database/new-journey/add-travel-option",
    settings = "/travel-database/settings"
}