import {PDFDocument, PDFFont, PDFPage, rgb} from "pdf-lib";
import {formatCo2ETotal, formatDate} from "../utils/string-mutation";
import {DateTime} from "luxon";
import {i18n} from "i18next";
import {AgenciesLogosValuesProps} from "../redux/reducer/user";
import {AllActivityTypes} from "../types-enums/activity";
import {isActivityFlightState, isActivityHotelState} from "../utils/type-guards";
import {generateCo2E, generateCustomerFlightRouteStringWithMetaDataAndCo2ForPdf} from "../utils/generate-route-strings";
import {generateCo2EForHotel, generateCustomerHotelLocationString} from "../utils/generate-location-strings";
import {CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL} from "../api/model/common";

export const primaryColor = rgb(0.058823529411764705, 0.3568627450980392, 0.5333333333333333)
export const secondaryColor = rgb(0.6078431372549019, 0.7607843137254902, 0.23529411764705882)
export const lightGrey = rgb(0.9019607843137255, 0.8980392156862745, 0.8980392156862745)
export const darkGrey = rgb(0.615686274509804, 0.615686274509804, 0.6196078431372549)
export const black = rgb(0, 0, 0)

export interface LogoProps {
    pathToLogo: ArrayBuffer | null,
    fileType: AgenciesLogosValuesProps["fileType"] | null
}

export const setHeader = async (
    page: PDFPage,
    pageHeight: number,
    pageWidth: number,
    fontNormal: PDFFont,
    fontBold: PDFFont,
    pageMarginLeft: number,
    pageMarginRight: number,
    i18n: i18n,
    pdfDoc: PDFDocument,
    employeeName: string,
    agencyName: string,
    agencyAddress: string,
    logo: LogoProps,
    email: string,
    completePhoneNumber: string,
) => {
    const fontSizeName = 15
    const titleHeight = 4.5 * fontSizeName
    const titleYPosition = pageHeight - titleHeight
    page.drawText(agencyName, {
        x: pageMarginLeft,
        y: titleYPosition,
        size: fontSizeName,
        font: fontBold,
        color: black,
    })

    const fontSizeAddress = 12
    const addressHeight = 1.2 * fontSizeAddress
    const addressYPosition = titleYPosition - addressHeight
    page.drawText(agencyAddress, {
        x: pageMarginLeft,
        y: addressYPosition,
        size: fontSizeAddress,
        font: fontNormal,
        color: black,
    })


    const currentDate = formatDate(DateTime.now(), i18n.resolvedLanguage)
    const fontSizeDate = 10
    const currentDateWidth = fontNormal.widthOfTextAtSize(currentDate, fontSizeDate);
    page.drawText(currentDate, {
        x: pageWidth - pageMarginRight - currentDateWidth,
        y: titleYPosition,
        size: fontSizeDate,
        font: fontNormal,
        color: black,
    })


    const fontSizeEmployeeName = 10
    const employeeHeight = 1.5 * fontSizeEmployeeName
    const employeeNameYPosition = addressYPosition - employeeHeight
    page.drawText(`${employeeName}, ${email}, ${completePhoneNumber}`, {
        x: pageMarginLeft,
        y: employeeNameYPosition,
        size: fontSizeEmployeeName,
        font: fontNormal,
        color: darkGrey,
    })

    const lineXPosition = 50;
    const lineStart = pageWidth - lineXPosition
    const lineThickness = 1.5
    const lineHeight = 5 * lineThickness
    const lineYPosition = employeeNameYPosition - lineHeight
    page.drawLine({
        start: {
            x: lineXPosition,
            y: lineYPosition
        },
        end: {
            x: lineStart,
            y: lineYPosition
        },
        thickness: lineThickness,
        color: black,
    })

    if (logo.pathToLogo && logo.fileType) await addLogo(page, pageHeight, pageWidth, pdfDoc, {
        pathToLogo: logo.pathToLogo,
        fileType: logo.fileType
    })
    return titleHeight + addressHeight + employeeHeight + lineHeight
}

export const addLogo = async (
    page: PDFPage,
    pageHeight: number,
    pageWidth: number,
    pdfDoc: PDFDocument,
    logo: LogoProps
) => {
    const {pathToLogo, fileType} = logo
    if (fileType && pathToLogo) {
        /// https://jsfiddle.net/Hopding/bcya43ju/5/

        if (fileType === "jpg") {
            const jpgImageBytes = pathToLogo
            const jpgImage = await pdfDoc.embedJpg(jpgImageBytes)
            const jpgDims = jpgImage.scale(0.25)

            page.drawImage(jpgImage, {
                x: page.getWidth() / 2 - jpgDims.width / 2,
                y: page.getHeight() / 2 - jpgDims.height / 2,
                width: jpgDims.width,
                height: jpgDims.height,
                opacity: 0.3,
            })
        }

        if (fileType === "png") {
            const pngImageBytes = pathToLogo
            const pngImage = await pdfDoc.embedPng(pngImageBytes)
            const pngDims = pngImage.scale(0.25)

            page.drawImage(pngImage, {
                x: page.getWidth() / 2 - pngDims.width / 2,
                y: page.getHeight() / 2 - pngDims.height / 2,
                width: pngDims.width,
                height: pngDims.height,
                opacity: 0.3,
            })
        }
    }
    return Promise.resolve()
}

export const setFooter = (
    page: PDFPage,
    pageHeight: number,
    pageWidth: number,
    fontNormal: PDFFont,
    fontBold: PDFFont,
    footerYStartPosition: number,
    pageMarginLeft: number,
    pageMarginRight: number,
    i18n: i18n,
) => {
    const {t} = i18n
    const lineStart = pageWidth - pageMarginLeft
    const lineThickness = 1
    const lineHeight = 5 * lineThickness
    const lineYPosition = footerYStartPosition - lineHeight
    const fontSizeInfo = 8
    const lineHeightTextInfo = fontSizeInfo
    const textBox = pageWidth - pageMarginRight - pageMarginLeft
    const lineHeightWrappedText = 10
    page.drawLine({
        start: {
            x: pageMarginLeft,
            y: lineYPosition
        },
        end: {
            x: lineStart,
            y: lineYPosition
        },
        thickness: lineThickness,
        color: darkGrey,
    })

    const text1 = wrapText(t("PDF.TRAVEL_COMPONENTS.FOOTER.REDUCE_EMISSIONS"), textBox, fontNormal, fontSizeInfo, " ")
    const text1Height = (text1.countTextLines * lineHeightTextInfo) - fontSizeInfo
    const marginToLine = 10
    const text1YPosition = lineYPosition - text1Height - marginToLine
    page.drawText(text1.text, {
        x: pageMarginLeft,
        y: text1YPosition,
        size: fontSizeInfo,
        font: fontNormal,
        lineHeight: lineHeightTextInfo,
        color: darkGrey,
    })

    const marginToText1 = 2
    const text2YPosition = text1YPosition - fontSizeInfo - marginToText1
    page.drawText(t("PDF.TRAVEL_COMPONENTS.FOOTER.DISCLAIMER"), {
        x: pageMarginLeft,
        y: text2YPosition,
        size: fontSizeInfo,
        font: fontNormal,
        color: darkGrey,
    })

    const text3 = wrapText(t("GENERAL.CO2_E_EXPLANATION"), textBox, fontNormal, fontSizeInfo, " ")
    const marginToText2 = 10
    const text3YPosition = text2YPosition - fontSizeInfo - marginToText2
    page.drawText(text3.text, {
        x: pageMarginLeft,
        y: text3YPosition,
        size: fontSizeInfo,
        lineHeight: lineHeightWrappedText,
        font: fontNormal,
        color: darkGrey,
    })
}

export const wrapText = (text: string, width: number, font: PDFFont, fontSize: number, separator: string) => {
    const words = text.split(separator);
    let line = '';
    let result = '';
    let countTextLines = 1
    for (let n = 0; n < words.length; n++) {
        const testLine = line + words[n] + ' ';
        const testWidth = font.widthOfTextAtSize(testLine, fontSize);
        if (testWidth > width) {
            result += line + '\n';
            line = words[n] + ' ';
            countTextLines += 1
        } else {
            line = testLine;
        }
    }
    result += line;
    return {text: result, countTextLines};
}

export const setBodyText = (
    activity: AllActivityTypes,
    i18n: i18n,
    textBlockMaxWidth: number,
    fontFamily: PDFFont,
    fontSize: number,
    separator: string,
) => {
    let totalTextLinesCount = 0
    let textResult: { activityText: string, linesCount: number }[] = []
    const {t} = i18n
    if (isActivityFlightState(activity)) {
        const {flightRoute, showFootNote} = generateCustomerFlightRouteStringWithMetaDataAndCo2ForPdf(activity, i18n)
        flightRoute.forEach(flight => {
            const result = wrapText(flight, textBlockMaxWidth, fontFamily, fontSize, separator)
            const {text, countTextLines} = result
            totalTextLinesCount += countTextLines
            textResult.push({
                activityText: `${text}\n`,
                linesCount: countTextLines
            })
        })
        if (showFootNote) {
            const result = wrapText(`*${t("SCREENS.COMMON.HINT_BETTER_FLIGHT_EMISSION_CALCULATION_SPECIFIC")}`, textBlockMaxWidth, fontFamily, fontSize, separator)
            const {text, countTextLines} = result
            totalTextLinesCount += countTextLines
            textResult.push({
                activityText: `${text}`,
                linesCount: countTextLines
            })
        }
    }
    if (isActivityHotelState(activity)) {
        const {locationString, returnStayString} = generateCustomerHotelLocationString(activity, i18n, false)
        const result = wrapText(locationString, textBlockMaxWidth, fontFamily, fontSize, separator)
        const {text, countTextLines} = result
        totalTextLinesCount += countTextLines
        textResult.push({
            activityText: `${text}\n`,
            linesCount: countTextLines
        })
        if (returnStayString) {
            const result1 = wrapText(returnStayString, textBlockMaxWidth, fontFamily, fontSize, separator)
            const text1 = result1.text
            const countTextLines1 = result1.countTextLines
            totalTextLinesCount += countTextLines1
            textResult.push({
                activityText: `${text1}`,
                linesCount: countTextLines1
            })
        }

    }

    return {
        activityText: textResult.map(text => text.activityText).join(""),
        textLineCount: textResult.map(text => text.linesCount),
        totalTextLinesCount,
    }
}

export const setBodyCo2 = (
    activity: AllActivityTypes,
    i18n: i18n,
    fontFamily: PDFFont,
    fontSize: number,
) => {
    let resultText: string[] = []
    if (isActivityFlightState(activity)) {
        const co2E = generateCo2E(activity, i18n)
        co2E.forEach(co2 => resultText.push(co2))
    }
    if (isActivityHotelState(activity)) {
        const co2E = generateCo2EForHotel(activity, i18n)
        co2E.forEach(co2 => resultText.push(co2))
    }

    const longestWord = resultText.reduce((a, b) => a.length > b.length ? a : b)

    return {
        co2Text: resultText,
        widthOfText: fontFamily.widthOfTextAtSize(longestWord, fontSize),
        numberOfLines: resultText.length,
    }
}

export const drawActivitySummary = (
    activity: AllActivityTypes,
    travelComponentCo2EBodyYPosition: number,
    page: PDFPage,
    travelComponentBodyFontSize: number,
    travelComponentBodyLineHeight: number,
    width: number,
    pageMarginRight: number,
    fontNormal: PDFFont,
    text: string,
) => page.drawText(text, {
    x: width - pageMarginRight - fontNormal.widthOfTextAtSize(text, travelComponentBodyFontSize),
    y: activity.common.totalEmissions?.status === CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.success_with_errors ? travelComponentCo2EBodyYPosition - travelComponentBodyLineHeight : travelComponentCo2EBodyYPosition,
    size: travelComponentBodyFontSize,
    lineHeight: travelComponentBodyLineHeight,
    font: fontNormal,
    color: black,
})

export const drawActivityMetaInformation = (
    i18n: i18n,
    page: PDFPage,
    pageMarginLeft: number,
    travelComponentBodyYPosition: number,
    travelComponentBodyFontSize: number,
    travelComponentTitleFontSize: number,
    travelComponentBodyLineHeight: number,
    travelComponentTitleYPosition: number,
    fontBold: PDFFont,
    width: number,
    pageMarginRight: number,
    fontNormal: PDFFont,
    summaryText: string,
    text: string,
    totalCo2KgE: number,
    resolvedLanguage: string | undefined,
) => {
    const {co2KgEString, co2KgETextWidth} = createTotalCo2String(
        i18n,
        totalCo2KgE!!,
        resolvedLanguage,
        fontNormal,
        travelComponentTitleFontSize,
        true
    )

    page.drawText(summaryText, {
        x: pageMarginLeft,
        y: travelComponentTitleYPosition,
        size: travelComponentTitleFontSize,
        font: fontBold,
        color: black,
    })
    page.drawText(co2KgEString, {
        x: width - pageMarginRight - co2KgETextWidth,
        y: travelComponentTitleYPosition,
        size: travelComponentTitleFontSize,
        font: fontNormal,
        color: black,
    })
    page.drawText(text, {
        x: pageMarginLeft,
        y: travelComponentBodyYPosition,
        size: travelComponentBodyFontSize,
        lineHeight: travelComponentBodyLineHeight,
        font: fontNormal,
        color: black,
    })
}

export const createTotalCo2String = (
    i18n: i18n,
    totalCo2KgE: number,
    resolvedLanguage: string | undefined,
    fontNormal: PDFFont,
    travelComponentTitleFontSize: number,
    addTotalString: boolean,
) => {
    const totalString = i18n.t("GENERAL.TOTAL")
    let co2KgEString = `${formatCo2ETotal(totalCo2KgE, resolvedLanguage)}`
    if (addTotalString) co2KgEString = `${totalString.charAt(0).toUpperCase() + totalString.slice(1)}: ${co2KgEString}`
    const co2KgETextWidth = fontNormal.widthOfTextAtSize(co2KgEString, travelComponentTitleFontSize)
    return {co2KgEString, co2KgETextWidth}
}

export const conditionallyAddPage = (
    heightLeftOver: number,
    totalTravelComponentHeight: number,
    footerHeight: number,
    rowGapTravelComponent: number,
    pdfDoc: PDFDocument,
    page: PDFPage,
    pageHeight: number,
    pageWidth: number,
    fontNormal: PDFFont,
    fontBold: PDFFont,
    pageMarginLeft: number,
    pageMarginRight: number,
    i18n: i18n,
    employeeName: string,
    agencyName: string,
    agencyAddress: string,
    logo: LogoProps,
    email: string,
    completePhoneNumber: string,
    heightForContent: number,
    travelComponentTitleYPosition: number,
    height: number,
    totalHeaderHeight: number,
    travelComponentBodyYPosition: number,
    marginFromTravelComponentTitleToBody: number,
) => {
    if (heightLeftOver < footerHeight - rowGapTravelComponent) {
        page = pdfDoc.addPage()
        setHeader(
            page,
            pageHeight,
            pageWidth,
            fontNormal,
            fontBold,
            pageMarginLeft,
            pageMarginRight,
            i18n,
            pdfDoc,
            employeeName,
            agencyName,
            agencyAddress,
            logo,
            email,
            completePhoneNumber
        )
        setFooter(
            page,
            pageHeight,
            pageWidth,
            fontNormal,
            fontBold,
            footerHeight,
            pageMarginLeft,
            pageMarginRight,
            i18n,
        )
        heightLeftOver = heightForContent - totalTravelComponentHeight
        travelComponentTitleYPosition = height - totalHeaderHeight
        travelComponentBodyYPosition = travelComponentTitleYPosition - marginFromTravelComponentTitleToBody
    }
    return {heightLeftOver, travelComponentTitleYPosition, travelComponentBodyYPosition, page}
}