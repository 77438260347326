import React, {useEffect, useRef, useState} from "react"
import CompleteUserAdminRegistration, {
    CompanyPropsForRegistration,
    UserPropsForRegistration
} from "../../components/registration/complete-user-admin-registration/CompleteUserAdminRegistration";
import {useAppSelector} from "../../redux/reducer/store";
import {
    apiSlice,
    useCreateAgencyMutation,
    useInviteUserMutation,
    useUpdateCompanyMutation,
    useUpdateUserInfoSelfMutation
} from "../../redux/reducer/api";
import {GENERAL_BACKEND_RESPONSE} from "../../api/model/common";
import ErrorPage from "../functional/error-page/ErrorPage";
import {CompanyProps} from "../../api/model/company";
import {getCompany} from "../../utils/agencies-company-getter";
import CreateInitialAgencyAndInviteUsers
    from "../../components/registration/create-initial-agency-and-invite-users/CreateInitialAgencyAndInviteUsers";
import {CreateAgencyRequest} from "../../api/model/agency";
import {toast} from "react-toastify";
import ToastWithTextOnly from "../../molecules/toasts/toast-with-text-only/ToastWithTextOnly";
import {useTranslation} from "react-i18next";
import {UserToInviteProps} from "../../api/model/user";
import FullscreenSpinner from "../../molecules/fullscreen-spinner/FullscreenSpinner";
import {PATH_STRINGS} from "../../routes/route-props";
import {Navigate} from "react-router-dom";

const CompleteRegistration = () => {
    const {userData, companies} = useAppSelector(state => state.user)
    const [userInfo, setUserInfo] = useState(userData)
    const [page, setPage] = useState(0)
    const [companyInfo, setCompanyInfo] = useState<CompanyProps | null>(null)
    const usersToInvite = useRef<UserToInviteProps[] | null>(null)
    const {t} = useTranslation()

    const [triggerGetUserInfoBackup, {
        data: userInfoBackupResponse,
        isLoading: isLoadingUserInfoBackup,
        error: userInfoErrorBackup
    }] = apiSlice.endpoints?.getUserInfo.useLazyQuery()

    const [triggerGetCompanyInfoBackup, {
        data: companyInfoBackupResponse,
        isLoading: loadingCompanyInfoBackup,
        error: companyInfoErrorBackup
    }] = apiSlice.endpoints?.getUsersCompany.useLazyQuery()

    const [updateUserInfo, {
        data: updateUserInfoResponse,
        isLoading: loadingUpdateUserInfo,
        error: isErrorUpdateUserInfo
    }] = useUpdateUserInfoSelfMutation()

    const [updateCompany, {
        data: updateCompanyResponse,
        isLoading: loadingUpdateCompanyRequest,
        isError: errorUpdateCompanyRequest
    }] = useUpdateCompanyMutation()

    const [createAgency, {
        data: createAgencyResponse,
        isLoading: loadingCreateAgencyRequest,
        isError: errorCreateAgencyRequest,
    }] = useCreateAgencyMutation()

    const [inviteUserRequest, {
        data: inviteUserResponse,
        isLoading: loadingInviteUser,
        isError: errorInviteUser
    }] = useInviteUserMutation()

    useEffect(() => {
        if (companies && userInfo) setCompanyInfo(getCompany(userInfo.isKlimaLink, userInfo.companyId, companies))
    }, []);

    useEffect(() => {
        if (!userData) triggerGetUserInfoBackup()
    }, []);

    useEffect(() => {
        if (!companies && userInfoBackupResponse) triggerGetCompanyInfoBackup()
    }, [userInfoBackupResponse]);

    useEffect(() => {
        if (userInfoBackupResponse) setUserInfo(userInfoBackupResponse)
    }, [userInfoBackupResponse]);

    useEffect(() => {
        if (companyInfoBackupResponse && userInfoBackupResponse) setCompanyInfo(getCompany(userInfoBackupResponse.isKlimaLink, userInfoBackupResponse.companyId, companyInfoBackupResponse))
    }, [companyInfoBackupResponse]);

    useEffect(() => {
        if (companyInfo?.isAdmin && page === 0) {
            if (
                updateUserInfoResponse?.status === GENERAL_BACKEND_RESPONSE.success &&
                updateCompanyResponse?.status === GENERAL_BACKEND_RESPONSE.success
            ) setPage(page + 1)
        } else {
            if (updateUserInfoResponse?.status === GENERAL_BACKEND_RESPONSE.success) {
                toast(
                    <ToastWithTextOnly
                        message={t("SCREENS.REGISTRATION.REGISTRATION_COMPLETE_MESSAGE")}/>,
                    {progress: undefined, type: "success"}
                )
                triggerGetUserInfoBackup()
            }
        }
    }, [updateUserInfoResponse, updateCompanyResponse]);

    useEffect(() => {
        if (usersToInvite.current && usersToInvite.current?.length > 0 && userInfo && userInfo.agencyIds[0] !== undefined) inviteUserRequest({
            users: usersToInvite.current,
            agencyId: userInfo.agencyIds[0]
        })
    }, [userInfo?.agencyIds.length]);

    useEffect(() => {
        if (errorCreateAgencyRequest) {
            toast(<ToastWithTextOnly
                    message={t("SCREENS.CREATE_AGENCY_INVITE_USERS.FAILED_CREATE_AGENCY")}/>,
                {progress: undefined, type: "error"}
            );
            updateUserInfo({registrationComplete: true})
        }
    }, [errorCreateAgencyRequest]);

    useEffect(() => {
        if (errorInviteUser) {
            toast(<ToastWithTextOnly
                    message={t("SCREENS.CREATE_AGENCY_INVITE_USERS.COMPLETE_FAIL_INVITE_USER")}/>,
                {progress: undefined, type: "error"}
            );
            updateUserInfo({registrationComplete: true})
        }
    }, [errorInviteUser]);

    const _completeUserAdminRegistration = (providedUserData: UserPropsForRegistration, providedCompanyData?: CompanyPropsForRegistration) => {
        if (providedCompanyData && userInfo && companyInfo) {
            updateCompany({
                name: companyInfo?.name,
                ...providedCompanyData
            })
            updateUserInfo(providedUserData)
        } else {
            updateUserInfo(providedUserData)
        }
    }

    const _onCreateAgencyAndInviteUsers = (users: UserToInviteProps[], agency: CreateAgencyRequest) => {
        usersToInvite.current = users
        createAgency(agency)
        updateUserInfo({registrationComplete: true})
    }

    const _chooseScreen = () => {
        if (companyInfo && userInfo) {
            if (page === 0) return <CompleteUserAdminRegistration
                onRegister={(user, company) => _completeUserAdminRegistration(user, company)}
                company={companyInfo}
                user={userInfo}
                isAdmin={Boolean(companyInfo.isAdmin)}/>
            if (page === 1) return <CreateInitialAgencyAndInviteUsers
                onCreateAgencyInviteUsers={(users, agency) => _onCreateAgencyAndInviteUsers(users, agency)}
                onSkip={() => updateUserInfo({registrationComplete: true})}
                onBack={() => setPage(page - 1)}
                agencyAdmin={userInfo}/>
        }
        return null
    }

    if (userInfoErrorBackup || companyInfoErrorBackup) return <ErrorPage/>
    if (isErrorUpdateUserInfo || errorUpdateCompanyRequest) return <ErrorPage
        additionalText={"SCREENS.REGISTRATION.UPDATE_USER_INFO_ERROR"}/>
    if (loadingCreateAgencyRequest || loadingInviteUser) return <FullscreenSpinner
        showLogo={false}
        message={loadingCreateAgencyRequest ? "SCREENS.REGISTRATION.CREATING_AGENCY_MESSAGE" : "SCREENS.REGISTRATION.INVITING_USERS_MESSAGE"}
        transparent={true}/>
    if (isLoadingUserInfoBackup || loadingCompanyInfoBackup) return <FullscreenSpinner
        showLogo={true}/>
    if (userInfo?.registrationComplete) return <Navigate
        to={PATH_STRINGS.travelDatabase}/>
    if (userInfo && companyInfo) return _chooseScreen()
    return <ErrorPage/>
}

export default CompleteRegistration