import React from 'react'
import {useFusionAuth} from "@fusionauth/react-sdk";
import {Navigate} from "react-router-dom";
import {PATH_STRINGS} from "../../routes/route-props";
import ErrorPage from "../functional/error-page/ErrorPage";
import AuthenticatingUser from "../authenticating-user/AuthenticatingUser";

const LogIn = () => {
    const {login, isAuthenticated, isLoading} = useFusionAuth();
    if (isLoading) return <AuthenticatingUser/>
    if (isAuthenticated) return <Navigate to={PATH_STRINGS.travelDatabase}/>
    if (!isAuthenticated) login()
    return <ErrorPage/>
}

export default LogIn