import "./styles.css"
import {useLocation, useNavigate} from "react-router-dom";
import {PATH_STRINGS} from "../../routes/route-props";
import {AtmosfairLogo, Text} from "atmosfair-ui"
import {useFusionAuth} from "@fusionauth/react-sdk";
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import {RED} from "../../styles/additional-colors";
import {useEffect, useState} from "react";
import {useAppSelector} from "../../redux/reducer/store";
import {Tooltip} from "@mui/material";
import KlimaLinkLogo from "../../molecules/logos/klima-link-logo/KlimaLinkLogo";
import {theme} from "../../styles/theme";

const NAVIGATION = [
    {
        label: 'NAVIGATION.HOME',
        path: PATH_STRINGS.travelDatabase
    },
    {
        label: 'NAVIGATION.SETTINGS',
        path: PATH_STRINGS.settings
    }
]

const Navigation = () => {
    const {agenciesLogos, agencies} = useAppSelector(state => state.user)
    const [agencyLogo, setAgencyLogo] = useState<string | null>(null)
    const [active, setActive] = useState(0)
    const {logout} = useFusionAuth();
    const navigate = useNavigate()
    const {t, i18n} = useTranslation()
    const {resolvedLanguage} = i18n
    const location = useLocation();
    const {pathname} = location

    useEffect(() => {
        if (pathname.includes("settings")) {
            setActive(1)
        } else {
            setActive(0)
        }
    }, [pathname]);

    useEffect(() => {
        if (agencies?.length === 1 && agenciesLogos) {
            const agenciesLogosKeys = Object.keys(agenciesLogos)
            if (agenciesLogosKeys.length === 1) { //TODO() Which logo to choose if there is more than one agency for the user (only the case for agency admin)
                setAgencyLogo(agenciesLogos[agenciesLogosKeys[0]].imageBlob)
            } else {
                setAgencyLogo(null)
            }
        }
    }, [agenciesLogos]);

    return <nav className={"navigation-container"}>
        <div className={"navigation-klimalink-logo-wrapper"}>
            <KlimaLinkLogo size={"medium"} imageClasses={'navigation-klimalink-logo'}/>
            <Tooltip title={t("GENERAL.BETA_TOOLTIP")}>
                <div className={"navigation-beta-badge"}>
                    <Text color={"white"}>BETA</Text>
                </div>
            </Tooltip>
            <div>
                <Text
                    italic={true}
                    style={{marginBottom: theme.spacer.small / 2}}
                    variant={"subtitleLarge"}>GENERAL.POWERED_BY</Text>
                <AtmosfairLogo style={{width: 80}} size={"small"} whichLogo={"only-logo"}/>
            </div>
        </div>
        <ul className={clsx("navigation-list", !agencyLogo && "navigation-list-no-logo")}>
            {NAVIGATION.map((menuPoint, index) => {
                const {path, label} = menuPoint
                return <li key={index}>
                    <Text
                        classes={clsx("navigation-link", active === index ? 'navigation-active' : 'navigation-inactive')}
                        onClick={() => navigate(path)}>
                        {label}
                    </Text>
                </li>
            })}
            <li>
                <Text
                    style={{color: RED}}
                    classes={clsx("navigation-link", "navigation-logout")}
                    onClick={logout}>Logout</Text>
            </li>
            {agencyLogo && <li>
                <div className={"navigation-company-logo-wrapper"}>
                    <img
                        className={"navigation-company-logo"}
                        src={agencyLogo}
                        alt={t("NAVIGATION.LOGO_ALT")}/>
                </div>
            </li>
            }
        </ul>
    </nav>
}

export default Navigation