import React, {CSSProperties} from 'react'
import {DropdownInput} from "atmosfair-ui";
import {convertCountriesFromDropdownFormat, convertCountriesToDropdownFormat,} from "../../utils/dropdown-converter";
import {COUNTRY_NAMES_FOR_DROPDOWN, defaultCountriesForTop} from "../../utils/default-values";
import {allCountryNames, CountryProps} from "../../components/settings/utils";

export interface CountriesDropdownProps {
    error: boolean,
    setSelection: (value: string | null) => void,
    country?: CountryProps | string | null,
    style?: CSSProperties,
    required: boolean,
    readOnly?: boolean,
}

const CountriesDropdown = ({
                               error,
                               setSelection,
                               country,
                               style,
                               required,
                               readOnly,
                           }: CountriesDropdownProps) => {
    const _getCountryFromCountryStringForDropdown = (country: string | null): CountryProps | null => convertCountriesFromDropdownFormat(
        convertCountriesToDropdownFormat(allCountryNames)
            .filter(countryObject => countryObject.raw.name.toLowerCase() === country?.toLowerCase())
    )

    const _convertCountry = () => {
        if (typeof country === "string") return _getCountryFromCountryStringForDropdown(country)?.id ?? null
        return country?.id ? country.id : null
    }

    return <DropdownInput
        readOnly={readOnly}
        defaultValue={_convertCountry()}
        labelColor={"primary"}
        ariaErrorMessage={"SCREENS.COMMON.ARIA_LABELS.COUNTRY_ERROR"}
        formError={error}
        style={style}
        required={required}
        label={"GENERAL.COUNTRY_LABEL"}
        showOnTop={defaultCountriesForTop()}
        options={COUNTRY_NAMES_FOR_DROPDOWN}
        setSelection={selection => setSelection(convertCountriesFromDropdownFormat(selection)?.name ?? null)}/>
}

export default CountriesDropdown