import React, {useEffect} from 'react'
import "./styles.css"
import {Text} from "atmosfair-ui"

export enum MENU_KEY {
    companyAgencySettings,
    userSettings,
    klimaLinkSettings
}

export const MENU = [
    {
        title: "SCREENS.SETTINGS.MENU.ADMIN_SETTINGS",
        onlyAdmin: true,
        onlyKlimaLinkAdmin: false,
        anyUser: false,
        key: MENU_KEY.companyAgencySettings
    },
    {
        title: "SCREENS.SETTINGS.MENU.USER_SETTINGS",
        onlyAdmin: false,
        onlyKlimaLinkAdmin: false,
        anyUser: true,
        key: MENU_KEY.userSettings
    },
    {
        title: "SCREENS.SETTINGS.MENU.KLIMALINK_SETTINGS",
        onlyAdmin: false,
        onlyKlimaLinkAdmin: true,
        anyUser: false,
        key: MENU_KEY.klimaLinkSettings
    }
]

interface SettingsMenuProps {
    onClickMenuPoint: (index: number) => void,
    activeMenuPoint: number,
    isAdminCompanyOrAgencyAdmin: boolean,
    isKlimaLinkAdmin: boolean,
}

const SettingsMenu = ({
                          activeMenuPoint,
                          onClickMenuPoint,
                          isAdminCompanyOrAgencyAdmin,
                          isKlimaLinkAdmin
                      }: SettingsMenuProps) => {
    useEffect(() => {
        const initialActiveMenuPoint = MENU[activeMenuPoint]
        const {onlyAdmin} = initialActiveMenuPoint
        if (onlyAdmin && !isAdminCompanyOrAgencyAdmin) {
            MENU.some((menuPoint, index) => {
                const {onlyAdmin} = menuPoint
                if (!onlyAdmin) {
                    onClickMenuPoint(index)
                    return true
                }
            })
        }
    }, [])

    const _renderMenuPoint = (index: number, title: string) => <li
        className={"settings-menu-menu-point"}
        onClick={() => onClickMenuPoint(index)}
        key={index}>
        <Text variant={index === activeMenuPoint ? "headlineSmall" : "body"}>
            {title}
        </Text>
    </li>

    return <ul className={"settings-menu-container"}>
        {MENU.map((menuPoint, index) => {
            const {onlyAdmin, title, onlyKlimaLinkAdmin, anyUser} = menuPoint
            if (anyUser) return _renderMenuPoint(index, title)
            if (onlyKlimaLinkAdmin && isKlimaLinkAdmin) return _renderMenuPoint(index, title)
            if (onlyAdmin && isAdminCompanyOrAgencyAdmin) return _renderMenuPoint(index, title)
            return null
        })}
    </ul>
}

export default SettingsMenu