import React from 'react';
import {
    convertAircraftTypeFromDropdownFormat,
    convertAircraftTypeToDropdownFormat,
    convertTravelClassesFromDropdownFormat,
    convertTravelClassesToDropdownFormat
} from './utils';
import './styles.css'
import {DropdownInput, Text, TextInput} from "atmosfair-ui";
import {AircraftTypeResponse, AirlineResponse, AirportResponse} from "../../../../api/model/activity-flight";
import {Leg} from "../../../../types-enums/activity-flight";
import DatePicker from "react-datepicker"
import {useTranslation} from "react-i18next";
import {DateTime} from "luxon";
import AutocompleteQuery, {
    AUTOCOMPLETE_ACTIVITY_TYPE,
    AUTOCOMPLETE_QUERY_TYPE,
    AutocompleteQueryProps
} from '../../../../molecules/autocomplete-query/AutocompleteQuery';

import {TravelClass} from "../../../../screens/activity/activity-form/flight";

export interface FlightSelectorProps {
    airportError: AutocompleteQueryProps["error"],
    aircraftsNetworkError: boolean,
    flightNumberError: boolean,
    airportLabel: AutocompleteQueryProps["label"],
    leg: Leg,
    setAirport: (airport: AirportResponse) => void,
    onBlurFlightNumber: (flightNumber: string | null | undefined) => void,
    onFocusFlightNumber?: () => void,
    onBlurFlightDate: () => void,
    required: AutocompleteQueryProps["required"],
    setAircraftType: (aircraftType: AircraftTypeResponse | null) => void,
    setTravelClass: (travelClass: TravelClass | null) => void,
    setFlightNumber: (flightNumber: string | null) => void,
    setAirline: (airline: AirlineResponse | null) => void,
    onCalenderClose?: () => void,
    onCalenderFocus?: () => void,
    aircrafts: AircraftTypeResponse[],
    travelClasses: { id: number, name: string }[],
    setDate: (date: DateTime | null) => void,
}

const FlightSelector = ({
                            airportError,
                            aircraftsNetworkError,
                            flightNumberError,
                            airportLabel,
                            leg,
                            setAirport,
                            setAircraftType,
                            setTravelClass,
                            setFlightNumber,
                            onBlurFlightNumber,
                            onBlurFlightDate,
                            onFocusFlightNumber,
                            required,
                            travelClasses,
                            setDate,
                            onCalenderClose,
                            onCalenderFocus,
                            aircrafts,
                            setAirline,
                        }: FlightSelectorProps) => {
    const {t, i18n} = useTranslation()
    const currentLanguage = i18n.resolvedLanguage

    const _convertFlightNumber = (flightNumber: string | null) => {
        if (flightNumber === null || flightNumber.length === 0) return null
        return flightNumber
    }

    return (
        <div
            className={'flight-selector-container'}>
            <AutocompleteQuery
                placeholder={"ACTIVITIES.FLIGHT.PLACEHOLDER_AIRPORT"}
                ariaErrorMessage={"ACTIVITIES.FLIGHT.ARIA_LABELS.ERROR_AIRPORT_SELECT_FROM_LIST"}
                type={AUTOCOMPLETE_ACTIVITY_TYPE.flight}
                labelColor={"primary"}
                required={required}
                defaultValue={leg.airport}
                error={airportError}
                label={airportLabel}
                queryType={AUTOCOMPLETE_QUERY_TYPE.airport}
                setSelection={airport => setAirport(airport as AirportResponse)}/>
            <div className={"flight-selector-datepicker-wrapper"}>
                <Text
                    color={"primary"}
                    variant={"label"}>
                    ACTIVITIES.FLIGHT.DEPARTURE_DATE
                </Text>
                <DatePicker
                    onFocus={onCalenderFocus}
                    closeOnScroll={true}
                    onCalendarClose={onCalenderClose}
                    onBlur={onBlurFlightDate}
                    dateFormat={currentLanguage === "de" ? "dd.MM.yyyy" : "MM/dd/yyyy"}
                    placeholderText={t('ACTIVITIES.FLIGHT.DEPARTURE_DATE')}
                    selected={leg.date ? leg.date.toJSDate() : null}
                    onChange={date => setDate(date ? DateTime.fromJSDate(date) : null)}/>
            </div>
            <TextInput
                ariaErrorMessage={'ACTIVITIES.FLIGHT.ARIA_LABELS.ERROR_FLIGHT_NUMBER_INVALID'}
                value={leg.flightNumber}
                error={flightNumberError}
                onBlur={value => {
                    const flightNumber = _convertFlightNumber(value.toUpperCase().trim())
                    onBlurFlightNumber(flightNumber)
                    setFlightNumber(flightNumber)
                }}
                labelColor={"primary"}
                onFocus={onFocusFlightNumber}
                containerStyle={{width: "10%"}}
                onChange={value => setFlightNumber(_convertFlightNumber(value.toUpperCase()))}
                placeholder={"ACTIVITIES.FLIGHT.FLIGHT_NUMBER"}
                label={"ACTIVITIES.FLIGHT.FLIGHT_NUMBER"}/>
            <AutocompleteQuery
                placeholder={"GENERAL.AIRLINE"}
                ariaErrorMessage={"ACTIVITIES.FLIGHT.ARIA_LABELS.ERROR_AIRLINE_SELECT_FROM_LIST"}
                type={AUTOCOMPLETE_ACTIVITY_TYPE.flight}
                labelColor={"primary"}
                required={false}
                defaultValue={leg.airline}
                queryType={AUTOCOMPLETE_QUERY_TYPE.airline}
                label={"GENERAL.AIRLINE"}
                containerStyle={{width: "15%"}}
                setSelection={airline => setAirline(airline as AirlineResponse)}/>
            <DropdownInput
                labelColor={"primary"}
                label={"ACTIVITIES.FLIGHT.AIRCRAFT_TYPE"}
                options={convertAircraftTypeToDropdownFormat(aircrafts)}
                dataError={aircraftsNetworkError}
                placeholder={"GENERAL.OPTION_OPTIONAL"}
                setSelection={value => setAircraftType(convertAircraftTypeFromDropdownFormat(value))}
                ariaErrorMessage={"ERRORS.GENERAL_NETWORK_ERROR_BODY"}
                errorMessage={"ERRORS.GENERAL_NETWORK_ERROR_BODY"}
                style={{width: "10%"}}
                showDeleteIcon={true}
                defaultValue={leg?.aircraftType?.id || null}/>
            <DropdownInput
                labelColor={"primary"}
                label={"ACTIVITIES.FLIGHT.TRAVEL_CLASS"}
                options={convertTravelClassesToDropdownFormat(travelClasses)}
                placeholder={"GENERAL.OPTION_OPTIONAL"}
                setSelection={value => setTravelClass(convertTravelClassesFromDropdownFormat(value))}
                style={{width: "5%"}}
                showDeleteIcon={true}
                defaultValue={leg?.travelClass?.id || null}/>
        </div>
    )
}

export default FlightSelector