import React from 'react'
import {Text} from 'atmosfair-ui'
import './styles.css'
import KlimaLinkLogo from "../../../molecules/logos/klima-link-logo/KlimaLinkLogo";
import LogoutButton from "../../../molecules/logout-button/LogoutButton";

const TermsAndConditionsNormalUser = () => {
    return <section className={'terms-and-conditions-normal-user-container'}>
        <LogoutButton/>
        <div className={'terms-and-conditions-normal-user-logo'}><KlimaLinkLogo size={"medium"}/></div>
        <Text variant={'headlineLarge'}>SCREENS.TERMS_AND_CONDITIONS.NORMAL_USER.TITLE</Text>
        <Text variant={'headlineSmall'}>SCREENS.TERMS_AND_CONDITIONS.NORMAL_USER.HINT</Text>
    </section>
}

export default TermsAndConditionsNormalUser