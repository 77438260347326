import {E_CO2} from "./unit-strings";
import {roundCo2E, roundCo2EPerPerson, roundNumber} from "./round-values";
import {DateTime} from "luxon";
import {BaseTextProps} from "atmosfair-ui"

export const constructRequiredLabel = (string: BaseTextProps['children']) => `${string}*`
export const formatCo2ETotal = (co2E: number, currentLanguage: string | undefined) => `${roundCo2E(co2E).toLocaleString(currentLanguage)} ${E_CO2}`.trim()
export const formatCo2ETotalForHotel = (co2E: number, currentLanguage: string | undefined) => `${roundNumber(co2E, 1).toLocaleString(currentLanguage)} ${E_CO2}`.trim()
export const formatCo2EPerPerson = (co2ETotal: number, countPerson: number, currentLanguage: string | undefined) => `${roundCo2EPerPerson(co2ETotal, countPerson).toLocaleString(currentLanguage)} ${E_CO2}`.trim()
export const formatCo2EPerPersonForHotels = (co2ETotal: number, countPerson: number, currentLanguage: string | undefined) => `${((roundNumber(co2ETotal, 1) / countPerson)).toLocaleString(currentLanguage)} ${E_CO2}`.trim()
export const formatDate = (dateToFormat: DateTime, currentLanguage: string | undefined) => {
    return dateToFormat.toLocaleString({
        day: '2-digit',
        month: 'long',
        year: 'numeric',
    }, {locale: currentLanguage})
}
export const ROUTE_SEPARATOR = ' --- '
export const NO_DATA_STRING = "--"
export const MORE_DATA_STRING = "..."
export const ADDITIONAL_FLIGHT_INFORMATION_SEPARATOR = " / "
export const COMMA_SEPARATOR = ', '
export const convertUndefinedOrNullToEmptyString = (word: string | null | undefined) => {
    if (word === null || typeof word === undefined) return ""
    return word
}