import React, {useEffect, useRef, useState} from 'react';
import './styles.css'
import {AllActivityTypesArrayDiscriminating} from "../../types-enums/activity";
import TravelComponentAbbreviation from "../travel-component-abbreviation/TravelComponentAbbreviation";
import {DropdownInput, IconAndText, IconButton, Text, TextInput} from "atmosfair-ui";
import {AgencyProps} from "../../api/model/agency";
import {
    validateAgency,
    validateCompanyName,
    validateEmail,
    validateFirstAndLastName,
    validTravelComponentsOrJounrneyName
} from "../forms/utils";
import {theme} from "../../styles/theme";
import {cloneDeep, orderBy} from "lodash";
import AutocompleteQuery, {
    AUTOCOMPLETE_ACTIVITY_TYPE,
    AUTOCOMPLETE_QUERY_TYPE
} from "../../molecules/autocomplete-query/AutocompleteQuery";
import {isCustomer} from "../../utils/type-guards";
import {convertAgenciesFromDropdownFormat, convertAgenciesToDropdownFormat,} from "../../utils/dropdown-converter";
import {CreateBundle} from "../../api/model/bundle";
import AddressForm, {AddressProps} from "../forms/address-form/AddressForm";
import PhoneNumberForm, {PhoneNumberProps} from "../forms/phone-number-form/PhoneNumberForm";
import clsx from "clsx";
import {Customer, CustomerCompany} from "../../api/model/customer";
import {useEffectAllDepsChange} from "../../utils/custom-use-effect";
import {VALIDATE_FORM_STARTING_NUMBER} from "../../constants";

interface SaveTravelComponentsDialogueProps {
    travelComponents: AllActivityTypesArrayDiscriminating,
    agencies: AgencyProps[],
    validateForm: number,
    setIsFormValid: (data: CreateBundle) => void,
}

interface SaveTravelComponentsDialogueFormProps {
    travelComponentName: string,
    description: string,
    customer: CustomerFormProps
    agency: AgencyProps | null,
}

interface SaveTravelComponentsDialogueFormErrorProps {
    travelComponentNameError: boolean,
    firstNameError: boolean,
    lastNameError: boolean,
    emailError: boolean,
    companyNameError: boolean,
    agencyError: boolean,
    descriptionError: boolean,
}

interface FreelyTypedCustomerProps extends Pick<Customer, 'firstName' | 'lastName' | 'company' | 'email'> {
    id: null
}

export interface CustomerFormProps {
    id: number | null,
    firstName: string,
    lastName: string,
    company: CustomerCompany | null,
    email: string,
}

const initialCustomer: CustomerFormProps = {
    id: null,
    firstName: '',
    lastName: '',
    company: null,
    email: '',
}

const SaveTravelComponentsDialogue = ({
                                          travelComponents,
                                          agencies,
                                          validateForm,
                                          setIsFormValid,
                                      }: SaveTravelComponentsDialogueProps) => {
    const [selectedTravelComponents, setSelectedTravelComponents] = useState(travelComponents.map(Boolean))
    const [addressFormData, setAddressFormData] = useState<AddressProps | null>(null)
    const [phoneNumberFormData, setPhoneNumberFormData] = useState<PhoneNumberProps | null>(null)
    const [editCustomerInformation, setEditCustomerInformation] = useState(true)
    const [showAdditionalCustomerInformation, setShowAdditionalCustomerInformation] = useState(false)
    const [formData, setFormData] = useState<SaveTravelComponentsDialogueFormProps>({
        travelComponentName: '',
        description: '',
        customer: initialCustomer,
        agency: null,
    })
    const [formErrorData, setFormErrorData] = useState<SaveTravelComponentsDialogueFormErrorProps>({
        travelComponentNameError: false,
        firstNameError: false,
        lastNameError: false,
        emailError: false,
        companyNameError: false,
        agencyError: false,
        descriptionError: false,
    })
    const {
        travelComponentNameError,
        firstNameError,
        lastNameError,
        emailError,
        agencyError,
        companyNameError,
        descriptionError
    } = formErrorData
    const {
        travelComponentName,
        agency,
        customer,
        description,
    } = formData
    let id: number | null = null
    let firstName = ""
    let lastName = ""
    let company: CustomerCompany | null = null
    let email = ""
    let street = ""
    let houseNumber = ""
    let city = ""
    let zipCode = ""
    let phoneNumber = ""
    let additionalAddressInformation = ""
    if (customer) {
        id = customer.id ?? null
        firstName = customer.firstName ?? ""
        lastName = customer.lastName ?? ""
        company = customer.company ?? null
        email = customer.email ?? ""
    }
    if (addressFormData) {
        street = addressFormData.street ?? ""
        houseNumber = addressFormData.houseNumber ?? ""
        city = addressFormData.city ?? ""
        zipCode = addressFormData.zipCode ?? ""
        additionalAddressInformation = addressFormData.additionalAddressInformation ?? ""
    }
    if (phoneNumberFormData) phoneNumber = phoneNumberFormData.phoneNumber ?? ""
    const showAgencySelection = agencies.length > 1
    const orderedTravelComponents = useRef(orderBy(travelComponents, travelComponent => travelComponent.common.activityType, ['desc']))
    const freelyTypedCustomer = useRef<FreelyTypedCustomerProps | null>(null)

    useEffectAllDepsChange(() => _saveTravelComponents(), [validateForm, addressFormData, phoneNumberFormData])

    useEffect(() => {
        if (validateForm > VALIDATE_FORM_STARTING_NUMBER) _validateForm()
    }, [validateForm])

    const _saveTravelComponents = () => {
        if (addressFormData && phoneNumberFormData) {
            let agencyId
            if (agencies.length === 1) {
                agencyId = agencies[0].id // currently a user (unless its KlimaLink Admin) can only be in one agency at a time, that is why it is save to simply pick the first agency in the array
            } else {
                if (agency) agencyId = agency.id
            }

            if (typeof agencyId === "number") {
                let customerToSave: CreateBundle["customer"]
                if (freelyTypedCustomer.current) {
                    customerToSave = {
                        id: freelyTypedCustomer.current.id,
                        firstName: freelyTypedCustomer.current.firstName,
                        lastName: freelyTypedCustomer.current.lastName,
                        email: freelyTypedCustomer.current.email,
                        company: freelyTypedCustomer.current.company,
                        ...addressFormData,
                        ...phoneNumberFormData,
                    }
                } else if (
                    (firstName.length > 0 || lastName.length > 0 || email.length > 0 || (company && company.name.length > 0)) ||
                    id !== null
                ) {
                    customerToSave = {
                        id,
                        firstName,
                        lastName,
                        email,
                        company,
                        ...addressFormData,
                        ...phoneNumberFormData,
                    }
                } else {
                    customerToSave = null
                }

                setIsFormValid({
                    name: travelComponentName,
                    description,
                    agencyId,
                    customer: customerToSave,
                    data: orderedTravelComponents.current.filter((_, index) => selectedTravelComponents[index])
                })
            }
        }
    }

    // useEffect(() => {
    //     if (validateForm > VALIDATE_FORM_STARTING_NUMBER) {
    //         const validForm = _validateForm()
    //         if (validForm && addressFormData && phoneNumberFormData) {
    //             let agencyId
    //             if (agencies.length === 1) {
    //                 agencyId = agencies[0].id // currently a user (unless its KlimaLink Admin) can only be in one agency at a time, that is why it is save to simply pick the first agency in the array
    //             } else {
    //                 if (agency) agencyId = agency.id
    //             }
    //
    //             if (typeof agencyId === "number") {
    //                 let customerToSave: CreateBundle["customer"]
    //                 if (freelyTypedCustomer.current) {
    //                     customerToSave = {
    //                         id: freelyTypedCustomer.current.id,
    //                         firstName: freelyTypedCustomer.current.firstName,
    //                         lastName: freelyTypedCustomer.current.lastName,
    //                         email: freelyTypedCustomer.current.email,
    //                         company: freelyTypedCustomer.current.company,
    //                         ...addressFormData,
    //                         ...phoneNumberFormData,
    //                     }
    //                 } else if (
    //                     (firstName.length > 0 || lastName.length > 0 || email.length > 0 || (company && company.name.length > 0)) ||
    //                     id !== null
    //                 ) {
    //                     customerToSave = {
    //                         id,
    //                         firstName,
    //                         lastName,
    //                         email,
    //                         company,
    //                         ...addressFormData,
    //                         ...phoneNumberFormData,
    //                     }
    //                 } else {
    //                     customerToSave = null
    //                 }
    //
    //                 setIsFormValid({
    //                     name: travelComponentName,
    //                     description,
    //                     agencyId,
    //                     customer: customerToSave,
    //                     data: orderedTravelComponents.current.filter((_, index) => selectedTravelComponents[index])
    //                 })
    //             }
    //         }
    //     }
    // }, [validateForm, addressFormData, phoneNumberFormData]);

    const _updateFormData = <K extends keyof SaveTravelComponentsDialogueFormProps>(key: K, payload: SaveTravelComponentsDialogueFormProps[K]) => setFormData({
        ...formData,
        [key]: payload
    })

    const _updateCustomerOnFreelyTypedString = <K extends keyof FreelyTypedCustomerProps>(key: K, payload: FreelyTypedCustomerProps[K]) => {
        if (freelyTypedCustomer.current) {
            freelyTypedCustomer.current[key] = payload
        } else {
            freelyTypedCustomer.current = {
                firstName: "",
                lastName: "",
                email: "",
                company: {
                    id: null,
                    name: ""
                },
                id: null,
                [key]: payload

            }
        }
    }

    const _validateForm = () => {
        // mandatory
        const validTravelComponentName = validTravelComponentsOrJounrneyName(travelComponentName)
        let validAgency = true
        if (showAgencySelection) validAgency = validateAgency(agency)
        let validCustomerFirstName = true
        let validCustomerLastName = true
        let validCustomerEmail = true
        let validCompanyName = true
        let validDescription = true
        if (freelyTypedCustomer.current) {
            const {email, firstName, lastName, company} = freelyTypedCustomer.current
            if (email.length > 0) validCustomerEmail = validateEmail(email)
            if (firstName?.length > 0) validCustomerFirstName = validateFirstAndLastName(firstName)
            if (lastName?.length > 0) validCustomerLastName = validateFirstAndLastName(lastName)
            if (company?.name) validCompanyName = validateCompanyName(company?.name)
        } else {
            if (email.length > 0) validCustomerEmail = validateEmail(email)
            if (firstName?.length > 0) validCustomerFirstName = validateFirstAndLastName(firstName)
            if (lastName?.length > 0) validCustomerLastName = validateFirstAndLastName(lastName)
            if (company && company.name) validCompanyName = validateCompanyName(company.name)
            if (description.length > 0) validDescription = description.length > 3
        }

        setFormErrorData({
            travelComponentNameError: !validTravelComponentName,
            firstNameError: !validCustomerFirstName,
            lastNameError: !validCustomerLastName,
            emailError: !validCustomerEmail,
            companyNameError: !validCompanyName,
            agencyError: !validAgency,
            descriptionError: !validDescription
        })

        return [
            validTravelComponentName,
            validCustomerFirstName,
            validCustomerLastName,
            validCustomerEmail,
            validDescription,
            validAgency
        ].every(Boolean)
    }

    return <div className={'save-travel-components-dialogue-container'}>
        <div className={"save-travel-components-dialogue-input-wrapper"}>
            <div className={"save-travel-components-dialogue-first-row-wrapper"}>
                <TextInput
                    required={true}
                    labelColor={"primary"}
                    value={travelComponentName}
                    error={travelComponentNameError}
                    ariaErrorMessage={"SCREENS.COMMON.ARIA_LABELS.TRAVEL_COMPONENT_NAME_ERROR"}
                    containerStyle={{flex: 1}}
                    onBlur={value => _updateFormData("travelComponentName", value)}
                    onChange={value => _updateFormData("travelComponentName", value)}
                    placeholder={"GENERAL.NAME"}
                    label={"GENERAL.TRAVEL_COMPONENT_NAME"}/>
                {showAgencySelection && <DropdownInput
                    labelColor={"primary"}
                    ariaErrorMessage={"SCREENS.COMMON.ARIA_LABELS.AGENCY_ERROR"}
                    formError={agencyError}
                    required={true}
                    style={{width: '30%'}}
                    label={"GENERAL.AGENCY"}
                    options={convertAgenciesToDropdownFormat(agencies)}
                    setSelection={selection => _updateFormData("agency", convertAgenciesFromDropdownFormat(selection))}
                />}
            </div>
            <div
                className={"save-travel-components-dialogue-input-wrapper"}>
                <TextInput
                    value={description}
                    error={descriptionError}
                    ariaErrorMessage={"SCREENS.COMMON.ARIA_LABELS.TRAVEL_COMPONENT_DESCRIPTION_ERROR"}
                    inputStyle={{resize: "vertical"}}
                    containerClasses={"save-travel-components-dialog-description"}
                    labelColor={"primary"}
                    onBlur={value => _updateFormData("description", value)}
                    onChange={value => _updateFormData("description", value)}
                    placeholder={"GENERAL.DESCRIPTION"} label={'GENERAL.DESCRIPTION'} multiline={true}/>
            </div>
            <Text classes={"save-travel-components-dialogue-customer-headline"}
                  variant={"headlineSmall"}>Customer</Text>
            <div
                className={"save-travel-components-dialogue-input-wrapper"}>
                <AutocompleteQuery
                    containerStyle={{flex: 1}}
                    required={false}
                    labelColor={"primary"}
                    defaultValue={customer}
                    error={firstNameError}
                    queryType={AUTOCOMPLETE_QUERY_TYPE.customerFirstName}
                    type={AUTOCOMPLETE_ACTIVITY_TYPE.customer}
                    ariaErrorMessage={"SCREENS.COMMON.ARIA_LABELS.FIRST_NAME_ERROR"}
                    setSelection={value => {
                        setEditCustomerInformation(false)
                        isCustomer(value) && _updateFormData("customer", value)
                    }}
                    getFreelyTypedString={validateForm}
                    placeholder={"GENERAL.FIRST_NAME"}
                    label={"GENERAL.CUSTOMER_FIRST_NAME"}
                    setFreelyTypedString={value => _updateCustomerOnFreelyTypedString("firstName", value)}
                    closeListBoxOnBlur={true}
                />
                <AutocompleteQuery
                    containerStyle={{flex: 1}}
                    required={false}
                    labelColor={"primary"}
                    defaultValue={customer}
                    error={lastNameError}
                    queryType={AUTOCOMPLETE_QUERY_TYPE.customerLastName}
                    type={AUTOCOMPLETE_ACTIVITY_TYPE.customer}
                    ariaErrorMessage={"SCREENS.COMMON.ARIA_LABELS.LAST_NAME_ERROR"}
                    setSelection={value => {
                        setEditCustomerInformation(false)
                        isCustomer(value) && _updateFormData("customer", value)
                    }}
                    getFreelyTypedString={validateForm}
                    placeholder={"GENERAL.LAST_NAME"}
                    label={"GENERAL.CUSTOMER_LAST_NAME"}
                    setFreelyTypedString={value => _updateCustomerOnFreelyTypedString("lastName", value)}
                    closeListBoxOnBlur={true}
                />
            </div>
            <div
                className={"save-travel-components-dialogue-input-wrapper"}>
                <AutocompleteQuery
                    containerStyle={{flex: 1}}
                    required={false}
                    labelColor={"primary"}
                    defaultValue={customer}
                    ariaErrorMessage={"SCREENS.COMMON.ARIA_LABELS.E_MAIL_ERROR"}
                    queryType={AUTOCOMPLETE_QUERY_TYPE.customerEmail}
                    type={AUTOCOMPLETE_ACTIVITY_TYPE.customer}
                    error={emailError}
                    setSelection={value => {
                        setEditCustomerInformation(false)
                        isCustomer(value) && _updateFormData("customer", value)
                    }}
                    getFreelyTypedString={validateForm}
                    placeholder={"GENERAL.EMAIL"}
                    label={"GENERAL.CUSTOMER_EMAIL"}
                    setFreelyTypedString={value => _updateCustomerOnFreelyTypedString("email", value)}
                    closeListBoxOnBlur={true}
                />
                <AutocompleteQuery
                    containerStyle={{flex: 1}}
                    required={false}
                    labelColor={"primary"}
                    defaultValue={customer}
                    ariaErrorMessage={"SCREENS.COMMON.ARIA_LABELS.COMPANY_ERROR"}
                    queryType={AUTOCOMPLETE_QUERY_TYPE.customerCompany}
                    type={AUTOCOMPLETE_ACTIVITY_TYPE.customer}
                    error={companyNameError}
                    setSelection={value => {
                        setEditCustomerInformation(false)
                        isCustomer(value) && _updateFormData("customer", value)
                    }}
                    getFreelyTypedString={validateForm}
                    placeholder={"GENERAL.NAME"}
                    label={"GENERAL.COMPANY_NAME"}
                    setFreelyTypedString={value => _updateCustomerOnFreelyTypedString("company", {id: null, name: value})}
                    closeListBoxOnBlur={true}
                />
            </div>
            <div className={'save-travel-components-dialogue-additional-customer-information-icon-wrapper'}>
                <IconAndText
                    color={"primary"}
                    containerStyle={{marginBottom: showAdditionalCustomerInformation ? theme.spacer.large : 0}}
                    text={"SCREENS.TRAVEL_COMPONENTS_OVERVIEW.SAVE_TRAVEL_COMPONENTS_MODAL.ADDITIONAL_CUSOMTER_INFORMATION"}
                    icon={showAdditionalCustomerInformation ? "caretDown" : "caretRight"}
                    onClick={() => setShowAdditionalCustomerInformation(!showAdditionalCustomerInformation)}/>
                <IconButton
                    onClick={() => setEditCustomerInformation(!editCustomerInformation)}
                    size={"medium"}
                    icon={"penToSquare"}
                    tooltip={"SCREENS.TRAVEL_COMPONENTS_OVERVIEW.EDIT_CUSTOMER_INFORMATION_TOOLTIP"}/>
            </div>
            <div
                style={{rowGap: theme.spacer.large,}}
                className={
                    clsx(
                        "save-travel-components-dialogue-input-wrapper",
                        !showAdditionalCustomerInformation && "save-travel-components-dialogue-additional-customer-information-visibility"
                    )}>
                <AddressForm
                    defaultValues={{
                        street,
                        houseNumber,
                        zipCode,
                        city,
                        additionalAddressInformation
                    }}
                    showCountry={false}
                    disableForm={!editCustomerInformation}
                    countryRequired={false}
                    streetRequired={false}
                    houseNumberRequired={false}
                    zipCodeRequired={false}
                    cityRequired={false}
                    additionalAddressInformationRequired={false}
                    validateForm={validateForm}
                    onFormIsValid={setAddressFormData}/>
                <PhoneNumberForm
                    showInternationalPhonePrefix={false}
                    disableForm={!editCustomerInformation}
                    defaultValues={{phoneNumber}}
                    internationalPhonePrefixRequired={false}
                    phoneNumberRequired={false}
                    validateForm={validateForm}
                    onFormIsValid={setPhoneNumberFormData}/>
            </div>
        </div>
        <Text variant={"headlineSmall"}>Travel Components To Save</Text>
        {orderedTravelComponents.current.map((travelComponent, index) =>
            <TravelComponentAbbreviation
                selectTravelComponent={() => {
                    const updatedSelectedTravelComponents = cloneDeep(selectedTravelComponents)
                    updatedSelectedTravelComponents[index] = !updatedSelectedTravelComponents[index]
                    setSelectedTravelComponents(updatedSelectedTravelComponents)
                }}
                key={index}
                selected={selectedTravelComponents[index]}
                travelComponent={travelComponent}/>)}
    </div>
}

export default SaveTravelComponentsDialogue