import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {TypeOptions} from "react-toastify";

type ApiProps = {
    message: string,
    toastType: TypeOptions
} | null

interface SettingsState {
    apiError: ApiProps,
    apiSuccess: ApiProps
}

const initialState: SettingsState = {
    apiError: null,
    apiSuccess: null,
}

export const settingsSlice = createSlice({
    name: 'journey',
    initialState,
    reducers: {
        setApiError: (state, action: PayloadAction<SettingsState["apiError"]>) => {
            state.apiError = action.payload
        },
        setApiSuccess: (state, action: PayloadAction<SettingsState["apiSuccess"]>) => {
            state.apiSuccess = action.payload
        },
        resetApiProps: (state, action: PayloadAction<"error" | "success">) => {
            const {payload} = action
            if (payload === "error") {
                state.apiError = null
            } else {
                state.apiSuccess = null
            }
        }
    }
})

export const {
    resetApiProps,
    setApiError,
    setApiSuccess
} = settingsSlice.actions;
export default settingsSlice.reducer;