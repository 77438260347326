const english = {
    GENERAL: {
        POWERED_BY: "Powered by",
        DESCRIPTION: "Description",
        START_DATE: "Start date",
        TRAVEL_COMPONENT_NAME: "Travel Component name",
        END_DATE: "End date",
        CONTACT_PERSON: "Contact person",
        CUSTOMER_FIRST_NAME: "Customer first name",
        CUSTOMER_LAST_NAME: "Customer last name",
        CUSTOMER_EMAIL: "Customer e-mail",
        CUSTOMER_PHONE: "Customer phone number",
        CUSTOMER_STREET: "Customer street",
        CUSTOMER_CITY: "Customer city",
        CUSTOMER_HOUSE_NUMBER: "Customer house number",
        CUSTOMER_ZIP_CODE: "Customer zip code",
        TOTAL_EMISSIONS: "Total emissions",
        BACK: "Back",
        YES: "Yes",
        NO: "No",
        ADMIN: "Admin",
        EMAIL: "E-Mail",
        PHONE: 'Phone number',
        DAY: "Day",
        ABORT: "Return",
        SAVE: "Save",
        TRANSPORT: "Transport",
        ACCOMMODATION: "Accommodation",
        UNDERTAKING: "Activity",
        ADD: "Add",
        TOTAL: "total",
        JOURNEY: "Journey",
        PERSONS: "Persons",
        PROCEED: "Proceed",
        DAYS: "Days",
        FROM: "from",
        LAST_EDITED: "last edited",
        DELETE: "Delete",
        SEARCH: "Search",
        SHARE: "Share",
        DATE_OF_ACTIVITY: "Date",
        TRANSFERS: "Transfers",
        EMISSIONS: "Emissions",
        SPINNER: "Spinner, shown as long as needed to load the data.",
        COPIED: "Copied",
        PASSENGER: 'Person',
        PASSENGERS: 'Persons',
        OPTION_OPTIONAL: 'Optional',
        FOR: "for",
        CALCULATE: "Calculate",
        CALCULATE_AGAIN: "Calculate again",
        UNDO: "Undo",
        DATE: "Date",
        FAILED: "Failed",
        TOTAL_CO2: "Total CO₂",
        RESET: "Reset",
        SELECT_ALL: "Select all",
        OK: "OK",
        EMISSIONS_CALCULATED: "Emissions calculated successfully!",
        EMISSIONS_CALCULATED_WITH_SCOPE: "Emissions calculated successfully at the {{scope}} level!",
        GROUP: "Group rows",
        TRAVELERS_COUNT: "Persons",
        EMISSIONS_PER_PERSON: "Emissions per person",
        PER_PERSON: "per person",
        INVITE: "Invite",
        TRAVEL_COMPONENTS: "Travel components",
        BETA_TOOLTIP: "This is a beta version. Some functionality might not work completly flawless. If you have any feedback or suggestions, please send them to info@klimalink.org.",
        CO2_E_EXPLANATION: 'All emissions are given in "kg CO₂ equivalent" (kg CO₂e); this corresponds to the amount of CO₂ that has the same climate-warming effect as the sum of all emissions produced, including other greenhouse gases (e.g. methane) and effects (contrails, ozone formation).',
        AGENCY: "Agency",
        AGENCY_NAME: "Agency name",
        FIRST_CONTACT_PERSON: "1. Contact person",
        SECOND_CONTACT_PERSON: "2. Contact person",
        FIRST_CONTACT_PERSON_FIRST_NAME: "1. Contact person first name",
        FIRST_CONTACT_PERSON_LAST_NAME: "1. Contact person last name",
        SECOND_CONTACT_PERSON_FIRST_NAME: "2. Contact person first name",
        SECOND_CONTACT_PERSON_LAST_NAME: "2. Contact person last name",
        STREET: "Street",
        FIRST_NAME: "First name",
        LAST_NAME: "Last name",
        ADDITIONAL_ADDRESS_INFORMATION: "Additional information",
        ZIP_CODE: "Zip code",
        CITY: "City",
        COMPANY_NAME: "Company name",
        HOUSE_NUMBER: "House number",
        TELEPHONE_NUMBER_WITH_AREA_CODE_LABEL: "Telephone number with area code",
        COUNTRY_LABEL: "Country",
        COUNTRY_CODE_LABEL: "Country dialling code",
        HELP: "Help",
        TERMS: "Terms",
        AIRPORT: 'Airport',
        HOTEL: 'Hotel',
        EINEN: 'an',
        EIN: 'a',
        EINE: 'a',
        AIRLINE: "Airline",
        COUNTRY: 'Country',
        COMPANY_EMAIL: 'Company e-mail',
        AGENCY_EMAIL: 'Agency e-mail',
        SKIP: "Skip",
        LOADING: "Loading..."
    },
    TOASTS: {
        SUCCESS: {
            CREATE_BUNDLE: "Travel Components saved.",
            UPDATE_USER_SUCCESS_ADMIN: "Expedient successfully edited.",
            MAKE_USER_AGENCY_ADMIN: "Expedient is now agency admin.",
            UNMAKE_USER_AGENCY_ADMIN: "Expedient is no longer agency admin.",
            ADD_COMPANY: "Company added successfully.",
            ADD_AGENCY: "Agency added successfully.",
            EDIT_AGENCY: "Agency edited successfully.",
            EDIT_COMPANY: "Company edited succesfully.",
            INVITE_COMPANY_ADMIN: "E-mail to company admin was send.",
            INVITE_USER: "Expedient invited successfully. An e-mail was send with further instructions.",
            DELETE_USER: "Expedient deleted.",
            DELETE_AGENCY: "Agency deleted successfully.",
            UPLOAD_AGENCY_LOGO: "Agency logo uploaded successfully.",
            DELETE_AGENCY_LOGO: "Agency logo deleted successfully.",
            CHANGE_USERS_AGENCY: "Expedient successfully assigned to different agency.",
            SEND_PASSWORD_RESET_EMAIL: "We have sent you an email. Follow the instructions in the email to set a new password.",
            UPDATE_USER_SELF: "You successfully edited your data.",
            HOTEL_FOUND_VIA_GIATA_ID: "Hotel with GIATA ID found",
            HOTEL_FOUND_VIA_GIATA_ID_REPLACED_PREVIOUS: "If needed we have replaced your previous values for Hotel, Country, City and Stars.",
            REPLACED_AIRPORT_SINGULAR: "We have corrected the previously entered airport to match the flight number and flight date.",
            REPLACED_AIRPORT_PLURAL: "We have corrected some of the previously entered airports to match the flight number and flight date.",
        },
        WARNING: {
            GETTING_FLIGHTS_VIA_NUMBER_AND_DATE_NO_FLIGHTS_FOUND_NO_AIRPORTS_IN_UI: "No flight could be found with the date and flight number entered. Please enter the departure and arrival airports instead.",
            GETTING_FLIGHTS_VIA_NUMBER_AND_DATE_NO_FLIGHTS_FOUND_AIRPORTS_IN_UI: "No flight could be found with the date and flight number entered. You can search with the airports instead.",
        },
        ERROR: {
            CREATE_BUNDLE: "Travel Components could not be saved.",
            UPDATE_USER_SELF: "Your data could not be edited.",
            UPDATE_USER_ADMIN: "User could not be edited.",
            MAKE_USER_ADMIN: "Expedient could not be made to agency admin.",
            UNSET_USER_ADMIN: "Expedient could not be revoked the admin rights.",
            ADD_COMPANY: "Company could not be added. Please try again",
            EDIT_COMPANY: "Company could not be edited.",
            ADD_AGENCY: "Agency could not be added. Please try again",
            EDIT_AGENCY: "Agency could not be edited.",
            INVITE_COMPANY_ADMIN: "The email to the company admin could not be send.",
            INVITE_USER: "Invitation could not be send.",
            DELETE_USER: "Failed to delete user.",
            DELETE_AGENCY: "Could not delete agency.",
            UPLOAD_AGENCY_LOGO: "Agency logo could not be uploaded.",
            DELETE_AGENCY_LOGO: "Agency logo could not be deleted.",
            CHANGE_USERS_AGENCY: "Expedient could not be assigned to the new agency.",
            SEND_PASSWORD_RESET_EMAIL: "E-Mail could not be sent.",
        }
    },
    NAVIGATION: {
        STATISTIC: "Statistics",
        JOURNEYS: "Journeys",
        HOME: "Calculate CO₂e",
        ADMINISTRATION: "Administration",
        SETTINGS: "Settings",
        LOGO_ALT: "KlimaLinkLogo of company"
    },
    SCREENS: {
        HELP: {
            TITLE: "We're here to help you!"
        },
        CREATE_AGENCY_INVITE_USERS: {
            GENERAL_FAILED: "Please try again later. You can still use the Travel Footprint Database.",
            FAILED_CREATE_AGENCY: "Agency could not be created. Don't worry. You can create an agency in the menu 'Settings'",
            COMPLETE_FAIL_INVITE_USER: "Expedients could not be invited. Don't worry, you can invite expedients in the menu 'Settings'.",
            INVITE_USERS: {
                ADD_EMAIL_HINT: "Please add e-mail",
                ADD_FIRST_NAME_HINT: "Please add first name",
                ADD_LAST_NAME_HINT: "Please add last name",
                HEADLINE: "Invite expedients",
                ADD_EXPEDIENT_BUTTON: 'Invite another expedient',
                REMOVE_EXPEDIENT_BUTTON: 'Remove expedient',
                NO_EXPEDIENT_HINT: "You can create an agency without adding expedients to it. You can add expedients afterwards via the settings."
            }
        },
        TERMS_AND_CONDITIONS: {
            COMPANY_ADMIN: {
                TITLE_WITH_ACCEPT: "Please accept our terms and conditions",
                TITLE_WITH_WITHOUT_ACCEPT: "Our terms and conditions",
                CHECKBOX_LABEL: "I accept the general terms and conditions",
                UPDATE_USER_INFO_ERROR: "Terms and conditions could not be accepted. Please try again."
            },
            NORMAL_USER: {
                TITLE: 'Terms and Conditions',
                HINT: 'Currently you cannot use the KlimaLink travel database because your company admin has not yet accepted the General Terms and Conditions.'
            },
        },
        NO_ROUTE: {
            TITLE: 'Hoops, nothing to see here...'
        },
        REGISTRATION: {
            REGISTRATION_COMPLETE_MESSAGE: "Registration successful",
            CREATING_AGENCY_MESSAGE: "Agency is created...",
            INVITING_USERS_MESSAGE: "Invitation email is being sent to expedients...",
            COMPANY_USER_TITLE: 'Please complete your details to finalise your registration for KlimaLink, the Travel Footprint Database',
            AGENCY_USER_TITLE: "Please create an agency and invite expedients",
            GIVEN_INFORMATION_TITLE: "Already given information",
            MISSING_USER_INFORMATION_TITLE: "Add missing user information for {{userName}} ({{email}})",
            COMPANY_ADMIN: {
                MISSING_COMPANY_INFORMATION_TITLE: "Add missing company information for {{company}}",
            },
            UPDATE_USER_INFO_ERROR: "Registration could not be completed. Please try again.",
            ARIA_LABELS: {
                TERMS_AND_CONDITIONS_ERROR: "Please accept the terms and conditions.",
            }
        },
        SETTINGS: {
            MENU: {
                ADMIN_SETTINGS: "Admin Settings",
                USER_SETTINGS: "Personal Settings",
                KLIMALINK_SETTINGS: "KlimaLink Settings",
            },
            ASPECT_RATIO_LOGO_HINT: "The logo you have selected does not have optimal dimensions. Ideally, your agency logo should be square.",
            NO_AGENCY_HINT_ADD_USERS: "Please create an agency to invite expedients.",
            NO_AGENCY_HINT_ADD_LOGO: "Please create an agency. Only then can you upload an agency logo.",
            NO_AGENCY_HINT_USER_SETTINGS: "Currently you are not belonging to any agency.",
            INVITE_COMPANY_ADMIN: "Invite company admin to KlimaLink",
            AMI_I_AGENCY_ADMIN: "Am I agency admin?",
            TITLE: "Settings",
            PERSONAL_DATA: "Personal data",
            USER_SETTINGS_AGENCY_TITLE: "Agency I am assigned to",
            USER_SETTINGS_AGENCIES_TITLE: "My agencies",
            ADD_USERS_NO_AGENCY_HINT: "Please add an agency. Only when you have added an agency, users can be added.",
            MAKE_ADMIN_MODAL_TITLE: "Make {{user}} agency admin",
            UNMAKE_ADMIN_MODAL_TITLE: "Withdraw agency admin rights from {{user}}",
            MAKE_USER_ADMIN_MODAL_TEXT: "Would you like to make {{user}} an agency admin? {{user}} can add expedients to the agency, delete the agency and edit the agency logo.",
            UNMAKE_USER_ADMIN_MODAL_TEXT: "Would you like to remove {{user}}'s agency admin rights? {{user}} will then no longer be able to add or remove expedients to the agency, delete the agency or edit the agency logo.",
            DELETE_USER: "Delete expedient {{user}}?",
            DELETE_USER_TEXT: "Do you really want to delete {{user}}? Then {{user}} can no longer use the KlimaLink Travel Footprint Database. However, the data saved by {{user}} will be retained.",
            DELETE_AGENCY_TEXT: "Are you sure you want to delete {{agency}}? All expedients associated with {{agency}} will also be deleted. All data saved by the expedients of {{agency}} will also be deleted.",
            DELETE_OWN_AGENCY_TEXT: "Because you are deleting the agency you are member of, your account will be deleted too.",
            DELETE_COMPANY_TEXT: "Are you sure you want to delete {{company}}? All agencies and expedients associated with {{company}} will also be deleted. All data saved by the expedients of {{company}} will also be deleted. Your own account will also be deleted.",
            DELETE_AGENCY: "Delete Agency {{name}}",
            DELETE_COMPANY: "Delete Company {{name}}",
            EDIT_USER: "Edit user",
            EDIT_USER_PERSONAL: "Edit personal data",
            ADD_NEW_USER: "New user",
            ADD_NEW_USER_FAIL: "User could not be added.",
            ADD_NEW_AGENCY: "New agency",
            ADD_NEW_COMPANY: "New company",
            COMPANY_TITLE: "Company",
            AGENCIES_TITLE: "Agencies",
            USERS_TITLE: "Expedient_innen",
            USERS_COUNT: "Number of travel agents",
            CHANGE_PASSWORD_LABEL: "Change password",
            CHANGE_PASSWORD_REQUEST_EMAIL: "Request e-mail",
            CHANGE_PASSWORD_EMAIL_SEND: "E-Mail send",
            CHANGE_PASSWORD_TOOLTIP: "We have sent an email to your registered email address with instructions on how to change your password.",
            LOADING_AGENCY_LOGO_ERROR: "Agency logo could not be loaded.",
            EDIT_AGENCY: "Edit agency",
            EDIT_COMPANY: "Edit company",
            ADD_LOGO: "Agency logo",
            ADD_LOGOS: "Agency logos",
            NO_LOGO_UPLOADED_YET: "No logo uploaded yet.",
            TELEPHONE_NUMBER_WITH_AREACODE: "Telephone number with area code",
            FILE_NAME: "File name",
            COUNTRY_CODE_LABEL: "Country code",
            DROP_LOGO_HOVER: "Drop logo here",
            DROP_LOGO_TEXT: "Upload or drop a file right here. Ideally 200x200px.",
            LOGO_UPLOADED_SUCCESS: "Uploaded the following file",
            COUNTRY_LABEL: "Land",
            AGENCY_NAME_ALREADY_GIVEN: "The agency name {{agencyName}} is already in use. Please use another one.",
            MAKE_ADMIN_TOOLTIP: "Make agency admin.",
            REMOVE_ADMIN_TOOLTIP: "Remove agency admin rights",
            IS_ADMIN_LABEL: "Agency Admin",
            EDIT_USER_TOOLTIP: "Edit user",
            EDIT_AGENCY_TOOLTIP: "Edit agency",
            DELETE_AGENCY_TOOLTIP: "Delete agency",
            DELETE_USER_TOOLTIP: "Delete user",
            DELETE_COMPANY_TOOLTIP: "Delete company",
            EDIT_COMPANY_TOOLTIP: "Edit company",
            IS_ADMIN_TOOLTIP: "If you make an user an admin, this user can create and delete users, edit the agency's information and change the logo of the agency.",
            USER_REGISTRATION_NOT_COMPLETED: "Registration not complete",
            INVITE_CONTACT_PERSON: 'Do you want to invite the {{contactPerson}} to the TravelDatabase?',
            MAKE_CONTACT_PERSON_AGENCY_ADMIN: 'Do you want to make the {{contactPerson}} to an agency admin?',
            ADD_SECOND_CONTACT: 'Add second contact person',
            ADD_USER_EMAIL_ALREADY_IN_USE: "Cannot add user. This e-mail ({{email}}) is already in use.",
            CHANGE_USERS_AGENCY_TOOLTIP: "Change agency",
            CHANGE_USERS_AGENCY_MODAL_TITLE: "Assign {{name}} to another agency",
            CHANGE_USERS_AGENCY_MODAL_TEXT: "Currently {{name}} is in {{oldAgency}}. Would you like to change the agency affiliation of {{name}}?",
            CHANGE_USERS_AGENCY_MODAL_TEXT_ADMIN: "{{name}} will lose its admin rights in the new agency. However, you can simply reassign them.",
            USER_PREFERENCES: {
                TITLE: "Personalisation",
                SHOW_GIATA_ID_FIELD_TEXT: "Would you like to be able to search for hotels with a GIATA ID?",
                SHOW_GIATA_ID_FIELD_LABEL: "Show Giata ID field",
            },
            ERRORS: {
                WRONG_FILE_TYPE: "The logo must be of type {{fileType}}."
            },
        },
        AUTHENTICATING_USER: {
            TEXT: "KLimaLink is authenticating you..."
        },
        COMMON: {
            AUTOMATED_REFETCHING_ERROR: "Something went wrong. Please reload the site.",
            HINT_BETTER_FLIGHT_EMISSION_CALCULATION_GENERAL: "You can calculate the emissions of at least one of the flight segments more precisely by adding further information such as airline, aircraft type or travel class.",
            HINT_BETTER_FLIGHT_EMISSION_CALCULATION_SPECIFIC: "You can calculate the emissions of this section of the flight more precisely by adding further information such as airline or travel class.",
            GO_TO_TRAVEL_COMPONENTS: "Go To Travel Components",
            NO_TRAVEL_COMPONENTS_TOOLTIP: "No Travel Components yet",
            ARIA_LABELS: {
                TRAVEL_COMPONENT_NAME_ERROR: "Please enter a travel component name.",
                CUSTOMER_NAME_ERROR: "Please enter a customer name.",
                AGENCY_ERROR: "Please enter a agency name.",
                COMPANY_ERROR: "Please enter a company name.",
                FIRST_CONTACT_PERSON_FIRST_NAME_ERROR: "Please enter the first name of the first contact person.",
                FIRST_CONTACT_PERSON_LAST_NAME_ERROR: "Pleaseenter the last name of the first contact person.",
                STREET_ERROR: "Please enter a street.",
                HOUSE_NUMBER_ERROR: "Please enter a house number.",
                ZIP_CODE_ERROR: "Please enter a postal code.",
                CITY_ERROR: "Please enter a city.",
                COUNTRY_CODE_ERROR: "Please enter a country code.",
                COUNTRY_ERROR: "Please enter a country.",
                TELEPHONE_ERROR: "Please enter a telephone number.",
                FIRST_NAME_ERROR: "Please enter a valid first name.",
                LAST_NAME_ERROR: "Please enter a valid last name.",
                EMAIL_ERROR: "Please enter a valid e-mail address.",
                ADDITIONAL_ADDRESS_INFORMATION_ERROR: "If you enter additional address information, this must be at least two characters long.",
                TRAVEL_COMPONENT_DESCRIPTION_ERROR: "Please enter a travel component description"
            }
        },
        SELECT_ACTIVITY_BY_TRAVEL_OPTION: {
            SITE_TITLE: "Query Travel Components",
            SEARCH_BAR_LABEL: "Search for travel components"
        },
        CREATE_JOURNEY: {
            SITE_TITLE: "Reise anlegen",
            JOURNEY_NUMBER: "Journey number"
        },
        FLIGHT: {
            SECOND_BUTTON: "Add flight and add more travel components",
            THIRD_BUTTON: "Add flight to travel components",
            FOURTH_BUTTON: "Add flight to travel components and calculate another flight",
            FIFTH_BUTTON: "Create journey with flight",
            SIXTH_BUTTON: "Go to travel components",
            ADD_FLIGHT_TO_TRAVEL_COMPONENTS: "Flight added to Travel Components.",
            RETURN_FLIGHT_HINT: "The return flight usually has a different flight number. If you do not specify the return flight separately, this may lead to an inaccurate calculation of the emissions."
        },
        HOTEL: {
            HOTEL_INFO: "Hotel info",
            STAY_INFO: "Stay info",
            GIATA_ID: "Giata ID",
            SEARCH: "Optional",
            HOTEL_NAME: "Hotel name",
            SEARCH_HOTEL_NAME: "Optional",
            PROPERTY: "Property",
            CITY: "City",
            SEARCH_CITY: "City",
            REGION: "Region",
            COUNTRY: "Country",
            SEARCH_COUNTRY: "Country",
            STARS: "Stars",
            ROOM_CATEGORY: "Room category",
            ROOM_CATEGORY_SS: "SG",
            ROOM_CATEGORY_DS: "DG",
            ROOM_CATEGORY_SP: "SGComf",
            ROOM_CATEGORY_DP: "DGComf",
            ROOM_CATEGORY_S: "Suite",
            ROOMS: "Rooms",
            MEAL_PLAN: "Meal plan",
            MEAL_CLASS_OV: "RO",
            MEAL_CLASS_ÜF: "BB",
            MEAL_CLASS_HP: "HB",
            MEAL_CLASS_VP: "FB",
            MEAL_CLASS_AI: "AI",
            PEOPLE: "People",
            NIGHTS: "Nights",
            CHOOSE_DATES: "Choose dates",
            SECOND_BUTTON: "Add hotel and add more travel components",
            THIRD_BUTTON: "Add hotel to travel components",
            THIRD_BUTTON_UPDATE: "Update hotel in travel components",
            FOURTH_BUTTON: "Add hotel to travel components and calculate another hotel",
            FIFTH_BUTTON: "Create journey with hotel",
            SIXTH_BUTTON: "Go to travel components",
            ADD_HOTEL_TO_TRAVEL_COMPONENTS: "Hotel added to Travel Components.",
            ARIA_LABELS: {
                GIATA_ID_ERROR: "Please enter a GIATA ID.",
                COUNTRY_ERROR: "Please choose a country.",
                CITY_ERROR: "Please choose a city.",
            },
        },
        TRAVEL_OPTIONS_OVERVIEW: {
            SITE_TITLE: "Travel Components",
            ADD: "Add more travel components"
        },
        TRAVEL_COMPONENTS_OVERVIEW: {
            SAVE_TRAVEL_COMPONENTS_MODAL: {
                ADDITIONAL_CUSOMTER_INFORMATION: "Additional customer information",
                TITLE: "Save Travel Components"
            },
            CHECKBOX_LABEL: "Selected Travel Component",
            CREATE_JOURNEY: "Create journey",
            SAVE_TRAVEL_COMPONENTS: "Save Travel Components",
            CREATE_PDF_TOOLTIP: "Create PDF",
            COPY_CLIPBOARD_TOOLTIP: "Copy to clipboard",
            EDIT_TRAVEL_COMPONENT_TOOLTIP: "Edit travel component",
            DELETE_TRAVEL_COMPONENT_TOOLTIP: "Delete travel component",
            SELECT_AGENCY_MODAL_TITLE: "Please select agency",
            SELECT_AGENCY_MODAL_EXPLANATION: "You are assigned to more than one agency. Please select the agency you want to sign the PDF with.",
            EDIT_CUSTOMER_INFORMATION_TOOLTIP: "Edit customer information",
            RADIO_BUTTON_GROUP: "Select agency",
            ADD_TRAVEL_COMPONENTS: "Add travel components",
            NO_TRAVEL_COMPONENTS: "You have not added any Travel Components.",
            TRAVEL_COMPONENTS_COPIED: "Copied travel components to your clipboard",
            NO_AGENCY_PDF: "You are not assigned to an agency. Please create an agency to create a PDF.",
            STAR: "star",
            STARS: "stars",
            ROOM: "room",
            ROOMS: "rooms",
            NIGHT: "night",
            NIGHTS: "nights",
        }
    },
    ALT: {
        KLIMALINK_LOGO: "The KlimaLink KlimaLink logo. An image with the words: KlimaLink - Travel Footprint Database",
        ATMOSFAIR_LOGO: "The atmosfair logo. An image with the words: Think, go climate conscious."
    },
    ACTIVITIES: {
        CAR: {
            TITLE: "Car/Rental car"
        },
        FLIGHT: {
            REMOVE_STOPOVER_TOOLTIP: "Remove stopover",
            TITLE: "Flight",
            NAME: 'Flight',
            FLIGHT_SINGULAR: "Flight",
            FLIGHT_PLURAL: "Flights",
            ONEWAY_SINGULAR: 'One-way',
            RETURN_FLIGHT_SINGULAR: 'Return flight',
            ONEWAY_ONLY_SINGULAR: 'One-way',
            ONEWAY_PLURAL: 'One-way flights',
            ONEWAY_ONLY_PLURAL: 'One-ways',
            ROUNDTRIP_SINGULAR: 'Round-trip',
            ROUNDTRIP_ONLY_SINGULAR: 'Round-trip',
            ROUNDTRIP_PLURAL: 'Round-trip flights',
            ROUNDTRIP_ONLY_PLURAL: 'Round-trips',
            OFFSET_ENTIRE_AIRCRAFT: 'Offset entire aircraft',
            FLIGHT_TYPE: 'Flight type',
            FLIGHT_TYPE_CHARTER: 'Charter',
            FLIGHT_TYPE_LINE: 'Scheduled',
            FLIGHT_CLASS_FIRST: 'First Class',
            FLIGHT_CLASS_BUSINESS: 'Business Class',
            FLIGHT_CLASS_ECONOMY_PREMIUM: 'Premium Eco',
            FLIGHT_CLASS_ECONOMY: 'Eco',
            CALCULATION_FAILED_FOR_LEGS: "For the following legs, no emission calculation could be performed:",
            CALCULATION_SUCCESSFUL_FOR_LEGS_WITH_RETURN_FLIGHT: "Für folgende Abschnitte (Hin- und Rückflug) konnte eine Emissionsberechnung durchgeführt werden:",
            CALCULATION_SUCCESSFUL_FOR_LEGS_WITHOUT_RETURN_FLIGHT: "Für folgende Abschnitte konnte eine Emissionsberechnung durchgeführt werden:",
            CALCULATION_DONE_FOR_LEGS: "The emissions are:",
            INTRO: 'Calculate your compensateFlight’s CO₂ footprint and offsetting costs in renewable energy projects. We accept all common means of payment. You will receive a personal certificate and a German donation receipt (tax deductibility depends on regulations of your country).',
            TITLE_RESULTS: 'Emission Calculation Results',
            FLIGHT_NUMBER: "Flightnumber",
            DEPARTURE_AIRPORT: 'Departure airport',
            NO_CITY_PAIR_FOUND_TITLE: 'No flight connection',
            NO_CITY_PAIR_FOUND: 'We cannot find this flight connection. Please try another one.',
            VIA_AIRPORT: 'Via airport',
            ARRIVAL_AIRPORT: 'Arrival airport',
            TRAVEL_CLASS: 'Travel class',
            AIRCRAFT_TYPE: 'Aircraft type',
            ADD_VIA_AIRPORT: 'Add stop-over',
            ADD_OR_REMOVE_VIA_AIRPORT: 'Add via airport',
            FLIGHT_COUNT: 'Flights',
            CHOOSE_FLIGHTS: "Please choose the flights",
            PLACEHOLDER_AIRPORT: 'City or Airport',
            FLIGHT_DISTANCE: 'Flight distance',
            DEPARTURE_DATE: "Departure date",
            GETTING_FLIGHTS_VIA_NUMBER_AND_DATE_SUCCESS: "The flight was found and automatically added.",
            DESCRIPTION: "You can search for a connection either by departure and arrival airport or by flight number and flight date. After entering the flight number and flight date, the connection is automatically searched for and the departure and arrival airports are filled in. If no information on date, flight number or class of service is available, the emissions value is averaged from all flights on this city pair.",
            AIRPORT_TRANSFER: "Airport transfer",
            ERRORS: {
                NETWORK_ERROR: {
                    GETTING_FLIGHTS_VIA_NUMBER_AND_DATE: "Something has gone wrong. Please try again or enter the departure and arrival airports instead of the flight number and flight date."
                },
                GENERAL: {
                    PARTIAL_OR_COMPLETE_CALCULATION_ERROR: "You can still use the service, but no CO₂ e will be displayed for this service.",
                    GETTING_FLIGHTS_CODE_SHARING_FLIGHT_ERROR: "Unfortunately, this flight is a code share flight operated by another airline. Please search with the departure and arrival airports or flight number of the operating airline instead.",
                },
            },
            ARIA_LABELS: {
                ERROR_FLIGHT_NUMBER_INVALID: "The flight number you have entered is invalid. Flight numbers consist of 1 letter and 1-4 numbers.",
                ERROR_AIRPORT_SELECT_FROM_LIST: 'Please specify an airport. To do this, you must select an airport from the list that opens. You cannot write out the airport completely yourself.',
                ERROR_AIRLINE_SELECT_FROM_LIST: 'Please specify an airline. To do this, you must select an airline from the list that opens. You cannot write out the airline completely yourself.',
                MIN_MAX_PASSENGERS_EXCEEDED_ERROR: 'Please enter a number of passengers that is greater than or equal to {{min}} and less than or equal to {{max}}.',
                MIN_MAX_FLIGHTS_EXCEEDED_ERROR: 'Please enter a number of flights that is greater than or equal to {{min}} and less than or equal to {{max}}.',
                NUMBER_FLIGHT_PERSONS_RETURN: 'Number of flights, Number of passangers, Return flight yes/no',

            },
        },
        TRAIN: {
            TITLE: "Train"
        },
        HOTEL: {
            TITLE: "Hotel",
            DESCRIPTION: "Should a specific hotel not be listed you can enter city, country and category to calculate an average footprint.",
            ARIA_LABELS: {
                MIN_MAX_PEOPLE_EXCEEDED_ERROR: 'Please enter a number of people that is greater than or equal to {{min}} and less than or equal to {{max}}.',
                MIN_MAX_ROOMS_EXCEEDED_ERROR: 'Please enter a number of rooms that is greater than or equal to {{min}} and less than or equal to {{max}}.',
                MIN_MAX_NIGHTS_EXCEEDED_ERROR: 'Please enter a number of nights that is greater than or equal to {{min}} and less than or equal to {{max}}.',
                MEAL_PLAN: 'Please choose the type of meals.',
                NUMBER_OF_NIGHTS: 'Please choose the number of nights.',
            },
            HOTEL_CLASS_UNKNOWN: "Unknown"
        },
        HOSTEL: {
            TITLE: "Hostel",
        }
    },
    TRAVEL_OPTIONS: {
        TRANSPORT: {
            TITLE: "Transport",
        },
        ACCOMMODATION: {
            TITLE: "Accommodation",
        }
    },
    ERRORS: {
        CLICK_AWAY_AUTOCOMPLETE_QUERY: "Please select {{value}} from the list.",
        HOTEL_NOT_FOUND: "Hotel not found",
        GENERAL_NETWORK_ERROR_TITLE: "General Error",
        GENERAL_NETWORK_ERROR_BODY: 'Oops... Unfortunately a technical error occurred.',
        CALCULATION_PARTIAL_FAILURE: "Partial calculation failure",
        CALCULATION_COMPLETE_FAILURE: "Complete calculation failure",
        ERROR_HINT: "If the error persists, please contact KlimaLink.",
        BACKEND_ERRORS: {
            CODE_501: "User is not an admin",
            CODE_504: "Maximum number of companies exceeded",
            CODE_508: "Agency with the same name exists already",
            CODE_514: 'Expedient was deactivated. Please contact KlimaLink support.',
            CODE_518: 'Expedient was deactivated. Please contact KlimaLink support.',

            CODE_601: "Airport \'{{airport}}\' not found.",
            CODE_602: "Airline \'{{airline}}\' not found.",
            CODE_603: "Aircraft \'{{aircraftType}}\' not found.",
            CODE_604: "No flights found for City-Pair \'{{airports}}\'.",
            CODE_605: "Flight number and flight date not specified.",
            CODE_606: "Flight number not specified.",
            CODE_607: "Flight date not specified.",
            CODE_608: "Flight date \'{{flightDate}}\' could not be parsed.",
            CODE_609: "Flight number \'{{flightNumber}}\' could not be parsed.",
            CODE_610: "The flight is a code share flight operated by another airline. Please search with the flight number of the operating airline.",
            CODE_611: "No flights found for flight number \'{{flightNumber}}\' and flight date \'{{flightDate}}\'.",
            CODE_612: "Several city pairs were found for flight \'{{flightNumber}}\'. Please specify the desired city pair additionally.",
            CODE_613: "Several city pairs were found for the flight \'{{flightNumber}}\' on \'{{flightDate}}\'. Please specify the desired city pair additionally.",
            CODE_614: "No flights could be found for the flight \'{{airports}}\', airline \'{{airline}}\' and aircraft type \'{{aircraftType}}\'.",
            CODE_615: "No flights could be found for the flight \'{{airports}}\' and airline \'{{airline}}\'.",
            CODE_619: "The index {{index}} occurs in more than one activity.",
            CODE_620: "Number of flights is 0 or negative.",
            CODE_621: "Number of passengers is 0 or negative.",
            CODE_622: "The flight number and the flight date must be entered together (or not at all).",
            CODE_623: "Departure and arrival must be specified together (or not at all).",
            CODE_624: "Either the flight number and date or the place of departure and arrival must be specified.",
            CODE_625: "The departure and arrival airports are identical: {{departure}}, {{arrival}}.",

            CODE_701: "Number of rooms must be a valid, positive integer.",
            CODE_702: "Number of nights must be a valid, positive integer.",
            CODE_703: "Country must be a valid ISO3166 Alpha3 country code.",
            CODE_704: "Number of people must be a valid, positive integer.",

            CODE_802: "Rate limit exceeded",
            CODE_830: "Index must be a valid, positive integer. (specified: {{index}}).",
            CODE_817: "An error occurred during the emission calculation.",

            CODE_906: "Your account as been deactivated. Please contact your admin if that was not intentional.",
            CODE_907: 'Company not found',
            CODE_910: 'Could not send e-mail',
            CODE_912: 'Company admin has not accepted terms and conditions',
            CODE_917: 'Expedient was deactivated. Please contact KlimaLink support.',
        }
    },
    PDF: {
        TRAVEL_COMPONENTS: {
            PDF_TITLE: "Emissions of the travel components",
            PDF_SUBJECT: "Emissions of the travel components",
            CO2_EMISSIONS_TOTAL_FAILURE: "No CO2 calculation possible.",
            CO2_EMISSIONS_PARTIAL_FAILURE: "Not for very part the CO2 could be calculated.",
            FOOTER: {
                REDUCE_EMISSIONS: "Would you like to reduce your carbon footprint? Your travel agency will be happy to give you tips on how you can travel in a more climate-conscious way.",
                DISCLAIMER: "This data is without guarantee and was determined by: KlimaLink e.V.",
            }

        }
    }
}

export default english