import React, {ReactNode} from 'react'
import LogoutButton from "../../../molecules/logout-button/LogoutButton";
import KlimaLinkLogo from "../../../molecules/logos/klima-link-logo/KlimaLinkLogo";
import {Text} from "atmosfair-ui";
import './styles.css'
import LanguageSelector from "../../../container/language-selector/LanguageSelector";
import {theme} from "../../../styles/theme";

interface RegistrationWrapperProps {
    children: ReactNode,
    title: string,
}

const RegistrationWrapper = ({
                                 children,
                                 title
                             }: RegistrationWrapperProps) => {
    return <div className={"registration-wrapper-container"}>
        <LogoutButton/>
        <div className={"registration-wrapper-klimalink-logo"}>
            <KlimaLinkLogo size={"medium"}/>
        </div>
        <Text
            variant={"headlineLarge"}
            style={{marginBottom: 2 * theme.spacer.large}}>
            {title}
        </Text>
        {children}
        <LanguageSelector classes={'registration-wrapper-language-selector'}/>
    </div>
}

export default RegistrationWrapper