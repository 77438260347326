export enum CustomerRequestType {
    firstName = "first_name",
    lastName = "last_name",
    email = "email",
    companyName = "company_name"
}

export interface SaveBundleCustomerRequest {
    type: CustomerRequestType,
    query: string,
}

export interface CustomerCompany {
    id: number | null,
    name: string,
}

export interface Customer {
    id: number | null,
    firstName: string,
    lastName: string,
    company: CustomerCompany | null,
    email: string,
    street: string,
    houseNumber: string,
    city: string,
    zipCode: string,
    phoneNumber: string,
    additionalAddressInformation: string,
}