import React from "react";
import SelectActivityByTravelOption
    from "../../components/select-activity-by-travel-option/SelectActivityByTravelOption";
import {useNavigate} from "react-router-dom";
import {PATH_STRINGS} from "../../routes/route-props";
import BottomButtons from "../../components/bottom-buttons/BottomButtons";
import {useAppSelector} from "../../redux/reducer/store";

const Home = () => {
    const navigate = useNavigate()
    const {activities} = useAppSelector(state => state.travelComponents)
    return <>
        <SelectActivityByTravelOption
            onSelect={activityType => navigate(`${PATH_STRINGS.addActivity}/${activityType}/`)}/>
        <BottomButtons buttons={[
            {
                tooltip: activities.length === 0 ? "SCREENS.COMMON.NO_TRAVEL_COMPONENTS_TOOLTIP" : undefined,
                text: "SCREENS.COMMON.GO_TO_TRAVEL_COMPONENTS",
                onClick: () => navigate(PATH_STRINGS.travelComponentsOverview),
            }
        ]}/>
    </>
}

export default Home