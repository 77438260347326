import React, {useEffect, useState} from "react"
import "./styles.css"
import clsx from "clsx";
import {useLocation, useParams} from "react-router-dom";
import {formatCo2ETotal, formatDate} from "../../utils/string-mutation";
import {useAppSelector} from "../../redux/reducer/store";
import {PATH_STRINGS} from "../../routes/route-props";
import {getCo2EForJourney} from "../../utils/co2-getter";
import {useTranslation} from "react-i18next";
import {Text} from "atmosfair-ui";
import {ACTIVITY_TYPE} from "../../types-enums/activity";

interface LeftRightTextProps {
    title?: string,
    textElements: { [key: string]: string | number }
}

interface MiddleTextProps {
    title: string,
    textElements?: { [key: string]: string | number },
}

const SiteTitle = () => {
    const location = useLocation();
    const {activity} = useParams();
    const {
        daysCount,
        personsCount,
        lastEdited,
        lastEditedFrom,
        travelOptions,
        title,
    } = useAppSelector(state => state.journey)
    const {i18n, t} = useTranslation();
    const currentLanguage = i18n.resolvedLanguage;
    const [leftText, setLeftText] = useState<LeftRightTextProps | null>(null)
    const [rightText, setRightText] = useState<LeftRightTextProps | null>(null)
    const [middleText, setMiddleText] = useState<MiddleTextProps | null>(null)

    useEffect(() => {
        const {pathname} = location

        switch (pathname) {
            case PATH_STRINGS.travelComponentsOverview || `${PATH_STRINGS.travelComponentsOverview}/`:
                setLeftText(null)
                setMiddleText({title: "SCREENS.TRAVEL_OPTIONS_OVERVIEW.SITE_TITLE"})
                setRightText(null)
                break
            case PATH_STRINGS.travelDatabase || `${PATH_STRINGS.travelDatabase}/`:
                setLeftText(null)
                setMiddleText({title: "SCREENS.SELECT_ACTIVITY_BY_TRAVEL_OPTION.SITE_TITLE"})
                setRightText(null)
                break
            case PATH_STRINGS.newJourney || `${PATH_STRINGS.newJourney}/`:
                setLeftText(null)
                setMiddleText({
                    title: "SCREENS.CREATE_JOURNEY.SITE_TITLE",
                })
                setRightText(null)
                break
            case `${PATH_STRINGS.addActivity}/${ACTIVITY_TYPE.flight}/`:
                setLeftText(null)
                setMiddleText({
                    title: "ACTIVITIES.FLIGHT.TITLE",
                })
                setRightText(null)
                break
            case `${PATH_STRINGS.addActivity}/${ACTIVITY_TYPE.hotel}/`:
                setLeftText(null)
                setMiddleText({
                    title: "ACTIVITIES.HOTEL.TITLE",
                })
                setRightText(null)
                break
            case PATH_STRINGS.journey || `${PATH_STRINGS.journey}/`:
                setLeftText(null)
                setMiddleText({
                    title: "JOURNEY TITLE",
                })
                setRightText(null)
                break
            case `${PATH_STRINGS.addTravelOption}/${activity}/`:
                setLeftText({
                    title: _constructCommonTitle(),
                    textElements: _constructCommonLeftTextElements()
                })
                setMiddleText({
                    title: `TRAVEL_OPTIONS.${activity?.toUpperCase()}`,
                    textElements: {
                        "TITLE GENERAL.ADD": ""
                    }
                })
                setRightText({
                    textElements: _constructCommonRightTextElements()
                })
                break
            case PATH_STRINGS.settings || `${PATH_STRINGS.journey}/`:
                setLeftText(null)
                setMiddleText({title: "SCREENS.SETTINGS.TITLE"})
                setRightText(null)
                break
            case PATH_STRINGS.help || `${PATH_STRINGS.help}/`:
                setLeftText(null)
                setMiddleText({title: "SCREENS.HELP.TITLE"})
                setRightText(null)
                break
            default:
                setLeftText(null)
                setMiddleText(null)
                setRightText(null)
                break
        }

    }, [location.pathname, title]);

    const _constructCommonTitle = () =>
        `GENERAL.JOURNEY ${title}`

    const _constructCommonLeftTextElements = () => ({
        "GENERAL.PERSONS": personsCount,
        "GENERAL.DAYS": daysCount,
        "GENERAL.TOTAL": formatCo2ETotal(getCo2EForJourney(travelOptions), currentLanguage)
    })

    const _constructCommonRightTextElements = () => ({
        "GENERAL.LAST_EDITED": formatDate(lastEdited, currentLanguage),
        "GENERAL.FROM": lastEditedFrom
    })

    const _constructLeftRightText = (text: LeftRightTextProps, align: "left" | "right") => {
        const {title, textElements} = text
        return <div
            className={clsx(
                "site-title-text-wrapper",
                `site-title-left-right-text-align-${align}`)
            }>
            {title && <p>{title}</p>}
            {(Object.keys(textElements) as Array<keyof LeftRightTextProps["textElements"]>).map((title, index) =>
                <p key={index}>{`${title}: ${textElements[title]}`}</p>
            )}
        </div>
    }

    const _constructMiddleText = (middleText: MiddleTextProps) => {
        return <div
            className={clsx(
                "site-title-text-wrapper",
                "site-title-middle-text"
            )}>
            <Text variant={"headlineLarge"}>{middleText.title}</Text>
            {middleText.textElements && (Object.keys(middleText.textElements) as Array<keyof MiddleTextProps["textElements"]>).map((title, index) => {
                    const rightOfColon = middleText.textElements!![title].toString()
                    const leftOfColon = title
                    if (rightOfColon.length === 0) {
                        return <p key={index}>{leftOfColon}</p>
                    } else {
                        return <p key={index}>{`${leftOfColon}: ${rightOfColon}`}</p>
                    }
                }
            )}
        </div>
    }

    return <header className={"site-title-container"}>
        {leftText && _constructLeftRightText(leftText, "left")}
        {middleText && <h2 className={"site-title-title"}>{_constructMiddleText(middleText)}</h2>}
        {rightText && _constructLeftRightText(rightText, "right")}
    </header>
}

export default SiteTitle