import React, {CSSProperties} from "react"
import {Text} from "atmosfair-ui";
import {NO_DATA_STRING} from "../../utils/string-mutation";

interface StringPairProps {
    label: string,
    value?: string | null,
    containerClasses?: string,
    containerStyle?: CSSProperties
    textStyle?: CSSProperties
    lengthForNoDataString?: number
}

const StringPair = ({
                        label,
                        value,
                        containerClasses,
                        containerStyle,
                        textStyle,
                        lengthForNoDataString
                    }: StringPairProps) => {
    const _renderLargeString = () => {
        if (value === null || typeof value === undefined) return NO_DATA_STRING
        if (typeof value === "string" && lengthForNoDataString && value.trim().length < lengthForNoDataString) return NO_DATA_STRING
        return value

    }

    return <div style={containerStyle} className={containerClasses}>
        <Text
            color={"primary"}
            weight={"bold"}
            variant={"subtitleLarge"}>{label}</Text>
        <Text style={textStyle} variant={"body"}>{_renderLargeString()}</Text>
    </div>
}


export default StringPair