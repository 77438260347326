import React from 'react'
import {Text} from "atmosfair-ui"
import "./styles.css"
import {useTranslation} from "react-i18next";
import {isNonCalculationErrorResponse} from "../../../utils/type-guards";
import {relevantErrorCodes} from "../../../api/model/common";
import LogoutButton from "../../../molecules/logout-button/LogoutButton";
import KlimaLinkLogo from "../../../molecules/logos/klima-link-logo/KlimaLinkLogo";

interface ErrorPageProps {
    additionalText?: string,
    error?: any
}

const ErrorPage = ({
                       additionalText,
                       error
                   }: ErrorPageProps) => {
    const {t} = useTranslation()

    const _renderErrorMessage = () => {
        if (error && isNonCalculationErrorResponse(error)) {
            const {data} = error
            const {type, errorCode} = data
            return <Text variant={"headlineMedium"}>{`ERRORS.${type}.${relevantErrorCodes[errorCode]}`}</Text>
        }

        return <Text variant={"headlineMedium"}>ERRORS.ERROR_HINT</Text>
    }

    return <div className={"error-page-container"}>
        <LogoutButton/>
        <Text
            variant={"headlineLarge"}>{additionalText ? t(additionalText) : t("ERRORS.GENERAL_NETWORK_ERROR_TITLE")}</Text>
        {_renderErrorMessage()}
        <KlimaLinkLogo size={"medium"}/>
    </div>
}

export default ErrorPage