import {ActivityCommonProperties} from "../types-enums/activity-common-properties";
import {ACTIVITY_TYPE, AllActivityTypesArrayDiscriminating, TravelOptionToActivitiesMap} from "../types-enums/activity";
import {TRAVEL_OPTION_TYPE} from "../types-enums/travel-option";

export const getActivitiesByDay = (
    activities: AllActivityTypesArrayDiscriminating,
    day: ActivityCommonProperties["common"]["day"],
) => activities.filter(activity => activity.common.day === day)

export const getActivitiesByActivityType = (
    activities: AllActivityTypesArrayDiscriminating,
    activityType: ACTIVITY_TYPE,
) => activities.filter(activity => activity.common.activityType === activityType)

export const getActivitiesByActivityTypeAndByDay = (
    activities: AllActivityTypesArrayDiscriminating,
    activityType: ACTIVITY_TYPE,
    day: number
) => getActivitiesByDay(activities?.filter(activity => activity.common.activityType === activityType), day)

export const getActivitiesByTravelOptionTypeAndDay = (
    travelOptions: TravelOptionToActivitiesMap,
    travelOptionType: TRAVEL_OPTION_TYPE,
    day: ActivityCommonProperties["common"]["day"],
) => getActivitiesByDay(travelOptions[travelOptionType], day)

export const getActivitiesByTravelOptionTypeAndActivityTypeAndDay = (
    travelOptions: TravelOptionToActivitiesMap,
    travelOptionType: TRAVEL_OPTION_TYPE,
    activityType: ACTIVITY_TYPE,
    day: ActivityCommonProperties["common"]["day"],
) => getActivitiesByDay(getActivitiesByActivityType(travelOptions[travelOptionType], activityType), day)

export const hasDayActivities = (
    travelOptions: TravelOptionToActivitiesMap,
    day: ActivityCommonProperties["common"]["day"],
) => (Object.keys(travelOptions) as Array<keyof TravelOptionToActivitiesMap>).some(travelOptionType => getActivitiesByDay(travelOptions[travelOptionType], day).length > 0)

export const getActivities = (travelOptions: TravelOptionToActivitiesMap, callback: (activity: AllActivityTypesArrayDiscriminating, travelOptionType: TRAVEL_OPTION_TYPE) => void) => {
    (Object.keys(travelOptions) as Array<keyof TravelOptionToActivitiesMap>).forEach(travelOptionType => {
        const activities = travelOptions[travelOptionType];
        callback(activities, travelOptionType)
    });
};
