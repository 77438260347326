import {DropdownOption} from "atmosfair-ui";
import {CityResponse, CountryResponse, HotelResponse} from "../../../../api/model/activity-hotel";
import {HotelCategory, MealPlan, RoomCategory} from "../../../../screens/activity/activity-form/hotel";

export const convertHotelClassesToDropdownFormat = (hotelClasses: HotelCategory[]): DropdownOption[] => {
    return hotelClasses.map(hotelClass => {
        const {name, id, value} = hotelClass
        return {
            label: name,
            value: id,
            raw: {name, id, value}
        }
    });
}

export const convertHotelClassesFromDropdownFormat = (hotelClasses: DropdownOption[] | null): HotelCategory | null => {
    if (hotelClasses === null) return null
    return hotelClasses.map(hotelClass => hotelClass.raw)[0]
}

export const convertRoomClassesToDropdownFormat = (roomClasses: RoomCategory[]): DropdownOption[] => {
    return roomClasses.map(roomClass => {
        const {name, id, value} = roomClass
        return {
            label: name,
            value: id,
            raw: {name, id, value}
        }
    });
}

export const convertRoomClassesFromDropdownFormat = (roomClasses: DropdownOption[] | null): RoomCategory | null => {
    if (roomClasses === null) return null
    return roomClasses.map(roomClass => roomClass.raw)[0]
}

export const convertMealClassesToDropdownFormat = (mealClasses: MealPlan[]): DropdownOption[] => {
    return mealClasses.map(mealClass => {
        const {name, id, value} = mealClass
        return {
            label: name,
            value: id,
            raw: {name, id, value}
        }
    });
}

export const convertMealClassesFromDropdownFormat = (mealClasses: DropdownOption[] | null): MealPlan | null => {
    if (mealClasses === null) return null
    return mealClasses.map(mealClass => mealClass.raw)[0]
}

export const emptyHotelResponse: HotelResponse = {
    id: 1,
    hotelId: null,
    name: '',
    region: null,
    alpha2: '',
    alpha3: '',
    countryName: '',
    city: '',
    stars: ''
};

export const emptyCityResponse: CityResponse = {
    id: 1,
    name: '',
    region: null,
    alpha2: '',
    alpha3: '',
    countryName: ''
};

export const emptyCountryResponse: CountryResponse = {
    id: 1,
    name: '',
    alpha2: '',
    alpha3: ''
};

export const toISODate = (date: Date) => {
    let offset = -date.getTimezoneOffset();
    date.setTime(date.getTime() + offset * 60 * 1000);
    return date ? date.toISOString().substring(0, 10) : '';
};

export interface DaysRange {
    startDate: Date;
    endDate: Date;
    key: string;
}

export const getDatePattern = (locale: string | string[]) => {
    const formatter = new Intl.DateTimeFormat(locale).formatToParts();
    return formatter.map(function (part) {
        switch (part.type) {
            case 'month':
                return 'MM';
            case 'day':
                return 'dd';
            case 'year':
                return 'yyyy';
            default:
                return part.value;
        }
    }).join('');
};

interface CreateRoundtripPassengersCountStringProps {
    peopleCount: number,
    forString: string,
    peopleCountString: string
}


export const createHotelPeopleCountString = ({
                                                 peopleCount,
                                                 forString,
                                                 peopleCountString
                                             }: CreateRoundtripPassengersCountStringProps): string => {
    const commonString = `Hotel ${forString} ${peopleCount} ${peopleCountString}`
    return commonString
}

export const areHotelsEqual = (hotel1: HotelResponse, hotel2: HotelResponse): boolean => {
    return hotel1.id === hotel2.id &&
        hotel1.name === hotel2.name &&
        hotel1.city === hotel2.city &&
        hotel1.region === hotel2.region &&
        hotel1.alpha2 === hotel2.alpha2 &&
        hotel1.alpha3 === hotel2.alpha3 &&
        hotel1.countryName === hotel2.countryName &&
        hotel1.stars === hotel2.stars;
}




