import "./styles.css"
import React, {useEffect} from "react";
import {registerLocale, setDefaultLocale} from "react-datepicker"
import {useTranslation} from "react-i18next";
import de from 'date-fns/locale/de';
import Navigation from "../../container/navigation/Navigation";
import SiteTitle from "../../container/site-title/SiteTitle";
import {Navigate, Outlet} from "react-router-dom";
import Footer from "../../molecules/footer/Footer";
import {RequireAuth, useFusionAuth} from "@fusionauth/react-sdk";
import {
    useGetAllUsersForAdminUserQuery,
    useGetUserInfoQuery,
    useGetUsersAgenciesQuery,
    useGetUsersCompanyQuery
} from "../../redux/reducer/api";
import FullscreenSpinner from "../../molecules/fullscreen-spinner/FullscreenSpinner";
import ErrorPage from "../../screens/functional/error-page/ErrorPage";
import {PATH_STRINGS} from "../../routes/route-props";
import {companyAdminHasAcceptedTermsAndConditions, normalUserHasNotAcceptedTermsAndConditions} from "../../utils/user";
import {useAppDispatch, useAppSelector} from "../../redux/reducer/store";
import {toast, ToastItem} from "react-toastify";
import ToastWithTextOnly from "../../molecules/toasts/toast-with-text-only/ToastWithTextOnly";
import {resetApiProps} from "../../redux/reducer/settings";

const ProtectedLayout = () => {
    const {i18n} = useTranslation()
    const currentLanguage = i18n.resolvedLanguage
    const {isAuthenticated, isLoading, login} = useFusionAuth()
    const {apiError, apiSuccess} = useAppSelector(state => state.settings)
    const {t} = useTranslation()
    const dispatch = useAppDispatch()

    const {
        data: userInfoResponse,
        isLoading: isLoadingUserInfoResponse,
        error: userInfoResponseError
    } = useGetUserInfoQuery()

    const {
        data: allUsersForAdminRepsonse,
        isLoading: isLoadingAllUsersForAdminResponse,
        error: allUserForAdminResponseError
    } = useGetAllUsersForAdminUserQuery()

    const {
        data: agenciesResponse,
        isLoading: isLoadingAgenciesResponse,
        error: agenciesResponseError
    } = useGetUsersAgenciesQuery()

    const {
        data: companyResponse,
        isLoading: isLoadingCompanyResponse,
        error: companyResponseError,
    } = useGetUsersCompanyQuery()

    useEffect(() => {
        if (!isAuthenticated) login()
    }, [isAuthenticated, isLoading]);

    useEffect(() => {
        if (apiError) {
            const {message, toastType} = apiError
            const toastId = toast(<ToastWithTextOnly
                    message={t(message)}/>,
                {progress: undefined, type: toastType}
            );
            const unsubscribe = toast.onChange((payload: ToastItem) => {
                switch (payload.status) {
                    case "added":
                        break;
                    case "updated":
                        break;
                    case "removed":
                        toastId && dispatch(resetApiProps("error"))
                        unsubscribe()
                        break;
                }
            })
        }
    }, [apiError]);

    useEffect(() => {
        if (apiSuccess) {
            const {message, toastType} = apiSuccess
            const toastId = toast(<ToastWithTextOnly
                    message={t(message)}/>,
                {progress: undefined, type: toastType}
            );
            const unsubscribe = toast.onChange((payload: ToastItem) => {
                switch (payload.status) {
                    case "added":
                        break;
                    case "updated":
                        break;
                    case "removed":
                        toastId && dispatch(resetApiProps("success"))
                        unsubscribe()
                        break;
                }
            })
        }
    }, [apiSuccess]);

    useEffect(() => {
        if (userInfoResponse) {
            const {language} = userInfoResponse
            language && language !== currentLanguage && i18n.changeLanguage(language)
        }
    }, [userInfoResponse]);

    useEffect(() => {
        // React CalenderPicker
        registerLocale("de", de)
    }, []);

    useEffect(() => {
        currentLanguage && setDefaultLocale(currentLanguage)
    }, [currentLanguage]);

    if (isLoadingUserInfoResponse || isLoadingCompanyResponse || agenciesResponseError) return <FullscreenSpinner
        showLogo={true}/>
    if (userInfoResponseError || companyResponseError || agenciesResponseError) return <ErrorPage
        error={userInfoResponseError}/>
    if (!companyAdminHasAcceptedTermsAndConditions(companyResponse, userInfoResponse)) return <Navigate
        to={PATH_STRINGS.termsAndConditionsAdmin}
        state={{showAcceptButton: true}}/>
    if (!normalUserHasNotAcceptedTermsAndConditions(userInfoResponse)) return <Navigate
        to={PATH_STRINGS.termsAndConditionsUser}/>
    if (userInfoResponse && !userInfoResponse.registrationComplete) return <Navigate to={PATH_STRINGS.register}/>
    return <RequireAuth>
        <Navigation/>
        <div className={"protected-layout-main"}>
            <SiteTitle/>
            <main
                className={"protected-layout-container"}>
                <Outlet/>
            </main>
        </div>
        <Footer/>
    </RequireAuth>

    // return <>
    //     <Navigation/>
    //     <main className={"protected-layout-main"}>
    //         <SiteHeadline/>
    //         <div
    //             className={"protected-layout-container"}>
    //             <Outlet/>
    //         </div>
    //     </main>
    //     <Footer/>
    // </>
}

export default ProtectedLayout