import {PDFDocument, StandardFonts} from "pdf-lib";
import {isActivityFlightState, isActivityHotelState} from "../utils/type-guards";
import {
    black,
    conditionallyAddPage,
    createTotalCo2String,
    darkGrey,
    drawActivityMetaInformation,
    drawActivitySummary,
    LogoProps,
    setBodyCo2,
    setBodyText,
    setFooter,
    setHeader
} from "./utils";
import {i18n} from "i18next";
import {ACTIVITY_TYPE, AllActivityTypesArrayDiscriminating} from "../types-enums/activity";
import fontkit from '@pdf-lib/fontkit'
import {CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL} from "../api/model/common";
import {
    createRoundtripPassengersCountString,
    generateFlightTypeString,
    generatePassengerString
} from "../components/activity/flight/choose-flight-connection";
import {createHotelPeopleCountString} from "../components/activity/hotel/choose-hotel";
import {getCo2KgEForActivityType, getTotalCo2KgEForAllActivities} from "../utils/co2-getter";

export const createTravelComponentsPdf = async (
    activities: AllActivityTypesArrayDiscriminating,
    i18n: i18n,
    agencyName: string,
    agencyAddress: string,
    userName: string,
    email: string,
    completePhoneNumber: string,
    logo: LogoProps
) => {
    const {resolvedLanguage, t} = i18n
    const pdfDoc = await PDFDocument.create()
    pdfDoc.setSubject('PDF.TRAVEL_COMPONENTS.PDF_SUBJECT');
    pdfDoc.setTitle(t("PDF.TRAVEL_COMPONENTS.PDF_TITLE"));
    pdfDoc.registerFontkit(fontkit)
    let page = pdfDoc.addPage()
    const url = 'https://pdf-lib.js.org/assets/ubuntu/Ubuntu-R.ttf'
    const fontNormalBytes = await fetch(url).then((res) => res.arrayBuffer())
    const fontBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold)
    const fontNormal = await pdfDoc.embedFont(fontNormalBytes, {subset: true})
    const pageMarginLeft = 50;
    const pageMarginRight = 50;
    const {width, height} = page.getSize()
    const headerHeight = await setHeader(
        page,
        height,
        width,
        fontNormal,
        fontBold,
        pageMarginLeft,
        pageMarginRight,
        i18n,
        pdfDoc,
        userName,
        agencyName,
        agencyAddress,
        logo,
        email,
        completePhoneNumber
    )
    const footerHeight = headerHeight + 15
    const marginFromBodyToHeader = 30
    const travelComponentTitleFontSize = 12
    const marginFromTravelComponentTitleToBody = travelComponentTitleFontSize + 2
    const rowGapTravelComponent = 10
    const travelComponentBodyFontSize = 9
    const travelComponentBodyLineHeight = 12
    const totalHeaderHeight = headerHeight + marginFromBodyToHeader
    const heightForContent = height - totalHeaderHeight - footerHeight
    setFooter(page, height, width, fontNormal, fontBold, footerHeight, pageMarginLeft, pageMarginRight, i18n)
    let travelComponentTitleYPosition = height - totalHeaderHeight
    let travelComponentBodyYPosition = travelComponentTitleYPosition - marginFromTravelComponentTitleToBody
    let heightLeftOver = heightForContent
    let totalTravelComponentBodyHeight = 0
    let totalTravelComponentHeight = 0

    activities.forEach((activity, index) => {
        if (activity.common?.totalEmissions?.status !== CALCULATE_EMISSIONS_RESPONSE_STATUS_TOTAL.failed) {
            const spacerToCo2E = 30
            const {widthOfText, co2Text} = setBodyCo2(activity, i18n, fontNormal, travelComponentBodyFontSize)
            const {activityText, textLineCount, totalTextLinesCount} = setBodyText(
                activity,
                i18n,
                width - pageMarginRight - pageMarginLeft - widthOfText - spacerToCo2E,
                fontNormal,
                travelComponentBodyFontSize,
                " "
            )
            textLineCount.forEach((count, index) => {
                if (count > 1 && typeof co2Text[index] !== 'undefined') co2Text[index] = `${co2Text[index]}${'\n'.repeat(count)}`
            })
            totalTravelComponentBodyHeight = (totalTextLinesCount * travelComponentBodyLineHeight) - travelComponentBodyFontSize
            totalTravelComponentHeight = travelComponentTitleFontSize + marginFromTravelComponentTitleToBody + totalTravelComponentBodyHeight
            heightLeftOver -= totalTravelComponentHeight
            const result = conditionallyAddPage(
                heightLeftOver,
                totalTravelComponentHeight,
                footerHeight,
                rowGapTravelComponent,
                pdfDoc,
                page,
                height,
                width,
                fontNormal,
                fontBold,
                pageMarginLeft,
                pageMarginRight,
                i18n,
                userName,
                agencyName,
                agencyAddress,
                logo,
                email,
                completePhoneNumber,
                heightForContent,
                travelComponentTitleYPosition,
                height,
                totalHeaderHeight,
                travelComponentBodyYPosition,
                marginFromTravelComponentTitleToBody
            )

            heightLeftOver = result.heightLeftOver
            travelComponentTitleYPosition = result.travelComponentTitleYPosition
            travelComponentBodyYPosition = result.travelComponentBodyYPosition
            page = result.page
            let travelComponentCo2EBodyYPosition = travelComponentBodyYPosition

            if (isActivityFlightState(activity)) {
                const {common, passengerCount, returnFlight} = activity
                const {totalEmissions} = common
                const totalCo2KgE = totalEmissions!.emissionKgCO2eTotal
                const summaryText = `${index + 1}. ${createRoundtripPassengersCountString({
                    flightCount: 0,
                    passengerCount,
                    forString: t("GENERAL.FOR"),
                    passengerCountString: t(generatePassengerString(passengerCount)),
                    returnOrOnewayString: t(generateFlightTypeString(1, returnFlight))
                })}`
                drawActivityMetaInformation(
                    i18n,
                    page,
                    pageMarginLeft,
                    travelComponentBodyYPosition,
                    travelComponentBodyFontSize,
                    travelComponentTitleFontSize,
                    travelComponentBodyLineHeight,
                    travelComponentTitleYPosition,
                    fontBold,
                    width,
                    pageMarginRight,
                    fontNormal,
                    summaryText,
                    activityText,
                    totalCo2KgE!!,
                    resolvedLanguage
                )
                co2Text.forEach((text, index) => {
                    drawActivitySummary(
                        activity,
                        travelComponentCo2EBodyYPosition,
                        page,
                        travelComponentBodyFontSize,
                        travelComponentBodyLineHeight,
                        width,
                        pageMarginRight,
                        fontNormal,
                        text,
                    )
                    travelComponentCo2EBodyYPosition = travelComponentCo2EBodyYPosition - textLineCount[index] * travelComponentBodyLineHeight
                })
            }
            if (isActivityHotelState(activity)) {
                const {numberOfPeople} = activity
                const {common} = activity
                const {totalEmissions} = common
                const totalCo2KgE = totalEmissions!.emissionKgCO2eTotal
                const summaryText = `${index + 1}. ${createHotelPeopleCountString({
                    peopleCount: numberOfPeople,
                    forString: t("GENERAL.FOR"),
                    peopleCountString: t(generatePassengerString(numberOfPeople))
                })}`
                drawActivityMetaInformation(
                    i18n,
                    page,
                    pageMarginLeft,
                    travelComponentBodyYPosition,
                    travelComponentBodyFontSize,
                    travelComponentTitleFontSize,
                    travelComponentBodyLineHeight,
                    travelComponentTitleYPosition,
                    fontBold,
                    width,
                    pageMarginRight,
                    fontNormal,
                    summaryText,
                    activityText,
                    totalCo2KgE!!,
                    resolvedLanguage
                )

                co2Text.forEach((text, index) => {
                    drawActivitySummary(
                        activity,
                        travelComponentCo2EBodyYPosition,
                        page,
                        travelComponentBodyFontSize,
                        travelComponentBodyLineHeight,
                        width,
                        pageMarginRight,
                        fontNormal,
                        text,
                    )
                    travelComponentCo2EBodyYPosition = travelComponentCo2EBodyYPosition - textLineCount[index] * travelComponentBodyLineHeight
                })
            }

            travelComponentTitleYPosition = travelComponentTitleYPosition - (totalTravelComponentHeight + rowGapTravelComponent)
            travelComponentBodyYPosition = travelComponentTitleYPosition - marginFromTravelComponentTitleToBody
        }
    })

    const textLinesCountPerActivitySummary = 1
    const textLinesCountForTotalSummary = 1
    const co2KgEPerActivity = getCo2KgEForActivityType(activities)
    const totalActivitySummaryTextLinesCount = Object.keys(co2KgEPerActivity).length

    const lineXPosition = 50;
    const lineStart = width - lineXPosition
    const lineThickness = 1.5
    const marginBottomLine = 15
    let offsetTotalCo2KgESummaryToLastActivity = 20
    let perActivitySummaryBodyHeight = 0
    let totalPerActivitySummaryHeight = 0
    let summaryAllActivitiesBodyHeight = 0
    let totalSummaryAllActivitiesHeight = 0
    perActivitySummaryBodyHeight = (totalActivitySummaryTextLinesCount * travelComponentBodyLineHeight) - travelComponentBodyFontSize
    totalPerActivitySummaryHeight = travelComponentTitleFontSize + marginFromTravelComponentTitleToBody + perActivitySummaryBodyHeight
    summaryAllActivitiesBodyHeight = (textLinesCountForTotalSummary * travelComponentBodyLineHeight) - travelComponentBodyFontSize
    totalSummaryAllActivitiesHeight = lineThickness + marginBottomLine + summaryAllActivitiesBodyHeight
    const completeHeightSummary = totalSummaryAllActivitiesHeight + totalPerActivitySummaryHeight + offsetTotalCo2KgESummaryToLastActivity
    const previousPageCount = pdfDoc.getPageCount()
    heightLeftOver -= completeHeightSummary
    const result = conditionallyAddPage(
        heightLeftOver,
        perActivitySummaryBodyHeight,
        footerHeight,
        rowGapTravelComponent,
        pdfDoc,
        page,
        height,
        width,
        fontNormal,
        fontBold,
        pageMarginLeft,
        pageMarginRight,
        i18n,
        userName,
        agencyName,
        agencyAddress,
        logo,
        email,
        completePhoneNumber,
        heightForContent,
        travelComponentTitleYPosition,
        height,
        totalHeaderHeight,
        travelComponentBodyYPosition,
        marginFromTravelComponentTitleToBody
    );
    heightLeftOver = result.heightLeftOver
    const currentPageCount = pdfDoc.getPageCount()
    if (currentPageCount !== previousPageCount) offsetTotalCo2KgESummaryToLastActivity = 0
    travelComponentTitleYPosition = result.travelComponentTitleYPosition - offsetTotalCo2KgESummaryToLastActivity
    travelComponentBodyYPosition = result.travelComponentBodyYPosition - offsetTotalCo2KgESummaryToLastActivity
    page = result.page;
    page.drawText(i18n.t("GENERAL.TOTAL_EMISSIONS"), {
        x: pageMarginLeft,
        y: travelComponentTitleYPosition,
        size: travelComponentTitleFontSize,
        font: fontBold,
        color: black,
    });
    (Object.keys(co2KgEPerActivity) as Array<keyof typeof ACTIVITY_TYPE>).forEach(activityType => {
        const activityTypeString = t(`ACTIVITIES.${activityType.toUpperCase()}.TITLE`,)
        const {co2KgEString, co2KgETextWidth} = createTotalCo2String(
            i18n,
            co2KgEPerActivity[activityType],
            resolvedLanguage,
            fontNormal,
            travelComponentBodyFontSize,
            false
        )
        page.drawText(activityTypeString, {
            x: pageMarginLeft,
            y: travelComponentBodyYPosition,
            size: travelComponentBodyFontSize,
            font: fontNormal,
            color: black,
        })
        page.drawText(co2KgEString, {
            x: width - pageMarginRight - co2KgETextWidth,
            y: travelComponentBodyYPosition,
            size: travelComponentBodyFontSize,
            font: fontNormal,
            color: black,
        })
        travelComponentBodyYPosition = travelComponentBodyYPosition - textLinesCountPerActivitySummary * travelComponentBodyLineHeight
    })
    const lineYPosition = travelComponentBodyYPosition
    page.drawLine({
        start: {
            x: lineXPosition,
            y: lineYPosition
        },
        end: {
            x: lineStart,
            y: lineYPosition
        },
        thickness: lineThickness,
        color: darkGrey,
    })
    const totalCo2KgE = getTotalCo2KgEForAllActivities(activities)
    const {co2KgEString, co2KgETextWidth} = createTotalCo2String(
        i18n,
        totalCo2KgE,
        resolvedLanguage,
        fontNormal,
        travelComponentBodyFontSize,
        true
    )
    page.drawText(co2KgEString, {
        x: width - pageMarginRight - co2KgETextWidth,
        y: lineYPosition - marginBottomLine,
        size: travelComponentBodyFontSize,
        font: fontNormal,
        color: black,
    })
    return await pdfDoc.save()
}