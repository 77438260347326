import React, {useState} from "react"
import AgenciesCompanyDataGrid
    from "../../components/settings/agencies-company/agencies-company-data-grid/AgenciesCompanyDataGrid";
import AgencyMembersDataGrid
    from "../../components/settings/agencies-company/agency-members-data-grid/AgencyMembersDataGrid";
import "./style.css"
import {useAppSelector} from "../../redux/reducer/store";
import SettingsMenu, {MENU, MENU_KEY} from "../../components/settings-menu/SettingsMenu";
import UserSettings from "../../components/settings/user-settings/UserSettings";
import AgenciesLogos from "../../components/settings/agencies-logos/AgenciesLogos";
import KlimaLinkAdministration from "../../components/settings/klimalink-administration/KlimaLinkAdministration";
import clsx from "clsx";
import {QueryStatus} from "@reduxjs/toolkit/query";
import ErrorPage from "../functional/error-page/ErrorPage";
import {isSomeAdmin} from "../../utils/user";
import {getCompany} from "../../utils/agencies-company-getter";

const Settings = () => {
    const [activeMenuPoint, setActiveMenuPoint] = useState(0)
    const {userData, agencies, companies, allUsers} = useAppSelector(state => state.user)
    const userAgenciesAdmin = agencies?.filter(agency => agency.isAdmin)
    const {
        isSomeAgencyAdmin,
        isCompanyAdmin,
        isKlimaLinkAdmin
    } = isSomeAdmin(userData, agencies, companies)

    const isErrorGetUsersAgency = useAppSelector(state => {
        return Object.values(state.api.queries).some(query => {
            if (query && query.endpointName === "getUsersAgencies") return query.status === QueryStatus.rejected;
        });
    })

    const isErrorGetUsersCompany = useAppSelector(state => {
        return Object.values(state.api.queries).some(query => {
            if (query && query.endpointName === "getUsersCompany") return query.status === QueryStatus.rejected;
        });
    });

    const isErrorGetUserInfo = useAppSelector(state => Object.values(state.api.queries).some(query => {
        if (query && query.endpointName === "getUserInfo") return query.status === QueryStatus.rejected;
    }))

    const _showMenuContent = (menu: MENU_KEY) => activeMenuPoint === MENU.findIndex(menuPoint => menuPoint.key === menu)

    if (isErrorGetUsersAgency || isErrorGetUsersCompany || isErrorGetUserInfo) return <ErrorPage/>

    return <div className={"settings-container"}>
        <SettingsMenu
            isKlimaLinkAdmin={isKlimaLinkAdmin}
            isAdminCompanyOrAgencyAdmin={isSomeAgencyAdmin || isCompanyAdmin}
            activeMenuPoint={activeMenuPoint}
            onClickMenuPoint={index => setActiveMenuPoint(index)}
        />
        <div className={clsx("settings-content", "settings-margin-navigation")}>
            {companies && allUsers && isCompanyAdmin && userData && _showMenuContent(MENU_KEY.companyAgencySettings) &&
                <>
                    <AgenciesCompanyDataGrid
                        allUsers={allUsers}
                        isCompanyAdmin={isCompanyAdmin}
                        company={getCompany(userData.isKlimaLink, userData.companyId, companies)}
                        whichType={"company"}/>
                    <AgenciesCompanyDataGrid
                        allUsers={allUsers}
                        isCompanyAdmin={isCompanyAdmin}
                        agencies={userAgenciesAdmin}
                        whichType={"agencies"}/>
                </>
            }
            {((isSomeAgencyAdmin && userAgenciesAdmin) || isCompanyAdmin) && userData && allUsers && _showMenuContent(MENU_KEY.companyAgencySettings) &&
                <>
                    <AgencyMembersDataGrid
                        isCompanyAdmin={isCompanyAdmin}
                        allMembers={allUsers}
                        adminUserId={userData.id}
                        agencies={userAgenciesAdmin ?? []}/>
                    <AgenciesLogos agencies={userAgenciesAdmin ?? []}/>
                </>
            }
            {isKlimaLinkAdmin && _showMenuContent(MENU_KEY.klimaLinkSettings) && <KlimaLinkAdministration/>}
            {_showMenuContent(MENU_KEY.userSettings) && userData && agencies && companies && <UserSettings
                userData={userData}
                agencies={agencies}
                company={getCompany(userData.isKlimaLink, userData.companyId, companies)}/>
            }
        </div>
    </div>
}

export default Settings