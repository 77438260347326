import {BASE_ENDPOINT} from "../api/endpoints";
import Cookies from "js-cookie";
import {COOKIE_NAME} from "../constants";
import {AgencyProps} from "../api/model/agency";
import {GENERAL_BACKEND_RESPONSE} from "../api/model/common";

export const getAgencyLogo = async (agencyId: AgencyProps["id"]) => {
    try {
        let headers = new Headers();
        const token = Cookies.get(COOKIE_NAME)
        headers.set('Authorization', `Bearer ${token}`);
        const response = await fetch(`${BASE_ENDPOINT}/api/v1/agency/${agencyId}/logo`, {
            method: 'GET',
            headers
        })
        if (response.ok) {
            const contentType = response.headers.get('Content-Type');
            const clonedResponse = response.clone()
            const imageBlob = await response.blob();
            const imageBuffer = await clonedResponse.arrayBuffer()
            const fileType = contentType?.split('/')[1];
            return {
                status: GENERAL_BACKEND_RESPONSE.success,
                imageBlob: URL.createObjectURL(imageBlob),
                arrayBuffer: imageBuffer,
                fileType
            }
        } else {
            return {
                status: GENERAL_BACKEND_RESPONSE.failure,
                imageBlob: null,
                arrayBuffer: null
            }
        }
    } catch (error) {
        console.error(`Failed to get agency logo with error: ${error}`)
    }
}