import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {UserProps} from "../../api/model/user";
import {AgencyProps} from "../../api/model/agency";
import {CompanyProps} from "../../api/model/company";

export interface AgenciesLogosValuesProps {
    imageBlob: string;
    arrayBuffer: ArrayBuffer;
    fileType: string
}

interface AgenciesLogosProps {
    [key: string]: AgenciesLogosValuesProps
}

export interface UserState {
    userData: UserProps | null,
    allUsers: UserProps[] | null,
    agencies: AgencyProps[] | null,
    companies: CompanyProps[] | null,
    agenciesLogos: AgenciesLogosProps | null
}

const initialState: UserState = {
    userData: null,
    allUsers: null,
    agencies: null,
    companies: null,
    agenciesLogos: null,
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserData: (state, action: PayloadAction<UserState["userData"]>) => {
            state.userData = action.payload
        },
        setAllUsersForAdmin: (state, action: PayloadAction<UserState["allUsers"]>) => {
            state.allUsers = action.payload
        },
        setAgencies: (state, action: PayloadAction<UserState["agencies"]>) => {
            state.agencies = action.payload
        },
        setCompany: (state, action: PayloadAction<UserState["companies"]>) => {
            state.companies = action.payload
        },
        setAgencyLogo: (state, action: PayloadAction<{
            agencyId: AgencyProps["id"],
            agencyLogo: AgenciesLogosValuesProps | null
        }>) => {
            const {agenciesLogos} = state
            const {agencyId, agencyLogo} = action.payload
            if (agenciesLogos) {
                agencyLogo ? agenciesLogos[agencyId] = agencyLogo : delete agenciesLogos[agencyId]
            } else {
                if (agencyLogo) state.agenciesLogos = {[agencyId]: agencyLogo}
            }
        }
    }
});

export const {
    setUserData,
    setCompany,
    setAgencies,
    setAgencyLogo,
    setAllUsersForAdmin,
} = userSlice.actions;
export default userSlice.reducer;