import React from "react"
import "./styles.css"
import LanguageSelector from "../../container/language-selector/LanguageSelector";
import {Text} from "atmosfair-ui"
import {useNavigate} from "react-router-dom";
import {PATH_STRINGS} from "../../routes/route-props";

const Footer = () => {
    const navigate = useNavigate()
    return <footer className={"footer-container"}>
        {/*<Text*/}
        {/*    onClick={() => navigate(PATH_STRINGS.help)}*/}
        {/*    classes={"footer-help-text"}*/}
        {/*    color={"white"}*/}
        {/*    variant={"subtitleLarge"}>*/}
        {/*    GENERAL.HELP*/}
        {/*</Text>*/}
        <Text
            onClick={() => navigate(PATH_STRINGS.termsAndConditionsAdmin, {state: {showAcceptButton: false}})}
            classes={"footer-help-text"}
            color={"white"}
            variant={"subtitleLarge"}>
            GENERAL.TERMS
        </Text>
        <LanguageSelector/>
    </footer>
}

export default Footer