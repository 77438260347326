import {CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL} from "../api/model/common";
import {ActivityFlightState, Leg} from "../types-enums/activity-flight";
import {LegForUi} from "../components/activity/flight/choose-flight-connection/ChooseFlight";

export type FailedLegsProps = Leg[][];

export const getFlightFailedOrSuccessfulLegs = (flight: ActivityFlightState, responseStatus: CALCULATED_EMISSIONS_RESPONSE_STATUS_PARTIAL) => {
    const {legs} = flight
    let failedLegs: FailedLegsProps = []
    const {common} = flight
    const {totalEmissions} = common
    if (totalEmissions) {
        const {data} = totalEmissions
        data.forEach(leg => {
            if (leg.status === responseStatus) {
                const {index} = leg
                const departure = legs[index]
                const arrival = legs[index + 1]
                if (!arrival.ownAirportTransfer && departure && arrival) failedLegs.push([departure, arrival])
            }
        })
        return failedLegs
    }
}

export const getFirstLeg = (legs: Leg[]) => legs.slice(0)[0]
export const getLastLeg = (legs: Leg[]) => legs.slice(-1)[0]
export const getFirstUiLeg = (legs: LegForUi[]) => legs.slice(0)[0]
export const getUiStopover = (legs: LegForUi[]) => {
    if (legs.length > 2) return legs.slice(1, legs.length - 2)
    return []
}
export const getUiLastLeg = (legs: LegForUi[]) => legs.slice(-1)[0]

