import React, {useState} from "react"
import {FlightsResponse} from "../../../../api/model/activity-flight";
import {CheckboxInput, Text} from "atmosfair-ui";
import "./style.css"
import {ROUTE_SEPARATOR} from "../../../../utils/string-mutation";

interface AmbiguousFlightListProps {
    flights: FlightsResponse[],
    setFlight: (flight: FlightsResponse) => void
}

const AmbiguousFlightList = ({
                                 flights,
                                 setFlight
                             }: AmbiguousFlightListProps) => {
    const [checked, setChecked] = useState<boolean[]>(flights.map(_ => false))

    const _onChange = (flight: FlightsResponse, index: number) => {
        setFlight(flight)
        setChecked([...checked.slice(0, index), !checked[index], ...checked.slice(index + 1)])
    }

    return <ul className={"ambiguous-flight-list-container"}>
        {flights.map((flight, index) => {
            const departureAirport = flight.departureAirport
            const arrivalAirport = flight.arrivalAirport
            const label = `${departureAirport.name} (${departureAirport.iata}) ${ROUTE_SEPARATOR} ${arrivalAirport.name} (${arrivalAirport.iata}) / ${flight.airline.name} (${flight.airline.iata}) / ${flight.aircraftType.name} (${flight.aircraftType.iata})`
            return <li
                className={"ambiguous-flight-list-element"}
                key={index}>
                <CheckboxInput
                    hideLabel={true}
                    checked={checked[index]}
                    onChange={() => _onChange(flight, index)}
                    label={label}/>
                <Text
                    classes={"ambiguous-flight-list-text"}
                    onClick={() => _onChange(flight, index)}>{label}</Text>
            </li>
        })}
    </ul>
}

export default AmbiguousFlightList