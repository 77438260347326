import React, {CSSProperties, useEffect, useState} from 'react'
import InternationalPhonePrefixDropdown, {
    InternationalPhonePrefixDropdownProps
} from "../../../molecules/dropdowns/InternationalPhonePrefixDropdown";
import {TextInput} from "atmosfair-ui";
import "./styles.css"
import {validateInternationalPhonePrefix, validatePhoneNumber,} from "../utils";
import {VALIDATE_FORM_STARTING_NUMBER} from "../../../constants";
import {INITIAL_SELECTED_INTERNATIONAL_PHONE_PREFIX} from "../../../utils/default-values";
import usePrevious from "../../../hooks/usePrevious";
import {isEqual} from "lodash";

interface PhoneNumberFormProps {
    internationalPhonePrefixRequired: InternationalPhonePrefixDropdownProps["required"]
    internationalPhonePrefixStyle?: CSSProperties,
    phoneNumberStyle?: CSSProperties,
    phoneNumberRequired: boolean,
    validateForm: number,
    onFormIsValid: (data: PhoneNumberProps) => void,
    defaultValues?: PhoneNumberProps
    containerStyle?: CSSProperties,
    disableForm?: boolean,
    showInternationalPhonePrefix: boolean
}

export interface PhoneNumberProps {
    internationalPhonePrefix?: InternationalPhonePrefixDropdownProps["internationalPhonePrefix"]
    phoneNumber: string
}

interface PhoneNumberErrorProps {
    phoneNumberError: boolean,
    internationalPhonePrefixError: boolean,
}


const PhoneNumberForm = ({
                             containerStyle,
                             internationalPhonePrefixRequired,
                             internationalPhonePrefixStyle = {width: "25%"},
                             phoneNumberStyle = {flex: 1},
                             phoneNumberRequired,
                             validateForm,
                             onFormIsValid,
                             defaultValues,
                             disableForm,
                             showInternationalPhonePrefix
                         }: PhoneNumberFormProps) => {
    const [formData, setFormData] = useState<PhoneNumberProps>({
        phoneNumber: defaultValues?.phoneNumber ?? "",
        internationalPhonePrefix: defaultValues?.internationalPhonePrefix ?? INITIAL_SELECTED_INTERNATIONAL_PHONE_PREFIX,
    })
    const [formErrorData, setFormErrorData] = useState<PhoneNumberErrorProps>({
        phoneNumberError: false,
        internationalPhonePrefixError: false,
    })
    const {
        phoneNumber,
        internationalPhonePrefix
    } = formData
    const {
        phoneNumberError,
        internationalPhonePrefixError
    } = formErrorData
    const previousDefaultValues = usePrevious(defaultValues)

    useEffect(() => {
        if (!showInternationalPhonePrefix && internationalPhonePrefixRequired) throw new Error("You cannot set showInternationalPhonePrefix = false and internationalPhonePrefixRequired = true")
    }, []);

    useEffect(() => {
        if (validateForm > VALIDATE_FORM_STARTING_NUMBER && _validateForm()) {
            let formData: PhoneNumberProps = {phoneNumber: phoneNumber.trim(),}
            if (showInternationalPhonePrefix) formData['internationalPhonePrefix'] = internationalPhonePrefix
            onFormIsValid(formData)
        }
    }, [validateForm]);

    useEffect(() => {
        if (defaultValues && !isEqual(defaultValues, previousDefaultValues)) {
            const {phoneNumber, internationalPhonePrefix} = defaultValues
            setFormData({
                phoneNumber: phoneNumber ?? "",
                internationalPhonePrefix: internationalPhonePrefix ?? ""
            })
        }
    }, [defaultValues]);

    const _updateFormData = <K extends keyof PhoneNumberProps>(key: K, payload: PhoneNumberProps[K]) => setFormData({
        ...formData,
        [key]: payload
    })

    const _validateForm = () => {
        let validInternationalPhonePrefix = true
        let validPhoneNumber = true

        if (internationalPhonePrefixRequired) validInternationalPhonePrefix = validateInternationalPhonePrefix(internationalPhonePrefix)
        if (phoneNumberRequired) {
            validPhoneNumber = validatePhoneNumber(phoneNumber)
        } else {
            if (phoneNumber.length > 0) validPhoneNumber = validatePhoneNumber(phoneNumber)
        }

        setFormErrorData({
            phoneNumberError: !validPhoneNumber,
            internationalPhonePrefixError: !validInternationalPhonePrefix,
        })

        return [
            validPhoneNumber,
            validInternationalPhonePrefix,
        ].every(Boolean)
    }

    return <div style={containerStyle} className={'form-row'}>
        {showInternationalPhonePrefix && <InternationalPhonePrefixDropdown
            readOnly={disableForm}
            internationalPhonePrefix={internationalPhonePrefix}
            error={internationalPhonePrefixError}
            setSelection={selection => _updateFormData("internationalPhonePrefix", selection)}
            required={internationalPhonePrefixRequired}
            style={internationalPhonePrefixStyle}/>}
        <TextInput
            readOnly={disableForm}
            labelColor={"primary"}
            ariaErrorMessage={"SCREENS.COMMON.ARIA_LABELS.TELEPHONE_NUMBER_WITH_AREA_CODE_ERROR"}
            error={phoneNumberError}
            onBlur={value => _updateFormData("phoneNumber", value)}
            onChange={value => _updateFormData("phoneNumber", value)}
            containerStyle={phoneNumberStyle}
            required={phoneNumberRequired}
            value={phoneNumber}
            placeholder={"GENERAL.TELEPHONE_NUMBER_WITH_AREA_CODE_LABEL"}
            label={"GENERAL.TELEPHONE_NUMBER_WITH_AREA_CODE_LABEL"}/>
    </div>
}

export default PhoneNumberForm