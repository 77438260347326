import React, {CSSProperties, useEffect, useRef, useState} from 'react'
import {CheckboxInput} from "atmosfair-ui";
import {FormPropsAgency} from "../../settings/agencies-company/agencies-company-data-grid/AgenciesCompanyDataGrid";
import {UserProps} from "../../../api/model/user";
import {useTranslation} from "react-i18next";
import {VALIDATE_FORM_STARTING_NUMBER} from "../../../constants";
import NameEmailForm, {NameEmailProps} from "../name-email-form/NameEmailForm";
import clsx from 'clsx';
import usePrevious from "../../../hooks/usePrevious";
import {isEqual} from 'lodash';

export interface ContactPersonProps {
    firstName: string,
    lastName: string,
    email: string,
    isAdmin?: boolean,
    inviteContact?: boolean,
}

interface ContactPersonFormProps {
    containerClasses?: string | string[],
    containerStyle?: CSSProperties,
    firstNameRequired: boolean,
    lastNameRequired: boolean,
    emailRequired: boolean,
    whichType: 'agencies' | "company",
    onInviteContact?: <K extends keyof FormPropsAgency>(key: K, value: FormPropsAgency[K], firstName: string, lastName: string, email: string) => void,
    onChangeContact?: <K extends keyof FormPropsAgency>(key: K, value: FormPropsAgency[K]) => void,
    defaultValues?: ContactPersonProps,
    allUsers: UserProps[],
    isEdit: boolean,
    showMakeAdminCheckboxes: boolean,
    validateForm: number,
    onFormIsValid: (data: ContactPersonProps) => void,
    whichContactPerson: "first" | "second",
    functionValueContactPerson?: keyof FormPropsAgency
}

const ContactPersonForm = ({
                               containerClasses,
                               containerStyle,
                               whichType,
                               onInviteContact,
                               onChangeContact,
                               allUsers,
                               isEdit,
                               defaultValues,
                               showMakeAdminCheckboxes,
                               validateForm,
                               onFormIsValid,
                               firstNameRequired,
                               lastNameRequired,
                               emailRequired,
                               whichContactPerson,
                               functionValueContactPerson,
                           }: ContactPersonFormProps) => {
    const [nameEmailFormData, setNameEmailFormData] = useState<NameEmailProps | null>(null)
    const [formData, setFormData] = useState<ContactPersonProps>({
        firstName: defaultValues?.firstName ?? "",
        lastName: defaultValues?.lastName ?? "",
        email: defaultValues?.email ?? "",
        inviteContact: defaultValues?.inviteContact ?? false,
        isAdmin: defaultValues?.isAdmin ?? false,
    })
    const {t} = useTranslation()
    const {
        firstName,
        lastName,
        email,
        inviteContact,
        isAdmin
    } = formData
    const stringValueContactPerson = useRef(whichContactPerson === "first" ? "FIRST" : "SECOND")
    const contactPersonFirstNameString = useRef<keyof FormPropsAgency>(whichContactPerson === "first" ? "firstContactFirstName" : "secondContactFirstName")
    const contactPersonLastNameString = useRef<keyof FormPropsAgency>(whichContactPerson === "first" ? "firstContactLastName" : "secondContactLastName")
    const contactPersonEmailString = useRef<keyof FormPropsAgency>(whichContactPerson === "first" ? "firstContactEmail" : "secondContactEmail")
    const previousDefaultValues = usePrevious(defaultValues)

    useEffect(() => {
        if (defaultValues && !isEqual(defaultValues, previousDefaultValues)) {
            const {firstName, lastName, email, inviteContact, isAdmin} = defaultValues
            setFormData({firstName, lastName, email, inviteContact, isAdmin})
        }
    }, [defaultValues]);

    useEffect(() => {
        if (functionValueContactPerson) {
            if (
                functionValueContactPerson !== "inviteSecondContact" &&
                functionValueContactPerson !== "inviteFirstContact"
            ) throw new Error("Please only provide inviteFirstContact or inviteSecondContact as a functionValueContactPerson")
        }
    }, []);

    useEffect(() => {
        if (!functionValueContactPerson && onChangeContact) throw new Error("If you provide an onChangeContact() function you must provide a functionValueContactPerson")
    }, []);

    useEffect(() => {
        if (validateForm > VALIDATE_FORM_STARTING_NUMBER && nameEmailFormData) onFormIsValid({
            ...nameEmailFormData,
            isAdmin,
            inviteContact,
        })
    }, [validateForm, nameEmailFormData]);

    const _updateFormData = <K extends keyof ContactPersonProps>(key: K, payload: ContactPersonProps[K]) => setFormData({
        ...formData,
        [key]: payload
    })

    const _showInviteCheckbox = () => {
        if (isEdit) return false
        return !Boolean(allUsers?.find(user => user.email === email))
    }

    const _onBlurChangeFirstName = (value: string) => {
        onChangeContact && onChangeContact(contactPersonFirstNameString.current, value)
        _updateFormData("firstName", value)
    }

    const _onBlurChangeLastName = (value: string) => {
        onChangeContact && onChangeContact(contactPersonLastNameString.current, value)
        _updateFormData("lastName", value)
    }

    const _onBlurChangeEmail = (value: string) => {
        onChangeContact && onChangeContact(contactPersonEmailString.current, value)
        _updateFormData("email", value)
    }

    return <div
        style={containerStyle}
        className={clsx("form-row", containerClasses)}>
        <NameEmailForm
            defaultValues={{firstName, lastName, email}}
            firstNameRequired={firstNameRequired}
            lastNameRequired={lastNameRequired}
            emailRequired={emailRequired}
            validateForm={validateForm}
            onFormIsValid={setNameEmailFormData}
            onChangeFirstName={_onBlurChangeFirstName}
            onBlurFirstName={_onBlurChangeFirstName}
            onChangeLastName={_onBlurChangeLastName}
            onBlurLastName={_onBlurChangeLastName}
            onChangeEmail={_onBlurChangeEmail}
            onBlurEmail={_onBlurChangeEmail}
            placeholderFirstName={"GENERAL.FIRST_NAME"}
            labelFirstName={`GENERAL.${stringValueContactPerson.current}_CONTACT_PERSON_FIRST_NAME`}
            placeholderLastName={"GENERAL.LAST_NAME"}
            labelLastName={`GENERAL.${stringValueContactPerson.current}_CONTACT_PERSON_LAST_NAME`}
            placeholderEmail={"GENERAL.EMAIL"}
            labelEmail={t("GENERAL.EMAIL_CONTACT_PERSON", {contactPerson: t(`GENERAL.${stringValueContactPerson.current}_CONTACT_PERSON`)})}
        />
        {whichType === "agencies" && <>
            {_showInviteCheckbox() && !isEdit && <CheckboxInput
                text={t("SCREENS.SETTINGS.INVITE_CONTACT_PERSON", {contactPerson: t(`GENERAL.${stringValueContactPerson.current}_CONTACT_PERSON`)})}
                hideLabel={true}
                textPosition={'right'}
                checked={Boolean(inviteContact)}
                onChange={() => {
                    const value = !inviteContact
                    onInviteContact && onInviteContact(
                        whichContactPerson === "first" ? "inviteFirstContact" : "inviteSecondContact",
                        value,
                        firstName,
                        lastName,
                        email
                    )
                    _updateFormData("inviteContact", value)
                }}
                label={t("SCREENS.SETTINGS.INVITE_CONTACT_PERSON", {contactPerson: t(`GENERAL.${stringValueContactPerson.current}_CONTACT_PERSON`)})}/>}
            {showMakeAdminCheckboxes && inviteContact && _showInviteCheckbox() && <CheckboxInput
                checked={Boolean(isAdmin)}
                onChange={() => _updateFormData("isAdmin", !isAdmin)}
                hideLabel={true}
                textPosition={'right'}
                text={t("SCREENS.SETTINGS.MAKE_USER_ADMIN_MODAL_TEXT", {user: t(`GENERAL.${stringValueContactPerson.current}_CONTACT_PERSON`)})}
                label={t("SCREENS.SETTINGS.MAKE_USER_ADMIN_MODAL_TEXT", {user: t(`GENERAL.${stringValueContactPerson.current}_CONTACT_PERSON`)})}/>
            }
        </>
        }
    </div>
}

export default ContactPersonForm