import React from 'react'
import './styles.css'
import {IconButton, Icons, Text} from "atmosfair-ui";
import {useTranslation} from "react-i18next";
import {textColor} from "../styles";

interface ToastUndoProps {
    message: string,
    onClick: () => void,
    icon?: Icons
}

const ToastWithButton = ({
                             message,
                             onClick,
                             icon = "undo"
                         }: ToastUndoProps) => {
    const {t} = useTranslation()
    return <div className={'toast-with-button-container'}>
        <Text color={textColor}>{t(message)}</Text>
        <IconButton
            color={"white"}
            icon={icon}
            onlyIcon={true}
            onClick={onClick}/>
    </div>
}

export default ToastWithButton