import React, {useEffect} from "react"
import {useFusionAuth} from "@fusionauth/react-sdk";
import {Navigate, useNavigate} from "react-router-dom";
import {PATH_STRINGS} from "../../../routes/route-props";
import {useAppDispatch} from "../../../redux/reducer/store";
import {setUserData} from "../../../redux/reducer/user";
import AuthenticatingUser from "../../authenticating-user/AuthenticatingUser";
import {useTranslation} from "react-i18next";
import {apiSlice} from "../../../redux/reducer/api";
import FullscreenSpinner from "../../../molecules/fullscreen-spinner/FullscreenSpinner";
import {companyAdminHasAcceptedTermsAndConditions} from "../../../utils/user";
import ErrorPage from "../error-page/ErrorPage";

const LogInSuccessful = () => {
    const {login, isAuthenticated, isLoading} = useFusionAuth();
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const {i18n} = useTranslation()
    const currentLanguage = i18n.resolvedLanguage

    const [triggerGetUserInfo, {
        data: userInfoResponse,
        isLoading: isLoadingUserInfoResponse,
        error: isUserInfoResponseError
    }] = apiSlice.endpoints?.getUserInfo.useLazyQuery()

    const [triggerGetCompanyInfo, {
        data: companyInfoResponse,
        isLoading: isLoadingCompanyInfo,
        error: companyInfoError
    }] = apiSlice.endpoints?.getUsersCompany.useLazyQuery()

    const termsAndConditionsAcceptedByCompanyAdmin = !companyAdminHasAcceptedTermsAndConditions(companyInfoResponse, userInfoResponse)

    useEffect(() => {
        if (isAuthenticated) triggerGetUserInfo()
    }, [isAuthenticated]);

    useEffect(() => {
        if (userInfoResponse && isAuthenticated) triggerGetCompanyInfo()
    }, [userInfoResponse]);

    useEffect(() => {
        if (userInfoResponse && !isUserInfoResponseError) {
            dispatch(setUserData(userInfoResponse))
            const {language} = userInfoResponse
            language && language !== currentLanguage && i18n.changeLanguage(language)
        }
    }, [userInfoResponse]);

    useEffect(() => {
        isAuthenticated && navigate(PATH_STRINGS.travelDatabase, {replace: true})
    }, [isAuthenticated, navigate]);

    if (isLoading) return <AuthenticatingUser/>
    if (!isAuthenticated) login()
    if (isLoadingUserInfoResponse || isLoadingCompanyInfo) return <FullscreenSpinner showLogo={true}/>
    if (isAuthenticated && !termsAndConditionsAcceptedByCompanyAdmin) return <Navigate
        to={PATH_STRINGS.termsAndConditionsAdmin}
        state={{showAcceptButton: true}}/>
    if (isAuthenticated && !userInfoResponse?.registrationComplete) return <Navigate to={PATH_STRINGS.register}/>
    if (isAuthenticated && termsAndConditionsAcceptedByCompanyAdmin) return <Navigate
        to={PATH_STRINGS.travelDatabase}/>
    if (isUserInfoResponseError || companyInfoError) return <ErrorPage/>
    return <FullscreenSpinner showLogo={true}/>
}

export default LogInSuccessful