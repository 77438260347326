import React from 'react'
import "./styles.css"
import {Text} from "atmosfair-ui"
import LogoutButton from "../../../molecules/logout-button/LogoutButton";

const NoRoute = () => {
    return <div className={"no-route-container"}>
        <LogoutButton/>
        <Text variant={"headlineMedium"}>SCREENS.NO_ROUTE.TITLE</Text>
    </div>
}

export default NoRoute