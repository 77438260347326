import {AircraftTypeResponse, FlightRequestLeg} from "../../../../api/model/activity-flight";
import {TravelClass} from "../../../../screens/activity/activity-form/flight";

interface CreateRoundtripPassengersCountStringProps {
    flightCount: number,
    passengerCount: number,
    forString: string,
    passengerCountString: string,
    returnOrOnewayString: string,
}

export const generateFlightCountString = (flightCount: number) => {
    if (flightCount === 1) return "ACTIVITIES.FLIGHT.FLIGHT_SINGULAR"
    return "ACTIVITIES.FLIGHT.FLIGHT_PLURAL"
}

export const generateFlightTypeString = (flightCount: number, returnFlight: boolean,) => {
    if (flightCount === 1) {
        if (returnFlight) return 'ACTIVITIES.FLIGHT.ROUNDTRIP_SINGULAR'
        return 'ACTIVITIES.FLIGHT.ONEWAY_SINGULAR'
    } else {
        if (returnFlight) return 'ACTIVITIES.FLIGHT.ROUNDTRIP_PLURAL'
        return 'ACTIVITIES.FLIGHT.ONEWAY_ONLY_PLURAL'
    }
}

export const generatePassengerString = (passengerCount: number) => {
    if (passengerCount === 1) return 'GENERAL.PASSENGER'
    return 'GENERAL.PASSENGERS'
}

export const createRoundtripPassengersCountString = ({
                                                         flightCount,
                                                         passengerCount,
                                                         forString,
                                                         passengerCountString,
                                                         returnOrOnewayString,
                                                     }: CreateRoundtripPassengersCountStringProps): string => {
    const commonString = `${returnOrOnewayString} ${forString} ${passengerCount} ${passengerCountString}`
    if (flightCount > 0) return `${flightCount} ${commonString}`
    return commonString
}

export const convertUiTravelClassToApiTravelClass = (travelClass: TravelClass | null): FlightRequestLeg['travelClass'] => {
    if (travelClass) {
        switch (travelClass.id) {
            case 1:
                return 'F'
            case 2:
                return 'B'
            case 3:
                return 'Y'
            case 4:
                return 'W'
            default:
                return null
        }
    }
    return null
}
export const convertUiAircraftTypeToApiAircraftType = (aircraftType: AircraftTypeResponse | null): FlightRequestLeg['aircraftType'] => {
    if (aircraftType) return aircraftType.iata
    return null;
}