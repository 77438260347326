import React from 'react'
import {PATH_STRINGS} from "../../../routes/route-props";
import klimalinkLogo from "../../../assets/logos/klima-link/klima_link.png";
import {useNavigate} from "react-router-dom";
import clsx from "clsx";
import './styles.css'
import {LogoProps} from 'atmosfair-ui';

const KlimaLinkLogo = ({
                           imageClasses,
                           size = "medium",
                       }: LogoProps) => {
    const navigate = useNavigate()
    return <img
        className={clsx(`klimalink-logo-${size}`, imageClasses && imageClasses)}
        onClick={() => navigate(PATH_STRINGS.travelDatabase)}
        src={klimalinkLogo}
        alt={"ALT.KLIMALINK_LOGO"}/>
}

export default KlimaLinkLogo