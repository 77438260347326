import {
    allCountryNames,
    allInternationalPhonePrefixesFormattedForDropdownAlpha2CountryCode
} from "../components/settings/utils";
import {COUNTRY_NAME_GERMANY, TOP_COUNTRY_NAMES, TOP_INTERNATIONAL_PHONE_PREFIXES} from "../constants";
import {
    convertCountriesFromDropdownFormat,
    convertCountriesToDropdownFormat,
    convertInternationalPhonePrefixesToDropdownFormat
} from "./dropdown-converter";

export const INTERNATIONAL_PHONE_PREFIX_CODES_FOR_DROPDOWN = convertInternationalPhonePrefixesToDropdownFormat(allInternationalPhonePrefixesFormattedForDropdownAlpha2CountryCode)

export const TOP_INTERNATIONAL_PHONE_PREFIX_CODES_ID: number[] = INTERNATIONAL_PHONE_PREFIX_CODES_FOR_DROPDOWN
    .filter(internationalPhonePrefix => TOP_INTERNATIONAL_PHONE_PREFIXES.includes(internationalPhonePrefix.label.slice(-3)))
    .map(result => result.value)

export const COUNTRY_NAMES_FOR_DROPDOWN = convertCountriesToDropdownFormat(allCountryNames)

export const defaultCountriesForTop = () => COUNTRY_NAMES_FOR_DROPDOWN
    .filter(country => TOP_COUNTRY_NAMES.includes(country.label))
    .map(result => result.value)

export const INITIAL_SELECTED_INTERNATIONAL_PHONE_PREFIX = "DE" as string

export const INITIAL_SELECTED_COUNTRY = convertCountriesFromDropdownFormat(
    convertCountriesToDropdownFormat(allCountryNames)
        .filter(country => country.label === COUNTRY_NAME_GERMANY)
)