import React, {CSSProperties, useEffect, useState} from 'react'
import {TextInput} from "atmosfair-ui";
import {validateEmail, validateFirstAndLastName} from "../utils";
import {RESET_FORM_STARTING_NUMBER, VALIDATE_FORM_STARTING_NUMBER} from "../../../constants";
import clsx from "clsx";
import {isEqual} from "lodash";
import usePrevious from "../../../hooks/usePrevious";

interface NameEmailFormProps {
    firstNameRequired: boolean,
    firstNameStyle?: CSSProperties,
    lastNameRequired: boolean,
    lastNameStyle?: CSSProperties,
    emailRequired: boolean,
    emailStyle?: CSSProperties,
    validateForm: number,
    onFormIsValid: (data: NameEmailProps) => void,
    onChangeFirstName?: (value: string) => void,
    onBlurFirstName?: (value: string) => void,
    onChangeLastName?: (value: string) => void,
    onBlurLastName?: (value: string) => void,
    onChangeEmail?: (value: string) => void,
    onBlurEmail?: (value: string) => void,
    placeholderFirstName?: string,
    labelFirstName?: string,
    placeholderLastName?: string,
    labelLastName?: string,
    placeholderEmail?: string,
    labelEmail?: string,
    showEmailField?: boolean,
    defaultValues?: NameEmailProps
    resetForm?: number
    containerStyle?: CSSProperties,
    containerClasses?: string | string[],
    useContainerDiv?: boolean,
}

export interface NameEmailProps {
    firstName: string,
    lastName: string,
    email: string,
}

interface NameEmailErrorProps {
    firstNameError: boolean,
    lastNameError: boolean,
    emailError: boolean,
}

const NameEmailForm = ({
                           firstNameRequired,
                           firstNameStyle = {flex: 1},
                           lastNameRequired,
                           lastNameStyle = {flex: 1},
                           emailRequired,
                           emailStyle = {flex: 1},
                           validateForm,
                           onFormIsValid,
                           showEmailField,
                           defaultValues,
                           resetForm,
                           containerStyle,
                           containerClasses,
                           useContainerDiv,
                           onChangeFirstName,
                           onBlurFirstName,
                           onChangeLastName,
                           onBlurLastName,
                           onChangeEmail,
                           onBlurEmail,
                           placeholderFirstName,
                           labelFirstName,
                           placeholderLastName,
                           labelLastName,
                           placeholderEmail,
                           labelEmail,
                       }: NameEmailFormProps) => {
    const [formData, setFormData] = useState<NameEmailProps>({
        firstName: defaultValues?.firstName ?? "",
        lastName: defaultValues?.lastName ?? "",
        email: defaultValues?.email ?? "",
    })
    const [formErrorData, setFormErrorData] = useState<NameEmailErrorProps>({
        firstNameError: false,
        lastNameError: false,
        emailError: false,
    })
    const {
        firstName,
        lastName,
        email
    } = formData
    const {
        firstNameError,
        lastNameError,
        emailError
    } = formErrorData
    const previousDefaultValues = usePrevious(defaultValues)

    useEffect(() => {
        if (defaultValues && !isEqual(defaultValues, previousDefaultValues)) {
            const {firstName, lastName, email} = defaultValues
            setFormData({firstName, lastName, email})
        }
    }, [defaultValues]);

    useEffect(() => {
        if (emailRequired && showEmailField === false) throw new Error("When you make Email required, you cannot set showEmailField to false")
    }, []);

    useEffect(() => {
        if (validateForm > VALIDATE_FORM_STARTING_NUMBER && _validateForm()) onFormIsValid({
            firstName: firstName.trim(), lastName: lastName.trim(), email: email.trim()
        })
    }, [validateForm]);

    useEffect(() => {
        if (resetForm && resetForm > RESET_FORM_STARTING_NUMBER) setFormData({
            firstName: "",
            lastName: "",
            email: "",
        })
    }, [resetForm])

    const _updateFormData = <K extends keyof NameEmailProps>(key: K, payload: NameEmailProps[K]) => setFormData({
        ...formData,
        [key]: payload
    })

    const _validateForm = () => {
        let validFirstName = true
        let validLastName = true
        let validEmail = true

        if (firstNameRequired) {
            validFirstName = validateFirstAndLastName(firstName)
        } else {
            if (firstName.length > 0) validFirstName = validateFirstAndLastName(firstName)
        }

        if (lastNameRequired) {
            validLastName = validateFirstAndLastName(lastName)
        } else {
            if (lastName.length > 0) validLastName = validateFirstAndLastName(lastName)
        }

        if (emailRequired) {
            if (typeof showEmailField === "boolean") {
                if (showEmailField) validEmail = validateEmail(email)
            } else {
                validEmail = validateEmail(email)
            }
        } else {
            if (email.length > 0) validEmail = validateEmail(email)
        }

        setFormErrorData({
            firstNameError: !validFirstName,
            lastNameError: !validLastName,
            emailError: !validEmail,
        })

        return [
            validFirstName,
            validLastName,
            validEmail
        ].every(Boolean)
    }

    const _renderEmailField = () => {
        const EmailTextInput = <TextInput
            value={email}
            ariaErrorMessage={"SCREENS.COMMON.ARIA_LABELS.EMAIL_ERROR"}
            labelColor={"primary"}
            error={emailError}
            containerStyle={emailStyle}
            onBlur={value => {
                onBlurEmail && onBlurEmail(value)
                _updateFormData("email", value)
            }}
            onChange={value => {
                onChangeEmail && onChangeEmail(value)
                _updateFormData("email", value)
            }}
            required={emailRequired}
            placeholder={placeholderEmail ?? "GENERAL.EMAIL"}
            label={labelEmail ?? "GENERAL.EMAIL"}/>


        if (typeof showEmailField === "boolean") {
            if (showEmailField) {
                return EmailTextInput
            } else {
                return null
            }
        } else {
            return EmailTextInput
        }
    }

    const _renderContent = () => <>
        <TextInput
            value={firstName}
            ariaErrorMessage={"SCREENS.COMMON.ARIA_LABELS.FIRST_NAME_ERROR"}
            labelColor={"primary"}
            error={firstNameError}
            containerStyle={firstNameStyle}
            onBlur={value => {
                onBlurFirstName && onBlurFirstName(value)
                _updateFormData("firstName", value)
            }}
            onChange={value => {
                onChangeFirstName && onChangeFirstName(value)
                _updateFormData("firstName", value)
            }}
            required={firstNameRequired}
            placeholder={placeholderFirstName ?? "GENERAL.FIRST_NAME"}
            label={labelFirstName ?? "GENERAL.FIRST_NAME"}/>
        <TextInput
            value={lastName}
            ariaErrorMessage={"SCREENS.COMMON.ARIA_LABELS.LAST_NAME_ERROR"}
            labelColor={"primary"}
            error={lastNameError}
            containerStyle={lastNameStyle}
            onBlur={value => {
                onBlurLastName && onBlurLastName(value)
                _updateFormData("lastName", value)
            }}
            onChange={value => {
                onChangeLastName && onChangeLastName(value)
                _updateFormData("lastName", value)
            }}
            required={lastNameRequired}
            placeholder={placeholderLastName ?? "GENERAL.LAST_NAME"}
            label={labelLastName ?? "GENERAL.LAST_NAME"}/>
        {_renderEmailField()}
    </>

    return useContainerDiv ? <div style={containerStyle} className={clsx('form-row', containerClasses)}>
        {_renderContent()}
    </div> : _renderContent()
}

export default NameEmailForm