import React from "react"
import {Spinner, Text} from "atmosfair-ui"
import "./styles.css"
import KlimaLinkLogo from "../logos/klima-link-logo/KlimaLinkLogo";
import clsx from "clsx";
import LogoutButton from "../logout-button/LogoutButton";

interface FullscreenSpinnerProps {
    showLogo?: boolean,
    transparent?: boolean,
    message?: string
}

const FullscreenSpinner = ({
                               showLogo,
                               transparent,
                               message,
                           }: FullscreenSpinnerProps) => {
    return <div className={clsx("fullscreen-spinner-container", transparent && 'fullscreen-spinner-container-opacity')}>
        <Spinner fullPage={false}/>
        <LogoutButton/>
        {message && <Text variant={"headlineMedium"}>{message}</Text>}
        {showLogo && <KlimaLinkLogo size={"medium"}/>}
    </div>
}

export default FullscreenSpinner