import React, {CSSProperties} from "react";
import {BaseTextProps, Text} from "atmosfair-ui"

interface ErrorTextProps {
    classes?: string,
    children: BaseTextProps['children'],
    style?: CSSProperties
}

const ErrorText = ({
                       classes,
                       children,
                       style,
                   }: ErrorTextProps) => <Text
    classes={classes}
    // style={style}
    weight={'bold'}
    color={"warningMain"}>
    {children}
</Text>

export default ErrorText