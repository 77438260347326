import React from "react"
import {Spinner} from "atmosfair-ui";
import "./styles.css"
import LogoutButton from "../../molecules/logout-button/LogoutButton";

const AuthenticatingUser = () => <div className={"authenticating-user-container"}>
    <LogoutButton/>
    <h1 style={{color: "white"}}>SCREENS.AUTHENTIATING_USER.TEXT</h1>
    <Spinner/>
</div>


export default AuthenticatingUser