import {ActivityCommonProperties} from "../types-enums/activity-common-properties";
import {getActivities, getActivitiesByDay} from "./activities-getter";
import {ACTIVITY_TYPE, AllActivityTypesArrayDiscriminating, TravelOptionToActivitiesMap} from "../types-enums/activity";


export const getCo2EForJourney = (travelOptions: TravelOptionToActivitiesMap) => {
    let co2E = 0;
    getActivities(travelOptions, activities => {
        activities.forEach(activity => {
            if (activity.common.activityType === ACTIVITY_TYPE.flight) {
                if (activity.common.totalEmissions && activity.common.totalEmissions.emissionKgCO2eTotal) co2E += activity.common.totalEmissions.emissionKgCO2eTotal;
            }
        });
    });
    return co2E
}

export const getCo2EForDayOfJourney = (travelOptions: TravelOptionToActivitiesMap, day: ActivityCommonProperties["common"]["day"]) => {
    let co2E = 0;
    getActivities(travelOptions, activities => {
        const activitiesByDay = getActivitiesByDay(activities, day)
        activitiesByDay.forEach(activity => {
            if (activity.common.activityType === ACTIVITY_TYPE.flight) {
                if (activity.common.totalEmissions && activity.common.totalEmissions.emissionKgCO2eTotal) co2E += activity.common.totalEmissions.emissionKgCO2eTotal;
            }
        })
    })
    return co2E
}

export const getCo2EForActivityTypeOfJourney = (travelOptions: TravelOptionToActivitiesMap, activityType: ACTIVITY_TYPE) => {
    let co2E = 0;
    getActivities(travelOptions, activities => {
        activities
            .filter(activity => activity.common.activityType === activityType)
            .forEach(activity => {
                if (activity.common.activityType === ACTIVITY_TYPE.flight) {
                    if (activity.common.totalEmissions && activity.common.totalEmissions.emissionKgCO2eTotal) co2E += activity.common.totalEmissions.emissionKgCO2eTotal;
                }
            })
    })
    return co2E
}

export const getCo2EForActivityTypeOfDay = (travelOptions: TravelOptionToActivitiesMap, activityType: ACTIVITY_TYPE, day: ActivityCommonProperties["common"]["day"]) => {
    let co2E = 0;
    getActivities(travelOptions, activities => {
        const activitiesByDay = getActivitiesByDay(activities, day)
        activitiesByDay
            .filter(activity => activity.common.activityType === activityType)
            .forEach(activity => {
                if (activity.common.activityType === ACTIVITY_TYPE.flight) {
                    if (activity.common.totalEmissions && activity.common.totalEmissions.emissionKgCO2eTotal) co2E += activity.common.totalEmissions.emissionKgCO2eTotal;
                }
            })
    })
    return co2E
}

export const getCo2KgEForActivityType = (activities: AllActivityTypesArrayDiscriminating): Record<ACTIVITY_TYPE, number> => {
    const emissions: Record<ACTIVITY_TYPE, number> = {} as Record<ACTIVITY_TYPE, number>;
    activities.forEach(activity => {
        const {common} = activity
        const {totalEmissions, activityType} = common
        if (totalEmissions) {
            const {emissionKgCO2eTotal} = totalEmissions
            if (emissionKgCO2eTotal) {
                if (!emissions[activityType]) {
                    emissions[activityType] = emissionKgCO2eTotal
                } else {
                    emissions[activityType] += emissionKgCO2eTotal
                }
            }
        }
    });
    return emissions;
}

export const getTotalCo2KgEForAllActivities = (activities: AllActivityTypesArrayDiscriminating) => {
    let totalEmissionsCo2KgE = 0
    const emissionsPerActivityType = getCo2KgEForActivityType(activities);
    (Object.keys(emissionsPerActivityType) as Array<keyof typeof ACTIVITY_TYPE>).forEach(activityType => {
        totalEmissionsCo2KgE += emissionsPerActivityType[activityType]
    })
    return totalEmissionsCo2KgE
}