import {ActivityFlightState} from "../types-enums/activity-flight";
import {ActivityCommonProperties} from "../types-enums/activity-common-properties";
import {AirlineResponse, AirportResponse} from "../api/model/activity-flight";
import {
    CalculatedFlightEmissionsResponseError,
    CalculatedHotelEmissionsResponseError,
    HotelCalculatedEmissionsResponsePartial
} from "../api/model/emissions";
import {GeneralBackendResponse, NonCalculationResponseError} from "../api/model/common";
import {ActivityHotelState} from "../types-enums/activity-hotel";
import {CityResponse, CountryResponse, HotelResponse} from "../api/model/activity-hotel";
import {Customer, CustomerCompany} from "../api/model/customer";

export const isActivityFlightState = (item: any): item is ActivityFlightState =>
    typeof item === 'object' &&
    item !== null &&
    'legs' in item &&
    'passengerCount' in item &&
    'returnFlight' in item

export const isActivityHotelState = (item: any): item is ActivityHotelState =>
    typeof item === 'object' &&
    item !== null &&
    'city' in item &&
    'country' in item &&
    'hotel' in item &&
    'startDate' in item &&
    'endDate' in item &&
    'numberOfPeople' in item

export const isCommonActivity = (item: any): item is ActivityCommonProperties => typeof item === 'object' &&
    item !== null &&
    'common' in item &&
    item.common !== null &&
    typeof item.common === 'object' &&
    'editIndex' in item.common &&
    'activityType' in item.common &&
    'travelOptionType' in item.common &&
    'calculatedEmissions' in item.common

export const isHotelCalculatedEmissionsResponsePartial = (item: any): item is HotelCalculatedEmissionsResponsePartial => typeof item === 'object' &&
    item !== null &&
    "scope" in item

export const isAirlineResponse = (item: any): item is AirlineResponse => typeof item === 'object' &&
    item !== null &&
    "name" in item &&
    "id" in item &&
    "iata" in item


export const isAirportResponse = (item: any): item is AirportResponse => typeof item === 'object' &&
    item !== null &&
    "name" in item &&
    "id" in item &&
    "iata" in item &&
    "country" in item

export const isCalculatedFlightEmissionsErrorResponse = (item: any): item is CalculatedFlightEmissionsResponseError => typeof item === 'object' &&
    item !== null &&
    item.data !== null &&
    typeof item.data === 'object' &&
    'flightActivities' in item?.data &&
    'status' in item?.data &&
    'emissionKgCO2eTotal' in item?.data &&
    'errorCodes' in item?.data

export const isCalculatedHotelEmissionsErrorResponse = (item: any): item is CalculatedHotelEmissionsResponseError => typeof item === 'object' &&
    item !== null &&
    item.data !== null &&
    typeof item.data === 'object' &&
    'hotelActivities' in item?.data &&
    'status' in item?.data &&
    'emissionKgCO2eTotal' in item?.data &&
    'errorCodes' in item?.data

export const isNonCalculationErrorResponse = (item: any): item is NonCalculationResponseError =>
    typeof item === 'object' &&
    item !== null &&
    'status' in item &&
    'data' in item &&
    item.data !== null &&
    typeof item.data === 'object' &&
    'errorCode' in item.data &&
    'message' in item.data &&
    'problematicValues' in item.data &&
    'type' in item.data;

export const isHotelResponse = (item: any): item is HotelResponse => typeof item === 'object' &&
    item !== null &&
    "name" in item &&
    "id" in item &&
    "city" in item &&
    "alpha2" in item &&
    "alpha3" in item &&
    "countryName" in item &&
    "stars" in item

export const isCityResponse = (item: any): item is CityResponse => typeof item === 'object' &&
    item !== null &&
    "name" in item &&
    "id" in item &&
    "alpha2" in item &&
    "alpha3" in item &&
    "countryName" in item &&
    !("stars" in item);

export const isCountryResponse = (item: any): item is CountryResponse => typeof item === 'object' &&
    item !== null &&
    "name" in item &&
    "id" in item &&
    "alpha2" in item &&
    "alpha3" in item &&
    !("countryName" in item)

export const isCustomerCompany = (item: any): item is CustomerCompany => typeof item === 'object' &&
    item !== null &&
    'id' in item &&
    'name' in item

export const isCustomer = (item: any): item is Customer => typeof item === 'object' &&
    item !== null &&
    'firstName' in item &&
    'lastName' in item &&
    'company' in item &&
    'email' in item &&
    'street' in item &&
    'houseNumber' in item &&
    'city' in item &&
    'zipCode' in item &&
    'phoneNumber' in item

export const isFormCustomer = (item: any): item is Customer => typeof item === 'object' &&
    item !== null &&
    'firstName' in item &&
    'lastName' in item &&
    'company' in item &&
    'email' in item

export const isEmptyHotelResponse = (item: any): item is HotelResponse => isHotelResponse(item) &&
    item.name === '' &&
    item.city === '' &&
    item.region === null &&
    item.alpha2 === '' &&
    item.alpha3 === '' &&
    item.countryName === '' &&
    item.stars === ''

export const isEmptyCityResponse = (item: any): item is CityResponse => isCityResponse(item) &&
    item.name === '' &&
    item.region === null &&
    item.alpha2 === '' &&
    item.alpha3 === '' &&
    item.countryName === ''

export const isGeneralBackendResponseWithErrorCode = (item: any): item is GeneralBackendResponse => typeof item === 'object' &&
    item !== null &&
    "status" in item &&
    "errorCode" in item &&
    "type" in item

export const isDefined = <T>(value: T | undefined): value is T => value !== undefined;