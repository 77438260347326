import React from "react"
import "./styles.css"
import {ActivityOptionsProps} from "../../constants";
import ActivityCard from "../../molecules/activity/activity-card/ActivityCard";
import {ACTIVITY_TYPE} from "../../types-enums/activity";

interface SelectActivityProps {
    activities: ActivityOptionsProps[]
    onSelect: (activityType: ACTIVITY_TYPE) => void,
}

const SelectActivity = ({
                            activities,
                            onSelect,
                        }: SelectActivityProps) => <div className={"select-activity-card-wrapper"}>
    {activities.map((activityOption, index) => {
        const {title, activityType} = activityOption
        return <ActivityCard
            onClick={() => onSelect(activityType)}
            key={index}
            title={title}/>
    })
    }
</div>

export default SelectActivity