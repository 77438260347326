import React from 'react'
import {AgencyProps} from "../../../api/model/agency";
import {Text} from "atmosfair-ui"
import UploadLogo from "../../../container/upload-logo/UploadLogo";
import "./styles.css"
import clsx from "clsx";
import SectionTitle from "../section-title/SectionTitle";

interface AgenciesLogosProps {
    agencies: AgencyProps[]
}

const AgenciesLogos = ({
                           agencies,
                       }: AgenciesLogosProps) => {
    return <section
        className={clsx("agencies-logo-container", agencies.length === 0 && 'agencies-logo-container-no-row-gap')}>
        <SectionTitle
            title={`SCREENS.SETTINGS.ADD_LOGO${agencies.length > 1 ? "S" : ""}`}/>
        {agencies.map((agency, index) => {
            const {name, id} = agency
            return <div key={index}>
                <Text variant={"headlineSmall"}>{name}</Text>
                <UploadLogo agencyId={id}/>
            </div>
        })
        }
        {agencies.length === 0 && <Text>SCREENS.SETTINGS.NO_AGENCY_HINT_ADD_LOGO</Text>}
    </section>

}

export default AgenciesLogos