import React, {useEffect, useState} from 'react'
import {CheckboxInput, Text, TextButton} from "atmosfair-ui"
import "./styles.css"
import {Navigate, useLocation, useNavigate} from "react-router-dom";
import {PATH_STRINGS} from "../../../routes/route-props";
import {apiSlice, useUpdateUserInfoSelfMutation} from "../../../redux/reducer/api";
import FullscreenSpinner from "../../../molecules/fullscreen-spinner/FullscreenSpinner";
import ErrorPage from "../../functional/error-page/ErrorPage";
import {setUserData} from "../../../redux/reducer/user";
import {useAppDispatch} from '../../../redux/reducer/store';
import {GENERAL_BACKEND_RESPONSE} from "../../../api/model/common";
import {useTranslation} from "react-i18next";
import LogoutButton from "../../../molecules/logout-button/LogoutButton";
import {theme} from "../../../styles/theme";

const TermsAndConditionsAcceptAndPdf = () => {
    const dispatch = useAppDispatch()
    const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] = useState(false)
    const [termsAndConditionsAcceptedSuccess, setTermsAndConditionsAcceptedSuccess] = useState(false)
    const {t} = useTranslation()
    const location = useLocation();
    const navigate = useNavigate()
    let showAcceptButton = false
    if (location && location.state && location.state.showAcceptButton) showAcceptButton = location.state.showAcceptButton
    const title = showAcceptButton ? t("SCREENS.TERMS_AND_CONDITIONS.COMPANY_ADMIN.TITLE_WITH_ACCEPT") : t("SCREENS.TERMS_AND_CONDITIONS.COMPANY_ADMIN.TITLE_WITHOUT_ACCEPT")
    const [triggerGetUserInfo, {
        data: userInfo,
        isLoading: isLoadingUserInfo,
        error: userInfoError
    }] = apiSlice.endpoints?.getUserInfo.useLazyQuery()

    const [updateUserInfo, {
        data: updateUserInfoResponse,
        isLoading: isLoadingUpdateUserInfo,
        error: isErrorUpdateUserInfo
    }] = useUpdateUserInfoSelfMutation()

    useEffect(() => {
        !showAcceptButton && triggerGetUserInfo()
    }, []);

    useEffect(() => {
        if (updateUserInfoResponse?.status === GENERAL_BACKEND_RESPONSE.success) setTermsAndConditionsAcceptedSuccess(true)
    }, [updateUserInfoResponse]);

    const _onClick = () => {
        try {
            updateUserInfo({termsAndConditionsAccepted: true})
            userInfo && dispatch(setUserData({
                ...userInfo,
                termsAndConditionsAccepted: true
            }))
        } catch (error) {
            console.error(`Something went wrong when accepting terms and conditions: ${error}`);
        }
    }

    if (userInfoError) return <ErrorPage/>
    if (isErrorUpdateUserInfo) return <ErrorPage
        additionalText={"SCREENS.TERMS_AND_CONDITIONS.COMPANY_ADMIN.UPDATE_USER_INFO_ERROR"}/>
    if (isLoadingUserInfo || isLoadingUpdateUserInfo) return <FullscreenSpinner showLogo={true}/>
    if ((userInfo?.termsAndConditionsAccepted || termsAndConditionsAcceptedSuccess) && showAcceptButton) return <Navigate
        to={PATH_STRINGS.travelDatabase}/>
    return <section className={"terms-and-conditions-accept-and-pdf-container"}>
        <LogoutButton/>
        <Text
            style={{marginBottom: theme.spacer.large}}
            variant={"headlineLarge"}>{title}</Text>
        <div className={'terms-and-conditions-accept-and-pdf-terms-text'}>
            <iframe
                width="100%"
                height="100%"
                title={title}
                src={"https://b2b.dev.klimalink.org/terms_and_conditions.pdf"}/>
        </div>
        {showAcceptButton && <>
            <div className={"terms-and-conditions-accept-and-pdf-checkbox-wrapper"}>
                <CheckboxInput
                    hideLabel={true}
                    label={'SCREENS.TERMS_AND_CONDITIONS.COMPANY_ADMIN.CHECKBOX_LABEL'}
                    text={"SCREENS.TERMS_AND_CONDITIONS.COMPANY_ADMIN.CHECKBOX_LABEL"}
                    checked={termsAndConditionsAccepted}
                    onChange={() => setTermsAndConditionsAccepted(!termsAndConditionsAccepted)}/>
            </div>
            <TextButton
                disabled={!termsAndConditionsAccepted}
                onClick={_onClick}>
                GENERAL.PROCEED
            </TextButton>
        </>
        }
        {
            !showAcceptButton &&
            <TextButton
                classes={'terms-and-conditions-accept-and-pdf-abort-button'}
                variant={'abort'}
                onClick={() => navigate(-1)}>
                {t('GENERAL.BACK')}
            </TextButton>
        }
    </section>
}

export default TermsAndConditionsAcceptAndPdf